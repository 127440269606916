import React from "react";
import {strings} from "config/strings";
import styled from "styled-components";
import Spaceman from "icons/spaceman.svg";

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
`;

export const Icon = styled.img`
  margin-bottom: 10px;
`;

export const Title = styled.span`
  font-size: 16px;
  color: #fff;
`;

export const Subtitle = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: #a39ea9;
`;

const VideoErrorContainer = () => (
  <ErrorWrapper>
    <Icon src={Spaceman} />
    <Title>{strings.video.displayTitleError}</Title>
    <Subtitle>{strings.video.displaySubtitleError}</Subtitle>
  </ErrorWrapper>
);

export default VideoErrorContainer;
