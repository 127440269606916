import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {Auth} from "aws-amplify";
import {SubmitHandler, useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

import {strings} from "config/strings";
import {Routes} from "config/routes";
import {getSignUpValidators} from "utils/validators/auth";
import {
  FieldValidationMessage,
  ResponseMessage,
  ResponseMessageTypes
} from "components/StyledElements/FormElements";
import {Checkbox} from "components/Checkbox";
import {Input} from "components/Input";
import {Spinner} from "components/Spinner";
import {formStyles} from "config/styleVars";
import {
  AuthWrapper,
  AuthHeading,
  AuthButton,
  FooterText
} from "components/Auth/AuthElements";
import {authErrorTranslate} from "components/Auth/AuthUtils";

interface IFormValues {
  username: string;
  password: string;
  age: boolean;
}

const SignUpForm: React.FunctionComponent = () => {
  const history = useHistory();
  const [formError, setFormError] = useState<string | undefined>();
  const {
    register,
    handleSubmit,
    control,
    formState: {errors, isSubmitting}
  } = useForm<IFormValues>({
    resolver: yupResolver(getSignUpValidators())
  });

  const onSubmit: SubmitHandler<IFormValues> = async ({username, password}) => {
    if (window.analytics) {
      window.analytics.track("Signed Up", {
        type: "organic",
        "over-13": "true",
        username
      });
    }
    try {
      await Auth.signUp({
        username,
        password
      });
      const verifyUrl: string = Routes.verifyEmail.replace(":email", username);
      history.push(verifyUrl);
    } catch (error: any) {
      setFormError(authErrorTranslate(error.message));
    }
  };

  return (
    <AuthWrapper isLoading={isSubmitting}>
      {isSubmitting && <Spinner />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <AuthHeading>{strings.auth.signUp}</AuthHeading>

        {formError && (
          <ResponseMessage
            message={formError}
            type={ResponseMessageTypes.Error}
          />
        )}

        <div style={{marginBottom: formStyles.marginBottom}}>
          <Input
            {...register("username")}
            invalid={!!errors.username}
            label="E-mail"
            type="text"
          />
          {errors.username && (
            <FieldValidationMessage>
              {errors.username.message}
            </FieldValidationMessage>
          )}
        </div>

        <div style={{marginBottom: formStyles.marginBottom}}>
          <Input
            {...register("password")}
            invalid={!!errors.password}
            label="Password"
            type="password"
          />
          {errors.password && (
            <FieldValidationMessage>
              {errors.password.message}
            </FieldValidationMessage>
          )}
        </div>

        <div style={{marginBottom: formStyles.marginBottom}}>
          <Controller
            control={control}
            defaultValue={false}
            name="age"
            render={({field}) => (
              <Checkbox
                {...field}
                invalid={!!errors.age}
                label="I am 13 years old or older."
                value={true}
              />
            )}
          />
        </div>

        <AuthButton disabled={isSubmitting} type="submit">
          Sign Up
        </AuthButton>

        <FooterText>
          {strings.auth.alreadyRegistered}{" "}
          <Link to={Routes.signin}>{strings.auth.signIn}</Link>
        </FooterText>
      </form>
    </AuthWrapper>
  );
};

export default SignUpForm;
