import moment from "moment";

export const setMomentRelativeTimeSetting = () => {
  moment.relativeTimeThreshold("s", 60);
  moment.relativeTimeThreshold("m", 60);
  moment.relativeTimeThreshold("h", 24);
  moment.relativeTimeThreshold("d", 30);
  moment.relativeTimeThreshold("M", 12);
  moment.defineLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "1m",
      ss: "1m",
      m: "%dm",
      mm: "%dm",
      h: "%dh",
      hh: "%dh",
      d: "%dd",
      dd: "%dd",
      M: "%dmo",
      MM: "%dmo",
      y: "%dy",
      yy: "%dY"
    }
  });
};
