import styled from "styled-components";
import DropArea from "components/Atoms/DropArea";
import Text from "components/Atoms/Text";
import {colors} from "theme/colors";

export const DropAreaStyled = styled(DropArea)`
  margin-top: 28px;
  margin-bottom: 12px;
`;

export const ErrorText = styled(Text)`
  color: ${colors.secondary.error};
`;
