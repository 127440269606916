import {useEffect, useState} from "react";
import {generateVideoThumbnail} from "utils/VideoFileUtils";
import {CLIPPING_TOOL_IMAGE_SIZE} from "config/constants";

import {ClippingToolItemImageProps, StoryboardSource} from "./types";

export function useClippingToolItemImage({
  itemProps,
  isVideoCodecSupported,
  uploadedVideoStoryboard,
  videoFile,
  videoInfo
}: ClippingToolItemImageProps) {
  const [source, setSource] = useState<string>();
  const [storyboardSource, setStoryboardSource] = useState<StoryboardSource>();

  useEffect(() => {
    if (!isVideoCodecSupported) {
      return;
    }

    generateVideoThumbnail({
      start: itemProps.start,
      size: CLIPPING_TOOL_IMAGE_SIZE,
      videoFile,
      videoInfo
    }).then((imageSource) => {
      setSource(imageSource);
    });
  }, [itemProps, isVideoCodecSupported, videoFile, videoInfo]);

  useEffect(() => {
    if (!uploadedVideoStoryboard || !uploadedVideoStoryboard.tiles?.length) {
      return;
    }

    let timeDifference = 100;
    let storyboardTileIndex = 0;
    for (let i = 0; i < uploadedVideoStoryboard.tiles.length; i++) {
      const difference = Math.abs(
        uploadedVideoStoryboard.tiles[i]?.start! - itemProps.start
      );
      if (difference < timeDifference) {
        timeDifference = difference;
        storyboardTileIndex = i;
      } else {
        break;
      }
    }

    const smallerSide = Math.min(
      uploadedVideoStoryboard.tile_height!,
      uploadedVideoStoryboard.tile_width!
    );
    const scale = CLIPPING_TOOL_IMAGE_SIZE / smallerSide;
    const widthOffset =
      (uploadedVideoStoryboard.tile_width! -
        uploadedVideoStoryboard.tile_width! * scale) /
      -2;
    const heightOffset =
      (uploadedVideoStoryboard.tile_height! -
        uploadedVideoStoryboard.tile_height! * scale) /
      -2;

    setStoryboardSource({
      scale,
      widthOffset,
      heightOffset,
      tileWidth: uploadedVideoStoryboard.tile_width!,
      tileHeight: uploadedVideoStoryboard.tile_height!,
      url: uploadedVideoStoryboard.url!,
      x: uploadedVideoStoryboard.tiles[storyboardTileIndex]?.x!,
      y: uploadedVideoStoryboard.tiles[storyboardTileIndex]?.y!
    });
  }, [itemProps, uploadedVideoStoryboard]);

  return {source, storyboardSource};
}
