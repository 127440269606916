import {useEffect, useMemo, useState} from "react";
import {numberWithCommas, roundTwoDigitsAfterComa} from "utils/FormatUtils";
import {BlockchainNetworks, MintTypes} from "pages/Web3Upload/types";
import {strings} from "config/strings";
import {ConfirmMintAndListProps} from "./types";

export const CURRENCY = "usd";

export function useConfirmMintAndList({
  blockchain,
  tierPrice,
  tokensAvailable,
  value
}: ConfirmMintAndListProps) {
  const [totalRevenueInCrypto, setTotalRevenueInCrypto] = useState<number>(0);
  const [isLowBalance, setIsLowBalance] = useState<boolean>(false);
  const [lazyMintGasFee] = useState<number>(15);
  const [upfrontMintGasFee] = useState<number>(45);

  /**
   * crypto balance fee will be calculated somehow
   */
  const [cryptoBalance] = useState<number>(0);

  const totalRevenue = useMemo(() => {
    if (!tierPrice?.length || !tokensAvailable?.length) {
      return 0;
    }

    let total = 0;
    for (let i = 0; i < tierPrice.length; i++) {
      const price = tierPrice[i].value;
      const amount = tokensAvailable[i].value;
      if (price && amount) {
        total += price * amount;
      }
    }

    return roundTwoDigitsAfterComa(total);
  }, [tierPrice, tokensAvailable]);

  /**
   * convert USD in crypto here
   */
  const rate = useMemo(() => {
    return blockchain === BlockchainNetworks.Ethereum ? 1276.11 : 1.09;
  }, [blockchain]);

  useEffect(() => {
    setTotalRevenueInCrypto(roundTwoDigitsAfterComa(totalRevenue / rate));
  }, [blockchain, rate, totalRevenue]);

  useEffect(() => {
    const gasFee =
      value === MintTypes.Lazy ? lazyMintGasFee : upfrontMintGasFee;
    setIsLowBalance(cryptoBalance < gasFee);
  }, [cryptoBalance, lazyMintGasFee, upfrontMintGasFee, value]);

  const radioButtonsOptions = useMemo(() => {
    return [
      {
        info: strings.pages.upload.modals.confirmMintAndList.lazyMintInfo,
        label: strings.pages.upload.modals.confirmMintAndList.lazyMint(
          `${strings.generic.currencies[CURRENCY].sign}${numberWithCommas(
            roundTwoDigitsAfterComa(lazyMintGasFee)
          )}`
        ),
        value: MintTypes.Lazy
      },
      {
        info: strings.pages.upload.modals.confirmMintAndList.upfrontMintInfo,
        label: strings.pages.upload.modals.confirmMintAndList.upfrontMint(
          `${strings.generic.currencies[CURRENCY].sign}${numberWithCommas(
            roundTwoDigitsAfterComa(upfrontMintGasFee)
          )}`
        ),
        value: MintTypes.Upfront
      }
    ];
  }, [lazyMintGasFee, upfrontMintGasFee]);

  return {
    isLowBalance,
    radioButtonsOptions,
    totalRevenue,
    totalRevenueInCrypto
  };
}
