import styled from "styled-components";
import {colors} from "theme/colors";
import Text from "components/Atoms/Text";
import InfoDialog from "../../Atoms/InfoDialog";

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 16px 0;
`;

export const ItemContainer = styled.div`
  display: flex;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const IconContainer = styled.div<{disabled?: boolean}>`
  margin-right: 12px;
  opacity: ${({disabled}) => (disabled ? 0.4 : 1)};
`;

export const Title = styled(Text)<{disabled?: boolean}>`
  > span {
    color: ${colors.primary.cyan};
  }
  opacity: ${({disabled}) => (disabled ? 0.4 : 1)};
`;

export const StyledDescription = styled(Text)<{disabled?: boolean}>`
  color: ${({disabled}) =>
    disabled ? colors.primary.lightGrey40 : colors.primary.lightGrey};
  display: block;
`;

export const ChildrenContainer = styled.div`
  align-self: center;
`;

export const StyledInfoDialog = styled(InfoDialog)`
  margin-bottom: -4px;
  margin-left: 4px;
`;
