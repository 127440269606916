import React from "react";
import {ReactComponent as HeartIcon} from "icons/24/heart.svg";
import {ReactComponent as HeartLikeIcon} from "icons/24/heart-liked.svg";
import styled from "styled-components";
import {useCountFormat} from "hooks/useCountFormat";

interface LikeButtonProps {
  onClick: () => void;
  liked?: boolean;
  likeCount?: number;
  isDisabled?: boolean;
}

const LikeContent = styled.p`
  margin-top: 0;
  font-size: 11px;
  text-align: center;
`;

type WrapperProps = {
  isDisabled?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  cursor: ${(props) => (props.isDisabled ? "wait" : "pointer")};
  pointer-events: ${(props) => (props.isDisabled ? "none" : "default")};
`;

export const LikeButton: React.FC<LikeButtonProps> = ({
  onClick,
  liked,
  likeCount,
  isDisabled
}: LikeButtonProps) => {
  return (
    <Wrapper isDisabled={isDisabled}>
      {liked ? (
        <HeartLikeIcon onClick={onClick} />
      ) : (
        <HeartIcon onClick={onClick} />
      )}
      <LikeContent>{useCountFormat(likeCount)}</LikeContent>
    </Wrapper>
  );
};
