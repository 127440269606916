import {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import ArrowUp from "icons/24/arrow-up.svg";
import ArrowUpHover from "icons/24/arrow-up-black.svg";
import ArrowDown from "icons/24/arrow-down.svg";
import ArrowDownHover from "icons/24/arrow-down-black.svg";
import {DisplayMode} from "apollo/reactive";
import {FeedVideo} from "apollo";
import {Direction} from "./Player";

const Container = styled.div<ContainerProps>`
  position: absolute;
  top: 40%;
  transition: right 150ms ease;
  right: ${(props: ContainerProps) =>
    props.displayState !== DisplayMode.fullscreen || props.show || props.hover
      ? "70px"
      : "-70px"};
  z-index: 1;
`;

const ArrowUpButton = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  top: 0px;
  cursor: pointer;
  width: 36px;
  height: 36px;

  img.hover {
    display: none;
  }

  &.active,
  &:hover {
    background: rgba(255, 255, 255, 0.8);
    transition: background 100ms ease;

    img.hover {
      display: block;
    }
  }
`;

const ArrowDownButton = styled(ArrowUpButton)`
  top: 50px;
`;

const Icon = styled.img`
  position: absolute;
  left: 16.66%;
  top: 16.66%;
`;

type ArrowsControlProp = {
  currentVideo: number | null;
  videos: FeedVideo[] | undefined;
  show: boolean;
  hover: boolean;
  displayState: DisplayMode;
  onVideoChange: (videoIndex: number) => void;
  onMouseOver: () => void;
  onMouseOut: () => void;
  onChangeCurrentVideo: (action: Direction) => void;
};

type ContainerProps = {
  show: boolean;
  hover: boolean;
  displayState: DisplayMode;
};

const ArrowsControl = ({
  currentVideo,
  show,
  hover,
  displayState,
  videos,
  ...props
}: ArrowsControlProp) => {
  const prevVideo = useRef<number | null>();
  const [goingUp, setGoingUp] = useState(false);
  const [goingDown, setGoingDown] = useState(false);

  useEffect(() => {
    if (currentVideo !== null && prevVideo && prevVideo.current) {
      if (currentVideo < prevVideo.current) {
        setGoingDown(true);
      } else if (currentVideo > prevVideo.current) {
        setGoingUp(true);
      }
    }
    prevVideo.current = currentVideo;
  }, [currentVideo]);

  useEffect(() => {
    setTimeout(() => {
      setGoingUp(false);
      setGoingDown(false);
    }, 100);
  }, [goingUp, goingDown]);

  const {onMouseOut, onMouseOver} = props;

  return (
    <Container
      displayState={displayState}
      hover={hover}
      show={show}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
    >
      <ArrowUpButton
        className={goingUp ? "active" : ""}
        onClick={() => props.onChangeCurrentVideo(Direction.Up)}
      >
        <Icon src={ArrowUp} />
        <Icon className="hover" src={ArrowUpHover} />
      </ArrowUpButton>
      <ArrowDownButton
        className={goingDown ? "active" : ""}
        onClick={() => props.onChangeCurrentVideo(Direction.Down)}
      >
        <Icon src={ArrowDown} />
        <Icon className="hover" src={ArrowDownHover} />
      </ArrowDownButton>
    </Container>
  );
};

export default ArrowsControl;
