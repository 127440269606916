import React, {useEffect, useState} from "react";

const useTabAnimation = (
  tabsHolder: React.RefObject<HTMLDivElement>,
  activeTabIndex: number,
  padding = 0
) => {
  const [underlineStyle, setUnderlineStyle] = useState<React.CSSProperties>({});
  const [activeStyle, setActiveStyle] = useState<React.CSSProperties>({});

  useEffect(() => {
    if (
      tabsHolder.current &&
      tabsHolder.current.children.length - 1 > activeTabIndex
    ) {
      if (activeTabIndex < 0) {
        const defaultStyle = {width: "0px", left: "0px"};
        setActiveStyle(defaultStyle);
        setUnderlineStyle(defaultStyle);
        return;
      }

      const activeElement = tabsHolder.current.children[
        activeTabIndex
      ] as HTMLDivElement;
      // @ts-ignore
      const style = {
        width: `${activeElement.offsetWidth - padding}px`,
        left: `${activeElement.offsetLeft}px`
      };
      setActiveStyle(style);
      setUnderlineStyle(style);
    }
  }, [activeTabIndex, tabsHolder.current]);

  const handleMouseEnter = (
    event: React.BaseSyntheticEvent<HTMLDivElement | HTMLAnchorElement> &
      React.MouseEvent
  ) => {
    setUnderlineStyle({
      width: `${event.target.offsetWidth - padding}px`,
      left: `${event.target.offsetLeft}px`
    });
  };

  const handleMouseLeave = () => {
    setUnderlineStyle({...activeStyle});
  };

  return {underlineStyle, handleMouseEnter, handleMouseLeave};
};

export default useTabAnimation;
