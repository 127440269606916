import styled from "styled-components";
import Text from "components/Atoms/Text";

import {AvatarContainerProps, BorderType} from "./types";

export const RootContainer = styled.div<AvatarContainerProps>`
  ${(props) => {
    switch (props.borderType) {
      case BorderType.black:
        return "border: 3px solid black;";

      case BorderType.white:
        return "border: 3px solid white;";

      case BorderType.none:
        return "border: none;";

      default:
        return "border: none;";
    }
  }}
  border-radius: ${(props) => (props.size <= 30 ? "4px" : "8px")};
  background: black;
  display: flex;
  text-align: center;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const LettersContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
`;

export const StyledText = styled(Text)<{size: number}>`
  color: white;
  font-size: ${(props) => props.size * 0.9}px;
  line-height: ${(props) => props.size * 0.9}px;
  text-transform: uppercase;
`;
