import styled from "styled-components";
import {ReactComponent as InfoIcon} from "icons/universal/info.svg";

export const StyledInfoIcon = styled(InfoIcon)<{color: string}>`
  color: ${({color}) => color};
  width: 16px;
  height: 16px;
  cursor: pointer;
  transition: all 0.2s ease-out;
`;
