import React from "react";
import Draggable from "react-draggable";

import {ChevronLeftIcon, ChevronRightIcon, Container} from "./styles";
import {ScrubberSideBarProps} from "./types";
import {useScrubberSideBar} from "./hooks";

const ScrubberSideBar: React.FC<ScrubberSideBarProps> = (
  props: ScrubberSideBarProps
) => {
  const {className, isLeft} = props;
  const {bounds, defaultPosition, onDrag, position, positionOffset} =
    useScrubberSideBar(props);
  return (
    <Draggable
      axis="x"
      bounds={bounds}
      defaultPosition={defaultPosition}
      onDrag={onDrag}
      position={position}
      positionOffset={positionOffset}
    >
      <Container className={className} isLeft={isLeft}>
        {isLeft ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </Container>
    </Draggable>
  );
};

export default React.memo(ScrubberSideBar);
