import React, {useCallback} from "react";
import {strings} from "config/strings";
import FollowIcon from "icons/20/user-plus.svg";
import FollowIconWhite from "icons/20/user-plus-white.svg";
import {
  GetUserDocument,
  IsUserFollowedByMeDocument,
  useFollowUserMutation,
  useUnfollowUserMutation,
  useIsUserFollowedByMeQuery
} from "apollo";
import {useUser} from "hooks/useUser";
import {FollowButton, FollowIconImg} from "./styles";

type Props = {
  uuid: string;
  small?: boolean;
};

export const ProfileFollowButton: React.FC<Props> = ({uuid, small}: Props) => {
  const {user: currentUser} = useUser();
  const {data: isFollowingData, loading: isFollowingLoading} =
    useIsUserFollowedByMeQuery({
      variables: {
        uuid
      }
    });

  const isFollowedByMe = () => {
    if (isFollowingLoading) {
      return false;
    }
    return !!isFollowingData?.isUserFollowedByMe;
  };

  const [followUser] = useFollowUserMutation({
    errorPolicy: "ignore",
    refetchQueries: [GetUserDocument, IsUserFollowedByMeDocument]
  });

  const [unfollowUser] = useUnfollowUserMutation({
    errorPolicy: "ignore",
    refetchQueries: [GetUserDocument, IsUserFollowedByMeDocument]
  });

  const onFollowUser = useCallback(() => {
    if (isFollowedByMe()) {
      if (window.analytics) {
        window.analytics.track("Unfollow User", {
          loggedUserId: currentUser?.uuid,
          followingUserId: uuid
        });
      }
      unfollowUser({variables: {uuid}});
      return;
    }
    if (window.analytics) {
      window.analytics.track("Follow User", {
        loggedUserId: currentUser?.uuid,
        followingUserId: uuid
      });
    }
    followUser({variables: {uuid}});
  }, [isFollowedByMe(), followUser, uuid, unfollowUser]);

  return (
    <FollowButton
      small={!!small}
      variant={isFollowedByMe()}
      onClick={onFollowUser}
    >
      {!isFollowedByMe() && (
        <>
          <FollowIconImg
            alt="follow/unfollow"
            className="black"
            src={FollowIcon}
          />
          <FollowIconImg
            alt="follow/unfollow"
            className="white"
            src={FollowIconWhite}
          />
        </>
      )}
      {isFollowedByMe() ? strings.generic.following : strings.generic.follow}
    </FollowButton>
  );
};
