import React, {useCallback} from "react";
import {RadioButtonStyled, RootContainer} from "./styles";
import {RadioButtonGroupProps} from "./types";

const RadioButtonsGroup: React.FC<RadioButtonGroupProps> = ({
  className,
  onChange,
  options,
  value
}: RadioButtonGroupProps) => {
  const renderItems = useCallback(() => {
    return options.map((item, index) => {
      return (
        <RadioButtonStyled
          {...item}
          checked={item.value === value}
          marginTop={index !== 0 ? 4 : 0}
          onChange={onChange}
        />
      );
    });
  }, [onChange, options, value]);

  return <RootContainer className={className}>{renderItems()}</RootContainer>;
};

export default React.memo(RadioButtonsGroup);
