import {FontStyle, PossibleTextTypes} from "components/Atoms/Text/types";
import React from "react";

export enum InputBorderType {
  underlined,
  outlined
}

export enum InputIconSide {
  right,
  left,
  both
}

export interface StateProps {
  isDisabled?: boolean;
  isInvalid?: boolean;
  isSucceeded?: boolean;
}

export interface TextInputProps extends StateProps {
  borderType?: InputBorderType;
  className?: string;
  type?: HTMLInputElement["type"];
  helperText?: string;
  hideMaxCharCount?: boolean;
  id?: string;
  initialValue?: string;
  value?: string;
  label?: string;
  maxCharCount?: number;
  multiline?: boolean;
  name?: string;
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFocus?: () => void;
  onKeyDown?: (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onPaste?: (
    e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  placeholder?: string;
  renderIcon?: (
    color: string,
    side: InputIconSide.left | InputIconSide.right
  ) => JSX.Element | null;
  iconSide?: InputIconSide;
  showClearButton?: boolean;
  textType?: PossibleTextTypes;
}

export interface TextInputContainerProps extends StateProps {
  borderType?: InputBorderType;
  isFocused: boolean;
  backgroundColor?: string;
  label?: string;
  showRightSidePanel: boolean;
  borderColor?: string;
}

export interface InputProps extends FontStyle {
  disabled?: boolean;
  label?: string;
}
