import React from "react";
import {InstantSearch} from "react-instantsearch-hooks";
import {indexName, searchClient} from "utils/AlgoliaUtils";
import AlgoliaSearchBox from "components/Molecules/AlgoliaSearchBox";
import AlgoliaInfiniteHits from "components/Molecules/AlgoliaInfiniteHits";
import UserListItem from "components/Molecules/UserListItem";

import {ResultsContainer, RootContainer, SearchBoxContainer} from "./styles";

const Search: React.FC = () => {
  return (
    <InstantSearch indexName={indexName} searchClient={searchClient}>
      <RootContainer>
        <SearchBoxContainer>
          <AlgoliaSearchBox />
        </SearchBoxContainer>
        <ResultsContainer>
          <AlgoliaInfiniteHits hitComponent={UserListItem} />
        </ResultsContainer>
      </RootContainer>
    </InstantSearch>
  );
};

export default React.memo(Search);
