export enum Routes {
  home = "/",
  explore = "/explore",
  following = "/player/following/",
  forYou = "/player/foryou",
  signin = "/signin",
  signup = "/signup",
  verifyEmail = "/verify/:email",
  forgotPassword = "/forgotPassword",
  profile = "/user/:username",
  profileEdit = "/user/:username/edit",
  likes = "/likes",
  settings = "/settings/:uuid",
  settingsAccount = "/settings/account/:uuid",
  settingsPassword = "/settings/changepassword/:uuid",
  settingsHelp = "/settings/help",
  upload = "/upload",
  web3Upload = "/web3Upload",
  onboarding = "/user/onboarding",
  feedPlayer = "/player/feed/:videoId",
  likedPostPlayer = "/user/:username/likedpost/:videoId",
  PostedVideoPlayer = "/user/:username/postedvideo/:videoId",
  followingPlayer = "/player/following/:videoId?",
  forYouPlayer = "/player/foryou/:videoId?",
  mintNFTs = "/nft/mintnfts",
  marketplace = "/nft/marketplace",
  privacy = "https://www.everse.tv/privacy-policy",
  tos = "https://www.everse.tv/terms-of-service",
  search = "/search",
  videoDetails = "/video/:videoId"
}

export const getProfileUrl = (username: string) => {
  return Routes.profile.replace(":username", username);
};

export const getSettingsUrl = (url: Routes, uuid: string) => {
  return url.replace(":uuid", uuid);
};

export const getProfileEditUrl = (username: string) => {
  return Routes.profileEdit.replace(":username", username);
};

export const getFeedPlayerUrl = (videoId: string, time?: number) => {
  const url = Routes.feedPlayer.replace(":videoId", videoId);
  return time ? `${url}?time=${time}` : url;
};

export const getLikedPostsUrl = (
  username: string,
  videoId: string,
  position: number
) => {
  const url = Routes.likedPostPlayer
    .replace(":username", username)
    .replace(":videoId", videoId.toString());

  return `${url}?position=${position}`;
};

export const getPostedVideoUrl = (
  username: string,
  videoId: string,
  position: number
) => {
  const url = Routes.PostedVideoPlayer.replace(":username", username).replace(
    ":videoId",
    videoId.toString()
  );

  return `${url}?position=${position}`;
};

export const getFollowingPlayerUrl = (videoId?: string) => {
  return videoId
    ? Routes.followingPlayer.replace(":videoId?", videoId)
    : Routes.followingPlayer.replace(":videoId?", "");
};

export const getForYouPlayerUrl = (videoId?: string) => {
  return videoId
    ? Routes.forYouPlayer.replace(":videoId", videoId)
    : Routes.forYouPlayer.replace(":videoId?", "");
};

export const getVideoDetailsUrl = (videoId: string) => {
  return Routes.videoDetails.replace(":videoId", videoId);
};
