import {PROFILE_VIDEOS_PER_PAGE} from "config/constants";
import {useGetUserQuery} from "apollo";
import {useState, useCallback} from "react";
import {useParams} from "react-router-dom";

export const useIndexedVideoPlayer = () => {
  const {username, videoId} = useParams<{username: string; videoId: string}>();
  const [error, setError] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const position = Number(searchParams.get("position") || "0");
  const {data, loading} = useGetUserQuery({
    variables: {
      username
    }
  });

  const getSkip = useCallback(
    (newPosition?: number) => {
      return (
        Math.floor((newPosition || position) / PROFILE_VIDEOS_PER_PAGE) *
        PROFILE_VIDEOS_PER_PAGE
      );
    },
    [position]
  );

  const getIndex = useCallback(
    (length = 0) => {
      if (position < length) {
        return position;
      }
      return position - getSkip();
    },
    [position, getSkip]
  );

  return {
    user: data?.getUser,
    videoId,
    loading,
    error,
    getIndex,
    position,
    getSkip,
    setError
  };
};
