export enum ImageSizes {
  Original,
  Medium,
  Small
}

export interface ImageAttributes {
  size: ImageSizes;
  isStatic: boolean;
}

export enum ImageTypes {
  Avatar = "avatar",
  Banner = "banner",
  Thumbnail = "thumbnail"
}

export interface ProgressiveImageAttributes {
  smallAttributes: ImageAttributes;
  bigAttributes: ImageAttributes;
  type: ImageTypes;
}

export interface ProgressiveImageProps {
  errorIcon?: JSX.Element;
  imageAttributes: ProgressiveImageAttributes;
  onError?: () => void;
  shouldLoadAnimatedImage: boolean;
  url: string;
  onLoad?: () => void;
}

export const VIDEO_THUMBNAIL_ATTRIBUTES: ProgressiveImageAttributes = {
  smallAttributes: {size: ImageSizes.Medium, isStatic: true},
  bigAttributes: {size: ImageSizes.Original, isStatic: false},
  type: ImageTypes.Thumbnail
};

export const AVATAR_ATTRIBUTES: ProgressiveImageAttributes = {
  smallAttributes: {size: ImageSizes.Medium, isStatic: true},
  bigAttributes: {size: ImageSizes.Original, isStatic: false},
  type: ImageTypes.Avatar
};

export const BANNER_ATTRIBUTES: ProgressiveImageAttributes = {
  smallAttributes: {size: ImageSizes.Medium, isStatic: true},
  bigAttributes: {size: ImageSizes.Original, isStatic: false},
  type: ImageTypes.Banner
};
