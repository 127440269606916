/* eslint-disable react-extra/no-inline-styles -- MAR-844 */

import {ReactChild, ReactChildren, useEffect, useRef, useState} from "react";
import {useUser} from "hooks/useUser";
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import {LoaderContainer} from "components/Profile/ProfileVideoListStyles";
import {Spinner} from "components/Spinner";

type ContainerProps = {
  withFeaturedVideo?: boolean;
};
const GridViewContainer = styled.div<ContainerProps>`
  padding: 0 40px;
  position: absolute;
  top: 0px;
  width: 100%;
  background: rgb(2, 0, 36);
  background: linear-gradient(
      rgba(0, 0, 0, 0) 0px,
      rgba(0, 0, 0, 0) 100px,
      rgb(0, 0, 0) 230px,
      rgb(0, 0, 0) 100%
    )
    repeat scroll 0% 0%;

  ${(props) =>
    !props.withFeaturedVideo
      ? "top: 70px;"
      : `top: calc(100vh - 150px);
    
    @media screen and (max-width: 1525px) {
      top: 75vh !important;
    }
    @media screen and (max-width: 899px) {
      top: 470px !important;
    }`}
`;

const GridTitle = styled.h2`
  font-weight: normal;
  font-size: 25px;
  position: relative;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

const BottomGrad = styled.div`
  pointer-events: none;
  height: 128px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(360deg, #000000 6.25%, rgba(0, 0, 0, 0) 100%);
`;

type GridViewProps = {
  title: string;
  withFeaturedVideo?: boolean;
  children: ReactChild | ReactChildren;
  handleLoadMore: () => void;
  hasMore: () => boolean;
};

const whichPage = () => {
  const pageLocation = window.location.href;
  if (pageLocation.indexOf("explore") !== -1) {
    return "Explore";
  }
  return "Home";
};

const GridView = (props: GridViewProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const {user: currentUser} = useUser();
  const location = useLocation();
  const [everseHitEndState, setEverseHitEndState] = useState(false);

  const trackEnd = () => {
    if (window.analytics) {
      const page = whichPage();
      window.analytics.track(
        `User reached the end of the video feed on the ${page} page`,
        {
          uuid: currentUser?.uuid || "Anonymous"
        }
      );
    }
  };

  const handleScroll = () => {
    if (ref && ref.current) {
      const gridTop = ref.current.offsetTop;
      const gridHeight = ref.current.clientHeight;
      const scrollPos = window.scrollY + window.innerHeight + gridTop;
      if (gridHeight - scrollPos <= 0 && !everseHitEndState) {
        trackEnd();
        setEverseHitEndState(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      requestAnimationFrame(handleScroll);
    });

    return () => {
      window.removeEventListener("scroll", () => {
        requestAnimationFrame(handleScroll);
      });
    };
  }, []);

  // clean up global var when page changes
  useEffect(() => {
    setEverseHitEndState(false);
  }, [location]);

  const handleLoadMore = () => {
    setEverseHitEndState(false);
    props.handleLoadMore();
  };

  const {withFeaturedVideo, hasMore, title, children} = props;

  return (
    <GridViewContainer ref={ref} withFeaturedVideo={withFeaturedVideo}>
      {everseHitEndState && <BottomGrad />}
      <GridTitle>{title}</GridTitle>
      <InfiniteScroll
        hasMore={hasMore()}
        loadMore={handleLoadMore}
        loader={
          <LoaderContainer
            key={new Date().getTime()}
            // eslint-disable-next-line react-extra/no-inline-styles -- MAR-844
            style={{clear: "both"}}
          >
            <Spinner />
          </LoaderContainer>
        }
        pageStart={1}
        threshold={100}
        useWindow={true}
      >
        <GridContainer>{children}</GridContainer>
      </InfiniteScroll>
    </GridViewContainer>
  );
};

export default GridView;
