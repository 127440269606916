import styled from "styled-components";

export const Container = styled.div`
  width: 948px;
  min-height: 100vh;
  border-radius: 8px 8px 0 0;
  border: 1px solid white;
  border-bottom: 0px solid transparent;
  margin: 80px auto 0 auto;
  position: relative;
`;

export const Wrapper = styled.div`
  padding: 0 140px;
  margin-top: 96px;
  width: 100%;
`;

export const BackButton = styled.div`
  position: absolute;
  top: 40px;
  left: 20.67px;
  padding-left: 27.33px;
  color: #a39ea9;
  cursor: pointer;
`;

export const Icon = styled.img`
  position: absolute;
  left: 5px;
  top: 2px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;

export const SectionContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const MenuSection = styled.div<{showBorder?: boolean}>`
  margin-top: 50px;
  //${(props) => props.showBorder && `border-bottom: 1px solid`}
`;

export const SectionTitle = styled.span`
  font-size: 13px;
  color: #a39ea9;
`;

export const SettingsItemContainer = styled.div`
  padding: 20px 0;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  position: relative;
`;

export const SettingsItemTextContainer = styled(SettingsItemContainer)`
  padding-top: 20px;
  width: 100%;
  cursor: initial;
`;

export const SettingsItemPasswordContainer = styled.div`
  padding: 20px 0;
  width: 94%;
  cursor: pointer;
  position: relative;
`;

export const SettingsItemPasswordEmail = styled.span`
  color: #fff;
  opacity: 0.8;
`;

export const SettingsActionContainer = styled(SettingsItemContainer)<{
  position?: boolean;
}>`
  width: 100%;
  cursor: initial;
  ${(props) => props.position && `justify-content: space-around`}
`;

export const SettingsPasswordBtnContainer = styled(SettingsItemContainer)`
  width: 84%;
  cursor: initial;
  justify-content: flex-end;
`;

export const SettingsItemTitle = styled.span`
  font-size: 14px;
  width: 65%;
`;

export const SettingsItemText = styled.span`
  font-size: 14px;
  width: 85%;
  color: #a39ea9;
`;

export const SettingsItemIcon = styled.img`
  padding-right: 20px;
`;

export const SettingsActionButtonContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  cursor: pointer;
`;

export const SettingsActionButton = styled.span`
  color: #e0115f;
  font-size: 13px;
`;

export const SettingsButton = styled.button`
  width: 40%;
  margin-bottom: 1px;
  padding: 13px 17px;
  border: 1px solid #ffffff;
  appearance: none;
  border-radius: 4px;
  background: transparent;
  color: #ffffff;
  padding: 9px 17px;
  margin-right: 1px;

  &:active {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const SettingsExternalLink = styled.a`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  outline: none;
`;
