import React, {PropsWithChildren, useCallback} from "react";
import ReactModal from "react-modal";
import Draggable from "react-draggable";

import {SwipeableSideModalProps} from "./types";
import {ContentContainer, getModalStyles} from "./styles";
import "./styles.css";
import {useSwipeableSideModal} from "./hooks";

const SwipeableSideModal: React.FC<PropsWithChildren<SwipeableSideModalProps>> =
  (props: PropsWithChildren<SwipeableSideModalProps>) => {
    const {children, handle, isOpen, side, toggleModal} = props;
    const {
      bounds,
      closeAnimationDuration,
      dragOnStart,
      dragOnStop,
      duration,
      isDragging,
      onAfterOpen,
      onAfterClose,
      onDrag,
      position
    } = useSwipeableSideModal(props);

    const renderContent = useCallback(
      (
        contentProps: React.ComponentPropsWithRef<"div">,
        contentChildren: React.ReactNode
      ) => {
        return (
          <div {...contentProps}>
            <Draggable
              bounds={bounds}
              handle={handle}
              onStop={dragOnStop}
              onStart={dragOnStart}
              onDrag={onDrag}
              position={position}
            >
              <ContentContainer duration={duration} isDragging={isDragging}>
                {contentChildren}
              </ContentContainer>
            </Draggable>
          </div>
        );
      },
      [
        bounds,
        dragOnStart,
        dragOnStop,
        duration,
        handle,
        isDragging,
        onDrag,
        position
      ]
    );

    return (
      <ReactModal
        ariaHideApp={false}
        closeTimeoutMS={duration}
        isOpen={isOpen}
        shouldCloseOnOverlayClick={false}
        onAfterOpen={onAfterOpen}
        onAfterClose={onAfterClose}
        onRequestClose={toggleModal}
        style={getModalStyles(side, duration, closeAnimationDuration)}
        contentElement={renderContent}
      >
        {children}
      </ReactModal>
    );
  };

export default React.memo(SwipeableSideModal);
