import React from "react";

import {Props, Container} from "./styles";

export const Button: React.FC<Props> = ({
  children,
  variant,
  disabled,
  type,
  ...rest
}: Props) => {
  return (
    <Container
      {...rest}
      disabled={disabled ?? false}
      type={type ?? "button"}
      variant={variant ?? "outline"}
    >
      {children}
    </Container>
  );
};
