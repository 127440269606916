import styled from "styled-components";

export const RootContainer = styled.li`
  display: flex;
  flex-direction: row;
  padding: 12px 16px;
  align-items: center;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const Button = styled.button`
  background: transparent;
  padding: 0px;
  color: white;
  border: none;
`;

export const IconContainer = styled.div`
  display: flex;
  margin-right: 12px;
`;
