import styled from "styled-components";
import {minContainerWidth, maxContainerWidth} from "config/styleVars";

export const Container = styled.div`
  position: relative;
  min-width: ${minContainerWidth};
  max-width: ${maxContainerWidth};
  margin: 0 auto;
  min-height: 100vh;
`;

export const Col50 = styled.div`
  float: left;
  width: 50%;
  padding: 10px;
  box-sizing: border-box;

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
`;

export const Col33 = styled(Col50)`
  width: 33%;
`;

export const Col66 = styled(Col50)`
  width: 66%;
`;
