import React from "react";
import {Container} from "components/StyledElements/LayoutElements";
import SignInForm from "components/Auth/SignInForm";

function SignInPage() {
  return (
    <>
      <Container>
        <SignInForm />
      </Container>
    </>
  );
}

export default SignInPage;
