import styled from "styled-components";
import Text from "components/Atoms/Text";
import {colors} from "theme/colors";

import {StyleProps} from "./types";

export function getTitleAndIconColor(
  isOption: boolean,
  isSelected: boolean,
  isDisabled?: boolean
) {
  if (isDisabled) {
    return colors.primary.lightGrey;
  }

  if (!isOption || !isSelected) {
    return colors.primary.white;
  }

  return colors.primary.black;
}

export const RootContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 20px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const Description = styled(Text)<StyleProps>`
  color: ${(props) => {
    if (!props.isOption) {
      return colors.primary.lightGrey;
    }

    if (props.isSelected) {
      return colors.primary.darkGrey;
    }

    if (props.isFocused) {
      return colors.primary.white;
    }

    return colors.primary.lightGrey;
  }};
`;

export const Title = styled(Text)<StyleProps>`
  color: ${({isDisabled, isOption, isSelected}) =>
    getTitleAndIconColor(isOption, isSelected, isDisabled)};
`;
