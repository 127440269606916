import styled from "styled-components";
import {colors} from "theme/colors";

export const RootContainer = styled.div`
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;

  &:hover {
    background: ${colors.primary.white16};
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
`;
