import {useCallback} from "react";
import {TagProps} from "./types";

export function useTag({onDelete, tag}: TagProps) {
  const deleteOnClick = useCallback(() => {
    onDelete(tag);
  }, [onDelete, tag]);

  return {deleteOnClick};
}
