import React, {PropsWithChildren} from "react";
import {TextTypes} from "components/Atoms/Text/types";
import {useText} from "components/Atoms/Text/hooks";

import {IconButtonProps, IconSide} from "./types";
import {Container, IconContainer, Title} from "./styles";

const IconButton: React.FC<PropsWithChildren<IconButtonProps>> = ({
  backgroundColours,
  borderColours,
  textColours,
  children,
  className,
  disabled,
  iconSide,
  onClick,
  textType,
  title
}: PropsWithChildren<IconButtonProps>) => {
  const textStyle = useText({textType: textType || TextTypes.SubHeadings.SH2});
  return (
    <Container
      backgroundColours={backgroundColours}
      borderColours={borderColours}
      textColours={textColours}
      disabled={disabled}
      fontSize={textStyle.fontSize}
      onClick={onClick}
      className={className}
    >
      {iconSide === IconSide.left && <IconContainer>{children}</IconContainer>}
      {title && (
        <Title
          iconSide={iconSide}
          textType={textType || TextTypes.SubHeadings.SH2}
        >
          {title}
        </Title>
      )}
      {iconSide === IconSide.right && <IconContainer>{children}</IconContainer>}
    </Container>
  );
};

export default React.memo(IconButton);
