import styled from "styled-components";
import {colors} from "theme/colors";
import {formStyles} from "config/styleVars";
import Text from "components/Atoms/Text";

type MenuContainerProps = {
  isOpen: boolean;
};

export const Container = styled.div`
  position: relative;
  cursor: pointer;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuContainer = styled.div<MenuContainerProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  min-width: 160px;
  background: ${colors.primary.black80};
  color: ${colors.primary.white};
  border-radius: ${formStyles.borderRadius};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
`;

export const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  border-top: 1px solid ${colors.primary.darkGrey};
  &:first-child {
    border-top-style: none;
  }
  &:hover {
    background-color: ${colors.primary.white10};
  }
`;

export const StyledMenuItemText = styled(Text)`
  margin-left: 12px;
`;
