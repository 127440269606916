import React from "react";
import Text from "components/Atoms/Text";
import {PossibleTextTypes} from "components/Atoms/Text/types";
import {isMobile} from "react-device-detect";
import UploadNotAllowedModal from "components/Molecules/UploadNotAllowedModal";
import {strings} from "config/strings";

import {StyledLink, LinkContainer} from "./styles";
import {TopNavigationMenuItemProps} from "./types";
import {useTopNavigationMenuItem} from "./hooks";

const TopNavigationMenuItem: React.FC<TopNavigationMenuItemProps> = (
  props: TopNavigationMenuItemProps
) => {
  const {hasUnderscore, isSelected, route, title, icon, ...otherProps} = props;
  const {isModalOpen, linkOnClick, toggleModal} =
    useTopNavigationMenuItem(route);
  return (
    <>
      <StyledLink
        {...otherProps}
        hasUnderscore={hasUnderscore}
        isSelected={isSelected}
        to={route}
        onClick={linkOnClick}
      >
        <LinkContainer hasIcon={!!icon}>
          {icon && <img src={icon} alt="upload icon" />}
          <Text textType={PossibleTextTypes.MenuItem}>{title}</Text>
        </LinkContainer>
      </StyledLink>

      {isMobile && (
        <UploadNotAllowedModal
          buttonText={strings.pages.upload.modals.uploadNotAllowed.buttonClose}
          isOpen={isModalOpen}
          toggleModal={toggleModal}
        />
      )}
    </>
  );
};

export default React.memo(TopNavigationMenuItem);
