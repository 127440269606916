import React, {PropsWithChildren} from "react";

import {TextProps} from "./types";
import {useText} from "./hooks";
import {BaseText} from "./styles";

const Text: React.FC<PropsWithChildren<TextProps>> = (
  props: PropsWithChildren<TextProps>
) => {
  const {children, className, textType, textWeight} = props;
  const fontStyle = useText({textType, textWeight});
  return (
    <BaseText className={className} {...fontStyle}>
      {children}
    </BaseText>
  );
};

export default React.memo(Text);
