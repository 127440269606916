import {useEffect, useRef, useState} from "react";
import useComments from "hooks/useComments";
import styled from "styled-components";
import {MessageResponse} from "stream-chat";
import {FeedVideo} from "apollo";
import Comment from "components/Comment/Comment";
import {Spinner} from "components/Spinner";
import InfiniteScroll from "react-infinite-scroller";
import {LoaderContainer} from "components/Profile/ProfileVideoListStyles";
import {COMMENTS_FETCH_LIMIT, COMMENTS_TEXT_LIMIT} from "config/constants";
import {
  FieldValidationMessage,
  TextArea
} from "components/StyledElements/FormElements";
import {strings} from "config/strings";
import {CommentsContentBox, SendButton} from "./PlayerStyles";

const ScrollContainer = styled.div`
  position: absolute;
  width: calc(100% - 7px);
  left: 2px;
  top: 5px;
  bottom: 4px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255, 0.4);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ActionContainer = styled.div`
  position: absolute;
  width: 100%;
  height: calc(3% + 32px);
  bottom: 0px;
`;

const CustomInput = styled(TextArea)`
  appearance: none;
  position: absolute;
  margin: 0px;
  left: 7px;
  width: 75%;
  max-width: 75%;
  height: 37px;
  overflow: hidden;
  bottom: 3px;
  resize: none;
  padding: 10px;
  font-size: 14px;
`;

const ErrorMessage = styled(FieldValidationMessage)`
  position: absolute;
  bottom: 3px;
  margin: 0px;
  left: 7px;
`;

const NoCommentsWrapper = styled.div`
  position: relative;
  height: calc(100% - 68px);
  width: calc(100% - 7px);

  div:first-child {
    position: absolute;
    top: 50%;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
  }

  div {
    position: absolute;
    top: calc(50% + 22px);
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #a39ea9;
  }
`;

type CommentsContainerProps = {
  video: FeedVideo;
};

const CommentsContainer = ({video}: CommentsContainerProps) => {
  const defaultScrollHeight = 78;
  const defaultTextAreaHeight = 37;
  const errorSize = 23;
  const {getComments, addComment} = useComments();
  const [comments, setComments] = useState<Array<MessageResponse>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [comment, setComment] = useState("");
  const [error, setError] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(defaultScrollHeight);
  const scrollParent = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const loadComments = () => {
    setIsLoading(true);
    getComments(video)
      .then((messages) => {
        setComments(messages || []);
      })
      .finally(() => {
        setIsLoading(false);
        setHasMore(true);
        setError(false);
      });
  };

  useEffect(() => {
    loadComments();
  }, [video]);

  const loadMoreComments = () => {
    const lastdate =
      comments.length > 0
        ? comments[comments.length - 1].created_at
        : undefined;
    getComments(video, lastdate).then((messages) => {
      if (!messages || messages.length === 0) {
        setHasMore(false);
      } else {
        setComments(comments.concat(messages));
        setHasMore(messages.length === COMMENTS_FETCH_LIMIT);
      }
    });
  };

  const resetScrollHeight = () => {
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.style.height = `${defaultTextAreaHeight}px`;
      setScrollHeight(defaultScrollHeight);
    }
  };

  const handleAddComment = () => {
    if (!!comment?.trim()) {
      addComment(video, comment)
        .then(() => {
          loadComments();
        })
        .catch(() => {
          setError(true);
        });
      setComment("");
      resetScrollHeight();
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {value} = e.target;
    if (value.length <= COMMENTS_TEXT_LIMIT) {
      setComment(value);
      setError(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const target = e.target as any;
    const targetScrollHeight = target.scrollHeight as number;

    target.style.height = "inherit";
    target.style.height = `${targetScrollHeight}px`;

    if (scrollParent.current) {
      const gapDifference = 12; // extra pixels that need to be added.
      const staticHeight =
        targetScrollHeight > defaultScrollHeight
          ? targetScrollHeight + gapDifference
          : defaultScrollHeight;
      setScrollHeight(staticHeight);
    }
  };

  return (
    <CommentsContentBox>
      {isLoading && <Spinner />}
      {!isLoading && comments.length > 0 && (
        <ScrollContainer
          ref={scrollParent}
          style={{
            height: `calc(100% - ${
              error ? scrollHeight + errorSize : scrollHeight
            }px)`
          }}
        >
          <InfiniteScroll
            getScrollParent={() => scrollParent.current}
            hasMore={hasMore}
            loadMore={loadMoreComments}
            loader={
              <LoaderContainer key={new Date().getTime()}>
                <Spinner />
              </LoaderContainer>
            }
            pageStart={0}
            threshold={25}
            useWindow={false}
          >
            {comments.map((message) => (
              <Comment key={message.id} message={message} />
            ))}
          </InfiniteScroll>
        </ScrollContainer>
      )}
      {!isLoading && comments.length === 0 && (
        <NoCommentsWrapper>
          <div>{strings.video.noCommentsHeader}</div>
          <div>{strings.video.noCommentsBody}</div>
        </NoCommentsWrapper>
      )}
      <ActionContainer style={{bottom: error ? `${errorSize}px` : "0px"}}>
        <CustomInput
          invalid={error}
          placeholder={strings.video.commentPlaceholder}
          ref={textAreaRef}
          value={comment}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
        />
        <SendButton onClick={handleAddComment}>
          {strings.generic.send}
        </SendButton>
      </ActionContainer>
      {error && <ErrorMessage>{strings.video.commentFailed}</ErrorMessage>}
    </CommentsContentBox>
  );
};

export default CommentsContainer;
