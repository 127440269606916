import {VideoInfo} from "components/Organisms/ClippingTool/types";

export async function generateVideoThumbnail({
  start,
  size,
  videoFile,
  videoInfo
}: {
  start: number;
  size: number;
  videoFile: File;
  videoInfo: VideoInfo;
}): Promise<string> {
  return new Promise((resolve, reject) => {
    window.URL = window.URL || window.webkitURL;
    const videoUrl = window.URL.createObjectURL(videoFile);
    const videoPlayer = document.createElement("video");
    videoPlayer.setAttribute("src", videoUrl);
    videoPlayer.crossOrigin = "Anonymous";

    const onError = (e: ErrorEvent) => {
      removeListeners();
      reject(`error when loading video file ${e}`);
    };
    const onLoadedData = () => {
      videoPlayer.currentTime = start;
    };
    const onSeeked = () => {
      // do capture here
      // define a canvas to have the same dimension as the video
      const canvas = document.createElement("canvas");
      const scaleFactor = size / Math.min(videoInfo.height, videoInfo.width);

      canvas.width = videoInfo.width * scaleFactor;
      canvas.height = videoInfo.height * scaleFactor;
      // draw the video frame to canvas
      const ctx = canvas.getContext("2d");
      ctx!.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);

      const dataURI = canvas.toDataURL("image/jpeg");
      removeListeners();
      resolve(dataURI);
    };

    const removeListeners = () => {
      videoPlayer.removeEventListener("error", onError);
      videoPlayer.removeEventListener("loadeddata", onLoadedData);
      videoPlayer.removeEventListener("seeked", onSeeked);
    };

    videoPlayer.addEventListener("error", onError);
    videoPlayer.addEventListener("loadeddata", onLoadedData);
    videoPlayer.addEventListener("seeked", onSeeked);

    videoPlayer.load();
  });
}
