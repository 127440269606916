import React, {useCallback, useEffect, useState} from "react";
import {User} from "apollo";
import {ApolloError} from "@apollo/client";
import {strings} from "config/strings";
import {Spinner} from "components/Spinner";
import {ReactComponent as EditIcon} from "icons/20/edit.svg";
import {ProfileUserList} from "components/Profile";
import ProfileVideos from "components/Profile/ProfileVideos";
import {Modal} from "components/Modal";
import {useHistory} from "react-router";
import {getProfileEditUrl} from "config/routes";
import ProfileInfo from "components/Organisms/ProfileInfo";
import {IconSide} from "components/Atoms/IconButton/types";
import {TextTypes} from "components/Atoms/Text/types";
import Banner from "components/Atoms/Banner";

import {
  Container,
  ControlsAndVideosContainer,
  EditProfileButton,
  InfoAndVideosContainer,
  SmallEditProfileButton
} from "./styles";

type Props = {
  user: User | null;
  userLikes: number | null | undefined;
  loading: boolean;
  error?: ApolloError;
  isOwnProfile: boolean;
  uuid: string;
  isUserLoggedIn: boolean;
};

const ProfileContainer: React.FC<Props> = ({
  user,
  userLikes,
  isOwnProfile,
  loading,
  uuid,
  isUserLoggedIn
}: Props) => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState<
    undefined | "following" | "followed"
  >();

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalType(undefined);
  };

  useEffect(() => {
    const unlisten = history.listen(() => {
      handleCloseModal();
    });
    return () => {
      unlisten();
    };
  }, [history]);

  const handleOpenFollowers = () => {
    setModalType("followed");
    setOpenModal(true);
  };

  const handleOpenFollowing = () => {
    setModalType("following");
    setOpenModal(true);
  };

  const handleOnEdit = useCallback(() => {
    if (window.analytics) {
      window.analytics.track("Edit Profile Start", {
        uuid
      });
    }
    history.push(getProfileEditUrl(user?.username || ""));
  }, [history, user, uuid]);

  return (
    <Container>
      {loading && <Spinner />}
      {!loading && user && (
        <>
          <Banner banner={user?.banner!} isOwnProfile={isOwnProfile} />
          <InfoAndVideosContainer>
            <ProfileInfo
              followersOnClick={handleOpenFollowers}
              followingOnClick={handleOpenFollowing}
              isOwnProfile={isOwnProfile}
              isUserLoggedIn={isUserLoggedIn}
              user={user}
              userLikes={userLikes}
            />

            {isOwnProfile && (
              <SmallEditProfileButton
                iconSide={IconSide.left}
                onClick={handleOnEdit}
                textType={TextTypes.Body}
              >
                <EditIcon />
              </SmallEditProfileButton>
            )}

            <ControlsAndVideosContainer>
              {isOwnProfile && (
                <EditProfileButton
                  iconSide={IconSide.left}
                  onClick={handleOnEdit}
                  textType={TextTypes.Body}
                  title={strings.pages.profile.edit}
                >
                  <EditIcon />
                </EditProfileButton>
              )}

              <ProfileVideos isOwnProfile={isOwnProfile} user={user} />
            </ControlsAndVideosContainer>
          </InfoAndVideosContainer>

          <Modal
            closeModalFn={handleCloseModal}
            isDialog={false}
            isOpen={openModal}
          >
            {user && user.uuid && modalType && (
              <ProfileUserList
                isOwnProfile={isOwnProfile}
                type={modalType}
                uuid={user.uuid}
              />
            )}
          </Modal>
        </>
      )}
    </Container>
  );
};

export default React.memo(ProfileContainer);
