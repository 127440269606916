import {useCallback, useEffect} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {videoUploadValidator} from "utils/validators/upload";
import {AnalyticsEvents} from "config/constants";

import {IFormValues, VideoPickerFormProps} from "./types";

export function useUploadForm({onSuccess, user}: VideoPickerFormProps) {
  const uuid = user?.uuid;
  const methods = useForm<IFormValues>({
    mode: "onBlur",
    resolver: yupResolver(videoUploadValidator())
  });

  const {
    handleSubmit,
    formState: {errors, isValid}
  } = methods;

  const onSubmit: SubmitHandler<IFormValues> = useCallback(
    ({file}: IFormValues) => onSuccess(file),
    [onSuccess]
  );

  const formOnSubmit = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [handleSubmit, onSubmit]);

  useEffect(() => {
    if (isValid) {
      if (window.analytics) {
        window.analytics.track(AnalyticsEvents.UploadProcessBegin, {
          uuid
        });
      }
      formOnSubmit();
    }
  }, [formOnSubmit, isValid, uuid]);

  return {errors, formOnSubmit, methods};
}
