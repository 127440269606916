import React, {HTMLAttributes, PropsWithChildren} from "react";
import {useDraggableItemsContainer} from "./hooks";

const DraggableItemsContainer: React.FC<
  PropsWithChildren<HTMLAttributes<HTMLDivElement>>
> = ({
  children,
  className,
  id,
  ...rest
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  const {events, itemsContainerRef} = useDraggableItemsContainer();

  return (
    <div
      {...rest}
      {...events}
      className={className}
      id={id}
      ref={itemsContainerRef}
    >
      {children}
    </div>
  );
};

export default React.memo(DraggableItemsContainer);
