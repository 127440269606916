import {useModal} from "hooks/useModal";
import {GroupBase, OptionsOrGroups} from "react-select";
import {strings} from "config/strings";
import {
  TiersDropdownDataProps,
  TierValues
} from "components/Atoms/TiersDropdownOption/types";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {inputPercentageValidator} from "utils/validators/upload";

import {FormEvent, useCallback, useEffect, useMemo, useState} from "react";
import {InputPercentageFormValues, PercentageProps} from "./types";

export const DropdownOptions: OptionsOrGroups<
  TiersDropdownDataProps,
  GroupBase<TiersDropdownDataProps>
> = [
  {
    description:
      strings.pages.upload.modals.inputPercentage.dropdownOptions
        .oneTierThumbnail.description,
    label:
      strings.pages.upload.modals.inputPercentage.dropdownOptions
        .oneTierThumbnail.label,
    value: TierValues.TierThumbnail
  },
  {
    description:
      strings.pages.upload.modals.inputPercentage.dropdownOptions.oneTierVideo
        .description,
    label:
      strings.pages.upload.modals.inputPercentage.dropdownOptions.oneTierVideo
        .label,
    value: TierValues.TierVideo
  },
  {
    description:
      strings.pages.upload.modals.inputPercentage.dropdownOptions.twoTiers
        .description,
    label:
      strings.pages.upload.modals.inputPercentage.dropdownOptions.twoTiers
        .label,
    value: TierValues.TwoTiers
  }
];

export function usePercentage({initialValue, onSubmit}: PercentageProps) {
  const {isModalOpen, toggleModal} = useModal();
  const methods = useForm<InputPercentageFormValues>({
    defaultValues: initialValue,
    mode: "onChange",
    resolver: yupResolver(inputPercentageValidator)
  });

  const musicRightsHoldersPercentage = 10;
  const collaboratorsRevenuePercentage = 10;
  const tokenHoldersPercentage = 0;

  const {setValue, watch} = methods;

  const formValues = watch();
  const {tier} = formValues;

  const [subtotal, setSubtotal] = useState<number>(
    musicRightsHoldersPercentage +
      collaboratorsRevenuePercentage +
      tokenHoldersPercentage
  );

  const remainingRevenue = useMemo(() => {
    return 100 - subtotal;
  }, [subtotal]);

  const dropdownOnChange = useCallback(
    (option: TiersDropdownDataProps) => {
      setValue("tier", option);
    },
    [setValue]
  );

  const formOnSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    setSubtotal(
      musicRightsHoldersPercentage +
        collaboratorsRevenuePercentage +
        tokenHoldersPercentage
    );
  }, []);

  const saveOnClick = useCallback(() => {
    if (!formValues.tier) {
      return;
    }

    onSubmit(formValues);
    toggleModal();
  }, [formValues, onSubmit, toggleModal]);

  const explanationText = useMemo(() => {
    if (!tier) {
      return "";
    }

    switch (tier.value) {
      case TierValues.TierThumbnail:
        return strings.pages.upload.modals.inputPercentage.dropdownOptions
          .oneTierThumbnail.explanation;

      case TierValues.TierVideo:
        return strings.pages.upload.modals.inputPercentage.dropdownOptions
          .oneTierVideo.explanation;

      case TierValues.TwoTiers:
        return strings.pages.upload.modals.inputPercentage.dropdownOptions
          .twoTiers.explanation;
    }

    return "";
  }, [tier]);

  return {
    collaboratorsRevenuePercentage,
    dropdownOnChange,
    explanationText,
    formOnSubmit,
    formValues,
    isModalOpen,
    methods,
    musicRightsHoldersPercentage,
    remainingRevenue,
    saveOnClick,
    subtotal,
    toggleModal
  };
}
