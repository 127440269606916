import {useCallback, useMemo} from "react";
import {formatCountNumber} from "utils/FormatUtils";

import {UseProfileMetricProps} from "./types";

export function useProfileMetric({onClick, value}: UseProfileMetricProps) {
  const formattedValue = useMemo(() => {
    if (!value || isNaN(value)) {
      return 0;
    }
    return formatCountNumber(value);
  }, [value]);

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return {
    formattedValue,
    handleClick
  };
}
