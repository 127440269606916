export enum ThumbnailType {
  Clipping,
  Upload
}

export interface ThumbnailFormProps {
  endTime: number;
  externalId?: string;
  isVideoUploaded: boolean;
  setEndTime: (time: number) => void;
  setIsThumbnailUploaded: (isUploaded: boolean) => void;
  setStartTime: (time: number) => void;
  setThumbnailType: (type: ThumbnailType) => void;
  startTime: number;
  thumbnailType: ThumbnailType;
  uuid?: string;
  video: File;
}
