import styled from "styled-components";
import {Link} from "react-router-dom";
import {TEXT_HOVER_SCALE} from "config/constants";
import {StyledLinkProps} from "./types";

export const LinkContainer = styled.div<{hasIcon: boolean}>`
  display: flex;
  flex-direction: ${({hasIcon}) => (hasIcon ? "row" : "column")};
  white-space: nowrap;
`;

export const StyledLink = styled(Link).withConfig<StyledLinkProps>({
  shouldForwardProp: (prop) => !["hasUnderscore", "isSelected"].includes(prop)
})`
  color: white;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  :hover {
    text-decoration: none;
    transform: scale(${TEXT_HOVER_SCALE});
    transition: transform 0.3s;
  }

  img {
    width: 20px;
    padding-right: 5px;
  }

  transform: scale(${(props) => (props.isSelected ? TEXT_HOVER_SCALE : 1)});
  transition: transform 0.3s;

  &::after {
    content: "";
    top: 100%;
    width: 100%;
    margin-top: 4px;
    height: 1px;
    background: white;
    transform: scaleX(${(props) => (props.isSelected ? 1 : 0)});
    transform-origin: center;
    transition: transform 0.3s;
    visibility: ${(props) => (props.hasUnderscore ? "visible" : "hidden")};
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: center;
  }
`;
