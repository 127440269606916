import styled from "styled-components";
import Text from "components/Atoms/Text";
import {colors} from "theme/colors";
import TextInput from "components/Atoms/TextInput";
import {ReactComponent as UnlockIcon} from "icons/24/unlock.svg";

export const UnlockIconStyled = styled(UnlockIcon)`
  margin-right: 8px;
`;

export const Container = styled.div`
  padding: 0px 72px 0 72px;
`;

export const ContentToTiersDescription = styled(Text)`
  color: ${colors.primary.lightGrey};
  margin-bottom: 20px;
`;

export const ContentToTiersH4 = styled(Text)`
  color: ${colors.primary.white};
`;

export const ContentToTiersH6 = styled(Text)`
  color: ${colors.primary.white};
  margin-top: 20px;
  margin-bottom: 6px;
  span {
    color: ${colors.primary.lightGrey};
  }
`;

export const TierName = styled(Text)`
  color: ${colors.primary.lightGrey};
  column-gap: 8px;
`;

export const TierRow = styled.div<{isLast: boolean}>`
  display: flex;
  margin-bottom: ${({isLast}) => (isLast ? 0 : 20)}px;
`;

export const FieldContainer = styled.div`
  margin-top: 20px;
`;

export const RewardName = styled(Text)`
  column-gap: 8px;
  color: ${colors.primary.white};
  margin-bottom: 20px;
`;

export const TextInputStyled = styled(TextInput)<{
  width?: string;
  marginLeft?: number;
}>`
  width: ${(props) => props.width || "100%"};
  display: inline-block;
  margin-left: ${(props) => props.marginLeft || 0}px;
`;

export const InlineInputContainer = styled.div`
  margin-top: 20px;
  display: flex;
`;
