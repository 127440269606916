import React from "react";
import {ReactComponent as XIcon} from "icons/x.svg";

import {Container, RootContainer} from "./styles";

export interface ClearButtonProps {
  className?: string;
  clearValue: () => void;
}

export default function ClearButton({className, clearValue}: ClearButtonProps) {
  return (
    <RootContainer className={className}>
      <Container onClick={clearValue}>
        <XIcon height={24} width={24} />
      </Container>
    </RootContainer>
  );
}
