import RightIcon from "icons/24/blue-chevron-right.svg";
import {useHistory} from "react-router-dom";
import {getSettingsUrl, Routes} from "config/routes";
import {
  SettingsItemIcon,
  SettingsItemText,
  SettingsActionContainer,
  SettingsActionButtonContainer,
  SettingsActionButton,
  SettingsButton
} from "./styles";

type SettingsActionProps = {
  text?: string;
  location?: Routes;
  actionText?: string;
  action?: () => void;
  showOnlyAction?: boolean;
  uuid?: string;
};

export const SettingsAction = ({
  text,
  actionText,
  location,
  showOnlyAction,
  action,
  uuid
}: SettingsActionProps) => {
  const history = useHistory();

  const handleRoute = () => {
    if (location) {
      history.push(getSettingsUrl(location, uuid || ""));
    }
  };

  return showOnlyAction ? (
    <SettingsActionContainer position>
      <SettingsButton onClick={action}>{text}</SettingsButton>
    </SettingsActionContainer>
  ) : (
    <SettingsActionContainer>
      <SettingsItemText>{text}</SettingsItemText>
      <SettingsActionButtonContainer onClick={handleRoute}>
        <SettingsActionButton>{actionText}</SettingsActionButton>
        <SettingsItemIcon src={RightIcon} />
      </SettingsActionButtonContainer>
    </SettingsActionContainer>
  );
};
