import React from "react";
import Text from "components/Atoms/Text";
import {TextTypes} from "components/Atoms/Text/types";
import {strings} from "config/strings";

import {useProfileFollowButton} from "./hooks";
import {ProfileFollowButtonProps} from "./types";
import {FollowButton, FollowIconImg} from "./styles";

const ProfileFollowButton: React.FC<ProfileFollowButtonProps> = ({
  className,
  followedByMe,
  showIcon,
  uuid
}: ProfileFollowButtonProps) => {
  const {onFollowUser} = useProfileFollowButton({followedByMe, uuid});

  return (
    <FollowButton
      className={className}
      isFollowed={followedByMe}
      onClick={onFollowUser}
    >
      {!followedByMe && showIcon && (
        <>
          <FollowIconImg />
        </>
      )}
      <div data-cy="follow-unfollow-button">
        <Text textType={TextTypes.Bodies.B1}>
          {followedByMe ? strings.generic.following : strings.generic.follow}
        </Text>
      </div>
    </FollowButton>
  );
};

export default React.memo(ProfileFollowButton);
