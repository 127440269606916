import styled from "styled-components";
import {colors} from "theme/colors";
import {ReactComponent as ChevronLeft} from "icons/universal/chevron-left.svg";
import {ReactComponent as ChevronRight} from "icons/universal/chevron-right.svg";

export const Container = styled.div<{isLeft?: boolean}>`
  width: 12px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;

  &::before {
    content: "";
    display: block;
    position: absolute;
    background-color: ${colors.primary.white};
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: ${(props) => (props.isLeft ? "8px 0 0 8px" : "0 8px 8px 0")};
    cursor: ew-resize;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    right: 12px;
    left: 12px;
    ${(props) => (props.isLeft ? "left: -500px" : "right: -500px")};
    background-color: rgba(0, 0, 0, 0.7);
    pointer-events: none;
  }
`;

export const ChevronLeftIcon = styled(ChevronLeft)`
  stroke: ${colors.primary.darkGrey};
  stroke-width: 1.5px;
  pointer-events: none;
  z-index: 2;
  position: absolute;
  width: 20px;
`;

export const ChevronRightIcon = styled(ChevronRight)`
  stroke: ${colors.primary.darkGrey};
  stroke-width: 1.5px;
  pointer-events: none;
  z-index: 2;
  position: absolute;
  width: 20px;
`;
