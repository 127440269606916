import React from "react";
import {
  DescriptionText,
  Input,
  SubtitleText,
  Tags
} from "pages/Web3Upload/styles";
import {TextTypes} from "components/Atoms/Text/types";
import {strings} from "config/strings";
import {VideoMetadataProps} from "./types";
import {useVideoMetadata} from "./hooks";

const VideoMetadata: React.FC<VideoMetadataProps> = (
  props: VideoMetadataProps
) => {
  const {titleOnBlur, descriptionOnBlur, tagsOnChange} =
    useVideoMetadata(props);
  const {disabled} = props;

  return (
    <>
      {/* Title */}
      <SubtitleText textType={TextTypes.Titles.H4}>
        {strings.generic.title}
        <span>*</span>
      </SubtitleText>
      <Input
        isDisabled={disabled}
        onBlur={titleOnBlur}
        placeholder={strings.pages.upload.inputTitle}
        maxCharCount={100}
      />
      {/* Title */}

      {/* Description */}
      <SubtitleText textType={TextTypes.Titles.H4}>
        {strings.generic.description}
        <span>*</span>
      </SubtitleText>
      <DescriptionText textType={TextTypes.Subtitle.LStrong}>
        {strings.pages.upload.descriptionDetails}
      </DescriptionText>
      <Input
        isDisabled={disabled}
        onBlur={descriptionOnBlur}
        placeholder={strings.pages.upload.inputDescription}
        maxCharCount={5000}
      />
      {/* Description */}

      {/* Tags */}
      <SubtitleText textType={TextTypes.Titles.H4}>
        {strings.generic.hashtags}
      </SubtitleText>
      <DescriptionText textType={TextTypes.Subtitle.LStrong}>
        {strings.pages.upload.hashtagsDetails}
      </DescriptionText>
      <Tags disabled={disabled} onChange={tagsOnChange} name="tags" />
      {/* Tags */}
    </>
  );
};

export default React.memo(VideoMetadata);
