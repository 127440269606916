import React from "react";
import {TextTypes, TextWights} from "components/Atoms/Text/types";
import Text from "components/Atoms/Text";
import Avatar from "components/Atoms/Avatar";
import {BorderType} from "components/Atoms/Avatar/types";
import {AddImageOverlay, ProfileImageTypes} from "components/Profile";
import ProfileMetric from "components/Atoms/ProfileMetric";
import {strings} from "config/strings";
import {ReactComponent as GiftIcon} from "icons/20/gift.svg";
import {ReactComponent as LinkIcon} from "icons/20/link.svg";
import {ReactComponent as MapPinIcon} from "icons/20/map-pin.svg";
import {addTZ} from "utils/FormatUtils";
import {format} from "date-fns";
import ProfileFollowButton from "components/Molecules/ProfileFollowButton";

import {
  AvatarContainer,
  BioText,
  BioTitle,
  FollowButtonContainer,
  IconItem,
  IconItemsContainer,
  IconText,
  MetricsAndFollowContainer,
  MetricsContainer,
  RootContainer,
  SiteLink
} from "./styles";
import {ProfileInfoProps} from "./types";

const ProfileInfo: React.FC<ProfileInfoProps> = ({
  followersOnClick,
  followingOnClick,
  isOwnProfile,
  isUserLoggedIn,
  user,
  userLikes
}: ProfileInfoProps) => {
  if (!user) {
    return null;
  }

  return (
    <RootContainer>
      <AvatarContainer>
        <Avatar
          avatarURL={user.avatar}
          borderType={BorderType.black}
          size={100}
          username={isOwnProfile ? "" : user.username}
        />
        {isOwnProfile && !user.avatar && (
          <AddImageOverlay type={ProfileImageTypes.avatar} />
        )}
      </AvatarContainer>

      <Text textType={TextTypes.Headings.H5} textWeight={TextWights.Medium}>
        {user.name}
      </Text>
      <Text textType={TextTypes.Bodies.B2}>@{user.username}</Text>

      <MetricsAndFollowContainer>
        <MetricsContainer>
          <ProfileMetric
            label={strings.generic.followers}
            value={user?.followersCount!}
            onClick={followersOnClick}
          />
          <div data-cy="profile-metrics-following">
            <ProfileMetric
              label={strings.generic.following}
              value={user?.followingCount!}
              onClick={followingOnClick}
            />
          </div>
          <ProfileMetric label={strings.generic.likes} value={userLikes} />
        </MetricsContainer>

        {!isOwnProfile && isUserLoggedIn && (
          <FollowButtonContainer>
            <ProfileFollowButton
              followedByMe={user.followedByMe!}
              showIcon={true}
              uuid={user.uuid!}
            />
          </FollowButtonContainer>
        )}
      </MetricsAndFollowContainer>

      <BioTitle textType={TextTypes.Headings.H5}>Bio</BioTitle>
      <BioText textType={TextTypes.Bodies.B2}>{user.bio}</BioText>

      <IconItemsContainer>
        {user?.website && (
          <IconItem>
            <LinkIcon height={18} />
            <SiteLink href={user.website}>
              <IconText textType={TextTypes.Bodies.B2}>{user.website}</IconText>
            </SiteLink>
          </IconItem>
        )}
        {user?.location && (
          <IconItem>
            <MapPinIcon height={18} />
            <IconText textType={TextTypes.Bodies.B2}>{user.location}</IconText>
          </IconItem>
        )}
        {user?.birthday && user?.profilePreferences?.show?.birthday && (
          <IconItem>
            <GiftIcon height={18} />
            <IconText textType={TextTypes.Bodies.B2}>
              {format(addTZ(user.birthday), "MMM dd")}
            </IconText>
          </IconItem>
        )}
      </IconItemsContainer>
    </RootContainer>
  );
};

export default React.memo(ProfileInfo);
