import React from "react";
import {FormProvider} from "react-hook-form";
import {DropAreaConfig} from "components/Atoms/DropArea/types";
import {TextTypes} from "components/Atoms/Text/types";

import {Web3VideoPickerFormProps} from "./types";
import {useWeb3VideoPickerForm} from "./hooks";
import {DropAreaStyled, ErrorText} from "./styles";

const dropAreaConfig: DropAreaConfig = {accept: "video/*", name: "file"};

const Web3VideoPickerForm: React.FC<Web3VideoPickerFormProps> = (
  props: Web3VideoPickerFormProps
) => {
  const {errors, formOnSubmit, methods} = useWeb3VideoPickerForm(props);
  const {user} = props;

  if (!user?.username) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={formOnSubmit}>
        <DropAreaStyled config={dropAreaConfig} hasError={!!errors.file} />
        {errors.file && (
          <ErrorText textType={TextTypes.Subtitle.L}>
            {errors.file.message}
          </ErrorText>
        )}
      </form>
    </FormProvider>
  );
};

export default React.memo(Web3VideoPickerForm);
