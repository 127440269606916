import React from "react";
import FadeLoader from "react-spinners/FadeLoader";
import {colors} from "theme/colors";

import {SpinnerContainer} from "./styles";
import {SpinnerProps} from "./types";

const FadeSpinner: React.FC<SpinnerProps> = ({loading}: SpinnerProps) => {
  return (
    <SpinnerContainer>
      <FadeLoader
        loading={loading}
        width={1.5}
        height={4}
        color={colors.primary.cyan}
        margin={-12}
        cssOverride={{
          width: 24,
          height: 24,
          margin: 17
        }}
      />
    </SpinnerContainer>
  );
};

export default React.memo(FadeSpinner);
