import {useState} from "react";

import {useUploadThumbnailLinkQuery} from "apollo";

interface UseThumbnailLinkProps {
  externalId?: string;
  contentType?: string;
  isVisible: boolean;
}

export const useThumbnailLink = ({
  externalId,
  contentType,
  isVisible
}: UseThumbnailLinkProps) => {
  const [link, setLink] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useUploadThumbnailLinkQuery({
    skip: !externalId || !isVisible,
    variables: {
      externalId,
      contentType
    },
    onCompleted: (data) => {
      setLink(data.uploadThumbnailLink?.uploadUrl!);
      setLoading(false);
    }
  });

  return {link, loading};
};
