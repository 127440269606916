import {useCallback, useState} from "react";

export function useAlgoliaSearch() {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  const onFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return {isFocused, onBlur, onFocus, searchText, setSearchText};
}
