import {useGetUserQuery, User} from "apollo";
import {SettingsPasswordContainer} from "components/Settings/SettingsPasswordContainer";
import {useParams} from "react-router-dom";

function SettingsPasswordPage() {
  const {uuid} = useParams<{uuid: string}>();

  const {
    data: userData,
    loading,
    error
  } = useGetUserQuery({
    variables: {
      uuid
    }
  });

  return (
    <SettingsPasswordContainer
      error={error}
      loading={loading}
      user={userData?.getUser as User}
      uuid={uuid}
    />
  );
}

export default SettingsPasswordPage;
