import React from "react";
import {ProgressBar} from "components/ProgressBar";
import {strings} from "config/strings";
import {ResponseMessageTypes} from "components/StyledElements/FormElements";

import {Web3VideoUploadProps} from "./types";
import {Container, ErrorMessage} from "./styles";
import {useWeb3VideoUploadHook} from "./hooks";

const Web3VideoUpload: React.FC<Web3VideoUploadProps> = (
  props: Web3VideoUploadProps
) => {
  const {video} = props;
  const {error, progress} = useWeb3VideoUploadHook(props);
  if (!video) {
    return null;
  }

  return (
    <Container>
      <ProgressBar filename={video.name} percent={progress} />
      {error && (
        <ErrorMessage
          message={strings.pages.upload.videoUploadError}
          type={ResponseMessageTypes.Error}
        />
      )}
    </Container>
  );
};

export default React.memo(Web3VideoUpload);
