import styled from "styled-components";
import Text from "components/Atoms/Text";
import {colors} from "theme/colors";
import {
  PercentageDetailText,
  RootContainer
} from "theme/genericStyles/uploadPopupStyles";

export const BottomContainer = styled(RootContainer)`
  padding-top: 24px;
`;

export const CollapsibleContainer = styled.div`
  padding-top: 24px;
`;

export const GrayText = styled(Text)`
  color: ${colors.primary.lightGrey};
  white-space: pre-wrap;
`;

export const PercentageOfRevenueText = styled(Text)`
  color: ${colors.primary.white};
  margin: 20px 0;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const PercentageDetail = styled.div`
  margin-top: 10px;
`;

export const Subtotal = styled(PercentageDetailText)`
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const YourRevenue = styled(PercentageDetailText)`
  margin-top: 16px;
`;

export const ErrorText = styled(Text)`
  color: ${colors.secondary.error};
`;

export const ExplanationTextContainer = styled.div`
  margin-top: 4px;
  position: relative;
`;

export const ExplanationText = styled(Text)`
  color: ${colors.primary.cyan};
  white-space: pre-wrap;
`;
