import {strings} from "config/strings";

// validate email addresses. from https://emailregex.com/
export const emailRegex =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export function authErrorTranslate(message: string): string {
  if (
    message.includes("Password did not conform with policy") ||
    message.includes("Value at 'password' failed")
  ) {
    return strings.auth.passwordError;
  }
  return message;
}

type ValidPasswordResponse = {
  isValid: boolean;
  message?: string;
};

export function validatePassword(password: string): ValidPasswordResponse {
  let isValid = true;
  let message;

  if (password.length < 8) {
    isValid = false;
    message = strings.auth.passwordLength(8);
  } else if (!/(.*[a-z].*)/.test(password)) {
    isValid = false;
    message = strings.auth.passwordLowercase;
  } else if (!/(.*[A-Z].*)/.test(password)) {
    isValid = false;
    message = strings.auth.passwordUppercase;
  } else if (!/(.*\d.*)/.test(password)) {
    isValid = false;
    message = strings.auth.passwordNumber;
  }

  return {
    isValid,
    message
  };
}
