import React, {useEffect, useState} from "react";
import {ChangeHandler} from "react-hook-form";

import {Container, InputField, Label} from "./styles";

type Props = {
  type: string;
  label?: string;
  value?: string;
  placeholder?: string;
  defaultValue?: string | null;
  readOnly?: boolean;
  style?: Record<string, string | number>;
  invalid?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: ChangeHandler;
  onClick?: () => void;
};

export const Input = React.forwardRef<HTMLInputElement, Props>(
  ({defaultValue, readOnly, ...props}: Props, ref) => {
    const [value, setValue] = useState("");
    const [isActive, setIsActive] = useState(false);

    const valueIsPresent = isActive || value.length > 0;

    useEffect(() => {
      if (defaultValue) {
        setValue(defaultValue);
      }
    }, [defaultValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      setValue(e.target.value);

      if (props.onChange) {
        props.onChange(e);
      }
    };

    const handleBlur = (e: React.ChangeEvent<HTMLInputElement>): void => {
      setIsActive(false);

      if (props.onBlur) {
        props.onBlur(e);
      }
    };

    const handleFocus = (): void => {
      setIsActive(true);
    };

    return (
      <Container>
        <InputField
          {...props}
          active={valueIsPresent && !!props.label}
          readOnly={!!readOnly}
          ref={ref}
          style={{cursor: props.onClick ? "pointer" : "auto"}}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
        />
        {props.label && <Label active={valueIsPresent}>{props.label}</Label>}
      </Container>
    );
  }
);
