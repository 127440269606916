import React from "react";

import {eraseVideoState} from "apollo/reactive";
import LogoImg from "icons/eVerse.svg";
import {Routes} from "config/routes";
import {SkeletonTheme} from "react-loading-skeleton";
import MyWallet from "components/Organisms/MyWallet";
import {
  CenterMenu,
  HomeLink,
  LeftAndRightMenu,
  LeftMenu,
  LoadingSkeleton,
  Logo,
  RightMenu,
  StyledTopNavigation
} from "./styles";
import {useTopNavigation} from "./hooks";

const TopNavigation: React.FC = () => {
  const {
    displayState,
    loading,
    renderLeftItems,
    renderCenterItems,
    renderRightItems,
    scrolled,
    user,
    isWalletOpen,
    toggleWallet
  } = useTopNavigation();

  return (
    <StyledTopNavigation
      aria-label="main menu"
      className={scrolled ? "scrolled" : ""}
      displayState={displayState}
      role="navigation"
    >
      <HomeLink to={Routes.home}>
        <Logo alt="eVerse Logo" src={LogoImg} onClick={eraseVideoState} />
      </HomeLink>

      <LeftAndRightMenu>
        <LeftMenu>{renderLeftItems()}</LeftMenu>
        <CenterMenu>{renderCenterItems()}</CenterMenu>
        <RightMenu>
          {renderRightItems()}
          <SkeletonTheme color="#202020" highlightColor="#444">
            {loading && <LoadingSkeleton height={23} width={410} />}
          </SkeletonTheme>
        </RightMenu>
      </LeftAndRightMenu>
      {isWalletOpen && <MyWallet user={user} toggleWallet={toggleWallet} />}
    </StyledTopNavigation>
  );
};

export default React.memo(TopNavigation);
