import React, {useEffect} from "react";
import {withRouter, RouteComponentProps} from "react-router-dom";

const Component: React.FC<RouteComponentProps> = ({
  location,
  history
}: RouteComponentProps) => {
  const segment: any = window.analytics;

  useEffect(() => {
    if (history.action === "PUSH" && segment) {
      segment.page(location.pathname);
    }
  });

  return null;
};

export const Analytics = withRouter(Component);
