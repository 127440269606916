import {FieldValidationMessage} from "components/StyledElements/FormElements";
import {strings} from "config/strings";
import {useEffect, useState} from "react";
import styled from "styled-components";
import LeftIcon from "icons/20/chevron-left.svg";
import UsernameInput from "components/UsernameInput/UsernameInput";
import ChangeBackIcon from "icons/16/refresh-ccw.svg";
import {useCheckUsernameReservationQuery} from "apollo";
import {Spinner} from "components/Spinner";
import ProfileEditSaveButton from "components/Button/ProfileEditSaveButton";
import {Modal} from "components/Modal";
import {
  BackButton,
  BackIcon,
  ChangeBackButton,
  ChangeBackIconContainer,
  TextSection,
  Title,
  UsernameText,
  Text
} from "./styles";

const Container = styled.div`
  position: absolute;
  top: 140px;
  left: 120px;
  width: 710px;
`;

type ChangeUsernameProps = {
  initialState: string;
  currentUserId: string;
  updateError: boolean;
  usernameUpdated: boolean;
  usernameReverted: boolean;
  onSave: (value: string, reverted?: boolean) => void;
  hideForm: () => void;
  onError: () => void;
};

const ChangeUsername = ({
  initialState,
  currentUserId,
  updateError,
  usernameUpdated,
  usernameReverted,
  hideForm,
  onSave,
  onError
}: ChangeUsernameProps) => {
  const [username, setUsername] = useState<string>("");
  const [reservedUsername, setReservedUsername] = useState("");
  const [reservationTime, setReservationTime] = useState(0);
  const [usernameError, setUsernameError] = useState<string | undefined>();
  const [displayReservation, setDisplayReservation] = useState(false);
  const [message, setMessage] = useState<string>();
  const {
    data,
    loading,
    error: reservationError
  } = useCheckUsernameReservationQuery({
    variables: {uuid: currentUserId},
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    setUsername(initialState);
  }, [initialState]);

  useEffect(() => {
    if (reservationError) {
      onError();
    }

    if (
      data?.checkUsernameReservation &&
      data.checkUsernameReservation.username
    ) {
      setReservedUsername(data.checkUsernameReservation.username);
      setReservationTime(
        parseInt(data.checkUsernameReservation.remainingTime || "0", 10)
      );
      setDisplayReservation(true);
    }
  }, [data, reservationError]);

  useEffect(() => {
    const result = usernameReverted
      ? strings.pages.profile.usernameReverted.replace("{0}", username)
      : strings.pages.profile.usernameChanged.replace("{0}", username);
    setMessage(result);
  }, [usernameReverted, username]);

  const handleOnChange = (value: string) => {
    setUsername(value);
  };

  return (
    <>
      {loading && <Spinner />}
      {!loading && !reservationError && (
        <>
          <BackButton onClick={hideForm}>
            <BackIcon src={LeftIcon} />
            {strings.generic.back}
          </BackButton>
          <Title>{strings.pages.profile.changeUsername}</Title>
          <Container>
            {!displayReservation && (
              <>
                <UsernameInput
                  initialValue={username}
                  invalid={!!usernameError}
                  label={strings.pages.profile.currentUsername}
                  maxChars={25}
                  validateOnBlur={true}
                  onChangeFn={handleOnChange}
                  onErrorFn={setUsernameError}
                  onFocusFn={() => setUsernameError("")}
                />
                {usernameError && (
                  <FieldValidationMessage>
                    {usernameError}
                  </FieldValidationMessage>
                )}
                <Text>{strings.pages.profile.usernameConstraints}</Text>
              </>
            )}
            {displayReservation && (
              <>
                {/* eslint-disable-next-line react-extra/no-inline-styles -- MAR-844 */}
                <TextSection style={{margin: "0px"}}>
                  {strings.pages.profile.currentUsername}
                </TextSection>
                <UsernameText>{`@${username}`}</UsernameText>
                <TextSection>{strings.pages.profile.previousName}</TextSection>
                <UsernameText>{`@${reservedUsername}`}</UsernameText>
                <ChangeBackButton
                  style={{
                    top: displayReservation ? "107px" : "144px"
                  }}
                  onClick={() => onSave(reservedUsername, true)}
                >
                  <ChangeBackIconContainer src={ChangeBackIcon} />
                  {strings.pages.profile.changeBack}
                </ChangeBackButton>
                <Text>
                  {strings.pages.profile.previousNameInfo.replace(
                    "{0}",
                    reservationTime.toString()
                  )}
                </Text>
              </>
            )}
          </Container>
          <ProfileEditSaveButton
            error={!!updateError}
            hideSaveButton={displayReservation}
            style={{top: !displayReservation ? "249px" : "377px"}}
            onSave={() => onSave(username)}
          />
          <Modal
            closeModalFn={hideForm}
            isDialog={true}
            isMessage={true}
            isOpen={usernameUpdated}
            message={message}
          />
        </>
      )}
    </>
  );
};

export default ChangeUsername;
