import styled from "styled-components";
import Text from "components/Atoms/Text";
import {colors} from "theme/colors";

export const HighlightText = styled(Text)`
  color: ${colors.primary.lightGrey};

  mark {
    color: ${colors.primary.white};
    background: transparent;
  }
`;
