import {strings} from "config/strings";
import React, {useCallback, MouseEvent, useEffect, ChangeEvent} from "react";
import {useSearchBox} from "react-instantsearch-hooks-web";
import {useLocation} from "react-router";
import {InputIconSide} from "components/Atoms/TextInput/types";

import {ClearButton, ClearIcon, SearchIcon} from "./styles";
import {AlgoliaSearchBoxProps, ClearSearch} from "./types";

export function useAlgoliaSearchBox(props: AlgoliaSearchBoxProps) {
  const {clear, query, refine} = useSearchBox(props);
  const {search} = useLocation();
  const {onChange} = props;

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const searchQuery = searchParams.get("q");
    if (searchQuery) {
      refine(searchQuery);
    }
  }, [refine, search]);

  useEffect(() => {
    if (onChange) {
      onChange(query);
    }
  }, [query, onChange]);

  const clearOnMouseDown = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  }, []);

  const renderIcon = useCallback(
    (color: string, side?: InputIconSide) => {
      if (side === InputIconSide.left) {
        return <SearchIcon stroke={color} strokeWidth={1.5} />;
      }

      if (query.length === 0) {
        return null;
      }

      return (
        <ClearButton
          type="button"
          id={ClearSearch}
          onMouseDown={clearOnMouseDown}
          onClick={clear}
        >
          {strings.search.clear}
          <ClearIcon stroke={color} strokeWidth={1.5} />
        </ClearButton>
      );
    },
    [clear, clearOnMouseDown, query.length]
  );

  const textOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      refine(e.target.value);
    },
    [refine]
  );

  return {query, renderIcon, textOnChange};
}
