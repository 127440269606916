import React from "react";
import Dropzone from "components/Molecules/Dropzone";
import {DropzoneConfig} from "components/Molecules/Dropzone/types";
import {Spinner} from "components/Spinner";
import {TextTypes} from "components/Atoms/Text/types";
import {FormProvider} from "react-hook-form";
import {strings} from "config/strings";

import {useThumbnailUpload} from "components/Organisms/ThumbnailUpload/hooks";
import {ThumbnailUploadProps} from "./types";
import {
  Container,
  Error,
  ProgressBackground,
  SpinnerContainer,
  UploadingText
} from "./styles";

const dropzoneConfig: DropzoneConfig = {
  accept: "image/gif",
  aspectRatio: "16/9",
  name: "file"
};

const ThumbnailUpload: React.FC<ThumbnailUploadProps> = (
  props: ThumbnailUploadProps
) => {
  const {isVisible, switchThumbnailType} = props;
  const {errors, methods, uploading} = useThumbnailUpload(props);

  return (
    <Container isVisible={isVisible}>
      <FormProvider {...methods}>
        <form>
          <Dropzone
            config={dropzoneConfig}
            invalid={!!errors.file}
            label={strings.dropzone.dndThumbnail}
            shouldOpenOnMount={isVisible}
            shouldHideUI={true}
            onCancel={switchThumbnailType}
          />
          {errors.file && (
            <Error>{strings.pages.upload.thumbnail.uploadThumbError}</Error>
          )}
        </form>
      </FormProvider>
      {uploading && (
        <ProgressBackground>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
          <UploadingText textType={TextTypes.SubHeadings.SH3}>
            {strings.pages.upload.uploading}
          </UploadingText>
        </ProgressBackground>
      )}
    </Container>
  );
};

export default React.memo(ThumbnailUpload);
