import {FeedVideo, useListVideosQuery} from "apollo";
import {Spinner} from "components/Spinner";
import Player from "components/Video/Player";
import {PROFILE_VIDEOS_PER_PAGE} from "config/constants";
import {getPostedVideoUrl} from "config/routes";
import {useIndexedVideoPlayer} from "hooks/useIndexedVideoPlayer";
import ErrorPage from "pages/ErrorPage";
import {useEffect} from "react";
import {useHistory} from "react-router";

const PostedVideosPlayer = () => {
  const history = useHistory();
  const {
    user,
    videoId,
    position,
    getSkip,
    error,
    setError,
    loading: indexLoading,
    getIndex
  } = useIndexedVideoPlayer();

  const {data, loading, refetch} = useListVideosQuery({
    variables: {
      uuid: user?.uuid,
      page: {
        take: PROFILE_VIDEOS_PER_PAGE,
        skip: getSkip()
      }
    },
    skip: !user
  });

  const videos = data?.listVideos?.videos || [];
  const count = data?.listVideos?.pager?.count || 0;
  const index = getIndex(videos.length);

  useEffect(() => {
    if (videos.length > 0) {
      setError(videos[index]?.externalId !== videoId);
    }
  }, [videos, videoId, index, setError]);

  const handleVideoChange = async (newIndex: number) => {
    if (videos.length > 0) {
      const shouldLoadPreviousPage = newIndex < 0 && position > 0;
      const shouldLoadNextPage =
        count && newIndex < count && newIndex >= videos.length;
      const shouldMoveInsidePage = count && newIndex < count && newIndex >= 0;

      if (shouldLoadPreviousPage || shouldLoadNextPage) {
        const newPosition = position + (shouldLoadNextPage ? 1 : -1);
        const newVidioIndex = shouldLoadNextPage
          ? 0
          : PROFILE_VIDEOS_PER_PAGE - 1;
        const {data: newData} = await refetch({
          uuid: user?.uuid!,
          page: {
            take: PROFILE_VIDEOS_PER_PAGE,
            skip: getSkip(newPosition)
          }
        });

        const externalId =
          newData?.listVideos?.videos?.[newVidioIndex]?.externalId || "";
        const url = getPostedVideoUrl(user?.username!, externalId, newPosition);
        history.push(url);
      } else if (shouldMoveInsidePage) {
        const externalId = videos[newIndex]?.externalId!;
        const newPosition = position + (newIndex < index ? -1 : 1);
        const url = getPostedVideoUrl(user?.username!, externalId, newPosition);
        history.push(url);
      }
    }
  };

  const isLoading = indexLoading || loading;

  return (
    <>
      {isLoading && !error && <Spinner />}
      {!isLoading && !error && (
        <Player
          handleVideoChange={handleVideoChange}
          videoIndex={index}
          videos={data?.listVideos?.videos as Array<FeedVideo>}
        />
      )}
      {error && <ErrorPage />}
    </>
  );
};

export default PostedVideosPlayer;
