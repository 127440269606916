import React from "react";
import Tooltip from "components/Atoms/Tooltip";

import {InfoDialogProps} from "./types";
import {StyledInfoIcon} from "./styles";

const InfoDialog: React.FC<InfoDialogProps> = ({
  className,
  color,
  info
}: InfoDialogProps) => {
  return (
    <Tooltip text={info}>
      <StyledInfoIcon className={className} color={color} />
    </Tooltip>
  );
};

export default React.memo(InfoDialog);
