import React, {useState} from "react";
import styled, {CSSProperties} from "styled-components";
import {textGray, formStyles} from "config/styleVars";
import DownArrow from "icons/24/chevron-down.svg";

type WrapperProps = {
  invalid: string | undefined;
  isFocused: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  position: relative;
  display: inline-block;
  margin-bottom: ${(props) => (props.invalid ? 0 : formStyles.marginBottom)};
  border: 1px solid
    ${(props) => {
      if (props.isFocused) return formStyles.activeBorderColor;
      if (props.invalid) return formStyles.errorBorderColor;
      return formStyles.borderColor;
    }};
  border-radius: ${formStyles.borderRadius};
  background: ${formStyles.backgroundColor};
  box-sizing: border-box;
  margin-bottom: ${(props) => (props.invalid ? 0 : formStyles.marginBottom)};
`;

const Label = styled.label`
  position: absolute;
  pointer-events: none;
  left: 14px;
  top: 2px;
  font-size: 13px;
  color: ${textGray};
  pointer-events: none;
`;

const SelectEl = styled.select`
  appearance: none;
  color: ${formStyles.textColor};
  background: black;
  border: none;
  width: 100%;
  padding: ${formStyles.inputPadding} ${formStyles.inputPadding} 2px
    ${formStyles.inputPadding};
  outline: none;
  font-weight: 200;
  font-size: 15px;
  box-sizing: border-box;
  line-height: 30px;
  border-radius: 4px;
`;

const DownIcon = styled.img`
  position: absolute;
  right: 7px;
  top: 16px;
  pointer-events: none;
`;

export type Option = {
  label: string;
  value: string;
};

type SelectProps = {
  label: string;
  options: Array<Option>;
  invalid?: string | undefined;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  onBlur?: () => void;
  onFocus?: () => void;
  style?: CSSProperties;
};

const Select = (props: SelectProps): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false);

  function handleFocus() {
    setIsFocused(true);
    if (props.onFocus) {
      props.onFocus();
    }
  }

  function handleBlur() {
    setIsFocused(false);
    if (props.onBlur) {
      props.onBlur();
    }
  }
  const {style, onChange, label, options, invalid} = props;

  return (
    <Wrapper invalid={invalid} isFocused={isFocused} style={style}>
      <Label>{label}</Label>
      <SelectEl onBlur={handleBlur} onChange={onChange} onFocus={handleFocus}>
        {options.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </SelectEl>
      <DownIcon alt="down arrow" src={DownArrow} />
    </Wrapper>
  );
};

export default Select;
