/* eslint-disable react-extra/no-inline-styles -- MAR-844 */
import React, {useEffect, useState, useRef, useCallback} from "react";
import {InputField} from "components/Input/styles";
import ReactCrop, {Crop} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {useGetUploadProfileImageLinkLazyQuery, User} from "apollo";
import {useImageUpload} from "hooks/useImageUpload";
import {useUser} from "hooks/useUser";
import {Spinner} from "components/Spinner";
import {strings} from "config/strings";
import {CropContainer, Cropholder, CropError} from "./styles";
import {ProfileImageTypes} from ".";

type Props = {
  doneCropping: boolean;
  doneUploadingFn: () => void;
  type: ProfileImageTypes;
  isDisplayed: boolean;
};

export const ProfileImageInput: React.FC<Props> = ({
  doneCropping,
  type,
  doneUploadingFn
}: Props) => {
  const imgRef = useRef(null);

  const getInitialCrop = (): Crop => {
    if (type === ProfileImageTypes.avatar) {
      return {
        x: 25,
        y: 25,
        width: 100,
        height: 100,
        unit: "px",
        aspect: 1
      };
    }
    return {
      x: 25,
      y: 25,
      width: 160,
      height: 90,
      unit: "px",
      aspect: 16 / 9
    };
  };

  const [oldImageUrl, setOldImageUrl] = useState<string | null>();
  const [src, setSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>(getInitialCrop());
  const [percentCrop, setPCrop] = useState<Crop>();
  const [file, setFile] = useState<File>();
  const [getLink, {data: link, error: linkError}] =
    useGetUploadProfileImageLinkLazyQuery();
  const {start, uploaded, error: uploadError} = useImageUpload();
  const {user, startPolling, stopPolling} = useUser();
  const [loading, setLoading] = useState(false);

  // file types that skip the cropping tool
  const SKIP_CROP_TYPES = ["image/gif"];
  const FILE_INPUT_ACCEPT_TYPES = "image/jpg,image/jpeg,image/png,image/gif";

  const upload = () => {
    if (file) {
      // the cropping tool (canvas.toBlob) generates PNG regardless of the input type
      const saveFileType = SKIP_CROP_TYPES.includes(file.type)
        ? file.type
        : "image/png";
      getLink({
        variables: {
          uploadObjectType: type,
          uploadObjectContentType: saveFileType
        }
      });
    }
  };

  const getImageUrl = (currentUser: User) => {
    if (currentUser) {
      if (type === ProfileImageTypes.avatar) {
        return currentUser.avatar;
      }
      if (type === ProfileImageTypes.banner) {
        return currentUser.banner;
      }
    }
    return null;
  };

  const getCroppedImage = () => {
    if (!imgRef.current || !percentCrop) {
      return;
    }
    const pixelRatio = window.devicePixelRatio;
    const image = imgRef.current as HTMLImageElement;
    const canvas = document.createElement("canvas");

    canvas.width = image.naturalWidth * (percentCrop.width / 100) * pixelRatio;
    canvas.height =
      image.naturalHeight * (percentCrop.height / 100) * pixelRatio;

    const ctx = canvas.getContext("2d");

    if (ctx === null) return;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      image.naturalWidth * (percentCrop.x / 100),
      image.naturalHeight * (percentCrop.y / 100),
      canvas.width,
      canvas.height,
      0,
      0,
      canvas.width,
      canvas.height
    );

    /* eslint-disable-next-line consistent-return -- MAR-843 */
    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          blob.name = "new.png";
          resolve(blob);
        },
        "image/png",
        1
      );
    });
  };

  // user clicks the "Save" button in the modal
  useEffect(() => {
    if (doneCropping) {
      setLoading(true);
      upload();
    }
  }, [doneCropping]);

  // trigger file upload when we get the link back
  useEffect(() => {
    (async function () {
      if (link && link.getUploadProfileImageLink && file) {
        const url = link.getUploadProfileImageLink.uploadUrl!;
        if (SKIP_CROP_TYPES.includes(file.type)) {
          start(url, file);
        } else if (percentCrop) {
          const cropFile = await getCroppedImage();
          start(url, cropFile as File);
        }
      }
    })();
  }, [link]);

  // callback to start polling user to figure out when image processing is done
  useEffect(() => {
    if (uploaded) {
      startPolling(800);
    }
  }, [uploaded]);

  // watch user, wait for url to update and then call success to close modal
  useEffect(() => {
    if (user && !oldImageUrl) {
      setOldImageUrl(getImageUrl(user!));
    } else if (user && oldImageUrl !== getImageUrl(user!)) {
      stopPolling();
      doneUploadingFn();
    }
  }, [user]);

  // stop loading spinner if an error comes back
  useEffect(() => {
    if (linkError || uploadError) {
      setLoading(false);
    }
  }, [linkError, uploadError]);

  const isError = (): boolean => {
    return !!linkError || !!uploadError;
  };

  const isLoading = (): boolean => {
    return loading && !isError();
  };

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (src) {
      setSrc(null);
    }
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
      const filetype = e.target.files[0].type;
      // eslint-disable-next-line no-empty
      if (SKIP_CROP_TYPES.includes(filetype)) {
      } else {
        const reader = new FileReader();
        reader.addEventListener("load", () =>
          setSrc(reader.result?.toString()!)
        );
        reader.readAsDataURL(e.target.files[0]);
        setCrop(getInitialCrop());
      }
    }
  };

  const handleCropChange = (newCrop: Crop, percentageCrop: Crop) => {
    setPCrop(percentageCrop);
    setCrop(newCrop);
  };

  const handleImageLoaded = useCallback((img) => {
    imgRef.current = img;
  }, []);

  return (
    <div>
      {isLoading() && <Spinner />}
      {!isError() && (
        <CropContainer isLoading={!!isLoading()}>
          <InputField
            accept={FILE_INPUT_ACCEPT_TYPES}
            type="file"
            onChange={handleSelectFile}
          />
          <Cropholder>
            {src && (
              <ReactCrop
                crop={crop}
                src={src}
                // eslint-disable-next-line react-extra/no-inline-styles -- MAR-844
                style={{border: "1px solid #4B494D"}}
                onChange={handleCropChange}
                onImageLoaded={handleImageLoaded}
              />
            )}
          </Cropholder>
        </CropContainer>
      )}
      {isError() && <CropError>{strings.cropping.error}</CropError>}
    </div>
  );
};
