import {useRef, useState} from "react";

export function usePagination(initialItemsCount: number) {
  const skip = useRef<number>(initialItemsCount);
  const [count, setCount] = useState<number>(0);

  return {
    skip,
    count,
    setCount
  };
}
