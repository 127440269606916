import {useModal} from "hooks/useModal";
import {useForm, useFieldArray} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {tokensAvailableValidator} from "utils/validators/upload";

import {FormEvent, useCallback, useEffect} from "react";
import {TokensAvailableFormValues, TokensAvailableProps} from "./types";

export function useTokensAvailable({
  initialValues,
  onSubmit
}: TokensAvailableProps) {
  const {isModalOpen, toggleModal} = useModal();

  const methods = useForm<TokensAvailableFormValues>({
    mode: "onBlur",
    resolver: yupResolver(tokensAvailableValidator)
  });

  const {
    control,
    formState: {errors, isValid},
    register,
    setValue,
    watch
  } = methods;

  useEffect(() => {
    setValue("tokensAvailable", initialValues);
  }, [initialValues, setValue]);

  const {fields} = useFieldArray({
    control,
    name: "tokensAvailable"
  });

  const formValues = watch();

  const formOnSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
  }, []);

  const saveOnClick = useCallback(() => {
    if (!isValid) {
      return;
    }
    onSubmit(formValues);
    toggleModal();
  }, [formValues, isValid, onSubmit, toggleModal]);

  return {
    errors,
    fields,
    formOnSubmit,
    isModalOpen,
    isValid,
    methods,
    saveOnClick,
    register,
    toggleModal
  };
}
