import styled from "styled-components";
import {colors} from "theme/colors";
import {navHeight} from "config/styleVars";
import {ReactComponent as SearchIconSvg} from "icons/universal/search.svg";
import Text from "../../Atoms/Text";

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SearchIcon = styled(SearchIconSvg)`
  width: 22px;
  height: 22px;
  pointer-events: none;
  margin-bottom: 30px;
`;

export const TotalContainer = styled.div`
  position: sticky;
  background-color: ${colors.primary.black};
  height: ${navHeight + 30}px;
  top: 0px;
  z-index: 89;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 12px;
  padding-left: 16px;
`;

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
  width: 100%;
  max-width: 880px;
`;

export const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 12px;
  margin-top: 72px;
`;

export const GrayText = styled(Text)`
  color: ${colors.primary.mediumGrey};
`;
