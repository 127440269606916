const FEED = "Feed";
const VIDEO_TOOLS = "VideoTools";

export interface FeedSession {
  count: number;
  itemsToSkip: number;
  currentFeedId: string;
}

export interface ToolState {
  informationBox?: boolean;
  informationBoxScrollY?: number;
  comments?: boolean;
}

const useSessionStorage = () => {
  const getCachedFeed = () => {
    const stringValue = window.sessionStorage.getItem(FEED);
    if (stringValue) {
      return JSON.parse(stringValue) as FeedSession;
    }

    return null;
  };

  const updateCachedFeed = (value: FeedSession) => {
    const stringValue = JSON.stringify(value);
    window.sessionStorage.setItem(FEED, stringValue);
  };

  const getToolState = () => {
    const stringValue = window.sessionStorage.getItem(VIDEO_TOOLS);
    if (stringValue) {
      return JSON.parse(stringValue) as ToolState;
    }

    return null;
  };

  const updateToolState = (value: ToolState) => {
    const stringValue = JSON.stringify(value);
    window.sessionStorage.setItem(VIDEO_TOOLS, stringValue);
  };

  return {getCachedFeed, updateCachedFeed, getToolState, updateToolState};
};

export default useSessionStorage;
