import React from "react";
import Button from "components/Atoms/Button";
import {ButtonSize, ButtonType} from "components/Atoms/Button/types";
import {ReactComponent as EditIcon} from "icons/20/edit.svg";
import {ReactComponent as PlusIcon} from "icons/plus.svg";

import {AddEditButtonProps} from "./types";

const AddEditButton: React.FC<AddEditButtonProps> = ({
  disabled,
  isEdit,
  onClick
}: AddEditButtonProps) => {
  return (
    <Button
      disabled={disabled}
      type={ButtonType.secondary}
      size={ButtonSize.m}
      onClick={onClick}
      renderIcon={(color, size) =>
        isEdit ? (
          <EditIcon
            width={size}
            height={size}
            stroke={color}
            strokeWidth={1.5}
          />
        ) : (
          <PlusIcon
            width={size}
            height={size}
            stroke={color}
            strokeWidth={1.5}
          />
        )
      }
    />
  );
};

export default React.memo(AddEditButton);
