import {useCallback, useEffect} from "react";
import {getProfileUrl, Routes} from "config/routes";
import {useHistory} from "react-router";
import {useUser} from "hooks/useUser";
import {AnalyticsEvents} from "config/constants";

export function useVideoUploadSuccess() {
  const history = useHistory();
  const {user} = useUser();

  useEffect(() => {
    if (window.analytics) {
      window.analytics.track(AnalyticsEvents.UploadProcessEnd, {
        uuid: user?.uuid
      });
    }
  }, [user]);

  const homeOnClick = useCallback(() => {
    history.push(Routes.home);
  }, [history]);

  const userOnClick = useCallback(() => {
    if (!user) {
      return;
    }

    history.push(getProfileUrl(user.username!));
  }, [history, user]);

  return {homeOnClick, userOnClick};
}
