import {useMemo} from "react";
import {formatCountNumber} from "utils/FormatUtils";

export const useCountFormat = (
  count: number | undefined,
  deps: any[] = []
): string => {
  const formattedCount = count ? formatCountNumber(count) : "";
  return useMemo(() => formattedCount, [formattedCount, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps
};
