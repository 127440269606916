import styled from "styled-components";
import {colors} from "theme/colors";

export const Container = styled.div`
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const LinkButton = styled.a`
  color: ${colors.primary.cyan};
  margin-top: 16px;
  text-decoration: underline;
  text-underline-offset: 6px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;
