import {strings} from "config/strings";
import chevronIcon from "icons/chevron-right.svg";
import {Button, Container, Icon, Label, Username} from "./styles";

type ChangeUsernameButtonProps = {
  username: string;
  onClick: () => void;
};

const ChangeUsernameButton = ({
  username,
  onClick
}: ChangeUsernameButtonProps) => (
  <Container>
    <Label>{strings.generic.username}</Label>
    <Username>{`@${username}`}</Username>
    <Button onClick={onClick}>
      {strings.pages.profile.changeUsername}
      <Icon src={chevronIcon} />
    </Button>
  </Container>
);

export default ChangeUsernameButton;
