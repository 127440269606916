import React from "react";

import {ButtonProps} from "./types";
import {IconContainer, RootContainer, StyledText} from "./styles";
import {useButton} from "./hooks";

const StyledButton: React.FC<ButtonProps> = (props: ButtonProps) => {
  const {className, disabled, iconSide, size, onClick, renderIcon, text, type} =
    props;
  const {iconColor, iconSize, textType} = useButton(props);

  return (
    <RootContainer
      buttonSize={size}
      buttonType={type}
      className={className}
      disabled={disabled}
      onClick={onClick}
      hasIcon={!!renderIcon}
      text={text}
    >
      <StyledText textType={textType}>{text}</StyledText>
      {renderIcon && (
        <IconContainer iconSide={iconSide} text={text}>
          {renderIcon(iconColor, iconSize)}
        </IconContainer>
      )}
    </RootContainer>
  );
};

export default React.memo(StyledButton);
