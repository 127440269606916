/* eslint-disable security/detect-object-injection */
import {
  MultiValue,
  GroupBase,
  OptionsOrGroups,
  SingleValue
} from "react-select";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {TextTypes} from "components/Atoms/Text/types";
import {
  RenderIcon,
  UnlockableDropdownDataProps
} from "components/Atoms/UnlockableDropdownOption/types";
import {Option} from "components/Select/Select";
import Dropdown from "components/Atoms/Dropdown";
import UnlockableDropdownOption, {
  MenuList
} from "components/Atoms/UnlockableDropdownOption";

import {OnDropdownChange} from "components/Atoms/Dropdown/types";
import {strings} from "config/strings";
import {UnlockableContentTypes as ContentType} from "apollo";
import {
  ExclusiveBenefitsProps,
  ContentMap,
  RequireCode,
  IFormValues,
  Reward,
  TierNumberDescription
} from "./types";
import {getUnlockableFormValidator} from "./utils";

import {
  FieldContainer,
  RewardName,
  TextInputStyled,
  InlineInputContainer,
  TierName,
  TierRow,
  ContentToTiersH6
} from "./styles";

const renderContentIcon = (content: ContentType[], renderLabel?: boolean) => {
  return content.map((type) => {
    const Icon = ContentMap[type].icon;
    const {label} = ContentMap[type];
    return (
      <>
        <Icon />
        {renderLabel && <span>{label}</span>}
      </>
    );
  });
};

const getRenderIcon = (type: ContentType): RenderIcon => {
  const Icon = ContentMap[type].icon;
  return (color: string, size: number) => (
    <Icon fill={color} width={size} height={size} />
  );
};

const dropdownOptions: OptionsOrGroups<
  UnlockableDropdownDataProps,
  GroupBase<UnlockableDropdownDataProps>
> = [
  {
    value: ContentType.DiscordInvite,
    label: ContentMap.DISCORD_INVITE.label,
    renderIcon: getRenderIcon(ContentType.DiscordInvite)
  },
  {
    value: ContentType.SendProduct,
    label: ContentMap.SEND_PRODUCT.label,
    renderIcon: getRenderIcon(ContentType.SendProduct)
  },
  {
    value: ContentType.ScheduledCall,
    label: ContentMap.SCHEDULED_CALL.label,
    renderIcon: getRenderIcon(ContentType.ScheduledCall)
  },
  {
    value: ContentType.DigitalTicket,
    label: ContentMap.DIGITAL_TICKET.label,
    renderIcon: getRenderIcon(ContentType.DigitalTicket)
  }
];

const getDefaultValue = (values: ContentType[]) => {
  return dropdownOptions.filter((option) => {
    const value = (option as UnlockableDropdownDataProps).value as ContentType;
    return values?.includes(value);
  });
};

const getContentTypes = (tiers: Array<Reward[]>): Array<ContentType[]> => {
  return tiers.map((tier) => {
    return tier.map((reward) => reward.type);
  });
};

const getRewardsPerTier = (
  rewardValues: IFormValues,
  tiers: Array<ContentType[]>
): Array<Reward[]> => {
  return tiers.map((rewards) => {
    return rewards.map((type): Reward => {
      const reward = rewardValues[type] as Reward;
      return {
        ...reward,
        type
      };
    });
  });
};

const getRewardValues = (tiers: Array<Reward[]> = []): IFormValues => {
  const rewardValues: IFormValues = {};
  tiers.forEach((tier) => {
    tier.forEach((reward) => {
      rewardValues[reward.type] = reward;
    });
  });
  return rewardValues;
};

export function useUnlockableContent({
  tiersNumber,
  value
}: ExclusiveBenefitsProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rewardsPerTier, setRewardsPerTier] = useState<Array<ContentType[]>>(
    Array(tiersNumber.length).fill([])
  );
  const toggleModal = useCallback(() => {
    setIsModalOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    if (value?.length) {
      setRewardsPerTier(getContentTypes(value));
    } else {
      setRewardsPerTier(Array(tiersNumber.length).fill([]));
    }
  }, [value, tiersNumber.length]);

  const isSubmitDisabled =
    rewardsPerTier[rewardsPerTier.length - 1]?.length === 0;

  const onChangeRegisteredDropdown = useRef<{
    [key: number]: OnDropdownChange<Option>;
  }>({});

  const onDropdownChange = useCallback(
    (options: MultiValue<Option>, tierNumber: number) => {
      const values = options.map((option) => option.value as ContentType);
      setRewardsPerTier((prev) => {
        const tierRewards = prev.slice(0);
        tierRewards[tierNumber] = values;
        const lastTierRewards = tierRewards[tierRewards.length - 1];
        const newRewards = values.filter((r) => !lastTierRewards.includes(r));
        lastTierRewards.push(...newRewards);
        // clean rewards NOT included in the last tier
        const newTiersRewards = tierRewards.map((rewards) => {
          return rewards.filter((reward) => lastTierRewards.includes(reward));
        });

        return newTiersRewards;
      });
    },
    []
  );

  const registerOnDropdownChange = useCallback(
    (tierNumber: number) => {
      if (onChangeRegisteredDropdown.current[tierNumber]) {
        return onChangeRegisteredDropdown.current[tierNumber];
      }
      onChangeRegisteredDropdown.current[tierNumber] = (
        options?: SingleValue<Option> | MultiValue<Option>
      ) => {
        if (!Array.isArray(options)) {
          return;
        }
        onDropdownChange(options, tierNumber);
      };
      return onChangeRegisteredDropdown.current[tierNumber];
    },
    [onDropdownChange]
  );

  const renderTiersToRewards = useCallback(() => {
    return tiersNumber.map((tierId, index) => (
      <>
        <ContentToTiersH6
          textType={TextTypes.Titles.H6}
          key={`tier-number ${tierId}`}
        >
          {
            strings.pages.upload.metaItems.exclusivebenefits
              .exclusivebenefitsModal.exclusivebenefitsPerTier
          }
          &nbsp;
          {index + 1}&nbsp;
          <span>({TierNumberDescription[tierId - 1]})</span>
        </ContentToTiersH6>
        <Dropdown
          isMulti
          value={getDefaultValue(rewardsPerTier[index])}
          // @ts-ignore
          onChange={registerOnDropdownChange(index)}
          // @ts-ignore
          OptionComponent={UnlockableDropdownOption}
          // @ts-ignore
          MenuListComponent={MenuList}
          options={dropdownOptions}
        />
      </>
    ));
  }, [tiersNumber, registerOnDropdownChange, rewardsPerTier]);

  return {
    toggleModal,
    onDropdownChange,
    isModalOpen,
    isSubmitDisabled,
    dropdownOptions,
    renderTiersToRewards,
    rewardsPerTier
  };
}

export function useContentFields(
  {onChange, value}: ExclusiveBenefitsProps,
  rewardsPerTier: Array<ContentType[]>
) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const initialValues = useMemo(() => {
    return getRewardValues(value);
  }, [value]);

  const methods = useForm<IFormValues>({
    resolver: yupResolver(
      getUnlockableFormValidator(
        rewardsPerTier[rewardsPerTier.length - 1] || []
      )
    ),
    mode: "onChange"
  });
  const {
    register,
    formState: {errors, isValid}
  } = methods;

  const toggleModal = useCallback(() => {
    setIsModalOpen((prev) => !prev);
  }, []);

  const onSubmitForm = useCallback(() => {
    if (!onChange) {
      return;
    }
    const values = methods.getValues();
    const rewards = getRewardsPerTier(values, rewardsPerTier);
    onChange(rewards);
    toggleModal();
  }, [methods, onChange, rewardsPerTier, toggleModal]);

  const renderTiersRewards = useCallback(() => {
    return rewardsPerTier.map((items, index) => {
      return (
        <TierRow
          // eslint-disable-next-line react/no-array-index-key
          key={`tier-${index}`}
          isLast={index === rewardsPerTier.length - 1}
        >
          <TierName textType={TextTypes.Paragraph.L}>
            <span>
              {strings.generic.tier} {index + 1}:
            </span>
            {renderContentIcon(items)}
          </TierName>
        </TierRow>
      );
    });
  }, [rewardsPerTier]);

  const renderContentFields = useCallback(() => {
    const rewards = rewardsPerTier[rewardsPerTier.length - 1] || [];
    return rewards.map((reward) => {
      const requireCode = RequireCode.includes(reward);

      return (
        <FieldContainer key={reward}>
          <RewardName textType={TextTypes.SubHeadings.SH4}>
            {renderContentIcon([reward], true)}
          </RewardName>
          <TextInputStyled
            placeholder={
              strings.pages.upload.metaItems.exclusivebenefits
                .exclusivebenefitsModal.fields.title
            }
            isInvalid={!!errors[reward]?.description}
            initialValue={initialValues[reward]?.description}
            {...register(`${reward}.description`)}
          />
          <InlineInputContainer>
            <TextInputStyled
              placeholder={
                strings.pages.upload.metaItems.exclusivebenefits
                  .exclusivebenefitsModal.fields.url
              }
              width={requireCode ? "70%" : "100%"}
              initialValue={initialValues[reward]?.url}
              isInvalid={!!errors[reward]?.url}
              {...register(`${reward}.url`)}
            />
            {requireCode && (
              <TextInputStyled
                placeholder={
                  strings.pages.upload.metaItems.exclusivebenefits
                    .exclusivebenefitsModal.fields.code
                }
                width="30%"
                initialValue={initialValues[reward]?.code}
                marginLeft={16}
                {...register(`${reward}.code`)}
              />
            )}
          </InlineInputContainer>
        </FieldContainer>
      );
    });
  }, [errors, register, rewardsPerTier, initialValues]);

  return {
    methods,
    renderContentFields,
    onSubmitForm,
    renderTiersRewards,
    toggleModal,
    isModalOpen,
    isFormInvalid: !isValid
  };
}
