import React, {useCallback} from "react";
import {ReactComponent as TokensAvailableIcon} from "icons/universal/tokensAvailable.svg";
import AddEditButton from "components/Molecules/AddEditButton";
import InputModal from "components/InputModal";
import {strings} from "config/strings";
import {TextTypes} from "components/Atoms/Text/types";
import TextInput from "components/Atoms/TextInput";
import Tooltip from "components/Atoms/Tooltip";
import {FormProvider} from "react-hook-form";

import {
  GrayText,
  InputLabel,
  RecommendedInput,
  RootContainer
} from "theme/genericStyles/uploadPopupStyles";
import {TokensAvailableProps} from "./types";
import {useTokensAvailable} from "./hooks";
import {Description2} from "./styles";

const TokensAvailable: React.FC<TokensAvailableProps> = (
  props: TokensAvailableProps
) => {
  const {disabled, initialValues, isEdit, tiers} = props;
  const {
    errors,
    fields,
    formOnSubmit,
    isModalOpen,
    isValid,
    methods,
    register,
    saveOnClick,
    toggleModal
  } = useTokensAvailable(props);

  const renderInput = useCallback(
    (index: number, tierId: number) => {
      return (
        <>
          <InputLabel
            marginTop={tierId === 2 && tiers.length === 2 ? 20 : 0}
            textType={TextTypes.Titles.H6}
          >
            {strings.pages.upload.modals.tokensAvailable.numberOfNFTs(tierId)}
            <span>
              {tierId === 1
                ? strings.pages.upload.modals.tokensAvailable.thumbnailLabelSpan
                : strings.pages.upload.modals.tokensAvailable.videoLabelSpan}
            </span>
          </InputLabel>
          <TextInput
            {...register(`tokensAvailable.${index}.value`)}
            helperText={
              !!errors?.tokensAvailable?.length &&
              !!errors.tokensAvailable[index]?.value?.message
                ? errors.tokensAvailable[index]?.value?.message
                : ""
            }
            initialValue={String(initialValues[index]?.value)}
            isInvalid={
              !!errors?.tokensAvailable?.length &&
              !!errors.tokensAvailable[index]?.value
            }
            placeholder={
              strings.pages.upload.modals.tokensAvailable.inputPlaceholder
            }
            type="number"
          />
          <Tooltip
            text={
              tierId === 1
                ? strings.pages.upload.modals.tokensAvailable.recommendedInput
                    .thumbnail
                : strings.pages.upload.modals.tokensAvailable.recommendedInput
                    .video
            }
          >
            <div>
              <RecommendedInput textType={TextTypes.Bodies.B1}>
                {strings.generic.recommendedInput}
              </RecommendedInput>
            </div>
          </Tooltip>
        </>
      );
    },
    [errors.tokensAvailable, initialValues, register, tiers.length]
  );

  return (
    <>
      <AddEditButton
        disabled={disabled}
        isEdit={isEdit}
        onClick={toggleModal}
      />

      <InputModal
        disableBodyPadding
        disableSubmit={!isValid}
        isOpen={isModalOpen}
        title={strings.pages.upload.modals.tokensAvailable.title}
        submitText={strings.generic.save}
        onSubmit={saveOnClick}
        onClose={toggleModal}
        headerIcon={<TokensAvailableIcon width={24} />}
      >
        <RootContainer>
          <GrayText textType={TextTypes.Paragraph.L}>
            {strings.pages.upload.modals.tokensAvailable.description}
          </GrayText>

          <Description2 textType={TextTypes.Titles.H5}>
            {strings.pages.upload.modals.tokensAvailable.description2}
          </Description2>

          <FormProvider {...methods}>
            <form onSubmit={formOnSubmit}>
              {fields.map((item, index) => {
                return (
                  <div key={item.id}>{renderInput(index, tiers[index])}</div>
                );
              })}
            </form>
          </FormProvider>
        </RootContainer>
      </InputModal>
    </>
  );
};

export default React.memo(TokensAvailable);
