import {useState, useEffect, useCallback, useMemo} from "react";
import {Option, VideoCategoryProps} from "./types";

export function useVideoCategory({
  categories,
  categoryOnSelect,
  selectedCategory
}: VideoCategoryProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option>();

  const toggleModal = useCallback(
    () => setIsOpen((prevState) => !prevState),
    []
  );

  const options = useMemo(
    () =>
      categories?.map((category) => ({
        label: category.name || "",
        value: `${category.id}`
      })) || [],
    [categories]
  );

  useEffect(() => {
    setSelectedOption(
      options?.find((option) => option.value === `${selectedCategory?.id}`)
    );
  }, [options, selectedCategory]);

  const isEdit = !!selectedCategory;

  const onOptionChange = useCallback((option) => setSelectedOption(option), []);

  const onSaveSelection = useCallback(() => {
    if (!selectedOption) {
      toggleModal();
      return;
    }

    categoryOnSelect({
      id: Number(selectedOption.value),
      name: selectedOption.label
    });
    setSelectedOption(undefined);
    toggleModal();
  }, [categoryOnSelect, selectedOption, toggleModal]);

  return {
    isOpen,
    isEdit,
    options,
    selectedOption,
    onOptionChange,
    onSaveSelection,
    toggleModal
  };
}
