import styled from "styled-components";
import {FontStyle} from "./types";

export const BaseText = styled.div<FontStyle>`
  font-family: ${(props) => props.fontFamily};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => props.lineHeight}px;
  display: flex;
  vertical-align: center;
`;
