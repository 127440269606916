import {usePagination} from "hooks/usePagination";
import {useEffect, useRef, useCallback} from "react";
import {ApolloError, useMutation, useQuery, gql} from "@apollo/client";
import {FeedVideo, GetFeedQuery} from "apollo";
import {INITIAL_ITEMS_COUNT, LOAD_MORE_ITEMS_COUNT} from "config/constants";
import {
  ApolloQueryResult,
  FetchMoreOptions,
  FetchMoreQueryOptions
} from "@apollo/react-hooks";
import useSessionStorage from "./useSessionStorage";
import {useUser} from "./useUser";

const PAGER_FRAGMENT = gql`
  fragment PagerFragment on Pager {
    count
    take
    skip
  }
`;

const FEED_PAGER_FRAGMENT = gql`
  fragment FeedPagerFragment on Feed {
    pager {
      ...PagerFragment
    }
  }
  ${PAGER_FRAGMENT}
`;

const VIDEO_PROPERTIES_FRAGMENT = gql`
  fragment VideoPropertiesFragment on VideoProperties {
    aspect_ratio
    created_at
    duration
    id
    master_access
    max_stored_frame_rate
    max_stored_resolution
    mp4_support
    passthrough
    playback_ids {
      id
      policy
    }
    status
    tracks {
      duration
      id
      max_frame_rate
      max_height
      type
      max_width
    }
  }
`;

const VIDEO_FRAGMENT = gql`
  fragment CustomVideoFragment on FeedVideo {
    createdOn
    description
    externalId
    playbackUrl
    tags
    likes
    liked
    thumbnailUrl
    title
    updatedOn
    uploadId
    uploadStatus
    userUuid
    username
    avatar
    plays
    properties {
      ...VideoPropertiesFragment
    }
  }
  ${VIDEO_PROPERTIES_FRAGMENT}
`;

const FEED_BASE_FRAGMENT = gql`
  fragment CustomFeedBaseFragment on Feed {
    id
    createdOn
    updatedOn
    userUuid
    videos {
      ...CustomVideoFragment
    }
  }
  ${VIDEO_FRAGMENT}
`;

export const GET_FEED = gql`
  query getFeed($feedId: Int, $page: PageRequest) {
    getFeed(feedId: $feedId, page: $page) {
      ...CustomFeedBaseFragment
      ...FeedPagerFragment
    }
  }
  ${FEED_BASE_FRAGMENT}
  ${FEED_PAGER_FRAGMENT}
`;

export const CREATE_FEED = gql`
  mutation createFeed($page: PageRequest, $anonymousUid: String) {
    createFeed(page: $page, anonymousUid: $anonymousUid) {
      ...CustomFeedBaseFragment
      ...FeedPagerFragment
    }
  }
  ${FEED_BASE_FRAGMENT}
  ${FEED_PAGER_FRAGMENT}
`;

type FetchMoreVariables = {
  feedId: string;
  page: {
    take: number;
    skip?: number;
  };
};

export interface UseFeedResult {
  __feedTypeName?: string;
  isLoading: boolean;
  isRefreshing: boolean;
  loadingError: ApolloError | undefined;
  refreshingError: ApolloError | undefined;
  count: number;
  videos: FeedVideo[];
  feedId: string;
  loadData: (refresh?: boolean) => void;
  fetchMore: (
    fetchMoreOptions: FetchMoreQueryOptions<FetchMoreVariables, GetFeedQuery> &
      FetchMoreOptions<GetFeedQuery, FetchMoreVariables>
  ) => Promise<ApolloQueryResult<GetFeedQuery>>;
}

export function useFeed(useCache?: boolean): UseFeedResult | null {
  const feedId = useRef<string>("-1");
  const {count, setCount, skip} = usePagination(INITIAL_ITEMS_COUNT);
  const hasLoadVideos = useRef(false);
  const {getCachedFeed, updateCachedFeed} = useSessionStorage();
  const {user, anonymousUid} = useUser();

  const [
    createFeed,
    {loading: isRefreshing, data: createFeedData, error: refreshingError}
  ] = useMutation(CREATE_FEED);

  const {
    loading: isLoading,
    data: getFeedData,
    error: loadingError,
    fetchMore
  } = useQuery(GET_FEED, {
    variables: {
      feedId: feedId.current,
      page: {take: LOAD_MORE_ITEMS_COUNT, skip: skip.current}
    },
    skip: feedId.current === "-1",
    fetchPolicy: "cache-first"
  });

  const setVariables = useCallback(
    (id, newCount, itemsToskip) => {
      feedId.current = id;
      setCount(newCount);
      skip.current = itemsToskip;
    },
    [setCount, skip]
  );

  const loadData = useCallback(
    (refresh?: boolean) => {
      if (useCache) {
        console.log("using cache the data");
        const cache = getCachedFeed();
        if (cache && (feedId.current === "-1" || refresh)) {
          setVariables(cache.currentFeedId, cache.count, cache.itemsToSkip);
          return;
        }
      }

      if (feedId.current === "-1" || refresh) {
        console.log("fetching the data");
        createFeed({
          variables: {page: {take: INITIAL_ITEMS_COUNT, skip: 0}, anonymousUid}
        })
          .then(({data}) => {
            if (data) {
              setVariables(
                data.createFeed.id,
                data.createFeed.pager.count,
                INITIAL_ITEMS_COUNT
              );
              updateCachedFeed({
                currentFeedId: data.createFeed.id,
                count: data.createFeed.pager.count,
                itemsToSkip: INITIAL_ITEMS_COUNT
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        // feed already created, pagination goes here
      }
    },
    [
      createFeed,
      setVariables,
      anonymousUid,
      getCachedFeed,
      useCache,
      updateCachedFeed
    ]
  );

  useEffect(() => {
    if ((!user && !anonymousUid) || hasLoadVideos.current) {
      console.log("Waiting", {
        user,
        anonymousUid
      });
      return;
    }
    console.log("Loading videos");
    hasLoadVideos.current = true;
    loadData();
  }, [loadData, user, anonymousUid]);

  const videos: FeedVideo[] =
    getFeedData?.getFeed?.videos || createFeedData?.createFeed?.videos || [];

  return {
    __feedTypeName: getFeedData?.getFeed?.__typename,
    isLoading,
    isRefreshing,
    loadingError,
    refreshingError,
    count,
    videos,
    feedId: feedId.current,
    loadData,
    fetchMore
  };
}
