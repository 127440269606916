import React from "react";
import {strings} from "config/strings";
import {PossibleTextTypes, TextTypes} from "components/Atoms/Text/types";
import {IconSide} from "components/Atoms/IconButton/types";

import {
  Chevron,
  GET_STARTED_BUTTON_COLORS,
  GetStartedButton,
  RootContainer,
  StepsContainer,
  Title
} from "./styles";
import {useMintNFTs} from "./hooks";

const MintNFTs: React.FC = () => {
  const {getStartedOnClick, renderSteps} = useMintNFTs();
  return (
    <RootContainer>
      <Title textType={PossibleTextTypes.PageTitle}>
        {strings.pages.mintNFTs.title}
      </Title>
      <StepsContainer>{renderSteps()}</StepsContainer>
      <GetStartedButton
        disabled
        backgroundColours={GET_STARTED_BUTTON_COLORS.backgroundColours}
        borderColours={GET_STARTED_BUTTON_COLORS.borderColours}
        textColours={GET_STARTED_BUTTON_COLORS.textColours}
        iconSide={IconSide.right}
        onClick={getStartedOnClick}
        textType={TextTypes.SubHeadings.SH3}
        title={strings.pages.mintNFTs.getStartedButton}
      >
        <Chevron />
      </GetStartedButton>
    </RootContainer>
  );
};

export default React.memo(MintNFTs);
