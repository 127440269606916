import React from "react";
import MetadataForm from "components/Organisms/MetadataForm";
import ThumbnailForm from "components/Organisms/ThumbnailForm";
import VideoUpload from "components/Organisms/VideoUpload";
import {Spinner} from "components/Spinner";
import {strings} from "config/strings";
import IconButton from "components/Atoms/IconButton";
import {IconSide} from "components/Atoms/IconButton/types";

import {UploadVideoAndMetadataProps} from "./types";
import {
  BottomContainer,
  DataContainer,
  FinishContainer,
  ProgressContainer,
  RootContainer
} from "./styles";
import {useUploadVideoAndMetadata} from "./hooks";

const UploadVideoAndMetadata: React.FC<UploadVideoAndMetadataProps> = ({
  onSuccess,
  uuid,
  video
}: UploadVideoAndMetadataProps) => {
  const {
    clippingLoading,
    endTime,
    externalId,
    finishButtonDisabled,
    finishOnClick,
    isVideoUploaded,
    metaLoading,
    onUploadCompleted,
    setEndTime,
    setExternalId,
    setIsThumbnailUploaded,
    setMeta,
    setStartTime,
    setThumbnailType,
    startTime,
    thumbnailType
  } = useUploadVideoAndMetadata(onSuccess);

  return (
    <RootContainer>
      <DataContainer>
        <VideoUpload
          onUploadCompleted={onUploadCompleted}
          setExternalId={setExternalId}
          video={video}
        />
        <BottomContainer>
          <MetadataForm externalId={externalId} onFillFn={setMeta} />
          <ThumbnailForm
            endTime={endTime}
            externalId={externalId}
            isVideoUploaded={isVideoUploaded}
            setEndTime={setEndTime}
            setIsThumbnailUploaded={setIsThumbnailUploaded}
            setStartTime={setStartTime}
            setThumbnailType={setThumbnailType}
            startTime={startTime}
            thumbnailType={thumbnailType}
            uuid={uuid}
            video={video}
          />
        </BottomContainer>
      </DataContainer>

      <FinishContainer>
        <IconButton
          iconSide={IconSide.left}
          disabled={finishButtonDisabled}
          onClick={finishOnClick}
        >
          {strings.pages.upload.finish}
        </IconButton>
      </FinishContainer>
      {(clippingLoading || metaLoading) && (
        <ProgressContainer>
          <Spinner />
        </ProgressContainer>
      )}
    </RootContainer>
  );
};

export default React.memo(UploadVideoAndMetadata);
