import styled from "styled-components";
import {errorRed} from "config/styleVars";
import Text from "components/Atoms/Text";

export const Container = styled.div<{isVisible: boolean}>`
  display: ${(props) => (props.isVisible ? "block" : "none")};
  position: relative;
`;

export const ProgressBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const SpinnerContainer = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
`;

export const UploadingText = styled(Text)`
  margin-top: 12px;
`;

export const Error = styled.p`
  font-size: 0.8em;
  color: ${errorRed};
  margin: 8px 0;
`;
