import {useState, useCallback} from "react";
import {useDisconnect} from "@thirdweb-dev/react/dist/thirdweb-dev-react.cjs";

export function useWalletMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const disconnect = useDisconnect();

  const toggleMenu = useCallback(
    () => setIsOpen((prevState) => !prevState),
    []
  );

  return {
    isOpen,
    toggleMenu,
    disconnect
  };
}
