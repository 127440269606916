import {MessageResponse} from "stream-chat";
import {formatTimeDistance} from "utils/FormatUtils";
import Avatar from "components/Avatar";
import {
  Body,
  Container,
  AvatarContainer,
  Header,
  TextContainer,
  Time
} from "./styles";

type CommentProps = {
  message: MessageResponse;
};

const Comment = ({message}: CommentProps) => {
  return (
    <Container>
      <AvatarContainer>
        <Avatar
          avatarURL={(message.user?.avatar as string) || ""}
          size={30}
          uuid={message.user?.id}
          username={message.user?.name}
        />
      </AvatarContainer>
      <TextContainer>
        <Time>{formatTimeDistance(message.created_at || "")}</Time>
        <Header>{`@${message.user?.name}`}</Header>
        <Body>{message.text}</Body>
      </TextContainer>
    </Container>
  );
};

export default Comment;
