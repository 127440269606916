import {Container} from "components/StyledElements/LayoutElements";
import SignUpForm from "components/Auth/SignUpForm";

function SignUpPage() {
  return (
    <>
      <Container>
        <SignUpForm />
      </Container>
    </>
  );
}

export default SignUpPage;
