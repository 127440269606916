import React from "react";
import Text from "components/Atoms/Text";

import {CollapsibleTriggerProps} from "./types";
import {Chevron, RootContainer} from "./styles";

const CollapsibleTrigger: React.FC<CollapsibleTriggerProps> = ({
  isOpen,
  text,
  textType
}: CollapsibleTriggerProps) => {
  return (
    <RootContainer>
      <Text textType={textType}>{text}</Text>
      <Chevron isOpen={isOpen} />
    </RootContainer>
  );
};

export default React.memo(CollapsibleTrigger);
