import React from "react";
import CollapsibleElement from "react-collapsible";
import CollapsibleTrigger from "components/Atoms/CollapsibleTrigger";

import {CollapsibleProps} from "./types";
import {useCollapsible} from "./hooks";

const Collapsible: React.FC<CollapsibleProps> = ({
  children,
  className,
  text,
  textType
}: CollapsibleProps) => {
  const {isOpen, onOpening, onClosing} = useCollapsible();
  return (
    <CollapsibleElement
      className={className}
      onOpening={onOpening}
      onClosing={onClosing}
      transitionTime={200}
      trigger={
        <CollapsibleTrigger text={text} textType={textType} isOpen={isOpen} />
      }
    >
      {children}
    </CollapsibleElement>
  );
};

export default React.memo(Collapsible);
