import styled from "styled-components";
import {colors} from "theme/colors";
import Text from "components/Atoms/Text";
import {ReactComponent as closeIcon} from "../../icons/close.svg";

export const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    background: colors.primary.black,
    backdropFilter: "blur(8px)",
    overflow: "visible",
    padding: "0",
    border: `2px solid ${colors.primary.darkGrey}`,
    borderRadius: "8px",
    display: "inline-block",
    width: "636px"
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.64)",
    zindex: 99999
  }
};

export const Title = styled(Text)`
  color: ${colors.primary.white};
`;

export const StyledCloseIcon = styled(closeIcon)`
  width: 24px;
  height: 24px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 72px;
  position: relative;
`;

export const IconContainer = styled.div`
  position: absolute;
  display: flex;
  left: -32px;
  align-items: center;
`;

export const TitleContainer = styled.div`
  position: relative;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${colors.primary.lightGrey};
  position: absolute;
  font-size: 24px;
  font-weight: 100;
  right: 0;
  line-height: 28px;
  margin-right: 24px;
  height: 24px;
`;

export const Body = styled.div<{disableBodyPadding?: boolean}>`
  padding: ${({disableBodyPadding}) =>
    disableBodyPadding ? "0" : "24px 72px;"};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 72px;
`;
