import styled from "styled-components";
import Text from "components/Atoms/Text";
import {GrayText} from "theme/genericStyles/uploadPopupStyles";
import {colors} from "theme/colors";
import RadioButtonsGroup from "components/Molecules/RadioButtonsGroup";

export const TopContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 16px;
`;

export const GridColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const RightColumn = styled(GridColumn)`
  align-items: flex-end;
`;

export const CryptoPriceContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CollapsibleContainer = styled.div`
  margin-top: 20px;
  position: relative;
`;

export const CollapsibleNumber = styled(Text)`
  color: ${colors.primary.lightGrey};
  position: absolute;
  left: -28px;
`;

export const GrayTextStyled = styled(GrayText)`
  margin-top: 20px;
`;

export const GoToHomeText = styled(GrayText)`
  margin-left: 24px;
  display: block;
`;

export const GoHomeButton = styled.a`
  color: ${colors.primary.cyan};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

export const LinkButtonContainer = styled.div`
  margin: 20px 0px 24px 0px;
`;

export const LinkButton = styled.a`
  color: ${colors.primary.cyan};
  text-decoration: underline;
  text-underline-offset: 6px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const RadioButtonsGroupStyled = styled(RadioButtonsGroup)`
  margin-top: 20px;
`;

export const ProgressContainer = styled.div`
  position: absolute;
  bottom: 17px;
  height: 44px;
  display: flex;
  max-width: 296px;
  align-items: center;
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  left: -34px;
  display: flex;
  align-items: center;
`;
