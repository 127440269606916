import styled from "styled-components";
import Text from "components/Atoms/Text";
import {colors} from "theme/colors";

export const RootContainer = styled.div<{width?: number}>`
  background-color: ${colors.inputBackground};
  border: 1px solid ${colors.primary.darkGrey};
  border-radius: 8px;
  padding: 8px 12px;
  width: ${({width}) => width || 310}px;
`;

export const StyledText = styled(Text)`
  color: ${colors.primary.lightGrey};
  white-space: pre-wrap;
`;
