export interface Pager {
  skip: number;
  take: number;
}

/**
 * manages the pagination in a format we use.
 */
export function offsetLimitPaginatedField<T>() {
  return {
    // false means we will handle all the merge by ourself
    keyArgs: false,
    merge(
      existing: T[] | undefined,
      incoming: T[],
      {variables}: {variables: {page: Pager}}
    ) {
      const {page} = variables as {page: Pager};
      // existing is immutable, that's why we need a new array
      const merged = existing ? existing.slice(0) : [];
      for (let i = page.skip; i < page.skip + page.take; i++) {
        const incomingItem = incoming[i - page.skip];
        if (incomingItem) {
          merged[i] = incoming[i - page.skip];
        }
      }

      return merged;
    }
  };
}
