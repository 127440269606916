import React from "react";
import {TextTypes} from "components/Atoms/Text/types";
import {strings} from "config/strings";
import {ButtonSize, ButtonType} from "components/Atoms/Button/types";
import SwipeableSideModal from "components/Molecules/SwipeableSideModal";
import {
  CloseButton,
  Description,
  Line,
  LineContainer,
  RootContainer,
  Title
} from "./styles";
import {UploadNotAllowedModalProps} from "./types";
import {ModalSide} from "../SwipeableSideModal/types";

const UploadNotAllowedModal: React.FC<UploadNotAllowedModalProps> = ({
  buttonText,
  isOpen,
  toggleModal
}: UploadNotAllowedModalProps) => {
  return (
    <SwipeableSideModal
      isOpen={isOpen}
      handle="#handle"
      side={ModalSide.bottom}
      toggleModal={toggleModal}
    >
      <RootContainer>
        <LineContainer id="handle">
          <Line />
        </LineContainer>
        <Title textType={TextTypes.Titles.H4}>
          {strings.pages.upload.modals.uploadNotAllowed.title}
        </Title>
        <Description textType={TextTypes.Paragraph.LLight}>
          {strings.pages.upload.modals.uploadNotAllowed.description}
        </Description>
        <CloseButton
          onClick={toggleModal}
          size={ButtonSize.l}
          text={buttonText}
          type={ButtonType.secondary}
        />
      </RootContainer>
    </SwipeableSideModal>
  );
};

export default React.memo(UploadNotAllowedModal);
