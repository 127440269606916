import {useHistory} from "react-router-dom";
import {useAuth} from "hooks/useAuth";
import {Routes} from "config/routes";
import {useCallback} from "react";

export function useSignOutButton() {
  const history = useHistory();
  const {signOut} = useAuth();

  const signOutOnComplete = useCallback(() => {
    if (window.analytics) {
      window.analytics.track("Signed Out");
      window.analytics.reset();
    }
    history.push(Routes.home);
  }, [history]);

  const signOutOnClick = useCallback(() => {
    signOut().then(signOutOnComplete);
  }, [signOut, signOutOnComplete]);

  return {signOutOnClick};
}
