import React from "react";
import {useParams} from "react-router-dom";
import {useUser} from "hooks/useUser";
import {useGetUserQuery, User, useGetUserVideoTotalLikesQuery} from "apollo";
import ProfileContainer from "components/Profile/ProfileContainer";

const ProfilePage: React.FC = () => {
  const {username} = useParams<{username: string}>();
  const {user: currentUser} = useUser();
  const isOwnProfile = username === currentUser?.username;

  const {
    data: userData,
    loading,
    error
  } = useGetUserQuery({
    variables: {
      username
    }
  });

  const {data: likesData} = useGetUserVideoTotalLikesQuery({
    variables: {
      uuid: userData?.getUser?.uuid!
    },
    skip: !userData?.getUser
  });

  return (
    <ProfileContainer
      error={error}
      isOwnProfile={isOwnProfile}
      isUserLoggedIn={!!currentUser}
      loading={loading}
      user={userData?.getUser as User}
      userLikes={likesData?.getUserVideoTotalLikes?.likesCount}
      uuid={userData?.getUser?.uuid as string}
    />
  );
};

export default React.memo(ProfilePage);
