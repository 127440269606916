import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {formStyles, textGray} from "config/styleVars";
import Select, {Option} from "components/Select/Select";

type WrapperProps = {
  invalid: string | undefined;
};

const Wrapper = styled.div<WrapperProps>`
  margin-bottom: ${(props) => (props.invalid ? 0 : formStyles.marginBottom)};
`;

const Label = styled.div`
  font-size: 13px;
  color: ${textGray};
`;

type DatePickerProps = {
  onChangeFn: (date: string) => void;
  onFocusFn?: () => void;
  onBlurFn?: () => void;
  label?: string;
  invalid: string | undefined;
};

function toString(y: string, m: string, d: string) {
  return `${y}-${m}-${d}`;
}

const DatePicker = (props: DatePickerProps): JSX.Element => {
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  useEffect(() => {
    if (day !== "" && month !== "" && year !== "") {
      props.onChangeFn(toString(year, month, day));
    }
  }, [day, month, year, props]);

  function handleDayChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setDay(e.currentTarget.value);
  }
  function handleMonthChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setMonth(e.currentTarget.value);
  }
  function handleYearChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setYear(e.currentTarget.value);
  }

  function handleBlur() {
    if (props.onBlurFn) {
      props.onBlurFn();
    }
  }

  function handleFocus() {
    if (props.onFocusFn) {
      props.onFocusFn();
    }
  }

  function generateDays(): Array<Option> {
    const days = [];
    days.push({
      label: "",
      value: ""
    });
    for (let i = 1; i <= 31; i++) {
      days.push({
        label: i.toString(),
        value: i.toString()
      });
    }
    return days;
  }

  function generateMonths(): Array<Option> {
    const months = [];
    months.push({
      label: "",
      value: ""
    });
    for (let i = 1; i <= 12; i++) {
      months.push({
        label: i.toString(),
        value: i.toString()
      });
    }
    return months;
  }

  function generateYears(): Array<Option> {
    const thisYear = new Date().getFullYear();
    const oldestYear = thisYear - 100;
    const years = [];
    years.push({
      label: "",
      value: ""
    });
    for (let i = thisYear; i >= oldestYear; i--) {
      years.push({
        label: i.toString(),
        value: i.toString()
      });
    }
    return years;
  }

  const {invalid, label} = props;

  return (
    <Wrapper invalid={invalid}>
      {label ? <Label>{label}</Label> : null}
      <Select
        invalid={invalid}
        label="Month"
        options={generateMonths()}
        // eslint-disable-next-line react-extra/no-inline-styles -- MAR-844
        style={{width: "84px", marginRight: "10px", marginBottom: "0"}}
        onBlur={handleBlur}
        onChange={handleMonthChange}
        onFocus={handleFocus}
      />
      <Select
        invalid={invalid}
        label="Day"
        options={generateDays()}
        // eslint-disable-next-line react-extra/no-inline-styles -- MAR-844
        style={{width: "84px", marginRight: "10px", marginBottom: "0"}}
        onBlur={handleBlur}
        onChange={handleDayChange}
        onFocus={handleFocus}
      />
      <Select
        invalid={invalid}
        label="Year"
        options={generateYears()}
        // eslint-disable-next-line react-extra/no-inline-styles -- MAR-844
        style={{width: "110px", marginBottom: "0"}}
        onBlur={handleBlur}
        onChange={handleYearChange}
        onFocus={handleFocus}
      />
    </Wrapper>
  );
};

export default DatePicker;
