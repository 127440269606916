import styled from "styled-components";
import {errorRed, formStyles, textGray} from "config/styleVars";
import Text from "components/Atoms/Text";
import {breakpointNames, media} from "theme/media";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  min-width: 350px;
`;

export const GrayText = styled(Text)`
  color: ${textGray};
`;

export const InnerContainer = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 94px;
  width: 100%;

  ${media.lessThan(breakpointNames.tablet)`
    flex-direction: column;
    align-items: center;
  `}
`;

export const DropzoneContainer = styled.div`
  width: 100%;
  max-width: 400px;
`;

export const Error = styled.p`
  font-size: 13px;
  line-height: 18px;
  font-weight: normal;
  color: ${errorRed};
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 200px;
  display: flex;

  ${media.lessThan(breakpointNames.tablet)`
    max-width: 400px;
    justify-content: flex-end;
    margin-top: 12px;
    margin-right: 24px;
  `}
`;

export const Description = styled.div`
  position: relative;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  padding-left: 48px;
  margin-top: -4px;

  ${media.lessThan(breakpointNames.tablet)`
    align-self: center;
    flex-direction: row;
    padding-left: 0;
    margin-top: 0;
  `}
`;

export const SpecificationText = styled(GrayText)`
  margin-top: 2px;

  ${media.lessThan(breakpointNames.tablet)`
    margin-top: 0px;
  `}
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${formStyles.borderColor};
  margin: 18px 0;

  ${media.lessThan(breakpointNames.tablet)`
    width: 1px;
    height: 94px;
    margin: 0 18px;
  `}
`;
