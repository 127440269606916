import styled from "styled-components";
import Text from "components/Atoms/Text";

export const Container = styled.div<{isVisible: boolean}>`
  min-height: 300px;
  display: ${(props) => (props.isVisible ? "block" : "none")};
`;

export const SpinnerContainer = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.5px dashed white;
  border-radius: 3px;
`;

export const SpinnerText = styled(Text)`
  margin: 8px 0;
`;

export const SpinnerWrapper = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
`;
