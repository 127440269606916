import React from "react";
import {BANNER_ATTRIBUTES} from "components/Atoms/ProgressiveImage/types";
import ProgressiveImage from "components/Atoms/ProgressiveImage";
import {AddImageOverlay, ProfileImageTypes} from "components/Profile";

import {BannerContainer} from "./styles";
import {BannerProps} from "./types";

const Banner: React.FC<BannerProps> = ({
  banner,
  className,
  isOwnProfile
}: BannerProps) => {
  return (
    <BannerContainer className={className}>
      <ProgressiveImage
        imageAttributes={BANNER_ATTRIBUTES}
        shouldLoadAnimatedImage={true}
        url={banner}
      />
      {isOwnProfile && !banner && (
        <AddImageOverlay type={ProfileImageTypes.banner} />
      )}
    </BannerContainer>
  );
};

export default React.memo(Banner);
