import React from "react";
import {TextTypes} from "components/Atoms/Text/types";
import {ProgressBar} from "components/ProgressBar";
import {strings} from "config/strings";
import {
  ResponseMessage,
  ResponseMessageTypes
} from "components/StyledElements/FormElements";

import {VideoUploadProps} from "./types";
import {Container, Header} from "./styles";
import {useVideoUploadHook} from "./hooks";

const VideoUpload: React.FC<VideoUploadProps> = (props: VideoUploadProps) => {
  const {video} = props;
  const {error, progress} = useVideoUploadHook(props);
  if (!video) {
    return null;
  }

  return (
    <Container>
      <Header textType={TextTypes.Headings.H3}>
        {strings.pages.upload.videoUpload}
      </Header>
      {!error ? (
        <ProgressBar filename={video.name} percent={progress} />
      ) : (
        <ResponseMessage
          message={strings.pages.upload.videoUploadError}
          type={ResponseMessageTypes.Error}
        />
      )}
    </Container>
  );
};

export default React.memo(VideoUpload);
