import {ApolloClient, InMemoryCache} from "@apollo/client";
import {offsetLimitPaginatedField} from "apollo/helpers/pagination";
import {ApolloLink, Observable, Operation} from "apollo-link";
import {onError} from "apollo-link-error";
import {HttpLink} from "apollo-link-http";
import {TOKEN_NAME} from "config/constants";
import {User, FeedVideo} from "./types";

const request = (operation: Operation) => {
  const token = localStorage.getItem(TOKEN_NAME);
  const authHeaders = token
    ? {
        Authorization: token
      }
    : {
        "x-api-key": process.env.REACT_APP_AWS_APPSYNC_API_KEY
      };

  operation.setContext({
    headers: authHeaders
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: any;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const cache = new InMemoryCache({
  typePolicies: {
    FeedVideo: {
      keyFields: ["externalId"]
    },
    Feed: {
      keyFields: ["id"],
      fields: {
        // @ts-ignore
        videos: offsetLimitPaginatedField<FeedVideo>()
      }
    },
    User: {
      keyFields: ["uuid"]
    },
    PagedVideoList: {
      keyFields: ["id"],
      fields: {
        // @ts-ignore
        videos: offsetLimitPaginatedField<FeedVideo>()
      }
    },
    PagedUserList: {
      keyFields: ["id"],
      fields: {
        // @ts-ignore
        users: offsetLimitPaginatedField<User>()
      }
    }
  }
});

const ENV = process.env.NODE_ENV;

const link = ApolloLink.from([
  onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors && ENV === "development") {
      console.log("[graphQLErrors]", graphQLErrors);
    }
    if (networkError && ENV === "development") {
      console.log("[networkError]", networkError);
    }
  }),
  requestLink,
  new HttpLink({
    uri: process.env.REACT_APP_GQL_URL
    // credentials: 'include',
  })
]);

const client = new ApolloClient({link: link as any, cache});

export * from "./types";
export {client, request};
