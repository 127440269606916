import styled from "styled-components";
import Text from "components/Atoms/Text";
import {colors} from "theme/colors";

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  overflow: visible;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const StartText = styled(Text)`
  color: ${colors.primary.lightGrey};
  transform: translateX(-50%);
  position: absolute;
  left: 0;
`;
export const EndText = styled(Text)`
  color: ${colors.primary.lightGrey};
  transform: translateX(50%);
  position: absolute;
  right: 0;
`;
export const DotsText = styled(Text)`
  color: ${colors.primary.darkGrey};
  margin-top: -3px;
`;
export const DotsContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
