import styled from "styled-components";
import {colors} from "theme/colors";
import Button from "components/Atoms/Button";
import Text from "components/Atoms/Text";
import {ReactComponent as SearchIconSvg} from "icons/universal/search.svg";

export const SearchIcon = styled(SearchIconSvg)`
  width: 22px;
  height: 22px;
  pointer-events: none;
  margin-bottom: 30px;
`;

export const Headline = styled.div`
  padding: 0px 16px;
`;

export const MoreButtonContainer = styled.div`
  margin-top: 24px;
  border-top: 1px solid ${colors.primary.white};
  display: flex;
  justify-content: center;
`;

export const Results = styled.div`
  position: absolute;
  top: 47px;
  width: 100%;
  background-color: ${colors.primary.black};
  border: 2px solid #ffffff;
  border-radius: 8px;
  z-index: 1;
  padding-top: 8px;
  margin-top: 8px;
`;

export const MoreButton = styled(Button)`
  margin: 4px;
`;

export const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 88px 0px 162px;
`;

export const GrayText = styled(Text)`
  color: ${colors.primary.mediumGrey};
`;
