import {useHistory} from "react-router-dom";
import {useCallback} from "react";

export function useUserMenuItem(onClick?: () => void, to?: string) {
  const history = useHistory();

  const buttonOnClick = useCallback(() => {
    if (onClick) {
      return onClick();
    }

    if (to) {
      return history.push(to);
    }

    throw new Error(`Please specify "onClick" or "to" property.`);
  }, [history, onClick, to]);

  return {
    buttonOnClick
  };
}
