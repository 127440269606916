import {useDraggable} from "react-use-draggable-scroll";
import {MutableRefObject, useRef} from "react";

export function useDraggableItemsContainer() {
  const itemsContainerRef = useRef<HTMLDivElement>(
    null
  ) as MutableRefObject<HTMLInputElement>;
  const {events} = useDraggable(itemsContainerRef);

  return {events, itemsContainerRef};
}
