import React from "react";
import Select from "react-select";

import {DropdownProps} from "./types";
import {getStyles, theme} from "./styles";
import {useDropdown} from "./hooks";

const Dropdown = <T,>(props: DropdownProps<T>) => {
  const {
    placeholder,
    defaultValue,
    isDisabled,
    options,
    onChange,
    isMulti,
    value
  } = props;
  const {
    Option,
    SingleValue,
    MultiValue,
    ClearIndicator,
    MultiValueContainer,
    MultiValueRemove,
    MenuList
  } = useDropdown<T>(props);

  return (
    <div>
      <Select
        isMulti={isMulti}
        placeholder={placeholder}
        defaultValue={defaultValue}
        hideSelectedOptions={false}
        isDisabled={isDisabled}
        styles={getStyles<T>()}
        value={value}
        theme={theme}
        options={options}
        isSearchable={false}
        components={{
          Option,
          MenuList,
          ClearIndicator,
          MultiValue,
          SingleValue,
          MultiValueContainer,
          MultiValueRemove
        }}
        onChange={onChange}
      />
    </div>
  );
};

export default React.memo(Dropdown);
