import React from "react";
import {ClippingToolItemImageProps} from "./types";
import {Image, ImageContainer, MuxStoryboardImage} from "./styles";
import {useClippingToolItemImage} from "./hooks";

const ClippingToolItemImage: React.FC<ClippingToolItemImageProps> = (
  props: ClippingToolItemImageProps
) => {
  const {itemProps} = props;
  const {duration} = itemProps;
  const {source, storyboardSource} = useClippingToolItemImage(props);

  return (
    <ImageContainer itemDuration={duration}>
      {source && <Image src={source} />}
      {storyboardSource && (
        <MuxStoryboardImage
          scale={storyboardSource.scale}
          widthOffSet={storyboardSource.widthOffset}
          heightOffset={storyboardSource.heightOffset}
          tile_width={storyboardSource.tileWidth}
          tile_height={storyboardSource.tileHeight}
          url={storyboardSource.url}
          x={storyboardSource.x}
          y={storyboardSource.y}
        />
      )}
    </ImageContainer>
  );
};

export default React.memo(ClippingToolItemImage);
