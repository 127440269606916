import {useCallback, useRef} from "react";
import {
  FieldPath,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
  UseFormSetValue
} from "react-hook-form";

export default function useRegisterInput<
  V extends FieldValues,
  TFieldName extends FieldPath<V>
>(setValue: UseFormSetValue<V>) {
  const {current: callbacks} = useRef(new Map());

  const registerInput = useCallback(
    (
      name: TFieldName,
      nodeToValue?: (input: any) => UnpackNestedValue<PathValue<V, Path<V>>>
    ) => {
      if (callbacks.has(name)) {
        return callbacks.get(name);
      }
      const callback = (value: UnpackNestedValue<PathValue<V, Path<V>>>) => {
        if (nodeToValue) {
          setValue(name, nodeToValue(value));
          return;
        }
        setValue(name, value);
      };
      callbacks.set(name, callback);
      return callback;
    },
    [setValue, callbacks]
  );

  return registerInput;
}
