import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  padding-left: 15px;
  padding-bottom: 10px;
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #a39ea9;
`;

export const Username = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
`;

export const Button = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  width: 160px;
  height: 40px;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  padding-top: 11px;
`;

export const Icon = styled.img`
  position: absolute;
  top: 9px;
`;
