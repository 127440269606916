import styled from "styled-components";

export const Meta = styled.div`
  position: absolute;
  bottom: -18px;
  left: 0;
  right: 0;
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
  height: 64px;
  opacity: 1;
  cursor: pointer;
`;

export const UnlikeButton = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
`;

export const Thumb = styled.div`
  position: relative;
  border-radius: 4px;
  overflow: visible;
  padding-bottom: 44px;
  margin-bottom: 20px;

  &:hover ${UnlikeButton} {
    display: block;
  }
`;

export const ThumbImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 44px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 4px;
  cursor: pointer;
`;

export const Sizer = styled.div`
  width: 100%;
  position: relative;
  z-index: 0;
  padding-top: 55%;
  display: inline-block;
  pointer-events: none;
`;

export const MetaClickArea = styled.div`
  position: absolute;
  top: 0;
  left: 40px;
  right: 0;
  bottom: 0;
  z-index: 2;
`;

export const MetaTitle = styled.div`
  font-size: 15px;
  line-height: 1.1;
  margin-left: 30px;
`;

export const MetaInfo = styled.div`
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 30px;

  a {
    color: white;

    &:hover {
      text-decoration: none;
    }
  }
`;

export const MetaViews = styled.span`
  margin-left: 14px;
  font-size: 11px;
  top: 22px;
`;

export const MetaViewsNumber = styled.span`
  font-weight: bold;
  font-size: 11px;
`;

export const MetaNumberDistance = styled.span`
  font-weight: bolder;
  margin-left: 14px;
  font-size: 12px;
`;

export const MetaAdditional = styled.span`
  font-weight: 200;
  font-size: 12px;
`;

export const MetaDuration = styled.span`
  float: right;
  font-size: 10px;
`;

type ErrorTextProps = {
  processing: boolean;
};

export const ErrorText = styled(ThumbImage)`
  text-align: center;
  background-color: black;
  color: white;
  padding-top: ${(props: ErrorTextProps) =>
    props.processing ? "170px" : "100px"};
`;

export const ContextMenu = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;
