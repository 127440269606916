import React, {useCallback, useMemo, useEffect, useState} from "react";
import {useInfiniteHits} from "react-instantsearch-hooks";
import {ReactComponent as ChevronRight} from "icons/universal/chevron-right.svg";
import {useHistory} from "react-router";
import {Routes} from "config/routes";

import {AlgoliaSearchResultsProps} from "./types";

const RESULTS_TO_SHOW_COUNT = 3;

export function useAlgoliaSearchResults(props: AlgoliaSearchResultsProps) {
  const {maxResults, isFocused} = props;
  const [hideResults, setHideResults] = useState(true);
  const {hits, results, showMore} = useInfiniteHits(props);
  const history = useHistory();
  const query = results?.query;

  useEffect(() => {
    setTimeout(() => {
      setHideResults(!isFocused);
    }, 200);
  }, [isFocused]);

  const filteredHits = useMemo(() => {
    if (!query) {
      return [];
    }

    if (maxResults && maxResults < hits.length) {
      return hits.slice(0, maxResults);
    }

    return hits;
  }, [hits, maxResults, query]);

  const moreHits = results?.nbHits
    ? Math.max(results.nbHits - RESULTS_TO_SHOW_COUNT, 0)
    : 0;
  const showNoResults = !results?.nbHits && query?.length && query?.length > 0;

  const renderMoreButtonIcon = useCallback((color: string, size: number) => {
    return (
      <ChevronRight
        width={size}
        height={size}
        strokeWidth={1.5}
        stroke={color}
      />
    );
  }, []);

  const goToSearchPage = useCallback(() => {
    history.push(`${Routes.search}?q=${query}`);
  }, [history, query]);

  return {
    filteredHits,
    goToSearchPage,
    moreHits,
    renderMoreButtonIcon,
    showNoResults,
    showMore,
    hideResults
  };
}
