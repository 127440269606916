import {Container} from "components/StyledElements/LayoutElements";
import ForgotPasswordForm from "components/Auth/ForgotPasswordForm";
import {RouteComponentProps} from "react-router-dom";

type StateType = {
  emailSent?: boolean;
  email?: string;
};

type Unknown = Record<string, string | undefined>;

type Props = RouteComponentProps<Unknown, Unknown, StateType>;

const ForgotPasswordPage: React.FC<Props> = ({location}: {location: any}) => {
  return (
    <>
      <Container>
        <ForgotPasswordForm
          emailSentSettings={location?.state?.emailSent || false}
          emailSettings={location?.state?.email || ""}
        />
      </Container>
    </>
  );
};

export default ForgotPasswordPage;
