import LeftIcon from "icons/20/chevron-left.svg";
import {strings} from "config/strings";
import {useHistory} from "react-router-dom";
import ButtonMailto from "components/Button/ButtonMailto/ButtonMailto";
import {
  BackButton,
  Icon,
  Container,
  MenuSection,
  SectionTitle,
  Title,
  SectionContainer,
  Wrapper,
  SettingsItemTextContainer,
  SettingsItemText
} from "./styles";

export const SettingsHelpContainer = () => {
  const history = useHistory();

  const handleOnBackClick = () => {
    history.goBack();
  };

  const mailto = `mailto:${strings.pages.settings.helpDisclaimer.email}`;

  return (
    <Container>
      <BackButton onClick={handleOnBackClick}>
        <Icon src={LeftIcon} />
        {strings.generic.back}
      </BackButton>
      <Wrapper>
        <Title>{strings.pages.settings.help}</Title>
        <SectionContainer>
          <MenuSection>
            <SectionTitle>{strings.pages.settings.getInTouch}</SectionTitle>
            <SettingsItemTextContainer>
              <SettingsItemText>
                {strings.pages.settings.helpDisclaimer.mailto}
                <ButtonMailto
                  label={strings.pages.settings.helpDisclaimer.email}
                  mailto={mailto}
                />
                {strings.pages.settings.helpDisclaimer.contactSupport}
              </SettingsItemText>
            </SettingsItemTextContainer>
          </MenuSection>
        </SectionContainer>
      </Wrapper>
    </Container>
  );
};
