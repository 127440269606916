import {InputContainer} from "components/Atoms/TextInput/styles";
import styled from "styled-components";
import {colors} from "theme/colors";

export const RootContainer = styled.div<{isFocused: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  position: relative;
  min-width: 569px;
  ${InputContainer} {
    z-index: 1;
    :focus-within {
      outline: ${colors.primary.black50} solid 9999px;
    }
  }
`;
