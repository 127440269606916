import {FeedVideo, useListLikedVideosQuery} from "apollo";
import {Spinner} from "components/Spinner";
import Player from "components/Video/Player";
import {PROFILE_VIDEOS_PER_PAGE} from "config/constants";
import {getLikedPostsUrl} from "config/routes";
import {useIndexedVideoPlayer} from "hooks/useIndexedVideoPlayer";
import ErrorPage from "pages/ErrorPage";
import {useHistory} from "react-router";
import {useEffect} from "react";

const LikedPostPlayer = () => {
  const history = useHistory();
  const {
    user,
    videoId,
    position,
    getSkip,
    error,
    setError,
    getIndex,
    loading: indexLoading
  } = useIndexedVideoPlayer();
  const {data, loading, refetch} = useListLikedVideosQuery({
    variables: {
      uuid: user?.uuid || "",
      page: {
        take: PROFILE_VIDEOS_PER_PAGE,
        skip: getSkip()
      }
    },
    skip: !user?.uuid
  });
  const videos = data?.listLikedVideos?.videos || [];
  const index = getIndex(data?.listLikedVideos?.videos?.length);
  const count = data?.listLikedVideos?.pager?.count || 0;

  useEffect(() => {
    if (videos.length > 0) {
      setError(videos[index]?.externalId !== videoId);
    }
  }, [videos, videoId, index, setError]);

  const handleVideoChange = async (newIndex: number) => {
    if (videos.length === 0) {
      return;
    }
    const shouldLoadPreviousPage = newIndex < 0 && position > 0;
    const shouldLoadNextPage =
      count && newIndex < count && newIndex >= videos?.length;
    const shouldMoveInsidePage = count && newIndex < count && newIndex >= 0;

    if (user?.uuid && (shouldLoadPreviousPage || shouldLoadNextPage)) {
      const newPosition = position + (shouldLoadNextPage ? 1 : -1);
      const newVidioIndex = shouldLoadNextPage
        ? 0
        : PROFILE_VIDEOS_PER_PAGE - 1;
      const {data: newData} = await refetch({
        uuid: user?.uuid,
        page: {
          take: PROFILE_VIDEOS_PER_PAGE,
          skip: getSkip(newPosition)
        }
      });
      const newVideos = newData?.listLikedVideos?.videos || [];
      const externalId = newVideos[newVidioIndex]?.externalId || "";
      const url = getLikedPostsUrl(user?.username!, externalId, newPosition);
      history.push(url);
    } else if (shouldMoveInsidePage) {
      const externalId = videos[newIndex]?.externalId!;
      const newPosition = position + (newIndex < index ? -1 : 1);
      const url = getLikedPostsUrl(user?.username!, externalId, newPosition);
      history.push(url);
    }
  };

  const isLoading = indexLoading || loading;

  return (
    <>
      {isLoading && !error && <Spinner />}
      {!isLoading && !error && videos && (
        <Player
          handleVideoChange={handleVideoChange}
          videoIndex={index}
          videos={videos as Array<FeedVideo>}
        />
      )}
      {error && !isLoading && <ErrorPage />}
    </>
  );
};

export default LikedPostPlayer;
