import styled from "styled-components";
import {zIndex, formStyles} from "config/styleVars";
import {DisplayMode} from "apollo/reactive";

type ControlsProps = {
  show: boolean;
  hover: boolean;
  displayState: DisplayMode;
};

export const SocialIconsContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-start;
  column-gap: 22px;
  top: 34px;
  right: 48px;
`;

export const PlayerContainer = styled.div<ControlsProps>`
  display: "block";
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndex.player};
  cursor: ${(props: ControlsProps) =>
    props.displayState !== DisplayMode.fullscreen || props.hover || props.show
      ? "auto"
      : "none"};
`;

export const Controls = styled.div<ControlsProps>`
  position: absolute;
  transition: opacity 150ms ease;
  opacity: ${(props: ControlsProps) =>
    props.displayState !== DisplayMode.fullscreen || props.show || props.hover
      ? 100
      : 0};
  left: 0;
  right: 0;
  bottom: 0;
  height: 144px;
  background: rgba(0, 0, 0, 0.9);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.85) 100%
  );
`;

export const Username = styled.div`
  font-size: 15px;
  position: absolute;
  top: 12px;
  left: 48px;
  color: white;

  &::before {
    content: "@";
  }
`;

export const FeaturedUsername = styled.div`
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: auto;
  color: white;
  top: -5px;

  &::before {
    content: "@";
  }
`;

export const MetaViews = styled.span`
  position: relative;
  display: inline-block;
  width: auto;
  margin-left: 18px;
  top: -5px;
`;

export const MetaViewsNumber = styled.span`
  font-weight: bold;
  font-size: 11px;
`;

export const MetaNumberDistance = styled.span`
  font-weight: bolder;
  margin-left: 14px;
  font-size: 12px;
`;

export const MetaAdditional = styled.span`
  font-weight: 200;
  font-size: 12px;
`;

export const Title = styled.div`
  font-size: 21px;
  position: absolute;
  top: 34px;
  left: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 348px);
`;

export const AvatarPositioner = styled.div``;

export const InfoButton = styled.img`
  cursor: pointer;
`;

export const LikeWrapper = styled.div``;

export const CommentButton = styled.img`
  cursor: pointer;
`;

export const FeaturedWrapper = styled.div`
  height: 0;
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  cursor: pointer;
  z-index: 0;
  min-height: 700px;

  .vjs-tech {
    object-fit: cover;
  }

  .video-js.vjs-fill {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 700px;
  }
`;

export const BottomGrad = styled.div`
  pointer-events: none;
  height: 128px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(360deg, #000000 6.25%, rgba(0, 0, 0, 0) 100%);
`;

export const Meta = styled.div`
  position: absolute;
  top: calc(100vh - 270px);
  left: 40px;
  z-index: 2;
  width: calc(100% - 80px);

  @media screen and (max-width: 1525px) {
    top: 60vh !important;
  }
  @media screen and (max-width: 899px) {
    top: 370px !important;
  }
`;

export const MetaText = styled.div`
  position: absolute;
  left: 48px;
  top: -3px;
  width: calc(100% - 48px);
`;

export const VideoTitle = styled.div`
  font-size: 19px;
  text-align: left;
  font-weight: bold;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MetaButtons = styled.div`
  position: relative;
`;

export const WatchNow = styled.button`
  background-color: white;
  font-weight: bold;
  border-radius: ${formStyles.borderRadius};
  border: none;
  padding: 10px 30px;
  line-height: 0.8;
  margin-left: 48px;
  margin-top: 10px;
  font-size: 16px;
`;

export const WatchNowIcon = styled.img`
  margin-right: 0.5em;
  vertical-align: bottom;
`;

export const MuteButton = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  top: 14px;
  left: 230px;
  pointer-events: auto;
`;

const ContentBox = styled.div`
  position: absolute;
  width: 340px;
  right: 20px;
  background: rgba(0, 0, 0, 0.72);
  border: 1px solid rgba(75, 73, 77, 0.4);
  box-sizing: border-box;
  backdrop-filter: blur(8px);
  border-radius: 8px;
  text-align: left;
  z-index: 90;
`;

export const InfoContentBox = styled(ContentBox)`
  display: flex;
  min-height: calc(55% - 195px);
  max-height: calc(100% - 195px);
  bottom: 120px;
  position: absolute;
  right: 20px;
`;

export const CommentsContentBox = styled(ContentBox)`
  height: calc(100vh - 195px);
  top: 75px;
`;

export const SendButton = styled.div`
  position: absolute;
  bottom: 3px;
  left: 78%;
  width: 21%;
  height: 37px;
  font-size: 15px;
  line-height: 37px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
  cursor: pointer;
  margin: 0;

  &:hover {
    opacity: 1;
  }
`;

export const MetaVignette = styled.div`
  background: linear-gradient(
    77deg,
    rgba(0, 0, 0, 0.6) 0,
    rgba(0, 0, 0, 0) 85%
  );
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 26%;
  z-index: 1;
`;

export const HeartAnim = styled.img`
  position: fixed;
  width: 70%;
  height: 70%;
  left: 15%;
  top: 15%;
  z-index: 99;
  opacity: 0;
  transform: scale(80%);
  pointer-events: none;

  &.show {
    opacity: 0.5;
    transform: scale(100%);
    transition: all 300ms ease-out;
  }
`;
