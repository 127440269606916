import {useGetPublishedVideoQuery} from "apollo";
import {useParams} from "react-router";

export function useVideoDetails() {
  const {videoId} = useParams<{videoId: string}>();

  const {
    data: videoData,
    loading: isVideoLoading,
    error: getVideoError
  } = useGetPublishedVideoQuery({
    variables: {
      externalId: videoId
    }
  });

  return {
    getVideoError,
    isVideoLoading,
    video: videoData?.getPublishedVideo
  };
}
