import React from "react";

import {TextButtonProps} from "./types";
import {ButtonText, RootContainer} from "./styles";

const TextButton: React.FC<TextButtonProps> = ({
  children,
  className,
  onClick,
  textType
}: TextButtonProps) => {
  return (
    <RootContainer onClick={onClick} className={className}>
      <ButtonText textType={textType}>{children}</ButtonText>
    </RootContainer>
  );
};

export default React.memo(TextButton);
