import {Auth} from "aws-amplify";
import {useApolloClient} from "@apollo/client";

import {User} from "apollo";
import {useAuthToken} from "./useAuthToken";
import {useUser} from "./useUser";

export const useAuth = () => {
  const client = useApolloClient();
  const {user, authenticated, refetch} = useUser();
  const {setAuthToken, removeAuthToken} = useAuthToken();

  const signin = async (username: string, password: string): Promise<User> => {
    const data = await Auth.signIn({
      username,
      password
    });

    data.getSession((_error: any, session: any) => {
      const jwt = session.accessToken.jwtToken;
      setAuthToken(jwt);
    });

    return refetch(username);
  };

  const signup = async (username: string, password: string) => {
    await Auth.signUp({
      username,
      password
    });
  };

  const signOut = async () => {
    await Auth.signOut();
    removeAuthToken();
    client.clearStore();
  };

  return {user, authenticated, signin, signup, signOut};
};
