import styled from "styled-components";
import Text from "components/Atoms/Text";
import {Link} from "react-router-dom";

export const UnlikeButton = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
`;

export const RootContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    transform: translateY(0) scale(1.02);
    z-index: 1;
  }

  &:hover ${UnlikeButton} {
    display: block;
  }
`;

export const ThumbnailContainer = styled.div`
  aspect-ratio: 16/9;
`;

export const MetadataContainer = styled.div`
  color: white;
  display: grid;
  grid-template-columns: auto 1fr 50px;
  grid-gap: 12px;
  padding: 12px 0px;
`;

export const LeftMetadataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

export const Title = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LeftMetadataBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MetadataText = styled(Text)`
  margin-left: 12px;
`;

export const RightMetadataContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ProfileLink = styled(Link)`
  color: white;
  outline: none;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
`;

export const ContextMenu = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;
