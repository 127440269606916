import {useEffect, useCallback, useState, ChangeEvent, useMemo} from "react";
import {
  useGetCategoriesQuery,
  useUpdateVideoMutation,
  useUpdateNftCollectionMutation,
  useGetPublishedVideoQuery,
  FormStatus,
  useUploadVideoMetadataMutation
} from "apollo";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {metaUploadValidator} from "utils/validators/upload";
import {useUser} from "hooks/useUser";
import {InputPercentageFormValues} from "components/Molecules/Percentage/types";
import {
  TokensAmountOrPrice,
  TokensAvailableFormValues
} from "components/Molecules/TokensAvailable/types";
import {TierValues} from "components/Atoms/TiersDropdownOption/types";
import useRegisterInput from "utils/useRegisterInput";
import {TierPriceFormValues} from "components/Molecules/TierPrice/types";
import {useModal} from "hooks/useModal";
import {strings} from "config/strings";

import {useHistory} from "react-router";
import {isMobile} from "react-device-detect";
import {Routes} from "config/routes";
import {UploadFormValues} from "./types";
import {shapeNftCollection} from "./utils";

const options = {
  shouldValidate: true,
  shouldDirty: true
};

function getIsUploadEnabled({
  videoStatus,
  collectionStatus,
  description,
  title,
  category,
  isTokenHolderNoTiers
}: {
  videoStatus?: FormStatus;
  collectionStatus?: FormStatus;
  description: string;
  title: string;
  category?: number;
  isTokenHolderNoTiers?: boolean;
}) {
  if (!title || !category || !description || isTokenHolderNoTiers) {
    return false;
  }

  return (
    videoStatus === FormStatus.Ready && collectionStatus === FormStatus.Ready
  );
}

export function useUpload() {
  const methods = useForm<UploadFormValues>({
    mode: "all",
    resolver: yupResolver(metaUploadValidator),
    defaultValues: {
      explicit: false
    }
  });
  const {register, setValue, watch} = methods;
  const formValues = watch();
  const {
    category,
    description,
    externalId,
    explicit,
    tags,
    title,
    tier: selectedTier,
    tierPrice,
    tokensAvailable,
    tokenResalePercentage,
    blockchain,
    rewardsPerTier
  } = formValues;

  const {user} = useUser();
  const [isTokenHolderEnabled, setIsTokenHolderEnabled] =
    useState<boolean>(false);
  const [isVideoMetadataUploaded, setIsVideoMetadataUploaded] =
    useState<boolean>(false);
  const [isThumbnailValid, setIsThumbnailValid] = useState<boolean>(false);
  const [isTutorialDisplaying, setIsTutorialDisplaying] = useState(false);
  const [videoFormStatus, setVideoFormStatus] = useState<FormStatus>();
  const [collectionFormStatus, setCollectionFormStatus] =
    useState<FormStatus>();
  const [isTermsOfServiceDisplaying, setIsTermsOfServiceDisplaying] =
    useState(false);
  const [isTermsOfServiceAccepted, setIsTermsOfServiceAccepted] =
    useState(false);
  const [updateNftCollection, {loading: isNFTCollectionLoading}] =
    useUpdateNftCollectionMutation();
  const [uploadVideoMetadata, {loading: isUploadingMetadataLoading}] =
    useUploadVideoMetadataMutation();
  const {isModalOpen: isMobileModalOpen, toggleModal: toggleMobileModal} =
    useModal();
  const history = useHistory();

  useEffect(() => {
    if (isMobile && !isMobileModalOpen) {
      toggleMobileModal();
    }
  }, [isMobileModalOpen, toggleMobileModal]);

  const closeMobileModal = useCallback(() => {
    toggleMobileModal();
    history.replace(Routes.home);
  }, [history, toggleMobileModal]);

  const onUploadCompleted = useCallback(() => {
    setIsVideoMetadataUploaded(true);
  }, []);

  const {
    isModalOpen: isConfirmAndMintModalOpen,
    toggleModal: toggleConfirmAndMintModal
  } = useModal();
  const {data: {getPublishedVideo} = {}} = useGetPublishedVideoQuery({
    variables: {externalId},
    pollInterval: 4000,
    skip: !externalId || !description || !title || !category
  });

  useEffect(() => {
    setVideoFormStatus(getPublishedVideo?.formStatus);
  }, [getPublishedVideo]);

  const registerInput = useRegisterInput(setValue);

  const {data: {getCategories: videoCategories} = {}} = useGetCategoriesQuery();

  const toggleVideoTutorial = useCallback(() => {
    setIsTutorialDisplaying((value) => !value);
  }, []);

  const toggleTermsOfService = useCallback(() => {
    setIsTermsOfServiceAccepted((value) => !value);
  }, []);

  const toggleTermsOfServiceModal = useCallback(() => {
    setIsTermsOfServiceDisplaying((value) => {
      if (value) {
        setIsTermsOfServiceAccepted(false);
      }
      return !value;
    });
  }, []);

  const onSubmit = useCallback(async () => {
    if (!isTermsOfServiceAccepted) {
      toggleTermsOfServiceModal();
      return;
    }
    if (!isVideoMetadataUploaded) {
      uploadVideoMetadata({variables: {externalId}}).then(() => {
        onUploadCompleted();
      });
    }
    toggleConfirmAndMintModal();
  }, [
    isTermsOfServiceAccepted,
    uploadVideoMetadata,
    externalId,
    isVideoMetadataUploaded,
    onUploadCompleted,
    toggleConfirmAndMintModal,
    toggleTermsOfServiceModal
  ]);

  const isTokensAvailableOrTierPriceEdit = useCallback(
    (data: Array<TokensAmountOrPrice>) => {
      if (!data?.length) {
        return false;
      }

      for (let i = 0; i < data.length; i++) {
        if (!data[i].value) {
          return false;
        }
      }

      return true;
    },
    []
  );

  const isTokensAvailableEdit = useMemo(() => {
    return isTokensAvailableOrTierPriceEdit(tokensAvailable);
  }, [isTokensAvailableOrTierPriceEdit, tokensAvailable]);

  const isTierPriceEdit = useMemo(() => {
    return isTokensAvailableOrTierPriceEdit(tierPrice);
  }, [isTokensAvailableOrTierPriceEdit, tierPrice]);

  const tiers = useMemo((): Array<number> => {
    let tiersArray: Array<number> = [];
    let tokensAvailableArray: Array<TokensAmountOrPrice> = [];
    let tierPriceArray: Array<TokensAmountOrPrice> = [];

    if (!setValue) {
      return tiersArray;
    }

    if (selectedTier !== undefined) {
      switch (selectedTier.value) {
        case TierValues.TierThumbnail:
          tiersArray = [1];
          tokensAvailableArray = [{tierId: 1}];
          tierPriceArray = [{tierId: 1}];
          break;

        case TierValues.TierVideo:
          tiersArray = [2];
          tokensAvailableArray = [{tierId: 2}];
          tierPriceArray = [{tierId: 2}];
          break;

        case TierValues.TwoTiers:
          tiersArray = [1, 2];
          tokensAvailableArray = [{tierId: 1}, {tierId: 2}];
          tierPriceArray = [{tierId: 1}, {tierId: 2}];
          break;
      }
    }

    setValue("tokensAvailable", tokensAvailableArray, {
      shouldDirty: true,
      shouldTouch: true
    });
    setValue("tierPrice", tierPriceArray, {
      shouldDirty: true,
      shouldTouch: true
    });
    return tiersArray;
  }, [selectedTier, setValue]);

  const toggleTokenHolder = useCallback(() => {
    setIsTokenHolderEnabled((value) => {
      if (value) {
        // clean blockchain data
        setValue("tokenHoldersPercentage", undefined, options);
        setValue("tier", undefined, options);
        setValue("rewardsPerTier", undefined, options);
        setValue("tokenResalePercentage", undefined, options);
        setValue("tokensAvailable", [], options);
        setValue("tierPrice", [], options);
      }
      return !value;
    });
  }, [setValue]);

  const [updateVideoMeta, {loading: isVideoMetadataLoading}] =
    useUpdateVideoMutation();

  useEffect(() => {
    if (!externalId) {
      return;
    }

    const metaPayload = {
      category,
      description,
      externalId,
      explicit,
      tags,
      title
    };
    updateVideoMeta({variables: metaPayload}).catch((e) => {
      console.log("meta update failed", e);
    });
  }, [
    category,
    description,
    externalId,
    explicit,
    tags,
    title,
    updateVideoMeta
  ]);

  useEffect(() => {
    if (!externalId) {
      return;
    }
    async function updateCollection() {
      const {data} = await updateNftCollection({
        variables: {nftCollection: shapeNftCollection(methods.getValues())}
      });
      setCollectionFormStatus(data?.updateNftCollection?.formStatus);
    }
    updateCollection();
  }, [
    methods,
    externalId,
    updateNftCollection,
    selectedTier,
    tierPrice,
    tokensAvailable,
    blockchain,
    tokenResalePercentage,
    rewardsPerTier
  ]);

  const inputPercentageOnSubmit = useCallback(
    (data: InputPercentageFormValues) => {
      setValue("tier", data.tier, options);
      // clean rewards per tier
      setValue("rewardsPerTier", undefined, options);
    },
    [setValue]
  );

  const tokensAvailableOnSubmit = useCallback(
    ({tokensAvailable: tokensAv}: TokensAvailableFormValues) => {
      setValue("tokensAvailable", tokensAv.slice(0));
    },
    [setValue]
  );
  const tierPriceOnSubmit = registerInput(
    "tierPrice",
    ({tierPrice: tierP}: TierPriceFormValues) => tierP.slice(0)
  );
  const onExplicitChange = registerInput(
    "explicit",
    (e: ChangeEvent<HTMLInputElement>) => e.target.checked
  );
  const mintTypeOnChange = registerInput(
    "mintType",
    (e: ChangeEvent<HTMLInputElement>) => e.target.value
  );

  /**
   * Go to home page and get the notification when it's done.
   */
  const goToHomePage = useCallback(() => {}, []);

  const confirmMintAndList = useCallback(() => {}, []);

  const confirmMintAndListStatus = useMemo(() => {
    if (
      !isVideoMetadataUploaded ||
      isVideoMetadataLoading ||
      isNFTCollectionLoading ||
      isUploadingMetadataLoading
    ) {
      return {
        loading: true,
        text: strings.generic.processing
      };
    }

    // Add some more processing cases here
    return {loading: false};
  }, [
    isNFTCollectionLoading,
    isVideoMetadataUploaded,
    isVideoMetadataLoading,
    isUploadingMetadataLoading
  ]);

  const isUploadEnabled = getIsUploadEnabled({
    title,
    description,
    category: category?.id,
    collectionStatus: collectionFormStatus,
    videoStatus: videoFormStatus,
    isTokenHolderNoTiers: isTokenHolderEnabled && !selectedTier?.value
  });

  return {
    closeMobileModal,
    confirmMintAndList,
    confirmMintAndListStatus,
    formValues,
    goToHomePage,
    onExplicitChange,
    inputPercentageOnSubmit,
    isConfirmAndMintModalOpen,
    isMobileModalOpen,
    isTokenHolderEnabled,
    isThumbnailValid,
    setIsThumbnailValid,
    toggleTokenHolder,
    methods,
    mintTypeOnChange,
    isUploadEnabled,
    onSubmit,
    registerInput,
    register,
    toggleTermsOfService,
    isTermsOfServiceDisplaying,
    toggleTermsOfServiceModal,
    isTermsOfServiceAccepted,
    isTokensAvailableEdit,
    isTierPriceEdit,
    isTutorialDisplaying,
    toggleVideoTutorial,
    toggleConfirmAndMintModal,
    onUploadCompleted,
    tokensAvailableOnSubmit,
    tierPriceOnSubmit,
    tiers,
    user,
    videoCategories
  };
}
