import React, {useCallback, useMemo, useState} from "react";
import {useText} from "components/Atoms/Text/hooks";
import {TextTypes} from "components/Atoms/Text/types";
import {colors} from "theme/colors";

import {InputIconSide, TextInputProps} from "./types";

export function useTextInput(props: TextInputProps) {
  const {
    hideMaxCharCount,
    initialValue,
    isDisabled,
    isInvalid,
    isSucceeded,
    label,
    maxCharCount,
    onBlur,
    onChange,
    onFocus,
    renderIcon,
    iconSide,
    showClearButton,
    textType,
    value: controlledValue
  } = props;

  if (label && showClearButton) {
    throw new Error("Label and Clear button should not be used together");
  }

  if (renderIcon && iconSide === undefined) {
    throw new Error("Add iconSide property");
  }

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [value, setValue] = useState<string>(
    initialValue || controlledValue || ""
  );
  const [charCount, setCharCount] = useState<number>(
    initialValue?.length || controlledValue?.length || 0
  );

  const textStyle = useText({
    textType: textType || TextTypes.Other.LinkLabelLLight
  });

  const handleOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setIsFocused(false);
      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur]
  );

  const handleOnFocus = useCallback(() => {
    setIsFocused(true);
    if (onFocus) {
      onFocus();
    }
  }, [onFocus]);

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value);
      setCharCount(e.target.value.length);
      if (onChange) {
        onChange(e);
      }
    },
    [onChange]
  );

  const clear = useCallback(() => {
    const clearEvent = {target: {value: ""}};
    setValue(clearEvent.target.value);
    setCharCount(clearEvent.target.value.length);
    if (onChange) {
      // @ts-ignore
      onChange(clearEvent);
    }
  }, [onChange]);

  const isFloating = isFocused || value.length > 0;
  const leftIconExist =
    renderIcon !== undefined &&
    (iconSide === InputIconSide.left || iconSide === InputIconSide.both);
  const rightIconExist =
    renderIcon !== undefined &&
    (iconSide === InputIconSide.right || iconSide === InputIconSide.both);

  const showRightSidePanel =
    (maxCharCount && !hideMaxCharCount) || rightIconExist;

  const iconColor = useMemo(() => {
    if (isDisabled) {
      return colors.primary.mediumGrey;
    }

    if (isInvalid) {
      return colors.secondary.error;
    }

    if (isSucceeded) {
      return colors.secondary.success;
    }

    if (isFocused) {
      return colors.primary.cyan;
    }

    return colors.primary.lightGrey;
  }, [isDisabled, isFocused, isInvalid, isSucceeded]);

  const inputValue =
    typeof controlledValue !== "undefined" ? controlledValue : value;

  return {
    charCount,
    clear,
    handleOnBlur,
    handleOnChange,
    handleOnFocus,
    iconColor,
    isFloating,
    isFocused,
    leftIconExist,
    rightIconExist,
    showRightSidePanel,
    textStyle,
    value: inputValue
  };
}
