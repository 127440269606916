import InputModal from "components/InputModal";
import React, {useCallback} from "react";
import {FormProvider} from "react-hook-form";
import {TextTypes} from "components/Atoms/Text/types";
import {strings} from "config/strings";
import AddEditButton from "components/Molecules/AddEditButton";

import {RootContainer} from "theme/genericStyles/uploadPopupStyles";
import {
  ContentToTiersDescription,
  ContentToTiersH4,
  UnlockIconStyled
} from "./styles";
import {ExclusiveBenefitsProps} from "./types";
import {useContentFields, useUnlockableContent} from "./hooks";

export default function UnlockableContentField(props: ExclusiveBenefitsProps) {
  const {
    toggleModal: toggleModalRewardsToTiersModal,
    isModalOpen: isRewardsToTiersModalOpen,
    rewardsPerTier,
    renderTiersToRewards,
    isSubmitDisabled
  } = useUnlockableContent(props);
  const {
    methods,
    isFormInvalid,
    renderContentFields,
    onSubmitForm,
    renderTiersRewards,
    isModalOpen: isContentToTiersModalOpen,
    toggleModal: toggleModalContentToTiersModal
  } = useContentFields(props, rewardsPerTier);
  const onSubmit = useCallback(() => {
    toggleModalRewardsToTiersModal();
    toggleModalContentToTiersModal();
  }, [toggleModalRewardsToTiersModal, toggleModalContentToTiersModal]);
  const {disabled, value} = props;

  return (
    <div>
      <AddEditButton
        disabled={disabled}
        isEdit={!!value}
        onClick={toggleModalRewardsToTiersModal}
      />

      {/* Rewards to Tiers */}
      <InputModal
        disableBodyPadding
        shouldCloseOnOverlayClick={false}
        isOpen={isRewardsToTiersModalOpen}
        headerIcon={<UnlockIconStyled />}
        title={
          strings.pages.upload.metaItems.exclusivebenefits
            .exclusivebenefitsModal.title
        }
        onClose={toggleModalRewardsToTiersModal}
        onSubmit={onSubmit}
        disableSubmit={isSubmitDisabled}
        submitText={strings.generic.addLinks}
      >
        <RootContainer>
          <ContentToTiersDescription textType={TextTypes.Paragraph.L}>
            {
              strings.pages.upload.metaItems.exclusivebenefits
                .exclusivebenefitsModal.description
            }
          </ContentToTiersDescription>
          <ContentToTiersH4 textType={TextTypes.SubHeadings.SH4}>
            {
              strings.pages.upload.metaItems.exclusivebenefits
                .exclusivebenefitsModal.tierInfo
            }
          </ContentToTiersH4>
          {renderTiersToRewards()}
        </RootContainer>
      </InputModal>
      {/* Tiers to Rewards */}
      <InputModal
        disableBodyPadding
        shouldCloseOnOverlayClick={false}
        isOpen={isContentToTiersModalOpen}
        title={strings.generic.addLinks}
        headerIcon={<UnlockIconStyled />}
        onClose={toggleModalContentToTiersModal}
        onSubmit={onSubmitForm}
        disableSubmit={isFormInvalid}
        submitText={strings.generic.save}
      >
        <RootContainer>
          {renderTiersRewards()}
          <FormProvider {...methods}>
            <form>{isContentToTiersModalOpen && renderContentFields()}</form>
          </FormProvider>
        </RootContainer>
      </InputModal>
    </div>
  );
}
