import React, {useCallback, useMemo, useState} from "react";
import {
  autoUpdate,
  flip,
  offset,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole
} from "@floating-ui/react-dom-interactions";
import {mergeRefs} from "react-merge-refs";
import {TooltipProps} from "./types";

export function useTooltip({children}: TooltipProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const {x, y, reference, floating, strategy, context} = useFloating({
    placement: "right-start",
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset({mainAxis: 8, crossAxis: -12}), flip()],
    whileElementsMounted: autoUpdate
  });

  const {getReferenceProps, getFloatingProps} = useInteractions([
    useHover(context, {restMs: 40}),
    useFocus(context),
    useRole(context, {role: "tooltip"}),
    useDismiss(context)
  ]);

  // Preserve the consumer's ref
  const ref = useMemo(
    () => mergeRefs([reference, (children as any).ref]),
    [reference, children]
  );

  const renderChildren = useCallback(() => {
    return React.cloneElement(
      children,
      getReferenceProps({ref, ...children.props})
    );
  }, [children, getReferenceProps, ref]);

  return {getFloatingProps, isOpen, renderChildren, floating, strategy, x, y};
}
