import React, {useCallback} from "react";
import {strings} from "config/strings";
import InputModal from "components/InputModal";
import {TextTypes} from "components/Atoms/Text/types";
import Collapsible from "components/Atoms/Collapsible";
import {GrayText, RootContainer} from "theme/genericStyles/uploadPopupStyles";
import Text from "components/Atoms/Text";
import Avatar from "components/Atoms/Avatar";
import {BorderType} from "components/Atoms/Avatar/types";
import {ReactComponent as EthereumIcon} from "icons/universal/ethereum.svg";
import {ReactComponent as PolygonIcon} from "icons/universal/polygon.svg";
import {BlockchainNetworks} from "pages/Web3Upload/types";
import {colors} from "theme/colors";
import {numberWithCommas} from "utils/FormatUtils";
import FadeSpinner from "components/Atoms/FadeSpinner";

import {ConfirmMintAndListProps} from "./types";
import {
  CollapsibleContainer,
  CollapsibleNumber,
  CryptoPriceContainer,
  GoHomeButton,
  GoToHomeText,
  GrayTextStyled,
  GridColumn,
  LinkButton,
  LinkButtonContainer,
  ProgressContainer,
  RadioButtonsGroupStyled,
  RightColumn,
  SpinnerContainer,
  TopContainer
} from "./styles";
import {CURRENCY, useConfirmMintAndList} from "./hooks";

const ConfirmMintAndList: React.FC<ConfirmMintAndListProps> = (
  props: ConfirmMintAndListProps
) => {
  const {
    blockchain,
    goToHome,
    isModalOpen,
    onChange,
    onSubmit,
    status,
    tierPrice,
    toggleModal,
    tokensAvailable,
    user,
    value
  } = props;
  const {
    isLowBalance,
    radioButtonsOptions,
    totalRevenue,
    totalRevenueInCrypto
  } = useConfirmMintAndList(props);
  const {loading, text} = status;
  const renderTiers = useCallback(() => {
    if (!tokensAvailable?.length || !tierPrice?.length) {
      return (
        <GrayText textType={TextTypes.Subtitle.MStrong}>
          {strings.pages.upload.modals.confirmMintAndList.singleVideoNFTs}
        </GrayText>
      );
    }

    return tokensAvailable.map((item, index) => (
      <GrayText
        key={`tier${item.tierId}`}
        textType={TextTypes.Subtitle.MStrong}
      >
        {`${strings.generic.tier} ${item.tierId} (${
          item.tierId === 1
            ? strings.pages.upload.modals.confirmMintAndList.thumbnailNFTs
            : strings.pages.upload.modals.confirmMintAndList.videoNFTs
        }) - ${
          strings.pages.upload.modals.confirmMintAndList.quantity
        }: ${numberWithCommas(item.value)} (${
          strings.generic.currencies[CURRENCY].sign
        }${numberWithCommas(tierPrice[index].value)} ${
          strings.generic.currencies[CURRENCY].name
        })`}
      </GrayText>
    ));
  }, [tokensAvailable, tierPrice]);

  const renderTotalRevenue = useCallback(() => {
    if (!tokensAvailable?.length || !tierPrice?.length) {
      return null;
    }

    return (
      <RightColumn>
        <GrayText textType={TextTypes.Subtitle.MStrong}>
          {strings.pages.upload.modals.confirmMintAndList.totalRevenue}
        </GrayText>
        <CryptoPriceContainer>
          {blockchain === BlockchainNetworks.Polygon ? (
            <PolygonIcon fill={colors.primary.white} width={20} height={20} />
          ) : (
            <EthereumIcon fill={colors.primary.white} width={20} height={20} />
          )}
          <Text textType={TextTypes.Paragraph.L}>
            {numberWithCommas(totalRevenueInCrypto)}
          </Text>
        </CryptoPriceContainer>
        <GrayText textType={TextTypes.Subtitle.MStrong}>
          {strings.generic.currencies[CURRENCY].sign}
          {numberWithCommas(totalRevenue)}{" "}
          {strings.generic.currencies[CURRENCY].name}
        </GrayText>
      </RightColumn>
    );
  }, [
    blockchain,
    tierPrice?.length,
    tokensAvailable?.length,
    totalRevenue,
    totalRevenueInCrypto
  ]);

  const renderLowBalance = useCallback(() => {
    if (!isLowBalance) {
      return null;
    }

    return (
      <>
        <GrayTextStyled textType={TextTypes.Paragraph.L}>
          {strings.pages.upload.modals.confirmMintAndList.lowBalance(0.05)}
        </GrayTextStyled>
        <LinkButtonContainer>
          <LinkButton
            onClick={() => {
              /* Add funds */
            }}
          >
            <Text textType={TextTypes.Other.LinkLabelL}>
              {strings.pages.upload.modals.confirmMintAndList.addFunds}
            </Text>
          </LinkButton>
        </LinkButtonContainer>
      </>
    );
  }, [isLowBalance]);

  return (
    <InputModal
      disableBodyPadding
      disableSubmit={!value || loading}
      isOpen={isModalOpen}
      title={strings.pages.upload.modals.confirmMintAndList.title}
      submitText={strings.pages.upload.modals.confirmMintAndList.submitText}
      onSubmit={onSubmit}
      onClose={toggleModal}
    >
      <RootContainer>
        <TopContainer>
          <GridColumn>
            <Avatar
              avatarURL={user?.avatar}
              borderType={BorderType.white}
              size={40}
              username={user?.username}
            />
          </GridColumn>
          <GridColumn>
            <Text textType={TextTypes.Paragraph.L}>{user?.username}</Text>
            {renderTiers()}
          </GridColumn>
          {renderTotalRevenue()}
        </TopContainer>
        <RadioButtonsGroupStyled
          options={radioButtonsOptions}
          onChange={onChange}
          value={value}
        />

        {renderLowBalance()}
        <CollapsibleContainer>
          <CollapsibleNumber textType={TextTypes.Titles.H4}>
            1
          </CollapsibleNumber>
          <Collapsible
            textType={TextTypes.Titles.H4}
            text={strings.pages.upload.modals.confirmMintAndList.approveNFTs}
          >
            <>
              <GrayTextStyled textType={TextTypes.Paragraph.L}>
                {
                  strings.pages.upload.modals.confirmMintAndList
                    .approveNFTsText1
                }
              </GrayTextStyled>
              <GrayTextStyled textType={TextTypes.Paragraph.L}>
                {
                  strings.pages.upload.modals.confirmMintAndList
                    .approveNFTsText2
                }
              </GrayTextStyled>
            </>
          </Collapsible>
        </CollapsibleContainer>
        <CollapsibleContainer>
          <CollapsibleNumber textType={TextTypes.Titles.H4}>
            2
          </CollapsibleNumber>
          <Collapsible
            textType={TextTypes.Titles.H4}
            text={
              strings.pages.upload.modals.confirmMintAndList.confirmTransaction
            }
          >
            <>
              <GrayTextStyled textType={TextTypes.Paragraph.L}>
                {
                  strings.pages.upload.modals.confirmMintAndList
                    .confirmTransactionText
                }
              </GrayTextStyled>
            </>
          </Collapsible>
        </CollapsibleContainer>
        {loading && (
          <ProgressContainer>
            <SpinnerContainer>
              <FadeSpinner loading={loading} />
            </SpinnerContainer>
            <Text textType={TextTypes.Paragraph.L}>{text}</Text>
            <GoToHomeText textType={TextTypes.Subtitle.MStrong}>
              {strings.pages.upload.modals.confirmMintAndList.goBackText1}
              <GoHomeButton onClick={goToHome}>
                {strings.pages.upload.modals.confirmMintAndList.goBackTextLink}
              </GoHomeButton>
              {strings.pages.upload.modals.confirmMintAndList.goBackText2}
            </GoToHomeText>
          </ProgressContainer>
        )}
      </RootContainer>
    </InputModal>
  );
};

export default React.memo(ConfirmMintAndList);
