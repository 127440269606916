import React, {memo, FC} from "react";
import {strings} from "config/strings";
import {ReactComponent as CategoryIcon} from "icons/universal/category.svg";
import {TextTypes} from "components/Atoms/Text/types";
import Dropdown from "components/Atoms/Dropdown";
import InputModal from "components/InputModal";
import AddEditButton from "components/Molecules/AddEditButton";

import {VideoCategoryProps} from "./types";
import {useVideoCategory} from "./hooks";
import {Container, StyledText, StyledOptionText} from "./styles";

const VideoCategory: FC<VideoCategoryProps> = (props: VideoCategoryProps) => {
  const {
    isOpen,
    isEdit,
    options,
    selectedOption,
    onOptionChange,
    onSaveSelection,
    toggleModal
  } = useVideoCategory(props);

  const {disabled} = props;

  return (
    <>
      <AddEditButton
        disabled={disabled}
        isEdit={isEdit}
        onClick={toggleModal}
      />
      <InputModal
        disableBodyPadding
        disableSubmit={!selectedOption}
        isOpen={isOpen}
        title={strings.pages.upload.modals.category.title}
        submitText={strings.generic.save}
        onSubmit={onSaveSelection}
        onClose={toggleModal}
        headerIcon={<CategoryIcon width={24} />}
      >
        <Container>
          <StyledText textType={TextTypes.Body}>
            {strings.pages.upload.videoCategoriesModalNote}
          </StyledText>
          <Dropdown
            placeholder={strings.pages.upload.categories}
            options={options}
            defaultValue={selectedOption}
            OptionComponent={StyledOptionText}
            onChange={onOptionChange}
          />
        </Container>
      </InputModal>
    </>
  );
};

export default memo(VideoCategory);
