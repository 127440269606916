import {FC, memo} from "react";
import {strings} from "config/strings";
import {TextTypes} from "components/Atoms/Text/types";
import {ReactComponent as ChevronDownIcon} from "icons/24/chevron-down.svg";
import {ReactComponent as SignOutIcon} from "icons/24/logout.svg";
import {useWalletMenu} from "./hooks";
import {
  Container,
  ItemContainer,
  MenuContainer,
  MenuItemContainer,
  StyledMenuItemText
} from "./styles";

type WalletMenuProp = {
  children: React.ReactNode;
  refetchBalance: () => void;
};

const WalletMenu: FC<WalletMenuProp> = ({
  children,
  refetchBalance
}: WalletMenuProp) => {
  const {isOpen, toggleMenu, disconnect} = useWalletMenu();
  return (
    <Container onClick={toggleMenu}>
      <ItemContainer>
        {children}
        <ChevronDownIcon width={24} height={24} />
      </ItemContainer>
      <MenuContainer isOpen={isOpen}>
        <MenuItemContainer onClick={disconnect}>
          <SignOutIcon width={20} height={20} />
          <StyledMenuItemText textType={TextTypes.Bodies.B1}>
            {strings.pages.settings.logout}
          </StyledMenuItemText>
        </MenuItemContainer>
        <MenuItemContainer onClick={refetchBalance}>
          <StyledMenuItemText textType={TextTypes.Bodies.B1}>
            {strings.wallet.refreshFunds}
          </StyledMenuItemText>
        </MenuItemContainer>
      </MenuContainer>
    </Container>
  );
};

export default memo(WalletMenu);
