import React from "react";
import {FeedVideo} from "apollo/types";
import VirtualizedGrid from "components/Organisms/VirualizedGrid";
import {Modal} from "components/Modal";
import {strings} from "config/strings";

import {ProfileLikedPostsProps} from "./types";
import {Container, Error, UnlikeConfirmationText} from "./styles";
import {useProfileLikedPosts} from "./hooks";

const ProfileLikedPosts: React.FC<ProfileLikedPostsProps> = ({
  user
}: ProfileLikedPostsProps) => {
  const {
    data,
    columnBreakpoints,
    confirmUnlikeOnPress,
    error,
    isConfirmationOpen,
    loading,
    loadMoreItems,
    modalOnClose,
    renderGridItem,
    renderItemPlaceholder
  } = useProfileLikedPosts(user);
  const videos: FeedVideo[] = data?.listLikedVideos?.videos || [];

  return (
    <Container>
      {error && <Error>{strings.pages.profile.error}</Error>}
      <VirtualizedGrid
        columnBreakpoints={columnBreakpoints}
        items={videos}
        loading={loading}
        loadMoreItems={loadMoreItems}
        loadMoreRowsCount={8}
        // @ts-ignore
        renderItem={renderGridItem}
        renderPlaceholder={renderItemPlaceholder}
        totalResults={data?.listLikedVideos?.pager?.count}
      />
      <Modal
        closeModalFn={modalOnClose}
        isDialog={true}
        isOpen={isConfirmationOpen}
        saveBtnLabel={strings.generic.unlike}
        saveFn={confirmUnlikeOnPress}
      >
        <UnlikeConfirmationText>
          {strings.generic.unlikeConfirmation}
        </UnlikeConfirmationText>
      </Modal>
    </Container>
  );
};

export default React.memo(ProfileLikedPosts);
