import styled from "styled-components";
import {navHeight, zIndex} from "config/styleVars";
import Skeleton from "react-loading-skeleton";
import {Link} from "react-router-dom";
import {DisplayMode} from "apollo/reactive";
import TextButton from "components/Atoms/TextButton";
import {NavPropsType} from "./types";

export const StyledTopNavigation = styled.nav<NavPropsType>`
  height: ${navHeight}px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: ${zIndex.navBar};
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.55) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  display: ${(props) =>
    props.displayState === DisplayMode.fullscreen ? "none" : "flex"};
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  transition: background 500ms ease-in-out;

  &.scrolled {
    background: black;
  }
`;

export const SearchContainer = styled.div`
  margin-right: 76px;
`;

export const HomeLink = styled(Link)`
  &:focus {
    outline: none;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  width: 36px;
  height: 36px;
`;

export const LeftAndRightMenu = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 24px;
`;

export const LeftMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CenterMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RightMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LoadingSkeleton = styled(Skeleton)`
  display: flex;
  height: 23px;
  float: right;
  margin-right: 20px;
  width: 410px;
`;

export const GetTheAppButton = styled(TextButton)`
  display: flex;
  margin-right: 20px;
  margin-bottom: 5px;
`;
