import {SettingsAccountContainer} from "components/Settings/SettingsAccountContainer";
import {useParams} from "react-router-dom";

function SettingsAccountPage() {
  const {uuid} = useParams<{uuid: string}>();

  return <SettingsAccountContainer uuid={uuid} />;
}

export default SettingsAccountPage;
