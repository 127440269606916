import styled, {css} from "styled-components";

import {formStyles} from "config/styleVars";

export type Props = {
  children: React.ReactChildren | string;
  type?: "button" | "submit" | "reset";
  style?: Record<string, string | number>;
  variant?: "outline" | "link";
  onClick?: () => void;
  disabled?: boolean;
};

export const Container = styled.button<Props>`
  ${({variant}) =>
    variant === "outline"
      ? css`
          appearance: none;
          border: 1px solid ${formStyles.borderColor};
          border-radius: ${formStyles.borderRadius};
          background: transparent;
          color: ${formStyles.textColor};
          padding: 9px 17px;
          display: inline-block;

          &:hover {
            border: 1px solid ${formStyles.buttonHoverBorderColor};
            box-shadow: 0 0 0 1px ${formStyles.buttonHoverBorderColor};
          }

          &:active {
            opacity: 0.8;
          }

          &:disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        `
      : css`
          background: transparent;
          border: none;
          margin: 0;
          padding: 0;
          display: inline-block;
          color: ${formStyles.activeBorderColor};

          &:hover {
            opacity: 0.8;
          }

          &:disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        `}
`;
