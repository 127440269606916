import {useEffect, useRef} from "react";
import {useInfiniteHits} from "react-instantsearch-hooks-web";

import {AlgoliaInfiniteHitsPops} from "./types";

export function useAlgoliaInfiniteHits(props: AlgoliaInfiniteHitsPops) {
  const {hits, isLastPage, showMore, results} = useInfiniteHits(props);
  const query = results?.query;
  const sentinelRef = useRef<HTMLDivElement>(null);

  const totalHits = results?.nbHits || 0;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage && query) {
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore, query]);

  return {hits, query, sentinelRef, totalHits};
}
