import React from "react";
import {strings} from "config/strings";
import {MAX_TAGS_COUNT} from "config/constants";
import Tag from "components/Atoms/Tag";
import TextInput from "components/Atoms/TextInput";
import {TextTypes} from "components/Atoms/Text/types";

import {InputTagProps} from "./types";
import {useInputTag} from "./hooks";
import {
  Container,
  CounterContainer,
  CounterText,
  TagsList,
  TextInputContainer
} from "./styles";

const InputTag: React.FC<InputTagProps> = (props: InputTagProps) => {
  const {className, invalid} = props;
  const {
    handleChange,
    handleKeyDown,
    handleOnPaste,
    handleRemoveTag,
    isInputDisabled,
    state,
    tags
  } = useInputTag(props);

  return (
    <Container className={className}>
      <TextInputContainer>
        <TextInput
          onChange={handleChange}
          isDisabled={isInputDisabled}
          isInvalid={invalid}
          onKeyDown={handleKeyDown}
          onPaste={handleOnPaste}
          placeholder={strings.pages.upload.enterTag}
          initialValue={state}
        />
        <CounterContainer>
          <CounterText textType={TextTypes.Other.ButtonMLight}>
            {tags.length}/{MAX_TAGS_COUNT}
          </CounterText>
        </CounterContainer>
      </TextInputContainer>
      {tags.length > 0 && (
        <TagsList>
          {tags.map((tag: string) => (
            <Tag key={tag} onDelete={handleRemoveTag} tag={tag} />
          ))}
        </TagsList>
      )}
    </Container>
  );
};

export default React.memo(InputTag);
