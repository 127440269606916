import styled from "styled-components";
import Text from "components/Atoms/Text";
import {accentColor, textGray} from "config/styleVars";

import {ContainerProps} from "./types";

const getBorder = (error?: boolean) => {
  if (error) {
    return "1px solid #E7514B";
  }
  return "0.5px dashed #ffffff";
};

export const Container = styled.div<ContainerProps>`
  flex: 1;
  aspect-ratio: ${(props) => props.aspectRatio ?? "auto"};
  height: ${(props) => props.height ?? "auto"};
  width: ${(props) => props.width ?? "100%"};
  display: flex;
  border: ${(props) => getBorder(props.error)};
  backdrop-filter: blur(4px);
  border-radius: 3px;
`;

export const InnerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
`;

export const Title = styled(Text)`
  margin: 12px 0 21px 0;
`;

export const Subtitle = styled(Text)`
  color: ${textGray};

  > span {
    color: ${accentColor};
  }
`;

export const PreviewImage = styled.img`
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: contain;
`;
