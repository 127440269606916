import {useCallback, useState} from "react";

export function useModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = useCallback(() => {
    setIsModalOpen((value) => !value);
  }, []);

  return {isModalOpen, toggleModal};
}
