import React, {useCallback} from "react";
import {strings} from "config/strings";
import Step1IconSource from "icons/step1.svg";
import Step2IconSource from "icons/step2.svg";
import Step3IconSource from "icons/step3.svg";
import Step4IconSource from "icons/step4.svg";
import {MintNFTsStepProps} from "components/MintNFTsStep/styles";
import {Step} from "./styles";

const steps: MintNFTsStepProps[] = [
  {
    borderColor: "rgba(168, 124, 211, 1)",
    description: strings.pages.mintNFTs.step1Description,
    iconSource: Step1IconSource,
    key: strings.pages.mintNFTs.step1,
    stepName: strings.pages.mintNFTs.step1,
    title: strings.pages.mintNFTs.step1Title
  },
  {
    borderColor: "rgba(249, 128, 255, 1)",
    description: strings.pages.mintNFTs.step2Description,
    iconSource: Step2IconSource,
    key: strings.pages.mintNFTs.step2,
    stepName: strings.pages.mintNFTs.step2,
    title: strings.pages.mintNFTs.step2Title
  },
  {
    borderColor: "rgba(140, 198, 124, 1)",
    description: strings.pages.mintNFTs.step3Description,
    iconSource: Step3IconSource,
    key: strings.pages.mintNFTs.step3,
    stepName: strings.pages.mintNFTs.step3,
    title: strings.pages.mintNFTs.step3Title
  },
  {
    borderColor: "rgba(91, 155, 213, 1)",
    description: strings.pages.mintNFTs.step4Description,
    iconSource: Step4IconSource,
    key: strings.pages.mintNFTs.step4,
    stepName: strings.pages.mintNFTs.step4,
    title: strings.pages.mintNFTs.step4Title
  }
];

export function useMintNFTs() {
  const renderSteps = useCallback(() => {
    return steps.map((step: MintNFTsStepProps) => {
      return <Step {...step} />;
    });
  }, []);

  const getStartedOnClick = useCallback(() => {}, []);

  return {getStartedOnClick, renderSteps};
}
