import {useCallback, useState} from "react";

export function useCollapsible() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onOpening = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClosing = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {isOpen, onOpening, onClosing};
}
