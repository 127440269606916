import styled from "styled-components";
import {colors} from "theme/colors";

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.primary.black};
`;

export const SearchBoxContainer = styled.div`
  position: sticky;
  z-index: 91;
  width: 440px;
  top: 15px;
  min-width: 569px;
`;

export const ResultsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  z-index: 88;
`;
