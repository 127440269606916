import {useEffect, useState} from "react";

export const useImageUpload = (uploadFile?: File) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const start = (endpoint: string, file: File) => {
    setLoading(true);

    fetch(endpoint, {
      method: "PUT",
      mode: "cors",
      body: file,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": file.type
      }
    })
      .then(() => {
        setUploaded(true);
        setLoading(false);
      })
      .catch((reqError) => {
        setLoading(false);
        setError(reqError);
      });
  };

  useEffect(() => {
    setError(null);
    setLoading(false);
    setUploaded(false);
  }, [uploadFile]);

  return {start, error, loading, uploaded};
};
