import React from "react";
import ProgressiveImage from "components/Atoms/ProgressiveImage";
import {AVATAR_ATTRIBUTES} from "components/Atoms/ProgressiveImage/types";
import {TextTypes} from "components/Atoms/Text/types";

import {LettersContainer, RootContainer, StyledText} from "./styles";
import {AvatarProps} from "./types";
import {useAvatar} from "./hooks";

const Avatar: React.FC<AvatarProps> = ({
  avatarURL,
  borderType,
  size,
  username
}: AvatarProps) => {
  const {imageOnError, showLetters} = useAvatar({avatarURL, username});
  return (
    <RootContainer borderType={borderType} size={size}>
      {avatarURL && (
        <ProgressiveImage
          imageAttributes={AVATAR_ATTRIBUTES}
          onError={imageOnError}
          shouldLoadAnimatedImage={true}
          url={avatarURL}
        />
      )}
      {showLetters && username && (
        <LettersContainer>
          <StyledText textType={TextTypes.SubHeadings.SH2} size={size}>
            {username.charAt(0)}
          </StyledText>
        </LettersContainer>
      )}
    </RootContainer>
  );
};

export default React.memo(Avatar);
