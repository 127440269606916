import React from "react";
import {useDropzoneHook} from "components/Molecules/Dropzone/hooks";

import {Copy, RootContainer} from "./styles";
import {DropAreaProps} from "./types";

const DropArea: React.FC<DropAreaProps> = ({
  className,
  config,
  hasError
}: DropAreaProps) => {
  const {getInputProps, getRootProps} = useDropzoneHook(config);

  return (
    <RootContainer
      {...getRootProps()}
      aria-label="File Upload"
      className={className}
      hasError={hasError}
      role="button"
    >
      <input {...getInputProps()} />
      <Copy />
    </RootContainer>
  );
};

export default React.memo(DropArea);
