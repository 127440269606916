import {useRef, useEffect} from "react";
import {
  useAddress,
  useBalance
} from "@thirdweb-dev/react/dist/thirdweb-dev-react.cjs";

export function useMyWallet(toggleWallet?: () => void) {
  const walletRef = useRef<HTMLDivElement>(null);
  const address = useAddress();
  const {data: balanceData, refetch: refetchBalance} = useBalance();

  useEffect(() => {
    if (!toggleWallet && !walletRef.current) {
      return undefined;
    }
    const handleClickOutside = (event: any): void => {
      if (
        walletRef.current &&
        !walletRef.current.contains(event.target as any) &&
        toggleWallet
      ) {
        toggleWallet();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return (): void => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [toggleWallet]);

  return {
    balanceData,
    address,
    walletRef,
    refetchBalance
  };
}
