import React from "react";
import Text from "components/Atoms/Text";
import {TextTypes} from "components/Atoms/Text/types";
import {ProfileMetricProps} from "./types";
import {Container} from "./styles";
import {useProfileMetric} from "./hooks";

const ProfileMetric: React.FC<ProfileMetricProps> = ({
  label,
  value,
  onClick
}: ProfileMetricProps) => {
  const {formattedValue, handleClick} = useProfileMetric({onClick, value});

  return (
    <Container hasClick={!!onClick} onClick={handleClick}>
      <Text textType={TextTypes.Headings.H5}>{formattedValue}</Text>
      <Text textType={TextTypes.Bodies.B2}>{label}</Text>
    </Container>
  );
};

export default React.memo(ProfileMetric);
