import {useModal} from "hooks/useModal";
import {useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {tierPriceValidator} from "utils/validators/upload";

import {FormEvent, useCallback, useEffect} from "react";
import {TierPriceFormValues, TierPriceProps} from "./types";

export function useTierPrice({initialValues, onSubmit}: TierPriceProps) {
  const {isModalOpen, toggleModal} = useModal();

  const methods = useForm<TierPriceFormValues>({
    mode: "onBlur",
    resolver: yupResolver(tierPriceValidator)
  });

  const {
    control,
    formState: {errors, isValid},
    register,
    setValue,
    watch
  } = methods;

  useEffect(() => {
    setValue("tierPrice", initialValues);
  }, [initialValues, setValue]);

  const {fields} = useFieldArray({
    control,
    name: "tierPrice"
  });

  const formValues = watch();

  const formOnSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
  }, []);

  const saveOnClick = useCallback(() => {
    if (!isValid) {
      return;
    }

    onSubmit(formValues);
    toggleModal();
  }, [formValues, isValid, onSubmit, toggleModal]);

  return {
    errors,
    fields,
    formOnSubmit,
    isModalOpen,
    isValid,
    methods,
    saveOnClick,
    register,
    toggleModal
  };
}
