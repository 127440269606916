import {useCallback, useMemo, useRef, useState} from "react";
import {
  ControlPosition,
  DraggableBounds,
  DraggableData,
  DraggableEvent
} from "react-draggable";
import {OnAfterOpenCallbackOptions} from "react-modal";
import {ModalSide, SwipeableSideModalProps} from "./types";

const DISTANCE_THRESHOLD = 100;
const VELOCITY_THRESHOLD = 15;
const ZERO_POSITION = {x: 0, y: 0};
const DEFAULT_ANIMATION_DURATION = 300;

export function useSwipeableSideModal({
  animationDuration,
  dragLengthThreshold,
  dragVelocityThreshold,
  side,
  toggleModal
}: SwipeableSideModalProps) {
  const duration = animationDuration ?? DEFAULT_ANIMATION_DURATION;
  const isVertical = side === ModalSide.top || side === ModalSide.bottom;
  const [lastDelta, setLastDelta] = useState<number>(0);
  const lastTimestamps = useRef<{
    last: number;
    previous: number;
  }>({last: 0, previous: 0});
  const [position, setPosition] = useState<ControlPosition>(ZERO_POSITION);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [size, setSize] = useState<number>(0);
  const [closeAnimationDuration, setCloseAnimationDuration] =
    useState<number>(0);

  const bounds: DraggableBounds = useMemo(() => {
    switch (side) {
      case ModalSide.bottom:
        return {top: 0, left: 0, right: 0};

      case ModalSide.top:
        return {bottom: 0, left: 0, right: 0};

      case ModalSide.left:
        return {right: 0, top: 0, bottom: 0};

      case ModalSide.right:
        return {left: 0, top: 0, bottom: 0};

      default:
        return {top: 0, left: 0, right: 0};
    }
  }, [side]);

  const onDrag = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      const {timeStamp} = e;
      lastTimestamps.current = {
        previous: lastTimestamps.current.last,
        last: timeStamp
      };
      const {deltaX, deltaY, x, y} = data;
      setLastDelta(Math.abs(isVertical ? deltaY : deltaX));
      setPosition({x, y});
    },
    [isVertical]
  );

  const dragOnStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const dragOnStop = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      setIsDragging(false);
      const {x, y} = data;
      const dragDistance = isVertical ? y : x;
      const velocityThreshold = dragVelocityThreshold ?? VELOCITY_THRESHOLD;
      if (
        lastDelta > velocityThreshold ||
        Math.abs(dragDistance) > (dragLengthThreshold ?? DISTANCE_THRESHOLD)
      ) {
        const originSpeed = size / duration;
        const speed =
          lastDelta !== 0
            ? lastDelta /
              (lastTimestamps.current.last - lastTimestamps.current.previous)
            : 0;

        setCloseAnimationDuration(
          (size - dragDistance) / Math.max(speed, originSpeed)
        );
        toggleModal();
        setTimeout(() => {
          setPosition(ZERO_POSITION);
          setLastDelta(0);
          setCloseAnimationDuration(0);
        }, duration);
      } else {
        setPosition({x: 0, y: 0});
      }
    },
    [
      dragLengthThreshold,
      dragVelocityThreshold,
      duration,
      isVertical,
      lastDelta,
      size,
      toggleModal
    ]
  );

  const onAfterOpen = useCallback(
    (callbackOptions: OnAfterOpenCallbackOptions | undefined): void => {
      if (!callbackOptions) {
        return;
      }

      const {contentEl} = callbackOptions;
      const {clientHeight, clientWidth} = contentEl;
      setSize(isVertical ? clientHeight : clientWidth);
      document.body.style.overflow = "hidden";
    },
    [isVertical]
  );

  const onAfterClose = useCallback(() => {
    document.body.style.overflow = "unset";
  }, []);

  return {
    bounds,
    closeAnimationDuration,
    dragOnStart,
    dragOnStop,
    duration,
    isDragging,
    onAfterOpen,
    onAfterClose,
    onDrag,
    position
  };
}
