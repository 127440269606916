import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Progress = styled.div`
  width: 100px;
  height: 100px;
  align-self: center;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  position: relative;
`;

export const WindowScrollerContainer = styled.div`
  flex: 1;
`;

export const CellContainer = styled.div`
  padding: 0.4rem;
  display: flex;
`;
