import {formStyles} from "config/styleVars";
import React, {ChangeEvent, ChangeEventHandler} from "react";
import styled from "styled-components";

export type Props = {
  label?: string;
  style?: React.CSSProperties;
  invalid?: boolean;
  checked?: boolean;
  activeBorderColor?: string;
  stroke?: string;
  className?: string;
  defaultValue?: (string | number) & boolean;
  value?: any;
  readOnly?: boolean;
  ref?: React.Ref<any>;
  onChange?: ChangeEventHandler<HTMLInputElement> &
    ((event: ChangeEvent<HTMLInputElement>, checked: boolean) => void);
  onBlur?: () => void;
};

type CProps = {
  invalid?: boolean;
  checked?: boolean;
  activeBorderColor?: string;
};

export const Label = styled.label`
  display: flex;

  > span {
    margin-left: 8px;
  }
`;

export const Container = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

export const HiddenCheckbox = styled.input.attrs({type: "checkbox"})<Props>`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div<CProps>`
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 3px;
  transition: all 150ms;
  border: 1px solid
    ${(props) =>
      props.invalid ? formStyles.errorBorderColor : formStyles.borderColor};
  border-radius: ${formStyles.borderRadius};
  background: ${formStyles.backgroundColor};
  color: ${formStyles.textColor};

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px
      ${({activeBorderColor}) =>
        activeBorderColor || formStyles.activeBorderColor};
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;
