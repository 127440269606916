import {stopVideoStateVar} from "apollo/reactive";
import {Modal} from "components/Modal";
import {strings} from "config/strings";
import {useEffect, useRef, useState} from "react";

export const TimeoutAlert = () => {
  const currentTimeout = useRef(0);
  const subscribeEvent = useRef(true);
  const [isOpened, setIsOpened] = useState(false);

  const handleInteraction = () => {
    clearTimeout(currentTimeout.current);

    if (subscribeEvent.current) {
      const timeout = window.setTimeout(() => {
        setIsOpened(true);
        stopVideoStateVar(true);
        subscribeEvent.current = false;
      }, 630000); // 10.5min

      currentTimeout.current = timeout;
    }
  };

  useEffect(() => {
    handleInteraction();
  }, []);

  useEffect(() => {
    window.addEventListener("keypress", handleInteraction);
    window.addEventListener("mousemove", handleInteraction);

    return () => {
      window.removeEventListener("keypress", handleInteraction);
      window.removeEventListener("mousemove", handleInteraction);
      clearTimeout(currentTimeout.current);
    };
  }, []);

  const handleCloseModal = () => {
    setIsOpened(false);
    stopVideoStateVar(false);
    subscribeEvent.current = true;
    handleInteraction();
  };

  return (
    <Modal
      closeModalFn={handleCloseModal}
      heading={strings.pages.player.stopPlayer.header}
      isDialog={false}
      isOpen={isOpened}
      subheading={strings.pages.player.stopPlayer.body}
    />
  );
};
