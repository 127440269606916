import styled from "styled-components";
import Text from "components/Atoms/Text";
import {colors} from "theme/colors";
import {FloatingTextProps} from "./types";

export const FloatingText = styled(Text)<FloatingTextProps>`
  pointer-events: none;
  position: absolute;
  padding: 0px;
  top: 12px;
  transform: ${(props) =>
    props.isFloating
      ? "translate(0px, -7px) scale(0.7)"
      : "translate(0, 0) scale(1)"};
  transform-origin: top left;
  transition: all 0.2s ease-out;
  color: ${(props) =>
    props.isDisabled ? colors.primary.mediumGrey : colors.primary.lightGrey};
`;
