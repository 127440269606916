import React from "react";
import {Link} from "react-router-dom";

type Props = {
  mailto: string;
  label: string;
};

const ButtonMailto: React.FC<Props> = ({mailto, label}: Props) => {
  return (
    <Link
      to="#"
      onClick={(e) => {
        window.location.href = mailto;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};

export default ButtonMailto;
