import {useState, useCallback} from "react";
import {
  useMetamask,
  useWalletConnect
} from "@thirdweb-dev/react/dist/thirdweb-dev-react.cjs";

export function useConnectWalletButtons() {
  const [isConnecting, setIsConnecting] = useState(false);
  const connectWithMetamask = useMetamask();
  const connectWithWalletConnect = useWalletConnect();

  const onMetaMaskClick = useCallback(async () => {
    setIsConnecting(true);
    await connectWithMetamask();
    setIsConnecting(false);
  }, []);
  const onWalletConnectClick = useCallback(async () => {
    setIsConnecting(true);
    await connectWithWalletConnect();
    setIsConnecting(false);
  }, []);

  return {
    isConnecting,
    onMetaMaskClick,
    onWalletConnectClick
  };
}
