import {TOKEN_NAME} from "config/constants";

export const useAuthToken = () => {
  const getToken = () => {
    return window.localStorage.getItem(TOKEN_NAME);
  };

  const setAuthToken = (authToken: string) => {
    window.localStorage.setItem(TOKEN_NAME, authToken);
  };

  const removeAuthToken = () => {
    window.localStorage.removeItem(TOKEN_NAME);
  };

  return {getToken, setAuthToken, removeAuthToken};
};
