import React from "react";
import {TextTypes} from "components/Atoms/Text/types";
import {colors} from "theme/colors";
import InfoDialog from "components/Atoms/InfoDialog";
import {GrayText, RootContainer, StyledRadioButton} from "./styles";
import {RadioButtonProps} from "./types";

const RadioButton: React.FC<RadioButtonProps> = ({
  checked,
  className,
  disabled,
  info,
  label,
  onChange,
  value
}: RadioButtonProps) => {
  return (
    <RootContainer className={className}>
      <StyledRadioButton
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        type="radio"
        value={value}
      />
      <GrayText disabled={disabled} textType={TextTypes.Paragraph.L}>
        {label}
      </GrayText>
      {info && (
        <InfoDialog
          info={info}
          color={disabled ? colors.primary.darkGrey : colors.primary.lightGrey}
        />
      )}
    </RootContainer>
  );
};

export default React.memo(RadioButton);
