import {colors} from "theme/colors";
import {StylesConfig, Theme} from "react-select";

export function getStyles<T>() {
  const styles: StylesConfig<T, false> = {
    menu: (base) => ({
      ...base,
      backgroundColor: colors.inputBackground,
      marginTop: 8,
      borderRadius: 32,
      border: `1px solid ${colors.primary.darkGrey}`,
      overflow: "hidden"
    }),
    menuList: () => ({
      margin: 0
    }),
    option: (base, state) => {
      const stringId = state.innerProps.id?.split("-").pop();
      const id = stringId ? parseInt(stringId) : 0;
      const {length} = state.options;
      const isFirst = id === 0;
      const isLast = id === length - 1;
      return {
        ...base,
        padding: 0,
        borderStyle: "solid",
        borderColor: colors.primary.white10,
        borderWidth: 0,
        borderTopWidth: isFirst ? 0 : 0.5,
        borderBottomWidth: isLast ? 0 : 0.5
      };
    },
    singleValue: (base) => {
      return {...base, margin: 0};
    },
    multiValue: (base) => {
      return {...base, margin: 0, padding: 0};
    },
    multiValueLabel: (base) => {
      return {...base, margin: 0, padding: 0, paddingLeft: 0};
    },
    valueContainer: (base, ctx) => {
      const value = ctx?.getValue()?.[0];
      const {isMulti} = ctx || {};

      return {...base, padding: 0, paddingLeft: isMulti && value ? 39 : 0};
    },
    control: (base) => {
      return {...base, overflow: "hidden"};
    },
    clearIndicator: (base) => {
      return {
        ...base,
        position: "absolute",
        left: 0,
        top: 0,
        padding: 0,
        height: "100%"
      };
    },
    dropdownIndicator: (base, state) => {
      return {
        ...base,
        color: colors.primary.lightGrey,
        "&:hover": {color: colors.primary.lightGrey},
        transform: `rotate(${state.selectProps.menuIsOpen ? 180 : 0}deg)`,
        transition: "transform 0.2s ease",
        marginRight: 20,
        padding: 0
      };
    },
    indicatorSeparator: (base) => {
      return {...base, margin: 0, width: 0};
    },
    placeholder: (base) => ({
      ...base,
      padding: "12px 14px",
      fontFamily:
        "proximanova, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue",
      fontSize: "15px",
      lineHeight: "20px",
      fontWeight: 300,
      color: colors.primary.white
    })
  };
  return styles;
}

export const theme = (baseTheme: Theme) => {
  return {
    ...baseTheme,
    borderRadius: 8,
    colors: {
      ...baseTheme.colors,
      primary: colors.primary.cyan,
      neutral0: colors.inputBackground,
      neutral5: colors.inputBackground,
      neutral10: colors.primary.mediumGrey, // disabled border
      neutral20: colors.primary.darkGrey,
      neutral30: colors.primary.lightGrey, // border on hover
      neutral40: colors.primary.darkGrey, // chevron down icon
      primary25: colors.primary.cyan20,
      primary50: colors.primary.cyan50
    }
  };
};
