import React from "react";
import {TextTypes} from "components/Atoms/Text/types";
import {DropdownStyleProps} from "components/Atoms/Dropdown/types";
import {TiersDropdownDataProps} from "./types";
import {Description, Title, RootContainer} from "./styles";

const TiersDropdownOption: React.FC<
  DropdownStyleProps<TiersDropdownDataProps>
> = (props: DropdownStyleProps<TiersDropdownDataProps>) => {
  const {description, isDisabled, isFocused, isOption, isSelected, label} =
    props;

  return (
    <RootContainer isOption={isOption}>
      <Title
        textType={TextTypes.Other.LinkLabelL}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        isOption={isOption}
      >
        {label}
      </Title>
      {isOption && (
        <Description
          textType={TextTypes.Other.LinkLabelM}
          isDisabled={isDisabled}
          isFocused={isFocused}
          isSelected={isSelected}
          isOption={isOption}
        >
          {description}
        </Description>
      )}
    </RootContainer>
  );
};

export default React.memo(TiersDropdownOption);
