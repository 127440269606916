import React from "react";
import InputModal from "components/InputModal";
import Text from "components/Atoms/Text";
import {TextTypes} from "components/Atoms/Text/types";
import {strings} from "config/strings";
import {ReactComponent as PyramidIcon} from "icons/universal/pyramid.svg";
import {colors} from "theme/colors";
import Dropdown from "components/Atoms/Dropdown";
import {FormProvider} from "react-hook-form";
import TiersDropdownOption from "components/Atoms/TiersDropdownOption";
import Collapsible from "components/Atoms/Collapsible";
import AddEditButton from "components/Molecules/AddEditButton";

import {
  GraySeparator,
  InputLabel,
  PercentageDetailText,
  RootContainer
} from "theme/genericStyles/uploadPopupStyles";
import {PercentageProps} from "./types";
import {DropdownOptions, usePercentage} from "./hooks";
import {
  BottomContainer,
  FormContainer,
  GrayText,
  ExplanationText,
  ExplanationTextContainer,
  PercentageDetail,
  PercentageOfRevenueText,
  Subtotal,
  YourRevenue,
  CollapsibleContainer
} from "./styles";

const Percentage: React.FC<PercentageProps> = (props: PercentageProps) => {
  const {
    collaboratorsRevenuePercentage,
    dropdownOnChange,
    explanationText,
    formOnSubmit,
    formValues,
    isModalOpen,
    methods,
    musicRightsHoldersPercentage,
    remainingRevenue,
    saveOnClick,
    subtotal,
    toggleModal
  } = usePercentage(props);

  const {disabled, initialValue} = props;
  return (
    <>
      <AddEditButton
        disabled={disabled}
        isEdit={!!initialValue?.tier}
        onClick={toggleModal}
      />

      <InputModal
        disableBodyPadding
        isOpen={isModalOpen}
        title={strings.pages.upload.modals.inputPercentage.title}
        submitText={strings.generic.save}
        disableSubmit={!formValues.tier}
        onSubmit={saveOnClick}
        onClose={toggleModal}
        headerIcon={<PyramidIcon width={24} />}
      >
        <FormProvider {...methods}>
          <form onSubmit={formOnSubmit}>
            <>
              <RootContainer>
                <GrayText textType={TextTypes.Paragraph.L}>
                  {strings.pages.upload.modals.inputPercentage.description}
                </GrayText>
                <PercentageOfRevenueText textType={TextTypes.Titles.H5}>
                  {
                    strings.pages.upload.modals.inputPercentage
                      .percentageOfRevenue
                  }
                </PercentageOfRevenueText>
                <FormContainer>
                  <InputLabel textType={TextTypes.Titles.H6}>
                    {strings.pages.upload.modals.inputPercentage.numberOfTiers}
                  </InputLabel>
                  <Dropdown
                    defaultValue={initialValue?.tier}
                    options={DropdownOptions}
                    // @ts-ignore
                    OptionComponent={TiersDropdownOption}
                    placeholder={
                      strings.pages.upload.modals.inputPercentage
                        .dropdownPlaceholder
                    }
                    // @ts-ignore
                    onChange={dropdownOnChange}
                  />
                  <ExplanationTextContainer>
                    <ExplanationText textType={TextTypes.Other.ButtonMLight}>
                      {explanationText}
                    </ExplanationText>
                  </ExplanationTextContainer>
                </FormContainer>
              </RootContainer>

              <BottomContainer>
                <Collapsible
                  textType={TextTypes.Titles.H4}
                  text={strings.pages.upload.modals.inputPercentage.breakdown}
                >
                  <CollapsibleContainer>
                    <Text textType={TextTypes.Titles.H5}>
                      {strings.generic.contentAllocations}
                    </Text>
                    <PercentageDetail>
                      <div>
                        <PercentageDetailText textType={TextTypes.Paragraph.L}>
                          {strings.pages.upload.tokenHolderOptions.musicRights}
                          <span>{musicRightsHoldersPercentage}%</span>
                        </PercentageDetailText>
                        <PercentageDetailText textType={TextTypes.Paragraph.L}>
                          {
                            strings.pages.upload.tokenHolderOptions
                              .collaboratorsRevenueAllocation
                          }
                          <span>{collaboratorsRevenuePercentage}%</span>
                        </PercentageDetailText>
                        <PercentageDetailText
                          color={colors.primary.mediumGrey}
                          textType={TextTypes.Paragraph.L}
                        >
                          {
                            strings.pages.upload.modals.inputPercentage
                              .tokenHolderAllocation
                          }
                          <span>
                            {subtotal -
                              musicRightsHoldersPercentage -
                              collaboratorsRevenuePercentage}
                            %
                          </span>
                        </PercentageDetailText>
                      </div>
                      <GraySeparator />
                      <Subtotal
                        textType={TextTypes.Titles.H5}
                        color={colors.primary.white}
                      >
                        {strings.generic.subtotal}: <span>{subtotal}%</span>
                      </Subtotal>
                      <GraySeparator />
                      <YourRevenue
                        textType={TextTypes.Titles.H5}
                        color={colors.primary.white}
                      >
                        {strings.pages.upload.tokenHolderOptions.revenueForYou}:
                        <span>{remainingRevenue}%</span>
                      </YourRevenue>
                    </PercentageDetail>
                  </CollapsibleContainer>
                </Collapsible>
              </BottomContainer>
            </>
          </form>
        </FormProvider>
      </InputModal>
    </>
  );
};

export default React.memo(Percentage);
