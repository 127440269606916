import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: block;
  margin-top: 2px;
  cursor: pointer;
`;

const Icon = styled.div`
  display: inline;
  float: right;
  margin-right: 15px;
`;

const Label = styled.div`
  display: inline;
  padding-left: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  vertical-align: top;
`;

type DropMenuItemProps = {
  label: string;
  iconPath: string;
  clickFn: () => void;
};

export const DropMenuItem: React.FC<DropMenuItemProps> = (
  props: DropMenuItemProps
) => {
  const handleClick = () => {
    props.clickFn();
  };

  const {label, iconPath} = props;

  return (
    <Container onClick={handleClick}>
      <Label>{label}</Label>
      <Icon>
        <img alt={`${label} icon`} src={iconPath} />
      </Icon>
    </Container>
  );
};
