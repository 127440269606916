export const errorRed = "#E7514B";
export const successGreen = "#4CAF50";
export const textColor = "#fff";
export const textGray = "#A39EA9";
export const textDarkGray = "#8D8D8D";
export const accentColor = "#E0115F";

export const formStyles = {
  backgroundColor: "transparent",
  borderColor: "#4B494D",
  activeBorderColor: accentColor,
  buttonHoverBorderColor: "#fff",
  textColor,
  borderRadius: "4px",
  inputPadding: "14px",
  marginBottom: "18px",
  errorBorderColor: errorRed
};

export const colours = {
  black: "#000000",
  darkGray: "#909090",
  lightGray: "#C4C4C4",
  white: "#FFFFFF",
  dark: "#202020"
};

export const zIndex = {
  navBar: 90,
  player: 89
};

export const breakPoints = {
  mobile: "760px"
};

export const minContainerWidth = "900px";
export const maxContainerWidth = "1700px";

export const navHeight = 68;
