import React from "react";
import {TextTypes} from "components/Atoms/Text/types";
import InputModal from "components/InputModal";
import TextInput from "components/Atoms/TextInput";
import IconButton from "components/Atoms/Button";
import {ReactComponent as PlusIcon} from "icons/plus.svg";
import {ReactComponent as EditIcon} from "icons/20/edit.svg";
import {ReactComponent as GroupIcon} from "icons/universal/group.svg";
import {colors} from "theme/colors";
import {ButtonSize, ButtonType} from "components/Atoms/Button/types";
import {strings} from "config/strings";

import {RecommendedInput} from "theme/genericStyles/uploadPopupStyles";
import {
  ResaleDescription,
  TitleFee,
  Legend,
  Separator,
  Container,
  InternalSeparator,
  PercentageDetail,
  PercentageDetailText
} from "./styles";
import {useTokenResalePercentage} from "./hooks";

export interface TokenResalePercentageProps {
  disabled?: boolean;
  onChange: (value: number) => void;
  initialValue?: number;
  maxValue: number;
  musicPercentage: number;
  tokenHolderPercentage: number;
  collaboratorsRevenuePercentage: number;
  platformFee: number;
}

const TokenResalePercentage: React.FC<TokenResalePercentageProps> = (
  props: TokenResalePercentageProps
) => {
  const {
    handleResalePercentageChange,
    resalePercentageError,
    isPopUpOpen,
    toggleModal,
    onSubmit,
    isEdit
  } = useTokenResalePercentage(props);
  const {
    disabled,
    initialValue,
    maxValue,
    musicPercentage,
    tokenHolderPercentage,
    collaboratorsRevenuePercentage,
    platformFee
  } = props;
  const subTotal =
    musicPercentage + tokenHolderPercentage + collaboratorsRevenuePercentage;
  const total = 100 - subTotal;

  return (
    <>
      <IconButton
        disabled={disabled}
        type={ButtonType.secondary}
        size={ButtonSize.m}
        onClick={toggleModal}
        renderIcon={(color, size) =>
          isEdit ? (
            <EditIcon
              width={size}
              height={size}
              stroke={color}
              strokeWidth={1.5}
            />
          ) : (
            <PlusIcon
              width={size}
              height={size}
              stroke={color}
              strokeWidth={1.5}
            />
          )
        }
      />
      <InputModal
        isOpen={isPopUpOpen}
        title={strings.pages.upload.tokenHolderOptions.title}
        onClose={toggleModal}
        shouldCloseOnOverlayClick={false}
        onSubmit={onSubmit}
        submitText={strings.generic.save}
        disableSubmit={resalePercentageError}
        disableBodyPadding
        headerIcon={<GroupIcon width={24} />}
      >
        <>
          <Container>
            <ResaleDescription
              textType={TextTypes.Paragraph.L}
              marginBottom={24}
            >
              {strings.pages.upload.tokenHolderOptions.resaleDescription}
            </ResaleDescription>
            <TitleFee textType={TextTypes.Titles.H5} marginBottom={2}>
              {strings.pages.upload.tokenHolderOptions.percentageDescription}
            </TitleFee>
            <ResaleDescription
              textType={TextTypes.Paragraph.L}
              marginBottom={24}
            >
              {strings.pages.upload.tokenHolderOptions.eversePercentage} (
              {platformFee}%)
            </ResaleDescription>
            <TitleFee textType={TextTypes.Titles.H5} marginBottom={16}>
              {strings.pages.upload.tokenHolderOptions.memberDescription}
            </TitleFee>
            <TitleFee textType={TextTypes.Titles.H6} marginBottom={6}>
              {strings.pages.upload.tokenHolderOptions.creatorEarnings}
              <Legend>(Max {maxValue}%)</Legend>
            </TitleFee>
            <TextInput
              placeholder={strings.generic.enterPercent}
              type="number"
              initialValue={String(initialValue)}
              onChange={handleResalePercentageChange}
            />
            <RecommendedInput textType={TextTypes.Bodies.B1}>
              {strings.generic.recommendedInput}
            </RecommendedInput>
          </Container>
          <Separator />
          <Container>
            <TitleFee textType={TextTypes.Titles.H5} marginBottom={16}>
              {strings.generic.contentAllocations}
            </TitleFee>

            <InternalSeparator marginStart={8} marginEnd={16} />
            <PercentageDetail>
              <PercentageDetailText textType={TextTypes.Paragraph.L}>
                {strings.pages.upload.tokenHolderOptions.musicRights}
                <span>{musicPercentage}%</span>
              </PercentageDetailText>
              <PercentageDetailText textType={TextTypes.Paragraph.L}>
                {
                  strings.pages.upload.tokenHolderOptions
                    .collaboratorsRevenueAllocation
                }
                <span>{collaboratorsRevenuePercentage}%</span>
              </PercentageDetailText>
              <PercentageDetailText textType={TextTypes.Paragraph.L} disabled>
                {strings.pages.upload.tokenHolderOptions.tokenHolderAllocation}
                <span>{tokenHolderPercentage}%</span>
              </PercentageDetailText>
              <InternalSeparator marginStart={32} marginEnd={20} />
              <PercentageDetailText
                textType={TextTypes.Titles.H5}
                color={colors.primary.white}
              >
                {strings.generic.subtotal}: <span>{subTotal}%</span>
              </PercentageDetailText>
              <InternalSeparator marginStart={20} marginEnd={24} />
              <PercentageDetailText
                textType={TextTypes.Titles.H5}
                color={colors.primary.white}
              >
                {strings.pages.upload.tokenHolderOptions.revenueForYou}:
                <span>{total}%</span>
              </PercentageDetailText>
            </PercentageDetail>
          </Container>
        </>
      </InputModal>
    </>
  );
};

export default React.memo(TokenResalePercentage);
