import {SyntheticEvent, useCallback, useEffect, useState} from "react";
import {
  CLIPPING_TOOL_IMAGE_SIZE,
  CLIPPING_TOOL_TIMELINE_OFFSET,
  SCRUBBER_INITIAL_END,
  SCRUBBER_INITIAL_START,
  SCRUBBER_MAX_SECONDS,
  SECONDS_PER_FRAME
} from "config/constants";
import {formatTimelineInterval} from "utils/FormatUtils";

import {ClippingToolTimelineProps, TimelineItemProps} from "./types";

const rootContainerId = "rootContainer";
const scrollableTimelineId = "scrollableTimeline";

export function useClippingToolTimeline(props: ClippingToolTimelineProps) {
  const {startTime, setStartTime, endTime, setEndTime, videoInfo} = props;
  // data to map to a list
  const [timelineSource, setTimelineSource] = useState<TimelineItemProps[]>();
  // whole content size - left and right offsets
  const [timelineContentSize, setTimelineContentSize] = useState<number>(0);
  const [timelineScrollPosition, setTimelineScrollPosition] =
    useState<number>(0);
  const [overflow, setOverflow] = useState<string>("auto");
  // string formatted interval to display on UI
  const [selectedFragment, setSelectedFragment] = useState<string>(
    formatTimelineInterval(
      SCRUBBER_INITIAL_START,
      Math.min(SCRUBBER_INITIAL_END, endTime)
    )
  );
  const [scrubberWidth, setScrubberWidth] = useState<number>(
    (CLIPPING_TOOL_IMAGE_SIZE * Math.min(SCRUBBER_MAX_SECONDS, endTime)) /
      SECONDS_PER_FRAME
  );
  const [scrubberTranslationX, setScrubberTranslationX] = useState<number>(
    CLIPPING_TOOL_TIMELINE_OFFSET
  );

  useEffect(() => {
    setSelectedFragment(formatTimelineInterval(startTime, endTime));
  }, [endTime, startTime]);

  useEffect(() => {
    const contentStart = CLIPPING_TOOL_TIMELINE_OFFSET - timelineScrollPosition;
    // set selected time
    const fragmentStart = scrubberTranslationX - contentStart;
    // convert UI position into the time
    const time =
      Math.floor(
        (fragmentStart / CLIPPING_TOOL_IMAGE_SIZE) * SECONDS_PER_FRAME * 10
      ) / 10;
    setStartTime(time);
    // calculate endTime using startTime and UI width of a scrubber
    setEndTime(
      time + (scrubberWidth / CLIPPING_TOOL_IMAGE_SIZE) * SECONDS_PER_FRAME
    );
  }, [
    scrubberWidth,
    scrubberTranslationX,
    timelineScrollPosition,
    setStartTime,
    setEndTime
  ]);

  useEffect(() => {
    if (!videoInfo?.duration) {
      return;
    }

    const integerDuration = Math.floor(videoInfo.duration);
    const timelineItems: TimelineItemProps[] = [];
    let i = 0;
    let contentSize = 0;
    for (let time = 0; time < integerDuration; time += SECONDS_PER_FRAME) {
      // the last step could be shorter
      const stepDuration = Math.min(integerDuration - time, SECONDS_PER_FRAME);
      timelineItems[i] = {
        start: time,
        duration: stepDuration
      };
      // because of different size of a step we can't just calculate it at the beginning and have to increment
      contentSize +=
        (CLIPPING_TOOL_IMAGE_SIZE * stepDuration) / SECONDS_PER_FRAME;
      i++;
    }

    // add left and right offsets
    setTimelineContentSize(contentSize + CLIPPING_TOOL_TIMELINE_OFFSET * 2);
    setTimelineSource(timelineItems);
  }, [videoInfo]);

  const scrollHandler = useCallback(
    (e: SyntheticEvent) => {
      const element = document.getElementById(scrollableTimelineId);

      if (!element) {
        return;
      }

      const scrollPosition = element?.scrollLeft;

      if (!scrollPosition) {
        return;
      }

      const positionOfTimelineStart =
        CLIPPING_TOOL_TIMELINE_OFFSET - scrollPosition;
      const positionOfTimelineEnd =
        positionOfTimelineStart +
        timelineContentSize -
        CLIPPING_TOOL_TIMELINE_OFFSET * 2;

      const stopScrolling = () => {
        setOverflow("hidden");
        e.preventDefault();
      };

      const setScrollPosition = (position: number) => {
        element.scrollLeft = position;
        setTimelineScrollPosition(position);

        setTimeout(() => {
          setOverflow("auto");
        }, 500);
      };

      if (positionOfTimelineStart > scrubberTranslationX) {
        stopScrolling();
        setScrollPosition(CLIPPING_TOOL_TIMELINE_OFFSET - scrubberTranslationX);
        return;
      }

      if (positionOfTimelineEnd < scrubberTranslationX + scrubberWidth) {
        stopScrolling();
        setScrollPosition(
          timelineContentSize -
            CLIPPING_TOOL_TIMELINE_OFFSET -
            (scrubberTranslationX + scrubberWidth)
        );
        return;
      }

      setTimelineScrollPosition(scrollPosition);
    },
    [
      scrubberTranslationX,
      scrubberWidth,
      timelineContentSize,
      timelineScrollPosition
    ]
  );

  const wheelHandler = useCallback((e: SyntheticEvent) => {
    const delta = (e.nativeEvent as WheelEvent).deltaY;
    if (Math.abs(delta) < 10) {
      return;
    }

    const element = document.getElementById(scrollableTimelineId);
    if (!element) {
      return;
    }
    element.scrollLeft += delta / 10;
  }, []);

  return {
    overflow,
    rootContainerId,
    scrollableTimelineId,
    scrollHandler,
    scrubberTranslationX,
    setScrubberTranslationX,
    scrubberWidth,
    setScrubberWidth,
    selectedFragment,
    timelineContentSize,
    timelineScrollPosition,
    timelineSource,
    wheelHandler
  };
}
