import React, {useState} from "react";
import styled from "styled-components";
import CameraIcon from "icons/cameraPlus.svg";
import {strings} from "config/strings";
import {Modal} from "components/Modal";
import {ProfileImageTypes, ProfileImageInput} from ".";

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  z-index: 1;
`;

const Icon = styled.div`
  position: absolute;
  left: 50%;
  margin-left: -12px;
  top: 50%;
  margin-top: -12px;
`;

type Props = {
  type: ProfileImageTypes;
};

export const AddImageOverlay: React.FC<Props> = ({type}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [doneCropping, setDoneCropping] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setDoneCropping(false);
    setModalOpen(false);
  };
  const handleSave = () => {
    setDoneCropping(true);
  };

  return (
    <>
      <Container onClick={handleOpenModal}>
        <Icon>
          <img alt="Add Image" src={CameraIcon} />
        </Icon>
      </Container>
      <Modal
        closeModalFn={handleCloseModal}
        heading={`${strings.generic.upload} ${type.replace(/^\w/, (c) =>
          c.toUpperCase()
        )}`}
        isDialog={true}
        isOpen={modalOpen}
        saveFn={handleSave}
        subheading={`${strings.cropping.subheading} ${type}`}
      >
        <ProfileImageInput
          doneCropping={doneCropping}
          doneUploadingFn={handleCloseModal}
          isDisplayed={modalOpen}
          type={type}
        />
      </Modal>
    </>
  );
};
