import React from "react";
import ClippingToolPreview from "components/Molecules/ClippingToolPreview";
import ClippingToolTimeline from "components/Molecules/ClippingToolTimeline";

import {Container} from "./styles";
import {ClippingToolProps} from "./types";
import {useClippingTool} from "./hooks";

const ClippingTool: React.FC<ClippingToolProps> = (
  props: ClippingToolProps
) => {
  const {endTime, isVisible, setEndTime, setStartTime, startTime, video} =
    props;

  const {
    isVideoCodecSupported,
    renderSpinner,
    uploadedVideoPlaybackUrl,
    uploadedVideoStoryboard,
    videoInfo
  } = useClippingTool(props);

  if (!video) {
    return null;
  }

  return (
    <Container isVisible={isVisible}>
      {renderSpinner()}
      {videoInfo && (isVideoCodecSupported || uploadedVideoPlaybackUrl) && (
        <>
          <ClippingToolPreview
            endTime={endTime}
            isVideoCodecSupported={isVideoCodecSupported}
            startTime={startTime}
            uploadedVideoPlaybackUrl={uploadedVideoPlaybackUrl}
            video={video}
          />
          <ClippingToolTimeline
            videoInfo={videoInfo}
            endTime={endTime}
            isVideoCodecSupported={isVideoCodecSupported}
            setEndTime={setEndTime}
            setStartTime={setStartTime}
            startTime={startTime}
            uploadedVideoStoryboard={uploadedVideoStoryboard}
            video={video}
          />
        </>
      )}
    </Container>
  );
};

export default React.memo(ClippingTool);
