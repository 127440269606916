export enum ButtonType {
  primary,
  secondary,
  text
}

export enum ButtonSize {
  s,
  m,
  l,
  xl
}

export enum IconSide {
  left,
  right
}

export interface ButtonProps {
  className?: string;
  disabled?: boolean;
  iconSide?: IconSide;
  onClick: () => void;
  renderIcon?: (color: string, size: number) => JSX.Element | null;
  size: ButtonSize;
  text?: string;
  type: ButtonType;
}

export interface ButtonContainerProps {
  buttonSize: ButtonSize;
  buttonType: ButtonType;
  disabled?: boolean;
  text?: string;
  hasIcon?: boolean;
}

export interface IconContainerProps {
  iconSide?: IconSide;
  text?: string;
}
