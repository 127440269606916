import {colors} from "theme/colors";
import styled from "styled-components";

import {ModalSide} from "./types";

function getTranslate(side: ModalSide) {
  switch (side) {
    case ModalSide.top:
      return "0, -100%";

    case ModalSide.bottom:
      return "0, 100%";

    case ModalSide.left:
      return "-100%, 0";

    case ModalSide.right:
      return "100%, 0";

    default:
      return "0";
  }
}

export const getModalStyles = (
  side: ModalSide,
  duration: number,
  closeDuration: number
) => ({
  content: {
    bottom: side === ModalSide.top ? "auto" : 0,
    left: side === ModalSide.right ? "auto" : 0,
    right: side === ModalSide.left ? "auto" : 0,
    top: side === ModalSide.bottom ? "auto" : 0,
    background: "transparent",
    overflow: "hidden",
    padding: "0",
    border: "none",
    display: "inline-block",
    borderRadius: "0",
    "--translateDuration": `${
      closeDuration !== 0 ? closeDuration : duration
    }ms`,
    "--translate": getTranslate(side)
  },
  overlay: {
    backgroundColor: colors.primary.black30,
    "--duration": `${duration}ms`,
    zIndex: 99999
  }
});

export const ContentContainer = styled.div<{
  duration: number;
  isDragging: boolean;
}>`
  transition: ${({duration, isDragging}) =>
    isDragging ? "none" : `transform ${duration}ms`};
`;
