import React, {useState} from "react";
import {useParams, useHistory} from "react-router-dom";
import {Auth} from "aws-amplify";
import {strings} from "config/strings";
import {Routes} from "config/routes";
import {
  FieldValidationMessage,
  ResponseMessage,
  ResponseMessageTypes,
  InlineTextButton
} from "components/StyledElements/FormElements";
import VerificationCodeInput from "components/VerificationCodeInput/VerificationCodeInput";
import {Spinner} from "components/Spinner";
import {
  AuthWrapper,
  AuthHeading,
  AuthSubHeading,
  AuthButton,
  FooterText
} from "components/Auth/AuthElements";

const VerifyForm: React.FunctionComponent = () => {
  const history = useHistory();

  const {email} = useParams<{email: string}>();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState<string | undefined>();
  const [formError, setFormError] = useState<string | undefined>();
  const [formSuccess, setFormSuccess] = useState<string | undefined>();

  function handleCodeComplete(value: string) {
    setCode(value);
  }

  function clearErrors() {
    setCodeError(undefined);
    setFormError(undefined);
    setFormSuccess(undefined);
  }

  function validateForm() {
    let isValid = true;
    if (code === undefined || code.length !== 6) {
      isValid = false;
      setCodeError(strings.auth.verificationCodeRequired);
    }
    return isValid;
  }

  async function confirmSignUp(newEmail: string, newCode: string) {
    try {
      await Auth.confirmSignUp(newEmail, newCode);
      if (window.analytics) {
        window.analytics.track("Verified via - Email", {
          type: "email-manual"
        });
      }
      setTimeout(() => history.push(`${Routes.signin}?from=SignUp`), 50);
    } catch (error: any) {
      console.error(error);
      setFormError(error.message);
    }
    setLoading(false);
  }

  async function resendConfirmationCode(newEmail: string) {
    try {
      await Auth.resendSignUp(newEmail);
      setFormSuccess(strings.auth.codeSentSuccess);
    } catch (error: any) {
      setFormError(error.message);
    }
    setLoading(false);
  }

  async function handleSubmit(
    e: React.FormEvent<HTMLFormElement | HTMLButtonElement>
  ) {
    e.preventDefault();
    clearErrors();
    const isValid = validateForm();
    if (!isValid) {
      // noop
    } else {
      setLoading(true);
      await confirmSignUp(email, code);
    }
  }

  async function handleResend() {
    clearErrors();
    setLoading(true);
    await resendConfirmationCode(email);
  }

  return (
    <AuthWrapper isLoading={loading}>
      {loading && <Spinner />}
      <form onSubmit={handleSubmit}>
        <AuthHeading>Verify Your Email</AuthHeading>
        <AuthSubHeading>{strings.auth.enterCode(email)}</AuthSubHeading>
        {formError && (
          <ResponseMessage
            message={formError}
            type={ResponseMessageTypes.Error}
          />
        )}
        {formSuccess && (
          <ResponseMessage
            message={formSuccess}
            type={ResponseMessageTypes.Success}
          />
        )}
        <div>
          <VerificationCodeInput
            fieldCount={6}
            invalid={!!codeError}
            onComplete={handleCodeComplete}
            onFocus={clearErrors}
          />
          {codeError && (
            <FieldValidationMessage>{codeError}</FieldValidationMessage>
          )}
        </div>
        <AuthButton onClick={handleSubmit}>{strings.generic.verify}</AuthButton>
        <FooterText>
          {strings.auth.noEmail}&nbsp;
          <InlineTextButton type="button" onClick={handleResend}>
            {strings.auth.resendCodeButton}
          </InlineTextButton>
        </FooterText>
      </form>
    </AuthWrapper>
  );
};

export default VerifyForm;
