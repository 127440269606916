import React, {useCallback} from "react";
import {ReactComponent as DollarIcon} from "icons/universal/dollar.svg";
import AddEditButton from "components/Molecules/AddEditButton";
import InputModal from "components/InputModal";
import {strings} from "config/strings";
import {TextTypes} from "components/Atoms/Text/types";
import TextInput from "components/Atoms/TextInput";
import Tooltip from "components/Atoms/Tooltip";
import {FormProvider} from "react-hook-form";

import {
  GrayText,
  InputLabel,
  RecommendedInput,
  RootContainer
} from "theme/genericStyles/uploadPopupStyles";
import {TierPriceProps} from "./types";
import {useTierPrice} from "./hooks";

const TierPrice: React.FC<TierPriceProps> = (props: TierPriceProps) => {
  const {disabled, initialValues, isEdit, tiers} = props;
  const {
    errors,
    fields,
    formOnSubmit,
    isModalOpen,
    isValid,
    methods,
    register,
    saveOnClick,
    toggleModal
  } = useTierPrice(props);

  const renderInput = useCallback(
    (index: number, tierId: number) => {
      return (
        <>
          <InputLabel marginTop={20} textType={TextTypes.Titles.H6}>
            {strings.pages.upload.modals.tierPrice.priceOfNFTs(tierId)}
            <span>
              {tierId === 1
                ? strings.pages.upload.modals.tierPrice.thumbnailLabelSpan
                : strings.pages.upload.modals.tierPrice.videoLabelSpan}
            </span>
          </InputLabel>
          <TextInput
            {...register(`tierPrice.${index}.value`)}
            helperText={
              !!errors?.tierPrice?.length &&
              !!errors.tierPrice[index]?.value?.message
                ? errors.tierPrice[index]?.value?.message
                : ""
            }
            initialValue={String(initialValues[index]?.value)}
            isInvalid={
              !!errors?.tierPrice?.length && !!errors.tierPrice[index]?.value
            }
            placeholder={strings.pages.upload.modals.tierPrice.inputPlaceholder}
            type="number"
          />
          <Tooltip
            text={strings.pages.upload.modals.tierPrice.recommendedInput}
          >
            <div>
              <RecommendedInput textType={TextTypes.Bodies.B1}>
                {strings.generic.recommendedInput}
              </RecommendedInput>
            </div>
          </Tooltip>
        </>
      );
    },
    [errors.tierPrice, initialValues, register]
  );

  return (
    <>
      <AddEditButton
        disabled={disabled}
        isEdit={isEdit}
        onClick={toggleModal}
      />

      <InputModal
        disableBodyPadding
        disableSubmit={!isValid}
        isOpen={isModalOpen}
        title={strings.pages.upload.modals.tierPrice.title}
        submitText={strings.generic.save}
        onSubmit={saveOnClick}
        onClose={toggleModal}
        headerIcon={<DollarIcon width={24} />}
      >
        <RootContainer>
          <GrayText textType={TextTypes.Paragraph.L}>
            {strings.pages.upload.modals.tierPrice.description}
          </GrayText>

          <FormProvider {...methods}>
            <form onSubmit={formOnSubmit}>
              {fields.map((item, index) => {
                return (
                  <div key={item.id}>{renderInput(index, tiers[index])}</div>
                );
              })}
            </form>
          </FormProvider>
        </RootContainer>
      </InputModal>
    </>
  );
};

export default React.memo(TierPrice);
