import styled from "styled-components";
import Text from "components/Atoms/Text";
import {textGray} from "config/styleVars";
import UploadSuccessNavigationItem from "components/Atoms/UploadSuccessNavigationItem";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 12px;
  min-width: 350px;
`;

export const GrayText = styled(Text)`
  color: ${textGray};
  text-align: center;
  margin: 36px 0;
`;

export const NavigationItem = styled(UploadSuccessNavigationItem)`
  margin-top: 36px;
`;
