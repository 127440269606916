import React from "react";
import {isMobile} from "react-device-detect";
import Text from "components/Atoms/Text";
import {TextTypes} from "components/Atoms/Text/types";
import {strings} from "config/strings";
import {ButtonSize, ButtonType} from "components/Atoms/Button/types";
import {FormProvider} from "react-hook-form";
import Dropdown from "components/Atoms/Dropdown";
import Button from "components/Atoms/Button";
import {GroupBase, OptionsOrGroups} from "react-select";
import {CryptoDropdownDataProps} from "components/Atoms/CryptoDropdownOption/types";
import {ReactComponent as EthereumIcon} from "icons/universal/ethereum.svg";
import {ReactComponent as PolygonIcon} from "icons/universal/polygon.svg";
import CryptoDropdownOption from "components/Atoms/CryptoDropdownOption";
import MetaItemContainer from "components/Molecules/MetaItemContainer";
import {colors} from "theme/colors";
import {ReactComponent as CategoryIcon} from "icons/universal/category.svg";
import {ReactComponent as MusicIcon} from "icons/universal/music.svg";
import {ReactComponent as RevenueIcon} from "icons/universal/revenueDistribution.svg";
import {ReactComponent as SensitiveContentIcon} from "icons/universal/sensitiveContent.svg";
import {ReactComponent as PlusIcon} from "icons/plus.svg";
import {ReactComponent as GroupIcon} from "icons/universal/group.svg";
import {ReactComponent as PyramidIcon} from "icons/universal/pyramid.svg";
import {ReactComponent as TokensAvailableIcon} from "icons/universal/tokensAvailable.svg";
import {ReactComponent as DollarIcon} from "icons/universal/dollar.svg";
import {ReactComponent as UnlockIcon} from "icons/universal/unlock.svg";
import TokenResalePercentage from "components/Molecules/TokenResalePercentage";
import InputModal from "components/InputModal";
import Toggle from "components/Atoms/Toggle";
import VideoMetadata from "components/Organisms/VideoMetadata";
import Web3VideoPickerForm from "components/Organisms/Web3VideoPickerForm";
import Web3UploadVideoAndThumbnail from "components/Organisms/Web3UploadVideoAndThumbnail";
import Percentage from "components/Molecules/Percentage";
import ExclusiveBenefitsField from "components/Molecules/ExclusiveBenefitsField";
import VideoCategory from "components/Molecules/VideoCategory";
import TokensAvailable from "components/Molecules/TokensAvailable";
import TierPrice from "components/Molecules/TierPrice";
import ConfirmMintAndList from "components/Molecules/ConfirmMintAndList";
import UploadNotAllowedModal from "components/Molecules/UploadNotAllowedModal";

import {useUpload} from "./hooks";
import {
  ButtonsContainer,
  CheckboxStyled,
  ContentContainer,
  DescriptionContainer,
  DescriptionText,
  Divider,
  IconButton,
  LinkButton,
  RequiredFieldsText,
  RootContainer,
  StyledInfoDialog,
  SubtitleText,
  TermsOfServiceContainer,
  TermsOfServiceDescription,
  TitleContainer,
  TokenHolderOptions,
  UploadButton,
  VideoContainer,
  VideoIframe
} from "./styles";
import {BlockchainNetworks} from "./types";

const blockchainOptions: OptionsOrGroups<
  CryptoDropdownDataProps,
  GroupBase<CryptoDropdownDataProps>
> = [
  {
    value: BlockchainNetworks.Ethereum,
    label: strings.pages.upload.blockchainDropdown.ethereum.label,
    description: strings.pages.upload.blockchainDropdown.ethereum.description,
    renderIcon: (color, size) => {
      return <EthereumIcon fill={color} width={size} height={size} />;
    }
  },
  {
    value: BlockchainNetworks.Polygon,
    label: strings.pages.upload.blockchainDropdown.polygon.label,
    description: strings.pages.upload.blockchainDropdown.polygon.description,
    renderIcon: (color, size) => {
      return <PolygonIcon fill={color} width={size} height={size} />;
    }
  }
];

const renderPlusIcon = (color: string, size: number) => {
  return <PlusIcon width={size} height={size} stroke={color} />;
};

const Upload: React.FC = () => {
  const {
    closeMobileModal,
    confirmMintAndList,
    confirmMintAndListStatus,
    formValues,
    goToHomePage,
    registerInput,
    methods,
    mintTypeOnChange,
    inputPercentageOnSubmit,
    isUploadEnabled,
    isTutorialDisplaying,
    toggleVideoTutorial,
    isConfirmAndMintModalOpen,
    isMobileModalOpen,
    isTokenHolderEnabled,
    isTokensAvailableEdit,
    isTierPriceEdit,
    setIsThumbnailValid,
    toggleTokenHolder,
    user,
    onExplicitChange,
    onSubmit,
    isTermsOfServiceDisplaying,
    tiers,
    toggleTermsOfServiceModal,
    toggleTermsOfService,
    toggleConfirmAndMintModal,
    isTermsOfServiceAccepted,
    tokensAvailableOnSubmit,
    tierPriceOnSubmit,
    videoCategories
  } = useUpload();

  const {
    blockchain,
    category,
    externalId,
    explicit,
    file,
    mintType,
    tier,
    tierPrice,
    tokensAvailable
  } = formValues;

  if (isMobile) {
    return (
      <>
        <UploadNotAllowedModal
          buttonText={strings.pages.upload.modals.uploadNotAllowed.buttonBrowse}
          isOpen={isMobileModalOpen}
          toggleModal={closeMobileModal}
        />
      </>
    );
  }

  return (
    <RootContainer>
      <ContentContainer>
        <TitleContainer>
          <Text textType={TextTypes.Titles.H2}>
            {strings.pages.upload.uploadNewVideo}
          </Text>
          <RequiredFieldsText textType={TextTypes.Titles.H4Light}>
            (<span>*</span>
            {strings.pages.upload.requiredFields})
          </RequiredFieldsText>
        </TitleContainer>
        <>
          <LinkButton onClick={toggleVideoTutorial}>
            <Text textType={TextTypes.Other.LinkLabelL}>
              {strings.pages.upload.newToNFTs}
            </Text>
          </LinkButton>
          <InputModal
            submitText={strings.generic.gotIt}
            isOpen={isTutorialDisplaying}
            onSubmit={toggleVideoTutorial}
            onClose={toggleVideoTutorial}
            title={strings.generic.nftTutorial}
            width="780px"
            shouldCloseOnOverlayClick
          >
            <VideoContainer>
              <VideoIframe
                src="https://www.youtube.com/embed/M7lc1UVf-VE?autoplay=1"
                frameBorder="0"
                allowFullScreen
              />
            </VideoContainer>
          </InputModal>
        </>

        <SubtitleText textType={TextTypes.Titles.H4}>
          {strings.generic.video}
          <span>*</span>
        </SubtitleText>
        <DescriptionText textType={TextTypes.Subtitle.LStrong}>
          <span>{strings.pages.upload.videoFileTypesTitle}</span>
          {strings.pages.upload.videoFileTypesDescription}
        </DescriptionText>
        <DescriptionText textType={TextTypes.Subtitle.LStrong}>
          <span>{strings.pages.upload.videoFileSuggestionsTitle}</span>
          {strings.pages.upload.videoFileSuggestionsDescription}
        </DescriptionText>

        <FormProvider {...methods}>
          {/* Video drop area */}
          {!file && (
            <Web3VideoPickerForm
              user={user}
              onSuccess={registerInput("file")}
            />
          )}
          {/* Video drop area */}

          {/* Upload and Thumbnail */}
          {file && (
            <Web3UploadVideoAndThumbnail
              externalId={externalId}
              video={file}
              uuid={user?.uuid}
              setIsThumbnailValid={setIsThumbnailValid}
              setExternalId={registerInput("externalId")}
            />
          )}
          {/* Upload and Thumbnail */}

          {/* Title, Description, Tags */}
          <VideoMetadata disabled={!externalId} registerInput={registerInput} />
          {/* Title, Description, Tags */}
        </FormProvider>

        {/* Category */}
        <MetaItemContainer
          disabled={!externalId}
          icon={<CategoryIcon width={24} />}
          info={strings.pages.upload.tooltips.category}
          isRequired
          description={strings.pages.upload.metaItems.category.description}
          title={strings.pages.upload.metaItems.category.title}
        >
          <VideoCategory
            categories={videoCategories}
            categoryOnSelect={registerInput("category", (item) => ({
              id: item.id
            }))}
            disabled={!externalId}
            selectedCategory={category}
          />
        </MetaItemContainer>
        {/* Category */}

        <Divider />

        {/* Music Recognition */}
        <MetaItemContainer
          icon={<MusicIcon width={24} />}
          info={strings.pages.upload.tooltips.musicRecognition}
          isRequired
          disabled
          description={
            strings.pages.upload.metaItems.musicRecognition.description
          }
          title={strings.pages.upload.metaItems.musicRecognition.title}
        >
          <IconButton
            disabled
            onClick={() => {}}
            type={ButtonType.secondary}
            size={ButtonSize.m}
            renderIcon={renderPlusIcon}
          />
        </MetaItemContainer>
        {/* Music Recognition */}

        <Divider />

        {/* Manage Revenue Distribution */}
        <MetaItemContainer
          icon={<RevenueIcon width={24} />}
          info={strings.pages.upload.tooltips.manageRevenueDistribution}
          isRequired
          disabled
          description={
            strings.pages.upload.metaItems.manageRevenueDistribution.description
          }
          title={strings.pages.upload.metaItems.manageRevenueDistribution.title}
        >
          <IconButton
            disabled
            onClick={() => {}}
            type={ButtonType.secondary}
            size={ButtonSize.m}
            renderIcon={renderPlusIcon}
          />
        </MetaItemContainer>
        {/* Manage Revenue Distribution */}

        <Divider />

        {/* Explicit & Sensitive Content */}
        <MetaItemContainer
          icon={<SensitiveContentIcon width={24} />}
          info={strings.pages.upload.tooltips.explicitContent}
          isRequired
          disabled={!externalId}
          description={
            strings.pages.upload.metaItems.explicitContent.description
          }
          title={strings.pages.upload.metaItems.explicitContent.title}
        >
          <Toggle
            checked={explicit}
            onChange={onExplicitChange}
            disabled={!externalId}
          />
        </MetaItemContainer>
        {/* Explicit & Sensitive Content */}

        {/* Enable Token Holder Options */}
        <TokenHolderOptions
          info={strings.pages.upload.tooltips.enableTokenHolderOptions}
          description={
            strings.pages.upload.metaItems.enableTokenHolderOptions.description
          }
          disabled={!externalId}
          title={strings.pages.upload.metaItems.enableTokenHolderOptions.title}
        >
          <Toggle
            checked={isTokenHolderEnabled}
            onChange={toggleTokenHolder}
            disabled={!externalId}
          />
        </TokenHolderOptions>

        {isTokenHolderEnabled && (
          <>
            {/* Select Tiers */}
            <Divider />
            <MetaItemContainer
              icon={<PyramidIcon width={24} />}
              info={strings.pages.upload.tooltips.inputPercentage}
              isRequired
              description={
                strings.pages.upload.metaItems.percentage.description
              }
              title={strings.pages.upload.metaItems.percentage.title}
            >
              <Percentage
                initialValue={{tier}}
                onSubmit={inputPercentageOnSubmit}
              />
            </MetaItemContainer>
            {/* Select Tiers */}

            {/* TokensAvailable */}
            <Divider />
            <MetaItemContainer
              icon={<TokensAvailableIcon width={24} />}
              info={strings.pages.upload.tooltips.tokensAvailable}
              disabled={!tier?.value}
              isRequired
              description={
                strings.pages.upload.metaItems.tokensAvailable.description
              }
              title={strings.pages.upload.metaItems.tokensAvailable.title}
            >
              <TokensAvailable
                disabled={!tier?.value}
                initialValues={tokensAvailable}
                isEdit={isTokensAvailableEdit}
                onSubmit={tokensAvailableOnSubmit}
                tiers={tiers}
              />
            </MetaItemContainer>
            {/* TokensAvailable */}

            {/* Tier Price */}
            <Divider />
            <MetaItemContainer
              icon={<DollarIcon width={24} />}
              info={strings.pages.upload.tooltips.tierPrice}
              disabled={!isTokensAvailableEdit}
              isRequired
              description={strings.pages.upload.metaItems.tierPrice.description}
              title={strings.pages.upload.metaItems.tierPrice.title}
            >
              <TierPrice
                disabled={!isTokensAvailableEdit}
                initialValues={tierPrice}
                isEdit={isTierPriceEdit}
                onSubmit={tierPriceOnSubmit}
                tiers={tiers}
              />
            </MetaItemContainer>
            {/* Tier Price */}

            {/* Exclusive Benefits */}
            <Divider />
            <MetaItemContainer
              disabled={!isTierPriceEdit}
              icon={<UnlockIcon width={24} />}
              info={strings.pages.upload.tooltips.exclusivebenefits}
              description={
                strings.pages.upload.metaItems.exclusivebenefits.description
              }
              title={strings.pages.upload.metaItems.exclusivebenefits.title}
            >
              <ExclusiveBenefitsField
                disabled={!isTierPriceEdit}
                tiersNumber={tiers}
                value={formValues.rewardsPerTier}
                onChange={registerInput("rewardsPerTier")}
              />
            </MetaItemContainer>
            {/* Exclusive Benefits */}

            {/* Token Resale Percentage */}
            <Divider />
            <MetaItemContainer
              icon={<GroupIcon width={24} />}
              info={strings.pages.upload.tooltips.tokenResalePercentage}
              disabled={!isTierPriceEdit}
              isRequired
              description={
                strings.pages.upload.tokenHolderOptions.tokenResalePercentage
                  .description
              }
              title={
                strings.pages.upload.tokenHolderOptions.tokenResalePercentage
                  .title
              }
            >
              <TokenResalePercentage
                disabled={!isTierPriceEdit}
                platformFee={5}
                collaboratorsRevenuePercentage={20}
                maxValue={10}
                musicPercentage={8}
                tokenHolderPercentage={0}
                initialValue={formValues.tokenResalePercentage}
                onChange={registerInput("tokenResalePercentage")}
              />
            </MetaItemContainer>
            {/* Token Resale Percentage */}
          </>
        )}
        {/* Enable Token Holder Options */}

        {/* Blockchain dropdown */}
        <SubtitleText textType={TextTypes.Titles.H4}>
          {strings.generic.blockchain}
          <span>*</span>
        </SubtitleText>
        <DescriptionContainer>
          <DescriptionText textType={TextTypes.Subtitle.LStrong}>
            {strings.pages.upload.blockchainDetails}
          </DescriptionText>
          <StyledInfoDialog
            color={colors.primary.white}
            info={strings.pages.upload.tooltips.blockchain}
          />
        </DescriptionContainer>
        <Dropdown
          isDisabled={!externalId}
          defaultValue={blockchainOptions[0]}
          options={blockchainOptions}
          onChange={registerInput("blockchain", (option) => option.value)}
          // @ts-ignore
          OptionComponent={CryptoDropdownOption}
        />
        {/* Blockchain dropdown */}

        {/* Terms of service */}

        <InputModal
          isOpen={isTermsOfServiceDisplaying}
          onClose={toggleTermsOfServiceModal}
          title={strings.pages.upload.termsOfService.termsAndConditions}
          submitText={strings.pages.upload.uploadVideoButton}
          disableSubmit={!isTermsOfServiceAccepted}
          onSubmit={onSubmit}
        >
          <TermsOfServiceContainer>
            <TermsOfServiceDescription textType={TextTypes.Paragraph.L}>
              <CheckboxStyled
                onChange={toggleTermsOfService}
                activeBorderColor={colors.primary.cyan}
              />
              {strings.pages.upload.termsOfService.description}
              <a href="https://www.everse.tv" target="_blank" rel="noreferrer">
                &nbsp;{strings.pages.upload.termsOfService.termsAndConditions}
              </a>
            </TermsOfServiceDescription>
          </TermsOfServiceContainer>
        </InputModal>

        {/* Terms of service */}

        {/* Confirm Mint And List */}
        <ConfirmMintAndList
          blockchain={blockchain}
          onSubmit={confirmMintAndList}
          status={confirmMintAndListStatus}
          goToHome={goToHomePage}
          isModalOpen={isConfirmAndMintModalOpen}
          tierPrice={tierPrice}
          toggleModal={toggleConfirmAndMintModal}
          tokensAvailable={tokensAvailable}
          onChange={mintTypeOnChange}
          value={mintType}
          user={user}
        />
        {/* Confirm Mint And List */}

        <ButtonsContainer>
          <Button
            disabled
            onClick={() => {}}
            size={ButtonSize.l}
            text={strings.pages.upload.saveProgressButton}
            type={ButtonType.secondary}
          />

          <UploadButton
            disabled={!isUploadEnabled}
            onClick={onSubmit}
            size={ButtonSize.l}
            text={strings.pages.upload.uploadVideoButton}
            type={ButtonType.primary}
          />
        </ButtonsContainer>
      </ContentContainer>
    </RootContainer>
  );
};

export default React.memo(Upload);
