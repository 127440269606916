import {utils} from "ethers";

export function shortenAddress(address?: string): string {
  if (typeof address === "string" && address.length > 0) {
    try {
      const formattedAddress = utils.getAddress(address);
      return `${formattedAddress.slice(0, 6)}...${formattedAddress.slice(-4)}`;
    } catch {
      return address;
    }
  }
  return address || "";
}
