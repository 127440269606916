import React, {useEffect, useState, ReactNode} from "react";
import styled from "styled-components";
import {formStyles} from "config/styleVars";
import MenuIcon from "icons/24/more-vertical.svg";

const Container = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
`;

const ToggleButton = styled.button`
  appearance: none;
  background: transparent;
  border: none;
`;

const Menu = styled.div`
  width: 144px;
  border-radius: ${formStyles.borderRadius};
  background: rgba(0, 0, 0, 0.8);
  padding: 4px 0;
  position: absolute;
  right: 0;
  top: 24px;
`;

type Props = {children: ReactNode | string};

export const DropMenu: React.FC<Props> = ({children}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleBodyClick = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleBodyClick);
    } else {
      document.removeEventListener("click", handleBodyClick);
    }
    return () => {
      document.removeEventListener("click", handleBodyClick);
    };
  }, [isOpen]);

  const handleToggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container>
      <ToggleButton onClick={handleToggleMenu}>
        <img alt="Menu Icon" src={MenuIcon} />
      </ToggleButton>
      {isOpen && <Menu>{children}</Menu>}
    </Container>
  );
};
