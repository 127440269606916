import {colors} from "theme/colors";
import styled from "styled-components";
import Text from "components/Atoms/Text";
import Button from "components/Atoms/Button";

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 32px;
  background-color: white;
  border-radius: 12px 12px 0 0;
`;

export const LineContainer = styled.div`
  padding: 12px;
  align-self: center;
`;

export const Line = styled.div`
  width: 48px;
  height: 4px;
  border-radius: 4px;
  background-color: ${colors.primary.mediumGrey40};
`;

export const Title = styled(Text)`
  margin-top: 58px;
  color: ${colors.primary.black};
`;

export const Description = styled(Text)`
  margin-top: 16px;
  color: ${colors.primary.black};
`;

export const CloseButton = styled(Button)`
  margin: 76px 0 57px 0;
`;
