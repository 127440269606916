import React from "react";

import {ProgressiveImageProps} from "./types";
import {RootContainer, StyledImage} from "./styles";
import {useProgressiveImage} from "./hooks";

const ProgressiveImage: React.FC<ProgressiveImageProps> = ({
  errorIcon,
  imageAttributes,
  onError,
  onLoad,
  shouldLoadAnimatedImage,
  url
}: ProgressiveImageProps) => {
  const {
    bigImageUrl,
    bigImageError,
    bigImageNotLoaded,
    onSmallImageError,
    onSmallImageLoaded,
    onBigImageError,
    onBigImageLoaded,
    onBigImageLoadStart,
    smallImageError,
    smallImageLoaded,
    smallImageUrl
  } = useProgressiveImage({
    imageAttributes,
    onError,
    onLoad,
    shouldLoadAnimatedImage,
    url
  });

  return (
    <RootContainer>
      {bigImageNotLoaded && !smallImageError && (
        <StyledImage
          alt=""
          src={smallImageUrl}
          onError={onSmallImageError}
          onLoad={onSmallImageLoaded}
        />
      )}
      {(smallImageLoaded || smallImageError) &&
        shouldLoadAnimatedImage &&
        !bigImageError && (
          <StyledImage
            alt=""
            src={bigImageUrl}
            onLoadStart={onBigImageLoadStart}
            onError={onBigImageError}
            onLoad={onBigImageLoaded}
          />
        )}
      {smallImageError && bigImageError && errorIcon && <>{errorIcon}</>}
    </RootContainer>
  );
};

export default React.memo(ProgressiveImage);
