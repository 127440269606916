import {Container} from "components/StyledElements/LayoutElements";
import VerifyForm from "components/Auth/VerifyForm";

function VerifyEmailPage() {
  return (
    <>
      <Container>
        <VerifyForm />
      </Container>
    </>
  );
}

export default VerifyEmailPage;
