import styled from "styled-components";
import {CLIPPING_TOOL_IMAGE_SIZE, SECONDS_PER_FRAME} from "config/constants";
import {colors} from "theme/colors";

export const ImageContainer = styled.div<{itemDuration: number}>`
  width: ${(props) =>
    (props.itemDuration * CLIPPING_TOOL_IMAGE_SIZE) / SECONDS_PER_FRAME}px;
  height: ${CLIPPING_TOOL_IMAGE_SIZE}px;
  background-color: ${colors.primary.darkGrey};
  border-right: 1px solid black;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const MuxStoryboardImage = styled.div<{
  scale: number;
  widthOffSet: number;
  heightOffset: number;
  tile_width: number;
  tile_height: number;
  url: string;
  x: number;
  y: number;
}>`
  float: left;
  background: url(${(p) => p.url});
  width: ${(p) => p.tile_width}px;
  height: ${(p) => p.tile_height}px;
  background-position: ${(props) => `${props.x * -1}px ${props.y * -1}px`};
  background-repeat: no-repeat;
  transform: scale(${(props) => props.scale});
  box-sizing: border-box;
  margin-left: ${(p) => p.widthOffSet}px;
  margin-top: ${(p) => p.heightOffset}px;
`;
