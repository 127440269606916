import styled from "styled-components";
import {navHeight} from "config/styleVars";
import {breakpointNames, media} from "theme/media";

export const RootContainer = styled.div`
  min-height: calc(93vh - ${navHeight}px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DataContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-width: 1000px;
  align-self: center;
`;

export const BottomContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  ${media.lessThan(breakpointNames.tablet)`
    flex-direction: column;
    align-items: center;
  `}
`;

export const FinishContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: flex-end;
  margin-top: 24px;
  padding: 24px 12px;
  width: 100%;
  max-width: 960px;
`;

export const ProgressContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;
