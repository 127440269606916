import styled from "styled-components";
import Text from "components/Atoms/Text";

export const Container = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 12px;
  align-self: center;
`;

export const Header = styled(Text)`
  margin-bottom: 48px;
`;
