import {Web3VideoPickerFormValues} from "components/Organisms/Web3VideoPickerForm/types";
import {Category} from "apollo";
import {Reward} from "components/Molecules/ExclusiveBenefitsField/types";
import {InputPercentageFormValues} from "components/Molecules/Percentage/types";
import {TokensAvailableFormValues} from "components/Molecules/TokensAvailable/types";
import {TierPriceFormValues} from "components/Molecules/TierPrice/types";

export interface UploadFormValues
  extends Web3VideoPickerFormValues,
    InputPercentageFormValues,
    TokensAvailableFormValues,
    TierPriceFormValues {
  externalId: string;
  title: string;
  description: string;
  tags: string;
  category?: Category;
  explicit: boolean;
  tokenHoldersPercentage?: number;
  tokenResalePercentage?: number;
  rewardsPerTier?: Array<Reward[]>;
  blockchain: BlockchainNetworks;
  mintType: MintTypes;
}

export enum MintTypes {
  Lazy = "Lazy",
  Upfront = "Upfront"
}

export enum BlockchainNetworks {
  Ethereum = "Ethereum",
  Polygon = "Polygon"
}
