import React from "react";
import ScrubberSideBar from "components/Molecules/ScrubberSideBar";

import {BottomLine, Container, TopLine} from "./styles";
import {ScrubberProps} from "./types";

const Scrubber: React.FC<ScrubberProps> = (props: ScrubberProps) => {
  const {scrubberTranslationX, scrubberWidth, timelineContentSize} = props;
  if (!timelineContentSize) {
    return null;
  }

  return (
    <Container>
      <ScrubberSideBar {...props} isLeft />
      <ScrubberSideBar {...props} />
      <TopLine
        scrubberTranslationX={scrubberTranslationX}
        scrubberWidth={scrubberWidth}
      />
      <BottomLine
        scrubberTranslationX={scrubberTranslationX}
        scrubberWidth={scrubberWidth}
      />
    </Container>
  );
};

export default React.memo(Scrubber);
