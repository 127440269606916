import React from "react";
import {ButtonSize, ButtonType} from "components/Atoms/Button/types";
import {strings} from "config/strings";
import Text from "components/Atoms/Text";
import {TextTypes} from "components/Atoms/Text/types";
import {colors} from "theme/colors";
import {useAlgoliaSearchResults} from "./hooks";
import {
  GrayText,
  MoreButton,
  NoResultsContainer,
  Results,
  MoreButtonContainer,
  Headline,
  SearchIcon
} from "./styles";
import {AlgoliaSearchResultsProps} from "./types";
import UserListItem from "../UserListItem";

const AlgoliaSearchResults: React.FC<AlgoliaSearchResultsProps> = (
  props: AlgoliaSearchResultsProps
) => {
  const {filteredHits, goToSearchPage, moreHits, showNoResults, hideResults} =
    useAlgoliaSearchResults(props);
  const {query} = props;
  if (hideResults || (!filteredHits.length && !showNoResults)) {
    return null;
  }

  return (
    <Results>
      {!showNoResults && (
        <Headline>
          <Text textType={TextTypes.Subtitle.LStrong}>
            {strings.search.profiles}
          </Text>
        </Headline>
      )}
      {filteredHits.map((hit) => {
        return <UserListItem hit={hit} />;
      })}
      {moreHits > 0 && (
        <MoreButtonContainer>
          <MoreButton
            size={ButtonSize.m}
            type={ButtonType.text}
            onClick={goToSearchPage}
            text={strings.search.moreResults(moreHits)}
          />
        </MoreButtonContainer>
      )}
      {showNoResults && (
        <NoResultsContainer>
          <SearchIcon stroke={colors.primary.white} strokeWidth={1.5} />
          <Text textType={TextTypes.Titles.H1Light}>
            {strings.search.noResults}
          </Text>
          <GrayText textType={TextTypes.Other.LinkLabelM}>
            {strings.search.noResultsQuery(query)}
          </GrayText>
        </NoResultsContainer>
      )}
    </Results>
  );
};

export default React.memo(AlgoliaSearchResults);
