import React from "react";
import {TextTypes} from "components/Atoms/Text/types";
import {DropdownStyleProps} from "components/Atoms/Dropdown/types";
import {ReactComponent as MinusIcon} from "icons/24/minus.svg";
import {ReactComponent as PlusIcon} from "icons/24/plus.svg";
import {colors} from "theme/colors";

import {strings} from "config/strings";
import {UnlockableDropdownDataProps} from "./types";
import {
  getTitleAndIconColor,
  RootContainer,
  TextContainer,
  Title,
  MenuListContainer,
  MenuListHeader,
  MenulistFooter,
  SelectedContainer,
  MenuListTitle,
  MenuListFooterText
} from "./styles";

export function MenuList({clearValue, children}: any) {
  return (
    <MenuListContainer>
      <MenuListHeader onClick={clearValue}>
        <MinusIcon height={24} width={24} stroke={colors.primary.white} />
        <MenuListTitle textType={TextTypes.Titles.H6}>
          {strings.generic.none}
        </MenuListTitle>
      </MenuListHeader>
      {children}
      <MenulistFooter>
        <MenuListFooterText textType={TextTypes.Titles.H7}>
          {strings.generic.moreComingSoon}
        </MenuListFooterText>
      </MenulistFooter>
    </MenuListContainer>
  );
}

const UnlockableDropdownOption: React.FC<
  DropdownStyleProps<UnlockableDropdownDataProps>
> = (props: DropdownStyleProps<UnlockableDropdownDataProps>) => {
  const {
    isDisabled,
    isFocused,
    isOption,
    isSelected,
    label,
    renderIcon,
    isMulti
  } = props;
  const LeftIcon = isSelected ? MinusIcon : PlusIcon;

  if (isMulti && !isOption) {
    return (
      <SelectedContainer>
        {renderIcon &&
          renderIcon(
            getTitleAndIconColor(isOption, isSelected, isDisabled),
            24
          )}
      </SelectedContainer>
    );
  }

  return (
    <RootContainer>
      {renderIcon &&
        renderIcon(getTitleAndIconColor(isOption, isSelected, isDisabled), 24)}
      <TextContainer>
        <Title
          isDisabled={isDisabled}
          isFocused={isFocused}
          isSelected={isSelected}
          isOption={isOption}
          textType={TextTypes.Titles.H6}
        >
          {label}
        </Title>
      </TextContainer>
      <LeftIcon
        fill={getTitleAndIconColor(isOption, isSelected, isDisabled)}
        height={24}
        width={24}
      />
    </RootContainer>
  );
};

export default React.memo(UnlockableDropdownOption);
