import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  top: 68px;
  left: 48px;
`;

const Tag = styled.div`
  font-size: 15px;
  float: left;
  margin-right: 0.5rem;
  cursor: pointer;
  opacity: 0.8;
  font-weight: 200;

  &:hover {
    opacity: 0.6;
  }
`;

type ContainerProps = {
  tags: string | undefined;
};

const TagsContainer = ({tags}: ContainerProps): JSX.Element => {
  const handleClick = () => {
    alert("Coming Soon!");
  };

  return (
    <Container>
      {tags &&
        tags
          .split(", ")
          .slice(0, 3)
          .map((tag) => <Tag key={tag} onClick={handleClick}>{`#${tag}`}</Tag>)}
    </Container>
  );
};

export default TagsContainer;
