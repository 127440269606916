import {strings} from "config/strings";
import {Routes} from "config/routes";
import {Link} from "react-router-dom";
import {Container} from "components/StyledElements/LayoutElements";
import Logo from "icons/eVerse.svg";

const logoStyles = {
  width: "600px",
  left: "50%",
  marginLeft: "-286px",
  opacity: 0.1,
  zIndex: -1,
  top: "80px"
};

function ErrorPage() {
  return (
    <Container>
      {/* eslint-disable-next-line react-extra/no-inline-styles -- MAR-844 */}
      <div style={{textAlign: "center", paddingTop: "140px"}}>
        <h1>{strings.pages.error.title}</h1>
        <p>
          {strings.pages.error.bodyParagraph}
          <br />
          <Link to={Routes.home}>{strings.pages.error.goBack}</Link>
        </p>
      </div>
      <img
        alt="everse logo"
        src={Logo}
        style={{position: "absolute", ...logoStyles}}
      />
    </Container>
  );
}

export default ErrorPage;
