import React from "react";

import {RootContainer} from "./styles";
import {useWeb3Upload} from "./hooks";

const Web3Upload: React.FC = () => {
  const {renderStep} = useWeb3Upload();

  return <RootContainer>{renderStep()}</RootContainer>;
};

export default React.memo(Web3Upload);
