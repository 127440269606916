import styled from "styled-components";
import {CLIPPING_TOOL_IMAGE_SIZE} from "config/constants";
import {colors} from "theme/colors";

export const Container = styled.div`
  width: 100%;
  height: ${CLIPPING_TOOL_IMAGE_SIZE}px;
  position: absolute;
  bottom: 0;
  pointer-events: none;
`;

const Line = styled.div<{
  scrubberTranslationX: number;
  scrubberWidth: number;
}>`
  position: absolute;
  background-color: ${colors.primary.white};
  height: 2px;
  left: ${(props) => props.scrubberTranslationX}px;
  width: ${(props) => props.scrubberWidth}px;
  pointer-events: none;
`;

export const TopLine = styled(Line)`
  top: 0;
`;

export const BottomLine = styled(Line)`
  bottom: 0;
`;
