import React from "react";
import {
  AutoSizer,
  ColumnSizer,
  Grid,
  InfiniteLoader,
  InfiniteLoaderChildProps,
  SectionRenderedParams,
  Size,
  SizedColumnProps,
  WindowScroller,
  WindowScrollerChildProps
} from "react-virtualized";
import {Spinner} from "components/Spinner";

import {Container, Progress, WindowScrollerContainer} from "./styles";
import {VirtualizedGridProps} from "./types";
import {useVirtualizedGrid} from "./hooks";

const VirtualizedGrid = <T,>({
  columnBreakpoints,
  items,
  loading,
  loadMoreItems,
  loadMoreRowsCount,
  renderItem,
  renderPlaceholder,
  totalResults
}: VirtualizedGridProps<T>) => {
  const {
    autoSizerOnResize,
    calculateColumnCount,
    cellRenderer,
    columnCount,
    isRowLoaded,
    listRowCount,
    loadMoreRows,
    measurerCache,
    onSectionRendered
  } = useVirtualizedGrid<T>({
    columnBreakpoints,
    items,
    loadMoreItems,
    renderItem,
    renderPlaceholder,
    totalResults
  });

  return (
    <Container>
      <WindowScrollerContainer>
        <WindowScroller>
          {({
            height,
            onChildScroll,
            scrollTop,
            registerChild
          }: WindowScrollerChildProps) => (
            <div ref={registerChild}>
              <InfiniteLoader
                isRowLoaded={isRowLoaded}
                loadMoreRows={loadMoreRows}
                rowCount={totalResults}
                minimumBatchSize={columnCount * loadMoreRowsCount}
                threshold={15}
              >
                {({onRowsRendered}: InfiniteLoaderChildProps) => (
                  <AutoSizer disableHeight onResize={autoSizerOnResize}>
                    {({width}: Size) => {
                      const countOfColumns = calculateColumnCount(width);
                      return (
                        <ColumnSizer width={width} columnCount={countOfColumns}>
                          {({registerChild: rg}: SizedColumnProps) => {
                            return (
                              <Grid
                                autoHeight
                                deferredMeasurementCache={measurerCache}
                                width={width}
                                height={height}
                                scrollTop={scrollTop}
                                ref={rg}
                                overscanRowCount={10}
                                scrollingResetTimeInterval={0}
                                onScroll={onChildScroll}
                                columnWidth={Math.floor(width / countOfColumns)}
                                columnCount={countOfColumns}
                                rowCount={listRowCount}
                                rowHeight={measurerCache.rowHeight}
                                cellRenderer={cellRenderer}
                                onSectionRendered={(
                                  renderedParams: SectionRenderedParams
                                ) => {
                                  onSectionRendered({
                                    onRowsRendered,
                                    renderedParams
                                  });
                                }}
                              />
                            );
                          }}
                        </ColumnSizer>
                      );
                    }}
                  </AutoSizer>
                )}
              </InfiniteLoader>
            </div>
          )}
        </WindowScroller>
      </WindowScrollerContainer>
      {loading && (
        <Progress>
          <Spinner />
        </Progress>
      )}
    </Container>
  );
};

export default React.memo(VirtualizedGrid);
