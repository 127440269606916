import React from "react";
import {TextTypes, TextWights} from "components/Atoms/Text/types";

import {ClippingToolItemTextProps} from "./types";
import {Container, DotsContainer, DotsText, EndText, StartText} from "./styles";
import {useClippingToolItemText} from "./hooks";

const ClippingToolItemText: React.FC<ClippingToolItemTextProps> = (
  props: ClippingToolItemTextProps
) => {
  const {dots, endText, shouldRenderSecondText, startText} =
    useClippingToolItemText(props);

  return (
    <Container>
      <StartText
        textType={TextTypes.Subtitle.MStrong}
        textWeight={TextWights.Medium}
      >
        {startText}
      </StartText>
      {shouldRenderSecondText && (
        <EndText
          textType={TextTypes.Subtitle.MStrong}
          textWeight={TextWights.Medium}
        >
          {endText}
        </EndText>
      )}

      <DotsContainer>
        <DotsText
          textType={TextTypes.Subtitle.MStrong}
          textWeight={TextWights.Medium}
        >
          {dots}
        </DotsText>
      </DotsContainer>
    </Container>
  );
};

export default React.memo(ClippingToolItemText);
