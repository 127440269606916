import {UseSearchBoxProps} from "react-instantsearch-hooks-web";

export const ClearSearch = "ClearSearch";
export const SearchBox = "SearchBox";

export interface AlgoliaSearchBoxProps extends UseSearchBoxProps {
  onBlur?: () => void;
  onFocus?: () => void;
  onChange?: (text: string) => void;
}
