import styled from "styled-components";
import {colors} from "theme/colors";
import Text from "components/Atoms/Text";

export const Container = styled.div`
  height: 100%;
  position: fixed;
  top: 60px;
  right: 0;
  width: 416px;
  z-index: 200;
  color: ${colors.primary.white};
  background-color: ${colors.primary.black};
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: ${colors.primary.white};
  transition: 0.5s;
`;

export const UserContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.primary.white};
  padding: 14px 24px;
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 24px;
  border: 1px solid ${colors.primary.darkGrey};
  border-radius: 32px;
`;

export const BalanceItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 24px;
  border-bottom: 1px solid ${colors.primary.darkGrey};
  &:last-child {
    border-bottom-style: none;
    padding: 12px 16px;
  }
`;

export const StyledBalanceText = styled(Text)`
  font-weight: 300;
  margin-top: 15px;
`;

export const StyledTitleText = styled(Text)`
  margin-left: 12px;
  margin-right: 8px;
  font-weight: 400;
`;
