import styled from "styled-components";
import Text from "components/Atoms/Text";

import {
  BUTTON_DEFAULT_BACKGROUND_COLOURS,
  BUTTON_DEFAULT_BORDER_COLOURS,
  BUTTON_DEFAULT_TEXT_COLOURS,
  ButtonStyleProps,
  IconSide,
  IconTitleProps
} from "./types";

export const Container = styled.button<ButtonStyleProps>`
  font-size: ${(props) => props.fontSize};
  border-radius: 10px;
  border-width: 1px;
  padding 0.3em 2em;
  background: ${(props) =>
    props?.backgroundColours?.default ||
    BUTTON_DEFAULT_BACKGROUND_COLOURS.default};
  color: ${(props) =>
    props?.textColours?.default || BUTTON_DEFAULT_TEXT_COLOURS.default};
  stroke: ${(props) =>
    props?.textColours?.default || BUTTON_DEFAULT_TEXT_COLOURS.default};
  border-color: ${(props) =>
    props?.borderColours?.default || BUTTON_DEFAULT_BORDER_COLOURS.default};
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  transition: all 300ms ease;

  &:active {
    background: ${(props) =>
      props?.backgroundColours?.clicked ||
      BUTTON_DEFAULT_BACKGROUND_COLOURS.clicked};
    color: ${(props) =>
      props?.textColours?.clicked || BUTTON_DEFAULT_TEXT_COLOURS.clicked};
    stroke: ${(props) =>
      props?.textColours?.clicked || BUTTON_DEFAULT_TEXT_COLOURS.clicked};
    border-color: ${(props) =>
      props?.borderColours?.clicked || BUTTON_DEFAULT_BORDER_COLOURS.clicked};
  }

  &:disabled {
    background: ${(props) =>
      props?.backgroundColours?.disabled ||
      BUTTON_DEFAULT_BACKGROUND_COLOURS.disabled};
    color: ${(props) =>
      props?.textColours?.disabled || BUTTON_DEFAULT_TEXT_COLOURS.disabled};
    stroke: ${(props) =>
      props?.textColours?.disabled || BUTTON_DEFAULT_TEXT_COLOURS.disabled};
    border-color: ${(props) =>
      props?.borderColours?.disabled || BUTTON_DEFAULT_BORDER_COLOURS.disabled};
  }
  &:hover:enabled {
    background: ${(props) =>
      props?.backgroundColours?.hovered ||
      BUTTON_DEFAULT_BACKGROUND_COLOURS.hovered};
    color: ${(props) =>
      props?.textColours?.hovered || BUTTON_DEFAULT_TEXT_COLOURS.hovered};
    stroke: ${(props) =>
      props?.textColours?.hovered || BUTTON_DEFAULT_TEXT_COLOURS.hovered};
    border-color: ${(props) =>
      props?.borderColours?.hovered || BUTTON_DEFAULT_BORDER_COLOURS.hovered};
  }
`;

export const Title = styled(Text)<IconTitleProps>`
  display: flex;
  flex: 1;
  margin-left: ${(props) => (props.iconSide === IconSide.left ? 0.4 : 0)}em;
  margin-right: ${(props) => (props.iconSide === IconSide.right ? 0.4 : 0)}em;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;
