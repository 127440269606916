import {useVideoUpload} from "hooks/useVideoUpload";
import {useVideoLink} from "hooks/useVideoLink";

import {useEffect} from "react";
import {Web3VideoUploadProps} from "./types";

export function useWeb3VideoUploadHook({
  setExternalId,
  onUploadCompleted,
  video
}: Web3VideoUploadProps) {
  const {externalId, videoLink, videoLoading} = useVideoLink();
  const {start, loading, progress, uploaded, error} = useVideoUpload();

  /**
   * Sets externalId when the video instance has been created.
   * That externalId can be used by other components (for example for metadata uploading).
   */
  useEffect(() => {
    if (externalId) {
      setExternalId(externalId);
    }
  }, [externalId, setExternalId]);

  useEffect(() => {
    if (uploaded) {
      onUploadCompleted();
    }
  }, [uploaded, onUploadCompleted]);

  /**
   * starts video upload if it hasn't
   */
  useEffect(() => {
    if (
      video &&
      videoLink &&
      !videoLoading &&
      !uploaded &&
      !loading &&
      !progress
    ) {
      start(videoLink, video);
    }
  }, [start, uploaded, loading, progress, video, videoLink, videoLoading]);

  return {error, progress};
}
