import {useReactiveVar} from "@apollo/client";
import {displayStateVar} from "apollo/reactive";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useUser} from "hooks/useUser";
import {getFollowingPlayerUrl, getForYouPlayerUrl, Routes} from "config/routes";
import {strings} from "config/strings";
import {
  AuthenticatedVisibility,
  NavigationItemButtonProps,
  NavigationItemCustomComponentProps,
  NavigationItemLinkProps,
  NavigationItemType,
  Side
} from "components/TopNavigation/types";
import {useHistory} from "react-router";
import {PossibleTextTypes} from "components/Atoms/Text/types";
import UserMenu from "components/TopNavigation/UserMenu";
import TopNavigationMenuItem from "components/TopNavigation/TopNavigationMenuItem";
import AlgoliaSearch from "components/Organisms/AlgoliaSearch";

import {GetTheAppButton, SearchContainer} from "./styles";

const SCROLL_EVENT_NAME = "scroll";
const SCROLL_THRESHOLD = 10;

const baseNavigationItem = {
  authenticatedVisibility: AuthenticatedVisibility.any,
  hasUnderscore: true,
  isVisibleWhileLoading: false,
  type: NavigationItemType.link
};

export function useTopNavigation() {
  const displayState = useReactiveVar(displayStateVar);
  const [scrolled, setScrolled] = useState(false);
  const [isWalletOpen, setIsWalletOpen] = useState(false);
  const {user, authenticated, loading} = useUser();

  const [pathName, setPathName] = useState("/");
  const history = useHistory();

  const configurePathName = useCallback(
    (value: string) => {
      const pathname = value.toLowerCase();
      if (pathname === Routes.home || pathname === Routes.explore) {
        setPathName(history.location.pathname);
        return;
      }

      if (pathname.startsWith(Routes.following)) {
        setPathName(Routes.following);
        return;
      }

      if (pathname.startsWith(Routes.forYou)) {
        setPathName(Routes.forYou);
        return;
      }

      setPathName(pathname);
    },
    [history.location.pathname]
  );

  useEffect(() => {
    configurePathName(history.location.pathname);
    const unsubscribe = history.listen((location) => {
      configurePathName(location.pathname);
    });

    return () => {
      unsubscribe();
    };
  }, [configurePathName, history]);

  const getAppOnClick = useCallback(() => {
    alert("Coming Soon!");
  }, []);

  const toggleWallet = useCallback(
    () => setIsWalletOpen((prevState) => !prevState),
    []
  );

  const navigationItems: Array<
    | NavigationItemLinkProps
    | NavigationItemButtonProps
    | NavigationItemCustomComponentProps
  > = useMemo(() => {
    const items = [
      {
        ...baseNavigationItem,
        key: Routes.home,
        side: Side.left,
        route: Routes.home,
        title: strings.generic.home
      },
      {
        ...baseNavigationItem,
        key: Routes.explore,
        side: Side.left,
        route: Routes.explore,
        title: strings.pages.feed.explore
      },
      {
        ...baseNavigationItem,
        key: Routes.following,
        side: Side.left,
        route: getFollowingPlayerUrl(),
        title: strings.generic.following
      },
      {
        ...baseNavigationItem,
        key: Routes.forYou,
        side: Side.left,
        route: getForYouPlayerUrl(),
        title: strings.generic.forYou
      },
      {
        ...baseNavigationItem,
        key: Routes.web3Upload,
        side: Side.right,
        route: Routes.web3Upload,
        title: strings.pages.upload.uploadVideoButton
      },
      {
        ...baseNavigationItem,
        authenticatedVisibility: AuthenticatedVisibility.authenticated,
        key: "GetTheApp",
        side: Side.right,
        type: NavigationItemType.customComponent,
        customComponent: (
          <div key="GetTheApp" data-cy="get-the-app-button">
            <GetTheAppButton
              key="GetTheApp"
              onClick={getAppOnClick}
              textType={PossibleTextTypes.MenuItem}
            >
              {strings.generic.getTheApp}
            </GetTheAppButton>
          </div>
        )
      },
      {
        ...baseNavigationItem,
        authenticatedVisibility: AuthenticatedVisibility.notAuthenticated,
        hasUnderscore: false,
        key: Routes.signin,
        side: Side.right,
        route: Routes.signin,
        title: strings.auth.signIn
      },
      {
        ...baseNavigationItem,
        authenticatedVisibility: AuthenticatedVisibility.authenticated,
        key: "UserMenu",
        side: Side.right,
        type: NavigationItemType.customComponent,
        customComponent: (
          <div key="UserMenu" data-cy="profile-link-button">
            <UserMenu key="UserMenu" user={user} toggleWallet={toggleWallet} />
          </div>
        )
      }
    ];

    if (history.location.pathname !== Routes.search) {
      items.push({
        ...baseNavigationItem,
        key: "Search",
        side: Side.center,
        type: NavigationItemType.customComponent,
        customComponent: (
          <SearchContainer key="Search">
            <AlgoliaSearch />
          </SearchContainer>
        )
      });
    }

    return items;
  }, [getAppOnClick, history.location.pathname, user]);

  const onScroll = useCallback(() => {
    const isScrolled = window.scrollY > SCROLL_THRESHOLD;
    if (scrolled !== isScrolled) {
      setScrolled(isScrolled);
    }
  }, [scrolled]);

  useEffect(() => {
    window.addEventListener(SCROLL_EVENT_NAME, () => {
      requestAnimationFrame(onScroll);
    });

    return () => {
      window.removeEventListener(SCROLL_EVENT_NAME, () => {
        requestAnimationFrame(onScroll);
      });
    };
  }, [onScroll]);

  const renderItems = useCallback(
    (side: Side) => {
      return navigationItems.map((navigationItem) => {
        if (!navigationItem.isVisibleWhileLoading && loading) {
          return null;
        }

        if (navigationItem.side !== side) {
          return null;
        }

        if (
          (navigationItem.authenticatedVisibility ===
            AuthenticatedVisibility.authenticated &&
            !authenticated) ||
          (navigationItem.authenticatedVisibility ===
            AuthenticatedVisibility.notAuthenticated &&
            authenticated)
        ) {
          return null;
        }

        switch (navigationItem.type) {
          case NavigationItemType.link:
            const isSelected = navigationItem.key === pathName;
            const navItemLink = navigationItem as NavigationItemLinkProps;
            const {
              authenticatedVisibility,
              hasUnderscore,
              isVisibleWhileLoading,
              ...otherProps
            } = navItemLink;
            return (
              <TopNavigationMenuItem
                {...otherProps}
                hasUnderscore={hasUnderscore}
                key={navigationItem.key}
                isSelected={isSelected}
              />
            );

          case NavigationItemType.customComponent:
            const navItemCustom =
              navigationItem as NavigationItemCustomComponentProps;
            return navItemCustom.customComponent;

          default:
            return null;
        }
      });
    },
    [authenticated, loading, navigationItems, pathName]
  );

  const renderLeftItems = useCallback(() => {
    return renderItems(Side.left);
  }, [renderItems]);

  const renderCenterItems = useCallback(() => {
    return renderItems(Side.center);
  }, [renderItems]);

  const renderRightItems = useCallback(() => {
    return renderItems(Side.right);
  }, [renderItems]);

  return {
    displayState,
    loading,
    renderLeftItems,
    renderCenterItems,
    renderRightItems,
    scrolled,
    user,
    isWalletOpen,
    toggleWallet
  };
}
