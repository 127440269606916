import {useEffect} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {imageUploadValidator} from "utils/validators/upload";
import {useThumbnailLink} from "hooks/useThumbnailLink";
import {useImageUpload} from "hooks/useImageUpload";
import {AnalyticsEvents} from "config/constants";

import {IFormValues, ThumbnailUploadProps} from "./types";

export function useThumbnailUpload({
  externalId,
  isVisible,
  setIsThumbnailUploaded,
  uuid
}: ThumbnailUploadProps) {
  const {link, loading} = useThumbnailLink({
    contentType: "image/gif",
    externalId,
    isVisible
  });
  const methods = useForm<IFormValues>({
    mode: "onBlur",
    resolver: yupResolver(imageUploadValidator())
  });
  const {
    watch,
    formState: {errors, isValid}
  } = methods;
  const file = watch("file");
  const {start, loading: uploading, uploaded} = useImageUpload(file);

  useEffect(() => {
    if (isValid && !loading && link && externalId && !uploaded && !uploading) {
      setIsThumbnailUploaded(false);
      start(link, file);
    }
  }, [
    externalId,
    file,
    isValid,
    setIsThumbnailUploaded,
    start,
    link,
    loading,
    uploaded,
    uploading
  ]);

  useEffect(() => {
    if (uploaded) {
      if (window.analytics && uuid) {
        window.analytics.track(AnalyticsEvents.UploadedGif, {
          uuid
        });
      }
      setIsThumbnailUploaded(true);
    }
  }, [setIsThumbnailUploaded, uploaded, uuid]);

  return {errors, methods, uploading};
}
