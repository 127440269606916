import React from "react";
import ClippingToolItem from "components/Molecules/ClippingToolItem";
import Scrubber from "components/Molecules/Scrubber";
import {TextTypes} from "components/Atoms/Text/types";

import {ClippingToolTimelineProps} from "./types";
import {useClippingToolTimeline} from "./hooks";
import {
  Container,
  ItemsContainer,
  RootContainer,
  SelectedTimeText
} from "./styles";

const ClippingToolTimeline: React.FC<ClippingToolTimelineProps> = (
  props: ClippingToolTimelineProps
) => {
  const {isVideoCodecSupported, uploadedVideoStoryboard, videoInfo, video} =
    props;

  const {
    overflow,
    rootContainerId,
    scrollableTimelineId,
    scrollHandler,
    scrubberTranslationX,
    setScrubberTranslationX,
    scrubberWidth,
    setScrubberWidth,
    selectedFragment,
    timelineContentSize,
    timelineScrollPosition,
    timelineSource,
    wheelHandler
  } = useClippingToolTimeline(props);
  if (!timelineSource?.length || !videoInfo) {
    return null;
  }

  return (
    <RootContainer id={rootContainerId}>
      <Container>
        <SelectedTimeText textType={TextTypes.SubHeadings.SH1}>
          {selectedFragment}
        </SelectedTimeText>

        <ItemsContainer
          id={scrollableTimelineId}
          onScroll={scrollHandler}
          onWheel={wheelHandler}
          overflow={overflow}
        >
          {timelineSource?.map((itemProps, index) => {
            return (
              <ClippingToolItem
                key={`ClippingToolItem${itemProps.start}`}
                itemProps={itemProps}
                isLast={index === timelineSource.length - 1}
                isVideoCodecSupported={isVideoCodecSupported}
                uploadedVideoStoryboard={uploadedVideoStoryboard}
                videoInfo={videoInfo}
                videoFile={video}
              />
            );
          })}
        </ItemsContainer>
        <Scrubber
          timelineContentSize={timelineContentSize}
          timelineScrollPosition={timelineScrollPosition}
          scrubberTranslationX={scrubberTranslationX}
          setScrubberTranslationX={setScrubberTranslationX}
          scrubberWidth={scrubberWidth}
          setScrubberWidth={setScrubberWidth}
        />
      </Container>
    </RootContainer>
  );
};

export default React.memo(ClippingToolTimeline);
