import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {Auth} from "aws-amplify";
import styled from "styled-components";
import {strings} from "config/strings";
import {Routes} from "config/routes";
import {textGray} from "config/styleVars";
import {Container} from "components/StyledElements/LayoutElements";
import {Spinner} from "components/Spinner";
import InputFloatLabel from "components/InputFloatLabel/InputFloatLabel";
import DatePicker from "components/DatePicker/DatePicker";

import {useUser} from "hooks/useUser";
import {GetUserDocument, useCreateUserMutation} from "apollo";
import {
  Button,
  FieldValidationMessage,
  ResponseMessage,
  ResponseMessageTypes
} from "../../components/StyledElements/FormElements";
import UsernameInput from "../../components/UsernameInput/UsernameInput";
import {validateBirthday, validateName, validateUsername} from "./Validation";

type WrapperProps = {
  isLoading: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  width: 300px;
  margin: 100px auto 0 auto;
  position: relative;

  &::after {
    content: "";
    display: ${(props) => (props.isLoading ? "block" : "none")};
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
`;

const Heading = styled.h4`
  margin-bottom: 0;
`;

const SubHead = styled.p`
  margin-top: 0;
  font-size: 13px;
  margin: 10px 0 20px 0;
  color: ${textGray};
`;

const BdayText = styled.p`
  font-size: 13px;
  margin: 0 0 10px 0;
  color: ${textGray};
`;

const getSession = async () => {
  try {
    return await Auth.currentSession();
  } catch {
    return null;
  }
};

function Onboarding() {
  const history = useHistory();
  const {user} = useUser();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState<string | undefined>();
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState<string | undefined>();
  const [birthday, setBirthday] = useState("");
  const [birthdayError, setBirthdayError] = useState<string | undefined>();

  const checkSession = async () => {
    const session = await getSession();
    if (session === null) {
      history.push(Routes.signin);
    }
  };

  useEffect(() => {
    checkSession();
  });

  function handleSaveComplete(data: any) {
    if (data && data.createUser) {
      if (window.analytics) {
        window.analytics.identify(data.createUser.uuid, {
          name: data.createUser.name,
          username: data.createUser.username,
          birthday: data.createUser.birthday,
          email: data.createUser.email
        });
        window.analytics.track("Completed Welcome Profile", {
          name: data.createUser.name,
          username: data.createUser.username,
          birthday: data.createUser.birthday,
          email: data.createUser.email
        });
      }
      history.push(Routes.home);
    }
  }

  const [createUser, {error: userError, loading: userLoading}] =
    useCreateUserMutation({
      onCompleted: (data) => {
        handleSaveComplete(data);
      },
      awaitRefetchQueries: true,
      refetchQueries: [GetUserDocument]
    });

  function handleNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setName(e.currentTarget.value);
  }
  function handleUsernameChange(newUsername: string) {
    setUsername(newUsername);
  }
  function handleBirthdayChange(e: string) {
    setBirthday(e);
  }

  function validateForm(): boolean {
    let isValid = true;
    const nameResult = validateName(name);
    const handleResult = validateUsername(username);
    const birthdayResult = validateBirthday(birthday);

    if (nameResult) {
      isValid = false;
      setNameError(nameResult);
    }
    if (handleResult) {
      isValid = false;
      setUsernameError(handleResult);
    }
    if (birthdayResult) {
      isValid = false;
      setBirthdayError(birthdayResult);
    }
    // check if error set by existance check
    if (usernameError) {
      isValid = false;
    }
    return isValid;
  }

  function handleSubmit() {
    const isValid = validateForm();

    if (isValid) {
      createUser({
        variables: {
          name,
          username,
          birthday,
          email: user?.email
        }
      });
    }
  }

  function clearErrors() {
    setNameError(undefined);
    setBirthdayError(undefined);
  }

  return (
    <>
      <Container>
        <Wrapper isLoading={userLoading}>
          {userLoading && <Spinner />}
          {/* eslint-disable-next-line react-extra/no-inline-styles */}
          <Heading style={{textAlign: "center"}}>
            {strings.pages.onboarding.title}
          </Heading>
          {/* eslint-disable-next-line react-extra/no-inline-styles */}
          <SubHead style={{textAlign: "center"}}>
            {strings.pages.onboarding.subHeader}
          </SubHead>
          {userError && (
            <ResponseMessage
              type={ResponseMessageTypes.Error}
              message={strings.pages.onboarding.submitError}
            />
          )}
          <InputFloatLabel
            type="text"
            label={strings.generic.name}
            maxChars={25}
            onChange={handleNameChange}
            onFocus={clearErrors}
            invalid={!!nameError}
          />
          {nameError && (
            <FieldValidationMessage>{nameError}</FieldValidationMessage>
          )}
          <UsernameInput
            maxChars={25}
            invalid={!!usernameError}
            onChangeFn={handleUsernameChange}
            onFocusFn={clearErrors}
            onErrorFn={setUsernameError}
          />
          {usernameError && (
            <FieldValidationMessage>{usernameError}</FieldValidationMessage>
          )}
          <div>Date of Birth</div>
          <BdayText>
            Confirm your own age, even if this account is for a business, a pet,
            or something else. Only the month and day will be visible on your
            profile.
          </BdayText>
          <DatePicker
            onChangeFn={handleBirthdayChange}
            onFocusFn={clearErrors}
            invalid={birthdayError}
          />
          {birthdayError && (
            <FieldValidationMessage>{birthdayError}</FieldValidationMessage>
          )}
          <Button
            // eslint-disable-next-line react-extra/no-inline-styles -- MAR-844
            style={{width: "100%", marginBottom: "10px"}}
            onClick={handleSubmit}
          >
            {strings.generic.complete}
          </Button>
        </Wrapper>
      </Container>
      <div className="bg-gradient" />
    </>
  );
}

export default Onboarding;
