import react from "react";
import {FieldValidationMessage} from "components/StyledElements/FormElements";
import styled from "styled-components";
import AlertIcon from "icons/24/alert-triangle.svg";
import {strings} from "config/strings";

const Container = styled.div`
  position: absolute;
  width: 710px;
  left: 120px;
`;

const ErrorMessage = styled(FieldValidationMessage)`
  position: absolute;
  display: inline-block;
  margin-left: 30px;
  top: 5px;
`;

const AlertIconContainer = styled.img`
  position: absolute;
  top: 5px;
`;

export const SaveButton = styled.div`
  position: absolute;
  width: 160px;
  height: 40px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 18px;
  line-height: 24px;
  padding: 8px 61px;
  cursor: pointer;
  top: 0px;
  right: 0px;
`;

type ProfileEditSaveButtonProps = {
  error?: boolean;
  hideSaveButton?: boolean;
  style: Record<string, string | number>;
  onSave: react.MouseEventHandler<HTMLDivElement> | undefined;
};

export const ProfileEditSaveButton = ({
  style,
  error,
  hideSaveButton,
  onSave
}: ProfileEditSaveButtonProps) => {
  return (
    <Container style={style}>
      {error && (
        <>
          <AlertIconContainer src={AlertIcon} />
          <ErrorMessage>
            {hideSaveButton
              ? strings.pages.profile.changeBackError
              : strings.pages.profile.tryAgainError}
          </ErrorMessage>
        </>
      )}
      {!hideSaveButton && (
        <SaveButton onClick={onSave}>{strings.generic.save}</SaveButton>
      )}
    </Container>
  );
};

export default ProfileEditSaveButton;
