import React, {useRef} from "react";

import InfiniteScroll from "react-infinite-scroller";
import {useListUserFollowsQuery, useRemoveFollowerMutation} from "apollo";
import {PROFILE_USERS_PER_PAGE} from "config/constants";
import {Spinner} from "components/Spinner";
import {strings} from "config/strings";
import {formatCountNumber} from "utils/FormatUtils";
import Avatar from "components/Avatar";
import RemoveIcon from "icons/16/x.svg";
import {useUser} from "hooks/useUser";
import {ProfileFollowButton} from "./ProfileFollowButton";
import {
  Container,
  Heading,
  Error,
  Empty,
  List,
  UserItem,
  Names,
  Name,
  Username,
  Actions,
  RemoveButton,
  AutomationQAWrapper
} from "./ProfileUserListStyles";

type Props = {
  type: "followed" | "following";
  uuid: string;
  isOwnProfile: boolean;
};

export const getEmptyMessage = (type: string, isOwnProfile: boolean) => {
  if (type === "followed") {
    if (isOwnProfile) {
      return strings.pages.profile.noFollowers;
    }
    return strings.pages.profile.noFollowersPublic;
  }
  if (isOwnProfile) {
    return strings.pages.profile.noFollowing;
  }
  return strings.pages.profile.noFollowingPublic;
};

export const ProfileUserList: React.FC<Props> = ({
  type,
  uuid,
  isOwnProfile
}: Props) => {
  const listRef = useRef(null);
  const {data, loading, error, fetchMore} = useListUserFollowsQuery({
    variables: {
      list: type,
      uuid,
      page: {
        take: PROFILE_USERS_PER_PAGE,
        skip: 0
      }
    },
    fetchPolicy: "no-cache"
  });
  const {user: currentUser} = useUser();

  const [removeFollower] = useRemoveFollowerMutation({
    onCompleted: () => {
      fetchMore({
        variables: {
          uuid,
          page: {
            take: PROFILE_USERS_PER_PAGE,
            skip: 0
          }
        }
      });
    }
  });

  const handleLoadMore = () => {
    if (!data || !data.listUserFollows || !data.listUserFollows.users) {
      return;
    }
    if (loading) {
      return;
    }
    fetchMore({
      variables: {
        uuid,
        page: {
          take: PROFILE_USERS_PER_PAGE,
          skip: data.listUserFollows.users.length
        }
      }
    });
  };

  const handleRemoveFollower = (userUuid: string) => {
    removeFollower({
      variables: {
        uuid: userUuid
      }
    });
  };

  const hasMore = () => {
    if (
      data?.listUserFollows &&
      data.listUserFollows.users &&
      data.listUserFollows.pager?.count
    ) {
      if (data.listUserFollows.pager.count === 0) {
        return false;
      }
      const hasmore =
        data.listUserFollows.users.length < data.listUserFollows.pager.count;
      return hasmore;
    }
    return true;
  };

  const getHeading = () => {
    let count = "";
    if (
      data &&
      data.listUserFollows &&
      data.listUserFollows.pager &&
      data.listUserFollows.pager?.count
    ) {
      count = `(${formatCountNumber(data.listUserFollows.pager?.count)})`;
    }
    if (type === "followed") {
      return `${strings.generic.followers} ${count}`;
    }
    return `${strings.generic.following} ${count}`;
  };

  const renderUsers = () => {
    if (
      data &&
      data.listUserFollows &&
      data.listUserFollows.users &&
      data.listUserFollows.users.length
    ) {
      return data.listUserFollows.users.map((user) => {
        return (
          <UserItem key={user?.uuid}>
            <Avatar
              avatarURL={user?.avatar!}
              hideBorder={true}
              size={50}
              username={user?.username!}
              uuid={user?.uuid!}
            />
            <Names>
              <Name>{user?.name}</Name>
              <Username>@{user?.username}</Username>
            </Names>
            <Actions>
              <AutomationQAWrapper data-cy="popup-follow-unfollow-button">
                {currentUser?.uuid !== user?.uuid && (
                  <ProfileFollowButton small={true} uuid={user?.uuid!} />
                )}
              </AutomationQAWrapper>
              {type === "followed" && isOwnProfile && (
                <RemoveButton onClick={() => handleRemoveFollower(user?.uuid!)}>
                  <img alt="remove icon" src={RemoveIcon} />
                </RemoveButton>
              )}
            </Actions>
          </UserItem>
        );
      });
    }
    return <Empty>{getEmptyMessage(type, isOwnProfile)}</Empty>;
  };

  return (
    <Container>
      <Heading>{getHeading()}</Heading>
      <List ref={listRef}>
        {loading && !data?.listUserFollows && <Spinner />}
        {error && <Error>{strings.pages.profile.userListError}</Error>}
        {!error && data && (
          <InfiniteScroll
            getScrollParent={() => listRef.current}
            hasMore={hasMore()}
            loadMore={handleLoadMore}
            threshold={250}
            useWindow={false}
          >
            {renderUsers()}
          </InfiniteScroll>
        )}
      </List>
    </Container>
  );
};
