import React from "react";
import ClippingTool from "components/Organisms/ClippingTool";
import ThumbnailUpload from "components/Organisms/ThumbnailUpload";
import {TextTypes} from "components/Atoms/Text/types";
import Text from "components/Atoms/Text";
import {strings} from "config/strings";

import {Container, LinkButton} from "./styles";
import {ThumbnailFormProps, ThumbnailType} from "./types";
import {useThumbnailForm} from "./hooks";

const ThumbnailForm: React.FC<ThumbnailFormProps> = (
  props: ThumbnailFormProps
) => {
  const {
    endTime,
    externalId,
    isVideoUploaded,
    setEndTime,
    setIsThumbnailUploaded,
    setStartTime,
    thumbnailType,
    startTime,
    uuid,
    video
  } = props;
  const {switchThumbnailType} = useThumbnailForm(props);

  return (
    <Container>
      <ClippingTool
        endTime={endTime}
        externalId={externalId}
        isVisible={thumbnailType === ThumbnailType.Clipping}
        isVideoUploaded={isVideoUploaded}
        setEndTime={setEndTime}
        setStartTime={setStartTime}
        startTime={startTime}
        video={video}
      />
      <ThumbnailUpload
        externalId={externalId}
        isVisible={thumbnailType === ThumbnailType.Upload}
        setIsThumbnailUploaded={setIsThumbnailUploaded}
        switchThumbnailType={switchThumbnailType}
        uuid={uuid}
      />

      {/* change link */}
      <LinkButton onClick={switchThumbnailType}>
        <Text textType={TextTypes.Other.LinkLabelL}>
          {thumbnailType === ThumbnailType.Clipping
            ? strings.pages.upload.uploadOwnThumbnail
            : strings.pages.upload.createThumbnail}
        </Text>
      </LinkButton>
    </Container>
  );
};

export default React.memo(ThumbnailForm);
