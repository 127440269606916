import {makeVar} from "@apollo/react-hooks";

// Global PLayer State
export enum DisplayMode {
  theater = "THEATER",
  fullscreen = "FULLSCREEN"
}

export type PlayerType = {
  currentVideo: number | null;
};

export type AudioType = {
  muted: boolean;
  volume: number;
};

export const videoStateVar = makeVar<PlayerType>({
  currentVideo: null
});

export const audioStateVar = makeVar<AudioType>({
  muted: true,
  volume: 0.5
});

export const stopVideoStateVar = makeVar<boolean>(false);
export const displayStateVar = makeVar<DisplayMode>(DisplayMode.theater);

export const eraseVideoState = () => {
  displayStateVar(DisplayMode.theater);
  videoStateVar({currentVideo: null});
};
