import styled from "styled-components";
import {colours} from "config/styleVars";

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ImagePlaceholder = styled.div`
  aspect-ratio: 16/9;
  background-color: ${colours.dark};
`;

export const MetadataContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
`;

export const AvatarPlaceholder = styled.div`
  background-color: ${colours.dark};
  width: 42px;
  height: 42px;
  border-radius: 5px;
`;

export const LinesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  margin-left: 12px;
  flex: 1;
`;

export const Line = styled.div<{height: number; marginRight: number}>`
  height: ${(props) => props.height}px;
  margin-right: ${(props) => props.marginRight}px;
  background-color: ${colours.dark};
`;
