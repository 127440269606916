import styled from "styled-components";
import Text from "components/Atoms/Text";
import MintNftStep from "components/MintNFTsStep";
import IconButton from "components/Atoms/IconButton";
import {ReactComponent as ChevronRight} from "icons/chevron-right-custom.svg";
import SvgBackground from "icons/mintNftBackground.svg";
import {ButtonColours} from "components/Atoms/IconButton/types";
import {colours} from "config/styleVars";

export const GET_STARTED_BUTTON_COLORS: {
  backgroundColours: ButtonColours;
  borderColours: ButtonColours;
  textColours: ButtonColours;
} = {
  backgroundColours: {
    default: "rgba(249,128,255,1)",
    hovered: "rgba(249,128,255,0.8)",
    clicked: "rgba(249,128,255,0.8)",
    disabled: colours.darkGray
  },
  borderColours: {
    default: "rgba(249,128,255,1)",
    hovered: "rgba(249,128,255,0.8)",
    clicked: "rgba(249,128,255,0.8)",
    disabled: colours.darkGray
  },
  textColours: {
    default: "rgba(255,255,255,1)",
    hovered: "rgba(255,255,255,1)",
    clicked: "rgba(255,255,255,1)",
    disabled: colours.lightGray
  }
};

export const RootContainer = styled.div`
  background-image: url(${SvgBackground});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

export const Title = styled(Text)`
  margin-top: 190px;
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 64px;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
  box-sizing: border-box;
`;

export const Step = styled(MintNftStep)`
  margin-left: 16px;
  margin-right: 16px;
`;

export const GetStartedButton = styled(IconButton)`
  border-radius: 36px;
  margin-top: 80px;
  padding: 12px 16px;
  disabled: true;
`;

export const Chevron = styled(ChevronRight)`
  stroke-width: 1.5;
  width: 20px;
  height: 20px;
`;
