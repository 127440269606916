import {useState} from "react";
import * as UpChunk from "@mux/upchunk";

export const useVideoUpload = () => {
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  let abort = () => {};

  const start = (endpoint: string, file: File) => {
    const instance = UpChunk.createUpload({
      endpoint,
      file,
      chunkSize: 5120
    });
    const {abort: instanceAbort} = instance;

    setLoading(true);
    abort = instanceAbort;

    instance.on("error", (err) => {
      setError(err.detail);
      setUploaded(false);
    });

    instance.on("progress", (currentProgress) => {
      setProgress(Math.round(currentProgress.detail));
    });

    instance.on("success", () => {
      setLoading(false);
      setUploaded(true);
    });

    return instance;
  };

  return {start, progress, error, loading, abort, uploaded};
};
