import React from "react";
import AlgoliaSearchBox from "components/Molecules/AlgoliaSearchBox";
import AlgoliaSearchResults from "components/Molecules/AlgoliaSearchResults";
import {InstantSearch} from "react-instantsearch-hooks";
import {indexName, searchClient} from "utils/AlgoliaUtils";

import {RootContainer} from "./styles";
import {useAlgoliaSearch} from "./hooks";

const AlgoliaSearch: React.FC = () => {
  const {isFocused, onBlur, onFocus, setSearchText, searchText} =
    useAlgoliaSearch();

  return (
    <InstantSearch indexName={indexName} searchClient={searchClient}>
      <RootContainer isFocused={isFocused}>
        <AlgoliaSearchBox
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={setSearchText}
        />
        <AlgoliaSearchResults
          maxResults={3}
          query={searchText}
          isFocused={isFocused}
        />
      </RootContainer>
    </InstantSearch>
  );
};

export default React.memo(AlgoliaSearch);
