import React from "react";

import {TextInputCounterProps} from "./types";
import {GrayText} from "./styles";
import {TextTypes} from "../Text/types";

const TextInputCounter: React.FC<TextInputCounterProps> = ({
  className,
  count,
  isDisabled,
  maxCount
}: TextInputCounterProps) => {
  return (
    <GrayText
      className={className}
      isDisabled={isDisabled}
      textType={TextTypes.Subtitle.M}
    >
      {count}/{maxCount}
    </GrayText>
  );
};

export default React.memo(TextInputCounter);
