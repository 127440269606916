import React from "react";
import {FormProvider} from "react-hook-form";
import InputTag from "components/Molecules/InputTag";
import {Input} from "components/Input";
import {Textarea} from "components/Textarea";
import {TextTypes} from "components/Atoms/Text/types";
import {strings} from "config/strings";

import {MetadataFormProps} from "./types";
import {Container, Error, FormItemContainer, Header} from "./styles";
import {useMetadataForm} from "./hooks";

const MetadataForm: React.FC<MetadataFormProps> = (
  props: MetadataFormProps
) => {
  const {changeHandler, errors, methods, onSubmit, register, setTags} =
    useMetadataForm(props);

  return (
    <Container>
      <Header textType={TextTypes.SubHeadings.SH3}>
        {strings.pages.upload.videoDetails}
      </Header>

      <FormProvider {...methods}>
        <form onSubmit={onSubmit} onChange={changeHandler}>
          <FormItemContainer>
            <Input
              {...register("title")}
              invalid={!!errors.title}
              label={strings.generic.title}
              type="text"
            />
            {errors.title && <Error>{errors.title.message}</Error>}
          </FormItemContainer>

          <FormItemContainer>
            <Textarea
              {...register("description")}
              invalid={!!errors.description}
              label={strings.generic.description}
            />
            {errors.description && <Error>{errors.description.message}</Error>}
          </FormItemContainer>
        </form>
      </FormProvider>

      <FormItemContainer>
        <InputTag name="tags" onChange={setTags} />
      </FormItemContainer>
    </Container>
  );
};

export default React.memo(MetadataForm);
