import React from "react";
import Avatar from "components/Atoms/Avatar";
import AlgoliaHighlight from "components/Atoms/AlgoliaHighlight";
import {TextTypes} from "components/Atoms/Text/types";
import {HitComponentProps} from "components/Molecules/AlgoliaInfiniteHits/types";

import {useUserListItem} from "./hooks";
import {RootContainer, TextContainer} from "./styles";
import {UserListItemProps} from "./types";

const UserListItem: React.FC<HitComponentProps> = ({
  hit
}: HitComponentProps) => {
  const userListItemProps = hit as unknown as UserListItemProps;
  const {avatar, username} = userListItemProps;
  const {onClick} = useUserListItem(username);

  return (
    <RootContainer onClick={onClick}>
      <Avatar size={36} avatarURL={avatar} username={username} />
      <TextContainer>
        <AlgoliaHighlight
          textType={TextTypes.Subtitle.LStrong}
          hit={hit}
          attribute="name"
        />
        <AlgoliaHighlight
          textType={TextTypes.Subtitle.MStrong}
          hit={hit}
          attribute="username"
          prefix="@"
        />
      </TextContainer>
    </RootContainer>
  );
};

export default React.memo(UserListItem);
