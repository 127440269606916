import {useApolloClient} from "@apollo/react-hooks";
import {GetPublishedVideoDocument, FeedVideo} from "apollo";
import {Spinner} from "components/Spinner";
import Player from "components/Video/Player";
import {INITIAL_ITEMS_COUNT} from "config/constants";
import {getForYouPlayerUrl} from "config/routes";
import {useFeed} from "hooks/useFeed";
import {useState, useEffect} from "react";
import {useHistory, useParams} from "react-router";

const ForYouPlayer = () => {
  /*
    Using the feed data source because so far here is where we generate the random videos.
    We expect to have a data source for it in the future, this is why we have a duplication
    with the FeedPlayer. In the future we just need to replace the datasource
  */
  const feedData = useFeed();
  const client = useApolloClient();
  const {videoId} = useParams<{videoId: string}>();
  const history = useHistory();
  const [index, setIndex] = useState(0);
  const [videos, setVideos] = useState<FeedVideo[]>();
  const [singleVideo, setSingleVideo] = useState<FeedVideo>();

  const [singleVideoLoading, setSingleVideoLoading] = useState<boolean>(false);

  useEffect(() => {
    if (videoId && !singleVideo) {
      setSingleVideoLoading(true);
      const response = client.query({
        query: GetPublishedVideoDocument,
        variables: {
          externalId: videoId
        }
      });

      response
        .then((result) => {
          setSingleVideo(result.data?.getPublishedVideo as FeedVideo);
        })
        .finally(() => {
          setSingleVideoLoading(false);
        });
    }
  }, [videoId, singleVideo]);

  const setVideoConfig = (video: FeedVideo, newIndex?: number) => {
    setSingleVideo(video);
    const url = getForYouPlayerUrl(video.externalId);
    history.push(url);
    if (newIndex) {
      setIndex(newIndex);
    }
  };

  useEffect(() => {
    if (!videoId && !!feedData?.videos?.length) {
      const video = feedData.videos[0];
      setVideoConfig(video as FeedVideo);
    }
  }, [feedData?.videos]);

  useEffect(() => {
    if (singleVideo && !videos && !!feedData?.videos?.length) {
      setVideos([
        singleVideo,
        ...(feedData?.videos?.filter((x) => x?.externalId !== videoId) || [])
      ]);
    }
  }, [feedData?.videos, singleVideo, videoId]);

  const handleVideoChange = async (newIndex: number) => {
    if (feedData?.videos && videos) {
      const shouldLoadNextPage =
        feedData?.count &&
        newIndex < feedData?.count &&
        newIndex >= videos.length;
      const shouldMoveInsidePage =
        feedData?.count && newIndex < feedData?.count && newIndex >= 0;

      if (shouldLoadNextPage) {
        const {data} = await feedData.fetchMore({
          variables: {
            page: {
              take:
                INITIAL_ITEMS_COUNT +
                Math.floor(newIndex / INITIAL_ITEMS_COUNT) * INITIAL_ITEMS_COUNT
            }
          }
        });

        setVideos([...((data?.getFeed?.videos as Array<FeedVideo>) || [])]);
        const video = data?.getFeed?.videos?.[newIndex];
        setVideoConfig(video as FeedVideo, newIndex);
      } else if (shouldMoveInsidePage) {
        const video = videos[newIndex];
        setVideoConfig(video, newIndex);
      }
    }
  };

  return (
    <>
      {(feedData?.isLoading || singleVideoLoading) && <Spinner />}
      {!feedData?.isLoading && !!videos?.length && (
        <Player
          videos={videos}
          videoIndex={index}
          handleVideoChange={handleVideoChange}
        />
      )}
    </>
  );
};

export default ForYouPlayer;
