import styled from "styled-components";
import Text from "components/Atoms/Text";
import {colors} from "theme/colors";

export const RootContainer = styled.div`
  padding: 0px 72px;
`;

export const GrayText = styled(Text)`
  color: ${colors.primary.lightGrey};
`;

export const WhiteAndGrayText = styled(Text)`
  color: ${colors.primary.white};

  span {
    color: ${colors.primary.lightGrey};
  }
`;

export const GraySeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.primary.mediumGrey};
  margin: 20px 0;
`;

export const PercentageDetailText = styled(Text)<{
  color?: string;
  disabled?: boolean;
}>`
  color: ${({color, disabled}) =>
    disabled ? colors.primary.mediumGrey : color || colors.primary.lightGrey};
  span {
    color: ${({disabled}) =>
      disabled ? colors.primary.mediumGrey : colors.primary.cyan};
    margin-left: auto;
  }
`;

export const RecommendedInput = styled(Text)`
  margin-top: 4px;
  color: ${colors.primary.cyan};
  text-decoration-line: underline;
  justify-content: flex-end;
`;

export const InputLabel = styled(Text)<{marginTop?: number}>`
  margin-bottom: 4px;
  margin-top: ${({marginTop}) => marginTop || 0}px;
  white-space: pre-wrap;
  color: ${colors.primary.white};

  span {
    color: ${colors.primary.lightGrey};
  }
`;
