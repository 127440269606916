import {useCallback, useEffect, useMemo, useState} from "react";
import {VideoJsPlayer} from "video.js";
import videojs from "@mux/videojs-kit";
import "@mux/videojs-kit/dist/index.css";
import {MUX_BASE_URL} from "config/constants";

import {ClippingToolPreviewProps} from "./types";

export function useClippingToolPreview({
  endTime,
  isVideoCodecSupported,
  startTime,
  uploadedVideoPlaybackUrl,
  video
}: ClippingToolPreviewProps) {
  const [videoNodeRef, setVideoNodeRef] = useState<any | null>(null);
  const [player, setPlayer] = useState<VideoJsPlayer | null>(null);

  const playerOptions = useMemo(() => {
    return JSON.stringify({
      plugins: {
        mux: {
          data: {}
        }
      }
    });
  }, []);

  /**
   * Initializing subscribing to events
   */
  useEffect(() => {
    if (videoNodeRef && !player) {
      setPlayer(
        videojs(videoNodeRef, {
          plugins: {
            mux: {
              debug: false,
              data: {
                env_key: process.env.REACT_APP_MUX_KEY,
                player_name: "Preview Video Player",
                player_init_time: Date.now()
              }
            }
          }
        })
      );
    }
  }, [player, videoNodeRef]);

  /**
   * Clean up the player
   */
  useEffect(() => {
    return () => {
      player?.dispose();
    };
  }, [player]);

  const onTimeUpdate = useCallback(() => {
    if (!player) {
      return;
    }

    const currentTime = player?.currentTime();
    if (currentTime > endTime || currentTime < startTime) {
      player.currentTime(startTime);
    }
  }, [startTime, endTime, player]);

  useEffect(() => {
    if (!player || player.src()) {
      return;
    }

    if (isVideoCodecSupported) {
      player.src({
        src: URL.createObjectURL(video),
        type: "video/mp4"
      });
      return;
    }

    if (uploadedVideoPlaybackUrl) {
      player.src({
        src: uploadedVideoPlaybackUrl.split(MUX_BASE_URL)[1],
        type: "video/mux"
      });
    }
  }, [isVideoCodecSupported, player, uploadedVideoPlaybackUrl, video]);

  return {
    onTimeUpdate,
    playerOptions,
    setVideoNodeRef
  };
}
