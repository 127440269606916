import React, {useState, useCallback} from "react";

import {TokenResalePercentageProps} from "./index";

function validateResalePercentage(value: number, max: number) {
  return Number.isNaN(value) || value > max || value < 0;
}

export function useTokenResalePercentage({
  onChange,
  initialValue,
  maxValue
}: TokenResalePercentageProps) {
  const [resalePercentage, setResalePercentage] = useState(0);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [resalePercentageError, setResalePercentageError] = useState(
    validateResalePercentage(Number(initialValue), maxValue)
  );

  const handleResalePercentageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = parseInt(e.target.value, 10);
      setResalePercentageError(validateResalePercentage(value, maxValue));
      setResalePercentage(value);
    },
    [maxValue]
  );

  const toggleModal = useCallback(() => {
    setIsPopUpOpen((value) => !value);
  }, []);

  const onSubmit = useCallback(() => {
    if (onChange) {
      onChange(resalePercentage);
    }
    toggleModal();
  }, [onChange, resalePercentage, toggleModal]);

  return {
    resalePercentage,
    toggleModal,
    handleResalePercentageChange,
    resalePercentageError,
    isPopUpOpen,
    onSubmit,
    isEdit: typeof initialValue === "number"
  };
}
