import {useFullScreen} from "hooks/useFullScreen";
import React, {useEffect, Fragment} from "react";
import {withRouter} from "react-router-dom";

// @ts-ignore
function RouteEvents({history, children}: {history: any; children: any}) {
  const [, exitFullScreen] = useFullScreen();

  useEffect(() => {
    const unlisten = history.listen(() => {
      const pathName = history.location?.pathname.toLowerCase();
      if (!pathName.includes("player")) {
        exitFullScreen();
      }
      setTimeout(() => {
        if (history.action !== "REPLACE") {
          window.scrollTo(0, 0);
        }
      });
    });
    return () => {
      unlisten();
    };
  }, []);

  return <Fragment>{children}</Fragment>;
}

// @ts-ignore
export default withRouter(RouteEvents);
