import React, {ForwardedRef, useCallback} from "react";
import TextInputFloatingLabel from "components/Atoms/TextInputFloatingLabel";
import {TextTypes} from "components/Atoms/Text/types";
import ClearButton from "components/Atoms/ClearButton";

import {InputIconSide, TextInputProps} from "./types";
import {
  Area,
  Counter,
  HelperText,
  IconContainer,
  Input,
  InputAndLabelContainer,
  InputContainer,
  LeftIconContainer,
  LeftSideContainer,
  RightSideContainer,
  RootContainer
} from "./styles";
import {useTextInput} from "./hooks";

const TextInput = React.forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  TextInputProps
>((props: TextInputProps, ref) => {
  const {
    borderType,
    className,
    helperText,
    hideMaxCharCount,
    id,
    isDisabled,
    isInvalid,
    isSucceeded,
    label,
    maxCharCount,
    multiline,
    name,
    onKeyDown,
    onPaste,
    placeholder,
    renderIcon,
    showClearButton,
    type
  } = props;
  const {
    charCount,
    clear,
    handleOnBlur,
    handleOnChange,
    handleOnFocus,
    iconColor,
    isFloating,
    isFocused,
    leftIconExist,
    rightIconExist,
    showRightSidePanel,
    textStyle,
    value
  } = useTextInput(props);

  const renderInput = useCallback(() => {
    const inputProps = {
      autoComplete: "off",
      id,
      disabled: isDisabled,
      label,
      maxLength: maxCharCount,
      name,
      onBlur: handleOnBlur,
      onChange: handleOnChange,
      onFocus: handleOnFocus,
      onKeyDown,
      onPaste,
      placeholder,
      value,
      type
    };
    if (multiline) {
      return (
        <Area
          {...textStyle}
          {...inputProps}
          ref={ref as ForwardedRef<HTMLTextAreaElement>}
        />
      );
    }

    return (
      <Input
        {...textStyle}
        {...inputProps}
        ref={ref as ForwardedRef<HTMLInputElement>}
      />
    );
  }, [
    type,
    handleOnBlur,
    handleOnChange,
    handleOnFocus,
    id,
    isDisabled,
    label,
    maxCharCount,
    multiline,
    name,
    onKeyDown,
    onPaste,
    placeholder,
    ref,
    textStyle,
    value
  ]);

  return (
    <RootContainer className={className}>
      <InputContainer
        isDisabled={isDisabled}
        isFocused={isFocused}
        isInvalid={isInvalid}
        isSucceeded={isSucceeded}
        borderType={borderType}
        label={label}
        showRightSidePanel={showRightSidePanel}
      >
        {showClearButton && (
          <LeftSideContainer>
            <ClearButton clearValue={clear} />
          </LeftSideContainer>
        )}

        {renderIcon && leftIconExist && (
          <LeftIconContainer clearButtonExist={showClearButton}>
            {renderIcon(iconColor, InputIconSide.left)}
          </LeftIconContainer>
        )}

        <InputAndLabelContainer
          clearButtonExist={showClearButton}
          leftIconExist={leftIconExist}
          label={label}
          rightIconExist={rightIconExist}
        >
          {label && (
            <TextInputFloatingLabel
              isDisabled={isDisabled}
              isFloating={isFloating}
              label={label}
            />
          )}
          {renderInput()}
        </InputAndLabelContainer>

        {showRightSidePanel && rightIconExist && (
          <RightSideContainer>
            {renderIcon && (
              <IconContainer>
                {renderIcon(iconColor, InputIconSide.right)}
              </IconContainer>
            )}
            {!hideMaxCharCount && maxCharCount && (
              <Counter
                count={charCount}
                isDisabled={isDisabled}
                maxCount={maxCharCount}
              />
            )}
          </RightSideContainer>
        )}
      </InputContainer>

      {helperText && (
        <HelperText
          isDisabled={isDisabled}
          isInvalid={isInvalid}
          isSucceeded={isSucceeded}
          textType={TextTypes.Other.ButtonMLight}
        >
          {helperText}
        </HelperText>
      )}
    </RootContainer>
  );
});

export default React.memo(TextInput);
