/* eslint-disable react-extra/no-inline-styles -- MAR-844 */
import {strings} from "config/strings";
import styled from "styled-components";
import LeftIcon from "icons/20/chevron-left.svg";
import {useForm} from "react-hook-form";
import {Input} from "components/Input";
import {FieldValidationMessage} from "components/StyledElements/FormElements";
import {Textarea} from "components/Textarea";
import {User, useUpdateUserMutation} from "apollo";
import {yupResolver} from "@hookform/resolvers/yup";
import {getEditProfileValidators} from "utils/validators/editProfile";
import {format} from "date-fns";
import {addTZ} from "utils/FormatUtils";
import {AddImageOverlay, ProfileImageTypes} from "components/Profile";
import {ApolloError} from "@apollo/client";
import {useHistory} from "react-router-dom";
import {Spinner} from "components/Spinner";
import {getProfileUrl} from "config/routes";
import {ChangeEvent, useEffect, useState} from "react";
import ProfileEditSaveButton from "components/Button/ProfileEditSaveButton";
import {Modal} from "components/Modal";
import {Checkbox} from "components/Checkbox";
import ChangeUsernameButton from "components/Button/ChangeUsernameButton/ChangeUsernameButton";
import ChangeUsername from "./ChangeUsername";
import {
  EditProfileBanner,
  EditProfileAvatar,
  InputContainer,
  FieldsContaner,
  ImageSizeSuggestion,
  ImageTypeSuggestion,
  BackButton,
  BackIcon,
  Container,
  Title
} from "./styles";

const LineBreak = styled.div`
  position: absolute;
  width: 146px;
  height: 0px;
  right: 178px;
  top: 242px;
  border: 1px solid #4b494d;
`;

type ProfileEditContainerProps = {
  user: User | undefined;
  isLoading: boolean;
  error?: ApolloError;
};

interface ProfileForm {
  name: string;
  location: string;
  birthday: string;
  website: string;
  bio: string;
  showBirthday?: boolean;
}

type UsernameUpdate = {
  usernameUpdated: boolean;
  usernameReverted: boolean;
  username?: string;
};

const ProfileEditContainer = ({user, isLoading}: ProfileEditContainerProps) => {
  const history = useHistory();
  const [isUsernameSaving, setIsUsernameSaving] = useState(false);
  const [displayUsernameForm, setDisplayUsernameForm] = useState(false);
  const [reservationError, setReservationError] = useState(false);
  const [usernameUpdateError, setUsernameUpdateError] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [usernameUpdate, setUsernameUpdate] = useState<UsernameUpdate>({
    usernameReverted: false,
    usernameUpdated: false,
    username: user?.username
  });
  const [currentPath, setCurrentPath] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors}
  } = useForm({
    resolver: yupResolver(getEditProfileValidators()),
    mode: "onBlur",
    reValidateMode: "onChange"
  });

  useEffect(() => {
    const fields = [
      "name",
      "username",
      "location",
      "birthday",
      "website",
      "bio"
    ];
    if (user) {
      const obj = user as {[key: string]: any};
      fields.forEach((field) => {
        const value =
          field === "birthday"
            ? format(addTZ(obj[field]), "MM/dd/yyyy")
            : obj[field];
        setValue(field, value);
      });

      setValue(
        "showBirthday",
        user.profilePreferences?.show?.birthday || false
      );
      setUsernameUpdate((prev) => ({...prev, username: user.username}));
    }
  }, [user, setValue]);

  const [updateUser, {loading: isUpdating, error: updateError}] =
    useUpdateUserMutation({
      onCompleted: (data) => {
        if (window.analytics && user && user.uuid) {
          window.analytics.identify(user.uuid, {
            name: user?.name,
            username: user?.username,
            birthday: user?.birthday,
            email: user?.email
          });
        }
        if (!isUsernameSaving) {
          history.push(getProfileUrl(user?.username || ""), false);
        } else {
          setIsUsernameSaving(false);
          setUsernameUpdate((prev) => ({
            ...prev,
            usernameUpdated: true,
            username: data.updateUser?.username
          }));
        }
      },
      onError: () => {
        if (isUsernameSaving) {
          setUsernameUpdateError(true);
          setIsUsernameSaving(false);
        }
      }
    });

  const handleOnSave = (data: ProfileForm) => {
    if (window.analytics) {
      window.analytics.track("Edit Profile Save", {
        uuid: user?.uuid
      });
      window.analytics.identify(user?.uuid as string, {
        name: user?.name,
        username: user?.username,
        birthday: user?.birthday,
        email: user?.email
      });
    }
    updateUser({variables: {...data}});
  };

  const handleUsernameSave = (username: string, reverted?: boolean) => {
    setReservationError(false);
    setIsUsernameSaving(true);
    setUsernameUpdate({
      ...usernameUpdate,
      usernameReverted: !!reverted
    });
    updateUser({variables: {username}});
  };

  const handleReservationError = () => {
    setReservationError(true);
    setDisplayUsernameForm(false);
  };

  const handleHideForm = () => {
    if (usernameUpdate.usernameUpdated) {
      window.location.href = getProfileUrl(usernameUpdate.username || "");
    }
  };

  const handleOnClickUsername = () => {
    setUsernameUpdateError(false);
    setDisplayUsernameForm(true);
  };

  const handleOnBackClick = () => {
    if (unsavedChanges) {
      setOpenModal(true);
      return;
    }

    history.goBack();
  };

  const handleShowbirthdayChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue("showBirthday", event.target.checked, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true
    });
    setUnsavedChanges(true);
  };

  useEffect(() => {
    // eslint-disable-next-line consistent-return -- MAR-841
    const unblock = history.block((location) => {
      if (unsavedChanges && location.state !== false) {
        setOpenModal(true);
        setCurrentPath(location.pathname);
        return false;
      }
    });

    return () => {
      unblock();
    };
  }, [history, unsavedChanges]);

  return (
    <Container showBorder>
      {(isLoading || isUpdating) && <Spinner />}
      {!isLoading && !isUpdating && !displayUsernameForm && user && (
        <>
          <BackButton onClick={handleOnBackClick}>
            <BackIcon src={LeftIcon} />
            {strings.generic.back}
          </BackButton>
          <Title>{strings.pages.profile.editProfile}</Title>
          <EditProfileBanner style={{backgroundImage: `url(${user?.banner!})`}}>
            <AddImageOverlay type={ProfileImageTypes.banner} />
            <EditProfileAvatar
              style={{backgroundImage: `url(${user?.avatar})`}}
            >
              <AddImageOverlay type={ProfileImageTypes.avatar} />
            </EditProfileAvatar>
          </EditProfileBanner>
          <ImageTypeSuggestion>
            {strings.pages.profile.imageTypeSuggestion}
          </ImageTypeSuggestion>
          <LineBreak />
          <ImageSizeSuggestion>
            {strings.pages.profile.imageSizeSuggestion}
          </ImageSizeSuggestion>
          <FieldsContaner>
            <InputContainer>
              <ChangeUsernameButton
                username={user?.username || ""}
                onClick={handleOnClickUsername}
              />
            </InputContainer>
            <InputContainer>
              <Input
                {...register("name")}
                defaultValue={user?.name?.toString()}
                invalid={!!errors.name}
                label={strings.generic.name}
                type="text"
                onChange={() => setUnsavedChanges(true)}
              />
              {errors.name && (
                <FieldValidationMessage>
                  {errors.name.message}
                </FieldValidationMessage>
              )}
            </InputContainer>
            <InputContainer>
              <Input
                {...register("location")}
                defaultValue={user?.location?.toString()}
                invalid={!!errors.location}
                label={strings.generic.location}
                type="text"
                onChange={() => setUnsavedChanges(true)}
              />
              {errors.location && (
                <FieldValidationMessage>
                  {errors.location.message}
                </FieldValidationMessage>
              )}
            </InputContainer>
            <InputContainer>
              <Input
                {...register("birthday")}
                defaultValue={user?.birthday?.toString()}
                invalid={!!errors.birthday}
                label={strings.generic.birthday}
                type="text"
                onChange={() => setUnsavedChanges(true)}
              />
              {errors.birthday && (
                <FieldValidationMessage>
                  {errors.birthday.message}
                </FieldValidationMessage>
              )}
            </InputContainer>
            <InputContainer>
              <Checkbox
                checked={!!user?.profilePreferences?.show?.birthday}
                label="Show birthday on profile"
                stroke="#E0115F"
                onChange={handleShowbirthdayChange}
              />
            </InputContainer>
            <InputContainer>
              <Input
                {...register("website")}
                defaultValue={user?.website?.toString()}
                invalid={!!errors.website}
                label={strings.generic.website}
                type="text"
                onChange={() => setUnsavedChanges(true)}
              />
              {errors.website && (
                <FieldValidationMessage>
                  {errors.website.message}
                </FieldValidationMessage>
              )}
            </InputContainer>
            <InputContainer>
              <Textarea
                {...register("bio")}
                defaultValue={user?.bio?.toString()}
                invalid={!!errors.bio}
                label={strings.generic.bio}
              />
              {errors.bio && (
                <FieldValidationMessage>
                  {errors.bio.message}
                </FieldValidationMessage>
              )}
            </InputContainer>
          </FieldsContaner>
          <ProfileEditSaveButton
            error={!!updateError || reservationError}
            // eslint-disable-next-line react-extra/no-inline-styles -- MAR-844
            style={{top: "950px", right: "120px"}}
            onSave={handleSubmit(handleOnSave)}
          />
        </>
      )}

      {!isLoading && !isUpdating && displayUsernameForm && (
        <ChangeUsername
          currentUserId={user?.uuid || ""}
          hideForm={handleHideForm}
          initialState={usernameUpdate.username || ""}
          updateError={usernameUpdateError}
          usernameReverted={usernameUpdate.usernameReverted}
          usernameUpdated={usernameUpdate.usernameUpdated}
          onError={handleReservationError}
          onSave={handleUsernameSave}
        />
      )}
      <Modal
        cancelBtnLabel={strings.generic.continueEditing}
        closeModalFn={() => setOpenModal(false)}
        heading={strings.generic.cancelEditing}
        isDialog={true}
        isMessage={false}
        isOpen={openModal}
        saveBtnLabel={strings.generic.discardChanges}
        saveFn={() => {
          setUnsavedChanges(false);
          history.push(currentPath, false);
        }}
        subheading={strings.pages.profile.unsavedChanges}
      />
    </Container>
  );
};

export default ProfileEditContainer;
