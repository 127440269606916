import React, {useCallback} from "react";
import {
  components,
  OptionProps,
  SingleValueProps,
  MultiValueProps,
  ClearIndicatorProps,
  MultiValueGenericProps,
  MenuListProps
} from "react-select";
import ClearButton from "components/Atoms/ClearButton";

import {DropdownProps} from "./types";

export function useDropdown<T>({
  OptionComponent,
  MenuListComponent
}: DropdownProps<T>) {
  const Option = useCallback((props: OptionProps<any>) => {
    const data = {...props, ...props.data};
    return (
      <components.Option {...props}>
        <OptionComponent {...data} isOption={true} />
      </components.Option>
    );
  }, []);

  const SingleValue = useCallback((props: SingleValueProps<any>) => {
    const data = {...props, ...props.data};
    return (
      <components.SingleValue {...props}>
        <OptionComponent {...data} isOption={false} />
      </components.SingleValue>
    );
  }, []);

  const MultiValue = useCallback((props: MultiValueProps<any>) => {
    const data = {...props, ...props.data};
    return (
      <components.MultiValue {...props}>
        <OptionComponent {...data} isOption={false} isMulti />
      </components.MultiValue>
    );
  }, []);

  const ClearIndicator = useCallback((props: ClearIndicatorProps<any>) => {
    return (
      <components.ClearIndicator {...props}>
        <ClearButton clearValue={props.clearValue} />
      </components.ClearIndicator>
    );
  }, []);

  const MenuList = useCallback((props: MenuListProps<any>) => {
    if (!MenuListComponent) {
      return <components.MenuList {...props} />;
    }

    return (
      <components.MenuList {...props}>
        <MenuListComponent {...props} />
      </components.MenuList>
    );
  }, []);

  const MultiValueRemove = useCallback(() => null, []);
  const MultiValueContainer = useCallback(
    ({children}: MultiValueGenericProps<any>) => {
      return <>{children}</>;
    },
    []
  );

  return {
    Option,
    SingleValue,
    MultiValue,
    ClearIndicator,
    MenuList,
    MultiValueContainer,
    MultiValueRemove
  };
}
