import styled from "styled-components";
import {breakpointNames, media} from "theme/media";

export const RootContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const DataContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-width: 1000px;
  align-self: center;
`;

export const BottomContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  ${media.lessThan(breakpointNames.tablet)`
    flex-direction: column;
    align-items: center;
  `}
`;
