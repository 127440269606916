import React from "react";
import {InvisibleCheckbox, RootContainer, Slider} from "./styles";
import {ToggleProps} from "./types";

export const Toggle: React.FC<ToggleProps> = ({
  checked,
  onChange,
  disabled
}: ToggleProps) => {
  return (
    <RootContainer>
      <InvisibleCheckbox
        disabled={disabled}
        type="checkbox"
        onChange={onChange}
        checked={checked}
      />
      <Slider disabled={disabled} />
    </RootContainer>
  );
};

export default React.memo(Toggle);
