import React from "react";
import Text from "components/Atoms/Text";
import {strings} from "config/strings";
import {TextTypes} from "components/Atoms/Text/types";
import Home from "icons/home.svg";
import User from "icons/user.svg";
import Plus from "icons/plus.svg";

import {VideoUploadSuccessProps} from "./types";
import {Container, GrayText, NavigationItem} from "./styles";
import {useVideoUploadSuccess} from "./hooks";

const VideoUploadSuccess: React.FC<VideoUploadSuccessProps> = ({
  uploadAnotherVideoOnClick
}: VideoUploadSuccessProps) => {
  const {homeOnClick, userOnClick} = useVideoUploadSuccess();

  return (
    <Container>
      <Text textType={TextTypes.Headings.H2}>
        {strings.pages.upload.headingSuccess}
      </Text>
      <GrayText textType={TextTypes.SubHeadings.SH3}>
        {strings.pages.upload.subheadingSuccess.one}
        <br />
        {strings.pages.upload.subheadingSuccess.two}
      </GrayText>

      <div>
        <NavigationItem
          button={strings.pages.upload.successPlusSection.button}
          caption={strings.pages.upload.successPlusSection.caption}
          icon={Plus}
          onClick={uploadAnotherVideoOnClick}
        />

        <NavigationItem
          button={strings.pages.upload.successHomeSection.button}
          caption={strings.pages.upload.successHomeSection.caption}
          icon={Home}
          onClick={homeOnClick}
        />

        <NavigationItem
          button={strings.pages.upload.successUserSection.button}
          caption={strings.pages.upload.successUserSection.caption}
          icon={User}
          onClick={userOnClick}
        />
      </div>
    </Container>
  );
};

export default React.memo(VideoUploadSuccess);
