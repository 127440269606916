import {SettingsContainer} from "components/Settings/SettingsContainer";
import {useParams} from "react-router-dom";

function SettingsPage() {
  const {uuid} = useParams<{uuid: string}>();

  return <SettingsContainer uuid={uuid} />;
}

export default SettingsPage;
