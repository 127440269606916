import {useCallback, useEffect, useRef, useState} from "react";
import {ThumbnailType} from "components/Organisms/ThumbnailForm/types";
import {SCRUBBER_INITIAL_END, SCRUBBER_INITIAL_START} from "config/constants";
import {useGenerateThumbnailFromVideoLazyQuery} from "apollo";

const CLIPPING_TOOL_SAVE_TIMEOUT = 5000;

export function useWeb3UploadVideoAndThumbnail(
  externalId: string,
  setIsThumbnailValid: (isValid: boolean) => void
) {
  const [isVideoUploaded, setIsVideoUploaded] = useState<boolean>(false);
  const [clippingSaved, setClippingSaved] = useState(false);
  const [thumbnailType, setThumbnailType] = useState<ThumbnailType>(
    ThumbnailType.Clipping
  );
  // clipping tool
  const [startTime, setStartTime] = useState<number>(SCRUBBER_INITIAL_START);
  const [endTime, setEndTime] = useState<number>(SCRUBBER_INITIAL_END);
  // thumbnail upload
  const [isThumbnailUploaded, setIsThumbnailUploaded] =
    useState<boolean>(false);
  const updateClippingDataTimeoutId = useRef<NodeJS.Timeout>();

  const onUploadCompleted = useCallback(() => {
    setIsVideoUploaded(true);
  }, []);

  const [updateClippingData, {loading: clippingLoading}] =
    useGenerateThumbnailFromVideoLazyQuery({
      onCompleted: () => {
        setClippingSaved(true);
      }
    });

  const updateClippingDataOnTimeout = useCallback(() => {
    const clippingPayload = {
      externalId,
      start: startTime,
      end: endTime
    };
    if (thumbnailType !== ThumbnailType.Clipping || !isVideoUploaded) {
      return;
    }

    setClippingSaved(false);

    updateClippingData({variables: clippingPayload});
    updateClippingDataTimeoutId.current = setTimeout(
      updateClippingDataOnTimeout,
      CLIPPING_TOOL_SAVE_TIMEOUT
    );
  }, [
    endTime,
    externalId,
    isVideoUploaded,
    startTime,
    thumbnailType,
    updateClippingData
  ]);

  useEffect(() => {
    if (thumbnailType !== ThumbnailType.Clipping || !isVideoUploaded) {
      return;
    }

    if (updateClippingDataTimeoutId.current) {
      clearTimeout(updateClippingDataTimeoutId.current);
    }

    updateClippingDataTimeoutId.current = setTimeout(
      updateClippingDataOnTimeout,
      CLIPPING_TOOL_SAVE_TIMEOUT
    );
  }, [
    endTime,
    externalId,
    isVideoUploaded,
    startTime,
    thumbnailType,
    updateClippingData,
    updateClippingDataOnTimeout
  ]);

  // watch relevant query success states and call success when everything is done
  useEffect(() => {
    if (isThumbnailUploaded || clippingSaved) {
      setIsThumbnailValid(true);
    }
  }, [clippingSaved, isThumbnailUploaded, setIsThumbnailValid]);

  return {
    clippingLoading,
    endTime,
    isThumbnailUploaded,
    isVideoUploaded,
    onUploadCompleted,
    setEndTime,
    setIsThumbnailUploaded,
    setStartTime,
    setThumbnailType,
    startTime,
    thumbnailType
  };
}
