import styled from "styled-components";
import {colors} from "theme/colors";

export const RootContainer = styled.label`
  position: relative;
`;

export const InvisibleCheckbox = styled.input`
  position: absolute;
  left: -9999px;

  &:checked + span {
    background-color: ${colors.primary.cyan50};

    &:before {
      left: calc(100% - 2px);
      transform: translateX(-100%);
      background-color: ${colors.primary.cyan};
    }
  }
`;

export const Slider = styled.span<{disabled?: boolean}>`
  display: flex;
  cursor: pointer;
  width: 40px;
  height: 24px;
  background-color: ${({disabled}) =>
    disabled ? colors.primary.white10 : colors.primary.lightGrey};
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;

  &:before {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border-radius: 22px;
    transition: 0.2s;
    background-color: ${({disabled}) =>
      disabled ? colors.primary.white16 : colors.primary.white};
  }

  &:active:before {
    width: 25px;
  }
`;
