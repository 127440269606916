import React from "react";
import styled from "styled-components";
import Text from "components/Atoms/Text";
import {strings} from "config/strings";

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BackgroundGradient = styled.div`
  background: linear-gradient(
    27deg,
    rgba(242, 134, 252, 0.33),
    rgba(0, 133, 255, 0) 33%
  );
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const SmallerRing = styled.img`
  position: absolute;
  width: 50%;
  top: 23%;
  left: 25%;
`;

export const BiggerRing = styled.img`
  position: absolute;
  width: 100%;
`;

export const Rings = styled.div`
  position: absolute;
  top: -5%;
  left: 17%;
  width: 40%;
  aspect-ratio: 1;
  z-index: -1;
`;

export const Logo = styled.img`
  width: 28%;
  position: absolute;
  right: 0px;
  bottom: 108px;
  z-index: -1;
`;

const fontSize = 56;
const lineHeight = 76;

export function TitleSvg() {
  return (
    <svg
      width="350px"
      height={`${lineHeight}px`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="gradient" y1="0" y2="1">
          <stop stopColor="rgba(83, 110, 241, 1)" offset="0" />
          <stop stopColor="rgba(249, 128, 255, 1)" offset="1" />
        </linearGradient>
      </defs>
      <g>
        <text
          id="text"
          y={lineHeight - (lineHeight - fontSize) / 2}
          strokeWidth="1"
          stroke="url(#gradient)"
          fill="none"
        >
          {strings.pages.marketplace.discover}
        </text>
      </g>
    </svg>
  );
}

export const GradientText = styled(Text)`
  margin-top: 9%;
`;

export const Divider = styled.div`
  margin-top: 44px;
  width: 244px;
  height: 8px;
  background: linear-gradient(
    90deg,
    #5b9bd5 0%,
    #7dddbb 23.44%,
    #f981ff 47.92%,
    #a77cd2 73.44%,
    #7030a0 100%
  );
`;

export const Description = styled(Text)`
  margin-top: 44px;
  white-space: pre-wrap;
  text-align: center;
`;

export const MarketplaceText = styled(Text)`
  margin-top: 175px;
`;
