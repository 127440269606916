import styled from "styled-components";
import {colours} from "config/styleVars";

export const RootContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${colours.dark};
`;

export const StyledImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
