import React from "react";
import Text from "components/Atoms/Text";
import {TextTypes} from "components/Atoms/Text/types";
import {strings} from "config/strings";

import {colors} from "theme/colors";
import {AlgoliaInfiniteHitsPops} from "./types";
import {useAlgoliaInfiniteHits} from "./hooks";
import {
  GrayText,
  ListContainer,
  NoResultsContainer,
  RootContainer,
  TotalContainer,
  SearchIcon
} from "./styles";

const AlgoliaInfiniteHits: React.FC<AlgoliaInfiniteHitsPops> = (
  props: AlgoliaInfiniteHitsPops
) => {
  const {hitComponent: HitComponent} = props;
  const {hits, query, sentinelRef, totalHits} = useAlgoliaInfiniteHits(props);

  return (
    <RootContainer>
      {query && hits.length > 0 && (
        <TotalContainer>
          <Text textType={TextTypes.Subtitle.LStrong}>
            {strings.search.results(totalHits)}
          </Text>
        </TotalContainer>
      )}
      {query && hits.length === 0 && (
        <NoResultsContainer>
          <SearchIcon stroke={colors.primary.white} strokeWidth={1.5} />
          <Text textType={TextTypes.Titles.H1Light}>
            {strings.search.noResults}
          </Text>
          <GrayText textType={TextTypes.Other.LinkLabelM}>
            {strings.search.noResultsQuery(query)}
          </GrayText>
        </NoResultsContainer>
      )}
      <ListContainer>
        {query &&
          hits.map((hit) => (
            <div key={hit.objectID}>
              <HitComponent hit={hit} />
            </div>
          ))}
        <div ref={sentinelRef} />
      </ListContainer>
    </RootContainer>
  );
};

export default React.memo(AlgoliaInfiniteHits);
