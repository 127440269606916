import styled from "styled-components";
import Text from "components/Atoms/Text";
import Button from "components/Atoms/Button";
import {colors} from "theme/colors";
import TextInput from "components/Atoms/TextInput";
import InputTag from "components/Molecules/InputTag";
import InfoDialog from "components/Atoms/InfoDialog";
import MetaItemContainer from "components/Molecules/MetaItemContainer";
import {Checkbox} from "components/Checkbox";

export const VideoIframe = styled.iframe`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
`;

export const VideoContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  margin-bottom: 20px;
`;

export const RootContainer = styled.div`
  width: 100%;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const ContentContainer = styled.div`
  max-width: 598px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const RequiredFieldsText = styled(Text)`
  margin-left: 12px;
  margin-bottom: 4px;
  color: ${colors.primary.lightGrey};

  > span {
    color: ${colors.primary.cyan};
  }
`;

export const LinkButton = styled.a`
  color: ${colors.primary.cyan};
  margin-top: 16px;
  text-decoration: underline;
  text-underline-offset: 6px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const SubtitleText = styled(Text)`
  margin-top: 24px;
  margin-bottom: 4px;
  > span {
    color: ${colors.primary.cyan};
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
`;

export const StyledInfoDialog = styled(InfoDialog)`
  margin-top: 1px;
  margin-left: 4px;
`;

export const DescriptionText = styled(Text)`
  margin-bottom: 8px;
  color: ${colors.primary.lightGrey};
  white-space: pre-line;
  display: block;

  > span {
    color: ${colors.primary.white};
    margin-right: 4px;
  }
`;

export const Input = styled(TextInput)`
  width: 100%;
`;

export const Tags = styled(InputTag)`
  width: 100%;
  margin-bottom: 40px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 160px;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const UploadButton = styled(Button)`
  margin-left: 16px;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.primary.darkGrey};
`;

export const TokenHolderOptions = styled(MetaItemContainer)`
  margin-top: 40px;
`;

export const IconButton = styled(Button)``;

export const TermsOfServiceDescription = styled(Text)`
  color: ${colors.primary.lightGrey};
  a {
    color: ${colors.primary.cyan};
    text-decoration: none;
    cursor: pointer;
  }
`;

export const TermsOfServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CheckboxStyled = styled(Checkbox)`
  margin-right: 14px;
`;
