import React from "react";
import UploadIcon from "icons/40/upload-cloud.svg";
import {TextTypes} from "components/Atoms/Text/types";
import {strings} from "config/strings";

import {DropzoneProps} from "./types";
import {useDropzoneHook} from "./hooks";
import {
  Container,
  InnerContainer,
  PreviewImage,
  Subtitle,
  Title
} from "./styles";

const Dropzone: React.FC<DropzoneProps> = ({
  config,
  invalid,
  label,
  shouldOpenOnMount,
  shouldHideUI,
  onCancel
}: DropzoneProps) => {
  const {aspectRatio, height, width} = config;
  const {file, getInputProps, getRootProps} = useDropzoneHook(
    config,
    shouldOpenOnMount,
    onCancel
  );

  return (
    <Container
      {...getRootProps()}
      aria-label="File Upload"
      aspectRatio={aspectRatio}
      error={invalid}
      height={height}
      role="button"
      width={width}
    >
      <input {...getInputProps()} />
      <InnerContainer>
        {file && (
          <PreviewImage
            alt={file.name}
            key={file.name}
            src={URL.createObjectURL(file)}
          />
        )}

        {!file && !shouldHideUI && (
          <>
            <img alt="Upload Icon" src={UploadIcon} />
            <Title textType={TextTypes.SubHeadings.SH3}>{label}</Title>
            <Subtitle textType={TextTypes.Body}>
              {strings.dropzone.or}
              <span>{strings.dropzone.browse}</span>
              {strings.dropzone.toChoose}
            </Subtitle>
          </>
        )}
      </InnerContainer>
    </Container>
  );
};

export default React.memo(Dropzone);
