import * as Yup from "yup";

import {PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH} from "config/constants";
import {strings} from "config/strings";

export function getEmailValidator() {
  return Yup.string()
    .email(strings.auth.emailInvalid)
    .required(strings.auth.emailRequired);
}

const lowercaseRegExp = new RegExp("(?=.*[a-z])");
const uppercaseRegExp = new RegExp("(?=.*[A-Z])");
const numberRegExp = new RegExp("(?=.*[0-9])");

export function getPasswordValidator() {
  return Yup.string()
    .min(PASSWORD_MIN_LENGTH, strings.auth.passwordLength(PASSWORD_MIN_LENGTH))
    .max(PASSWORD_MAX_LENGTH, strings.auth.passwordError)
    .matches(lowercaseRegExp, strings.auth.passwordError)
    .matches(uppercaseRegExp, strings.auth.passwordError)
    .matches(numberRegExp, strings.auth.passwordError);
}

export function getPasswordConfirmValidator() {
  return Yup.string().oneOf(
    [Yup.ref("password")],
    strings.auth.passwordMismatch
  );
}

export function getCodeValidator() {
  return Yup.string()
    .length(6)
    .matches(/[0-9]{6}/gi)
    .required();
}

export function getSignInValidators() {
  return Yup.object().shape({
    username: getEmailValidator(),
    password: getPasswordValidator()
  });
}

export function getSignUpValidators() {
  return Yup.object().shape({
    username: getEmailValidator(),
    password: getPasswordValidator(),
    age: Yup.bool().isTrue()
  });
}

export function getResetPasswordConfirmValidators() {
  return Yup.object().shape({
    code: getCodeValidator(),
    password: getPasswordValidator()
  });
}
