import React from "react";
import {VideoMetadataProps} from "./types";

export function useVideoMetadata({registerInput}: VideoMetadataProps) {
  const titleOnBlur = registerInput(
    "title",
    (e: React.FocusEvent<HTMLInputElement>) => e.target.value
  );

  const descriptionOnBlur = registerInput(
    "description",
    (e: React.FocusEvent<HTMLInputElement>) => e.target.value
  );

  const tagsOnChange = registerInput("tags", (tags: string[]) => {
    return tags.join(",");
  });

  return {titleOnBlur, descriptionOnBlur, tagsOnChange};
}
