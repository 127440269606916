import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {strings} from "config/strings";
import {Auth} from "aws-amplify";
import {Routes} from "config/routes";
import {
  FieldValidationMessage,
  ResponseMessage,
  ResponseMessageTypes,
  InlineTextButton
} from "components/StyledElements/FormElements";
import VerificationCodeInput from "components/VerificationCodeInput/VerificationCodeInput";
import InputFloatLabel from "components/InputFloatLabel/InputFloatLabel";
import {Spinner} from "components/Spinner";
import {
  AuthWrapper,
  AuthHeading,
  AuthSubHeading,
  AuthButton
} from "components/Auth/AuthElements";
import {
  authErrorTranslate,
  validatePassword,
  emailRegex
} from "components/Auth/AuthUtils";

type Props = {
  emailSentSettings: boolean;
  emailSettings: string;
};

const ForgotPasswordForm: React.FunctionComponent<Props> = ({
  emailSentSettings,
  emailSettings
}: Props) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<string | undefined>();
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState<string | undefined>();
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState<
    string | undefined
  >();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    string | undefined
  >();
  const [formError, setFormError] = useState<any>();

  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.currentTarget.value);
  }

  function handleCodeChange(value: string) {
    setCode(value);
  }

  function handleNewPasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    setNewPassword(e.currentTarget.value);
  }

  function handleConfirmPasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    setConfirmPassword(e.currentTarget.value);
  }

  function clearErrors() {
    setEmailError(undefined);
    setCodeError(undefined);
    setNewPasswordError(undefined);
    setConfirmPasswordError(undefined);
    setFormError(undefined);
  }

  function clearAllFields() {
    setEmail("");
    setCode("");
    setNewPassword("");
    setConfirmPassword("");
  }

  function handleGoBack() {
    setEmailSent(false);
    clearErrors();
    clearAllFields();
  }

  function validateEmail() {
    let isValid = true;
    if (!email || email.trim().length <= 0) {
      isValid = false;
      setEmailError(strings.auth.emailRequired);
    } else if (!emailRegex.test(email)) {
      isValid = false;
      setEmailError(strings.auth.emailInvalid);
    }
    return isValid;
  }

  function validateNewPasswordForm() {
    let isValid = true;
    if (!code || code.trim().length !== 6) {
      isValid = false;
      setCodeError(strings.auth.verificationCodeRequired);
    }
    if (!newPassword.trim()) {
      isValid = false;
      setNewPasswordError(strings.auth.newPasswordRequired);
    }
    if (!confirmPassword.trim()) {
      isValid = false;
      setConfirmPasswordError(strings.auth.confirmPasswordRequired);
    }
    if (newPassword !== confirmPassword) {
      isValid = false;
      setConfirmPasswordError(strings.auth.passwordMismatch);
    }
    const validPassResult = validatePassword(newPassword);
    if (!validPassResult.isValid) {
      isValid = validPassResult.isValid ? isValid : validPassResult.isValid;
      setNewPasswordError(validPassResult.message);
    }
    return isValid;
  }

  async function handleSubmit(
    e: React.FormEvent<HTMLFormElement | HTMLButtonElement>
  ) {
    e.preventDefault();
    clearErrors();
    const isValid = validateEmail();
    if (!isValid) {
      // noop
    } else {
      setLoading(true);
      try {
        await Auth.forgotPassword(email);
        setEmailSent(true);
      } catch (error: any) {
        setFormError(error.message);
      }
      setLoading(false);
    }
  }

  async function handleNewPasswordSubmit(
    e: React.FormEvent<HTMLFormElement | HTMLButtonElement>
  ) {
    e.preventDefault();
    clearErrors();
    const isValid = validateNewPasswordForm();
    if (!isValid) {
      // noop
    } else {
      setLoading(true);
      try {
        await Auth.forgotPasswordSubmit(
          email || emailSettings,
          code,
          newPassword
        );
        history.push(`${Routes.signin}?from=ResetPassword`);
      } catch (error: any) {
        setFormError(authErrorTranslate(error.message));
      }
      setLoading(false);
    }
  }

  function renderEmailForm() {
    return (
      <form onSubmit={handleSubmit}>
        <AuthHeading>{strings.auth.forgotPassHeading}</AuthHeading>
        <AuthSubHeading>{strings.auth.forgotPassSubHeading}</AuthSubHeading>
        {formError && (
          <ResponseMessage
            message={formError}
            type={ResponseMessageTypes.Error}
          />
        )}
        <div>
          <InputFloatLabel
            invalid={!!emailError}
            label={strings.generic.email}
            type="text"
            onBlur={clearErrors}
            onChange={handleEmailChange}
            onFocus={clearErrors}
          />
          {emailError && (
            <FieldValidationMessage>{emailError}</FieldValidationMessage>
          )}
        </div>
        <AuthButton onClick={handleSubmit}>
          {strings.auth.emailCodeButton}
        </AuthButton>
      </form>
    );
  }

  function renderNewPasswordForm() {
    return (
      <form onSubmit={handleNewPasswordSubmit}>
        <AuthHeading>{strings.auth.resetPassword}</AuthHeading>
        <AuthSubHeading>{strings.auth.resetPasswordSubHeading}</AuthSubHeading>
        {formError && (
          <ResponseMessage
            message={formError}
            type={ResponseMessageTypes.Error}
          />
        )}
        <div>
          <VerificationCodeInput
            fieldCount={6}
            invalid={!!codeError}
            onComplete={handleCodeChange}
            onFocus={clearErrors}
          />
          {codeError && (
            <FieldValidationMessage>{codeError}</FieldValidationMessage>
          )}
        </div>
        <div
          // eslint-disable-next-line react-extra/no-inline-styles -- MAR-844
          style={{
            textAlign: "right",
            fontSize: "13px",
            marginBottom: "10px"
          }}
        >
          {strings.auth.noCode}{" "}
          <InlineTextButton type="button" onClick={handleGoBack}>
            {strings.generic.goBack}
          </InlineTextButton>
        </div>
        <div>
          <InputFloatLabel
            invalid={!!newPasswordError}
            key="newpassword"
            label={strings.auth.newPassword}
            type="password"
            onBlur={clearErrors}
            onChange={handleNewPasswordChange}
          />
          {newPasswordError && (
            <FieldValidationMessage>{newPasswordError}</FieldValidationMessage>
          )}
        </div>
        <div>
          <InputFloatLabel
            invalid={!!confirmPasswordError}
            key="confirmpass"
            label={strings.auth.confirmPassword}
            type="password"
            onBlur={clearErrors}
            onChange={handleConfirmPasswordChange}
          />
          {confirmPasswordError && (
            <FieldValidationMessage>
              {confirmPasswordError}
            </FieldValidationMessage>
          )}
        </div>
        <AuthButton onClick={handleNewPasswordSubmit}>
          {strings.auth.resetPassword}
        </AuthButton>
      </form>
    );
  }

  return (
    <AuthWrapper isLoading={loading}>
      {loading && <Spinner />}
      {emailSent || emailSentSettings
        ? renderNewPasswordForm()
        : renderEmailForm()}
    </AuthWrapper>
  );
};

export default ForgotPasswordForm;
