import React from "react";
import Text from "components/Atoms/Text";
import ThumbnailForm from "components/Organisms/ThumbnailForm";
import {strings} from "config/strings";
import Web3VideoUpload from "components/Organisms/Web3VideoUpload";
import {TextTypes} from "components/Atoms/Text/types";

import {Web3UploadVideoAndThumbnailProps} from "./types";
import {BottomContainer, DataContainer, RootContainer} from "./styles";
import {useWeb3UploadVideoAndThumbnail} from "./hooks";

const Web3UploadVideoAndThumbnail: React.FC<Web3UploadVideoAndThumbnailProps> =
  ({
    externalId,
    setExternalId,
    setIsThumbnailValid,
    uuid,
    video
  }: Web3UploadVideoAndThumbnailProps) => {
    const {
      endTime,
      isVideoUploaded,
      onUploadCompleted,
      setEndTime,
      setIsThumbnailUploaded,
      setStartTime,
      setThumbnailType,
      startTime,
      thumbnailType
    } = useWeb3UploadVideoAndThumbnail(externalId, setIsThumbnailValid);

    return (
      <RootContainer>
        <DataContainer>
          <Web3VideoUpload
            onUploadCompleted={onUploadCompleted}
            setExternalId={setExternalId}
            video={video}
          />
          <Text textType={TextTypes.Titles.H4}>
            {strings.pages.upload.createThumbnail}
          </Text>
          <BottomContainer>
            <ThumbnailForm
              endTime={endTime}
              externalId={externalId}
              isVideoUploaded={isVideoUploaded}
              setEndTime={setEndTime}
              setIsThumbnailUploaded={setIsThumbnailUploaded}
              setStartTime={setStartTime}
              setThumbnailType={setThumbnailType}
              startTime={startTime}
              thumbnailType={thumbnailType}
              uuid={uuid}
              video={video}
            />
          </BottomContainer>
        </DataContainer>
      </RootContainer>
    );
  };

export default React.memo(Web3UploadVideoAndThumbnail);
