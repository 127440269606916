import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {textGray, formStyles} from "config/styleVars";
import {StyledInput} from "components/StyledElements/FormElements";

type WrapperProps = {
  invalid?: boolean;
  styles?: Record<string, string | number>;
};

type FloatLabelProps = {
  float: boolean;
  invalid?: boolean;
};

type FloatInputProps = {
  float: boolean;
  maxChars?: number;
  prefix?: string;
  isFocused?: boolean;
};

type WithShowProp = {
  show: boolean;
};

type CharCountProps = {
  show: boolean;
  count: number;
  max: number;
};

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  position: relative;
  margin-bottom: ${(props) => (props.invalid ? 0 : formStyles.marginBottom)};
`;

const Label = styled.label<FloatLabelProps>`
  position: absolute;
  pointer-events: none;
  left: 14px;
  top: ${(props) => (props.float ? "2px" : "10px")};
  font-size: ${(props) => (props.float ? "13px" : "16px")};
  color: ${textGray};
  transition: all 100ms ease;
`;

const FLTextInput = styled(StyledInput)<FloatInputProps>`
  margin-bottom: 0;
  padding: ${(props) => (props.float ? "21px 14px 7px 14px" : "14px")};
  padding-right: ${(props) => (props.maxChars ? "50px" : "14px")};
  padding-left: ${(props) => (props.prefix ? "27px" : "14px")};
`;

const CharCount = styled.div<CharCountProps>`
  font-size: 0.8em;
  opacity: ${(props) => (props.count >= props.max ? "1" : "0.5")};
  position: absolute;
  right: 8px;
  bottom: 5px;
  display: ${(props) => (props.show ? "block" : "none")};
  color: ${(props) => (props.count >= props.max ? "red" : "white")};
`;

const Prefix = styled.div<WithShowProp>`
  position: absolute;
  left: 14px;
  font-size: 16px;
  text-align: right;
  top: 16px;
  width: 13px;
  display: ${(props) => (props.show ? "block" : "none")};
`;

type InputFloatLabelProps = {
  label: string;
  type: string;
  initialValue?: string;
  invalid?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;

  onFocus?: () => void;

  onBlur?: () => void;
  style?: Record<string, unknown>;
  maxChars?: number;
  prefix?: string;
};

const InputFloatLabel = ({
  initialValue,
  onChange,
  onBlur,
  onFocus,
  type,
  invalid,
  maxChars,
  prefix,
  label,
  style
}: InputFloatLabelProps): JSX.Element => {
  const [value, setValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.currentTarget.value);
    setCharCount(e.currentTarget.value.length);
    if (onChange) {
      onChange(e);
    }
  }

  function handleFocus() {
    setIsFocused(true);
    if (onFocus) {
      onFocus();
    }
  }

  function handleBlur() {
    setIsFocused(false);
    if (onBlur) {
      onBlur();
    }
  }

  function shouldFloat(newType: string): boolean {
    if (newType === "date") {
      return true;
    }
    return isFocused || value !== "";
  }

  return (
    <Wrapper invalid={!!invalid} style={style}>
      <Label float={shouldFloat(type)} invalid={!!invalid}>
        {label}
      </Label>
      {prefix ? <Prefix show={shouldFloat(type)}>{prefix}</Prefix> : ""}
      <FLTextInput
        float={shouldFloat(type)}
        invalid={!!invalid}
        maxChars={maxChars}
        prefix={prefix}
        type={type}
        value={initialValue}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
      />
      {maxChars ? (
        <CharCount count={charCount} max={maxChars} show={isFocused}>
          {charCount}/{maxChars}
        </CharCount>
      ) : (
        ""
      )}
    </Wrapper>
  );
};

export default InputFloatLabel;
