import React, {ReactChild, ReactChildren} from "react";
import ReactModal from "react-modal";
import {TextTypes} from "components/Atoms/Text/types";
import Button from "components/Atoms/Button";
import {ButtonSize, ButtonType} from "components/Atoms/Button/types";

import {
  Header,
  Body,
  Footer,
  CloseButton,
  StyledCloseIcon,
  modalStyles,
  Title,
  IconContainer,
  TitleContainer
} from "./styles";

export interface InputModalProps {
  isOpen: boolean;
  contentLabel?: string;
  title: string;
  className?: string;
  children: ReactChild | ReactChildren;
  disableSubmit?: boolean;
  headerIcon?: ReactChild | ReactChildren | React.FC;
  disableBodyPadding?: boolean;
  submitText: string;
  shouldCloseOnOverlayClick?: boolean;
  onSubmit: () => void;
  onClose: () => void;
  width?: string;
}

export default function InputModal({
  isOpen,
  title,
  disableSubmit,
  children,
  headerIcon,
  onClose,
  onSubmit,
  submitText,
  disableBodyPadding,
  contentLabel,
  shouldCloseOnOverlayClick,
  width
}: InputModalProps): React.ReactElement<InputModalProps> {
  modalStyles.content.width = width || "636px";
  return (
    <ReactModal
      contentLabel={contentLabel}
      isOpen={isOpen}
      preventScroll={true}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={onClose}
      style={modalStyles}
    >
      <Header>
        <TitleContainer>
          {headerIcon && <IconContainer>{headerIcon}</IconContainer>}
          <Title textType={TextTypes.Titles.H4}>{title}</Title>
        </TitleContainer>
        <CloseButton onClick={onClose}>
          <StyledCloseIcon />
        </CloseButton>
      </Header>
      <Body disableBodyPadding={disableBodyPadding}>{children}</Body>
      <Footer>
        <Button
          onClick={onSubmit}
          disabled={disableSubmit}
          size={ButtonSize.xl}
          text={submitText}
          type={ButtonType.primary}
        />
      </Footer>
    </ReactModal>
  );
}
