import {useEffect, useState, MouseEvent, useRef} from "react";

import PlayIcon from "icons/transparent-play.svg";
import PauseIcon from "icons/pause.svg";
import MutedIcon from "icons/24/volume-x.svg";
import NotMutedIcon from "icons/24/volume-2.svg";
import TimePreviewIcon from "icons/TimePreview.svg";
import {FullScreenButton} from "components/Button/FullScreenButton";
import {formatTimeStamp} from "utils/FormatUtils";
import canAutoplay from "can-autoplay";
import {
  Container,
  CurrentTimeLabel,
  IconContainer,
  FullScreenContainer,
  PauseButton,
  PlayButton,
  ProgressBarContainer,
  ProgressBar,
  ProgressPointer,
  TimePreviewContainer,
  TimePreviewText,
  VolumeBar,
  VolumeButton
} from "./PlayerControlsStyles";

type PlayerControlsProp = {
  duration: number | undefined;
  currentTime: number;
  isPlaying: boolean | undefined;
  isMuted: boolean | undefined;
  volume: number | undefined;
  onUpdateCurrentTime: (currentTime: number) => void;
  onTogglePlayerState: () => void;
  onUpdateVolume: (volume: number) => void;
  onMuteToggle: () => void;
};

const PlayerControls = ({
  duration,
  currentTime,
  isPlaying,
  isMuted,
  volume,
  onUpdateCurrentTime,
  onMuteToggle,
  onTogglePlayerState,
  onUpdateVolume
}: PlayerControlsProp) => {
  const [progressBarWitdh, setProgressBarWitdh] = useState(0);
  const [volumeBarWitdh, setVolumeBarWitdh] = useState(0);
  const [durationTimeMinutes, setDurationTimeMinutes] = useState<string>();
  const [timePreview, setTimePreview] = useState("");
  const [popupPosition, setPopupPosition] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const progressRef = useRef<HTMLDivElement>(null);
  const volumeBarRef = useRef<HTMLDivElement>(null);
  const [mouseDown, setMouseDown] = useState(false);
  const [mouseDownAudio, setMouseDownAudio] = useState(false);

  const setProgressBar = (time: number) => {
    const percentage = (time / (duration || 0)) * 100;
    setProgressBarWitdh(percentage);
  };

  useEffect(() => {
    setProgressBar(currentTime);
    const timespan = (duration || 0).toString();
    const formattedDurationTime = formatTimeStamp(timespan);
    setDurationTimeMinutes(formattedDurationTime);
    setVolumeBarWitdh((volume || 0) * 100);
  }, [currentTime, duration]);

  const handleOnClick = (event: MouseEvent<HTMLDivElement>) => {
    const offsetWidth = progressRef.current?.offsetWidth || 0;
    const timeNow = (event.nativeEvent.offsetX / offsetWidth) * (duration || 0);
    setProgressBar(timeNow);
    onUpdateCurrentTime(timeNow);
  };

  const handleMouseInAndOut = () => {
    setShowPopup(!showPopup);
  };

  const handleMouseOverForPointer = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    const offsetWidth = event.currentTarget.parentElement?.offsetWidth || 0;
    const progressBarWidth =
      event.currentTarget.previousElementSibling?.scrollWidth || 0;
    const width = event.nativeEvent.offsetX + progressBarWidth;
    const timeNow = (width / offsetWidth) * (duration || 0);
    const formattedTime = formatTimeStamp(timeNow.toString());
    setTimePreview(formattedTime);

    setPopupPosition(width - 18);
  };

  const handleOnMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();

    const offsetWidth = progressRef.current?.offsetWidth || 1080;
    const offsetLeft = progressRef.current?.offsetLeft || 78;

    if (
      event.nativeEvent.clientX - 96 > offsetWidth ||
      event.nativeEvent.clientX < offsetLeft
    ) {
      return;
    }

    const timeNow =
      ((event.nativeEvent.clientX - 86) / offsetWidth) * (duration || 0);
    const formattedTime = formatTimeStamp(timeNow.toString());

    setTimePreview(formattedTime);
    setPopupPosition(event.nativeEvent.clientX - 96);

    if (mouseDown) {
      setProgressBar(timeNow);
      onUpdateCurrentTime(timeNow);
    }
  };

  const handleOnMouseDownAudio = (event: MouseEvent<HTMLDivElement>) => {
    if (isMuted) {
      onMuteToggle();
    }
    if (volumeBarRef.current) {
      const barLeft = volumeBarRef.current.offsetLeft;
      const mousePos = event.clientX;
      const newVolume = (mousePos - barLeft) / volumeBarRef.current.offsetWidth;
      setVolumeBarWitdh(newVolume * 100);
      setMouseDownAudio(true);
      setTimeout(() => {
        onUpdateVolume(newVolume);
      }, 1);
    }
  };

  const handleOnMouseMoveAudio = async (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const {result: canUnmute} = await canAutoplay.audio();
    if (!canUnmute) {
      return;
    }

    if (isMuted) {
      onMuteToggle();
    }

    if (mouseDownAudio && volumeBarRef.current) {
      const barLeft = volumeBarRef.current.offsetLeft;
      const mousePos = event.clientX;
      // prevent dragging out of bounds of bar
      if (
        mousePos - barLeft >= 0 &&
        mousePos - barLeft <= volumeBarRef.current.offsetLeft
      ) {
        const newVolume =
          (mousePos - barLeft) / volumeBarRef.current.offsetWidth;
        setVolumeBarWitdh(newVolume * 100);
        onUpdateVolume(newVolume);
      }
    }
  };

  return (
    <Container
      onMouseLeave={() => setMouseDown(false)}
      onMouseMove={handleOnMouseMove}
      onMouseUp={() => {
        setMouseDown(false);
        setMouseDownAudio(false);
      }}
    >
      <PlayButton
        style={{display: isPlaying ? "none" : "block"}}
        onClick={onTogglePlayerState}
      >
        <img alt="play" src={PlayIcon} />
      </PlayButton>
      <PauseButton
        style={{display: !isPlaying ? "none" : "block"}}
        onClick={onTogglePlayerState}
      >
        <img alt="pause" src={PauseIcon} />
      </PauseButton>
      <ProgressBarContainer
        ref={progressRef}
        onClick={handleOnClick}
        onMouseDown={() => setMouseDown(true)}
        onMouseOut={handleMouseInAndOut}
        onMouseOver={handleMouseInAndOut}
      >
        <ProgressBar style={{width: `${progressBarWitdh}%`}} />
        <ProgressPointer
          style={{left: `${progressBarWitdh - 0.3}%`}}
          onMouseMove={handleMouseOverForPointer}
        />
        {showPopup && (
          <TimePreviewContainer style={{left: `${popupPosition}px`}}>
            <IconContainer src={TimePreviewIcon} />
            <TimePreviewText>{timePreview}</TimePreviewText>
          </TimePreviewContainer>
        )}
      </ProgressBarContainer>
      <CurrentTimeLabel>{durationTimeMinutes}</CurrentTimeLabel>
      <VolumeBar
        ref={volumeBarRef}
        onMouseDown={handleOnMouseDownAudio}
        onMouseLeave={() => setMouseDownAudio(false)}
        onMouseMove={handleOnMouseMoveAudio}
      >
        <ProgressBar style={{width: `${!isMuted ? volumeBarWitdh : 0}%`}} />
        <ProgressPointer
          style={{left: `${(!isMuted ? volumeBarWitdh : 0) - 7}%`}}
        />
      </VolumeBar>

      <VolumeButton onClick={onMuteToggle}>
        {isMuted ? (
          <img alt="mute" src={MutedIcon} />
        ) : (
          <img alt="mute" src={NotMutedIcon} />
        )}
      </VolumeButton>
      <FullScreenContainer>
        <FullScreenButton />
      </FullScreenContainer>
    </Container>
  );
};

export default PlayerControls;
