import * as yup from "yup";
import {UnlockableContentTypes} from "apollo";

export function getUnlockableFormValidator(types: UnlockableContentTypes[]) {
  const validateRedward = yup.object().optional().shape({
    url: yup.string().url().required(),
    description: yup.string().required(),
    code: yup.string().optional()
  });
  const validators = types.reduce((acc, type) => {
    return {
      ...acc,
      [type]: validateRedward
    };
  }, {});
  return yup.object().shape(validators);
}
