import {
  ListLikedVideosDocument,
  User,
  useUnlikeVideoMutation,
  FeedVideo
} from "apollo";
import React, {useCallback, useState} from "react";
import {useHistory} from "react-router";
import {IndexRange} from "react-virtualized";
import VideoListItem from "components/Molecules/VideoListItem";
import {getLikedPostsUrl} from "config/routes";
import {RenderGridItemProps} from "components/Organisms/VirualizedGrid/types";
import VideoListItemScrollPlaceholder from "components/Molecules/VideoListItemScrollPlaceholder";
import {useQuery} from "@apollo/react-hooks";
import {PROFILE_VIDEOS_PER_PAGE} from "config/constants";

const columnBreakpoints = [600, 1050, 1400];

export function useProfileLikedPosts(user?: User) {
  const userUuid = user?.uuid;
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [videoToUnlike, setVideoToUnlike] = useState<string>("");
  const history = useHistory();

  const {data, error, fetchMore, loading, refetch} = useQuery(
    ListLikedVideosDocument,
    {
      variables: {
        uuid: userUuid || "",
        page: {
          take: PROFILE_VIDEOS_PER_PAGE,
          skip: 0
        }
      },
      notifyOnNetworkStatusChange: true
    }
  );

  const [unlikeVideo] = useUnlikeVideoMutation({
    onCompleted: () => {
      refetch();
      setIsConfirmationOpen(false);
    }
  });

  const confirmUnlikeOnPress = useCallback(() => {
    unlikeVideo({variables: {externalId: videoToUnlike}});
  }, [unlikeVideo, videoToUnlike]);

  const handleOnClick = useCallback(
    (number: number) => {
      if (data?.listLikedVideos?.videos) {
        const externalId = data.listLikedVideos.videos[number]?.externalId!;
        const url = getLikedPostsUrl(user?.username!, externalId, number);
        history.push(url);
      }
    },
    [data, history, user]
  );

  const loadMoreItems = useCallback(
    ({startIndex, stopIndex}: IndexRange) => {
      return fetchMore({
        variables: {
          page: {
            take: stopIndex - startIndex + 1,
            skip: startIndex
          },
          uuid: userUuid
        }
      }).catch((e) => {
        console.log("error while loading listLikedVideos", e);
      });
    },
    [fetchMore, userUuid]
  );

  const modalOnClose = useCallback(() => {
    setIsConfirmationOpen(false);
  }, []);

  const showModal = useCallback((id: string) => {
    setIsConfirmationOpen(true);
    setVideoToUnlike(id);
  }, []);

  const renderGridItem = useCallback(
    ({
      index,
      item,
      onLoad
    }: RenderGridItemProps<FeedVideo>): React.ReactNode => {
      return (
        <VideoListItem
          index={index}
          key={item.externalId}
          onClick={handleOnClick}
          onLoad={onLoad}
          openUnlikeModal={showModal}
          // @ts-ignore
          video={item!}
        />
      );
    },
    [handleOnClick]
  );

  const renderItemPlaceholder = useCallback(() => {
    return <VideoListItemScrollPlaceholder />;
  }, []);

  return {
    data,
    columnBreakpoints,
    confirmUnlikeOnPress,
    error,
    isConfirmationOpen,
    loading,
    loadMoreItems,
    modalOnClose,
    renderGridItem,
    renderItemPlaceholder
  };
}
