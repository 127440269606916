import styled from "styled-components";
import {TEXT_HOVER_SCALE} from "config/constants";
import Text from "components/Atoms/Text";

export const RootContainer = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;

  transform: scaleX(1);
  transform-origin: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(${TEXT_HOVER_SCALE});
  }
`;

export const ButtonText = styled(Text)`
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`;
