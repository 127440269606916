import {useCallback} from "react";

import {ThumbnailFormProps, ThumbnailType} from "./types";

export function useThumbnailForm({
  setThumbnailType,
  thumbnailType
}: ThumbnailFormProps) {
  const switchThumbnailType = useCallback(() => {
    setThumbnailType(
      thumbnailType === ThumbnailType.Clipping
        ? ThumbnailType.Upload
        : ThumbnailType.Clipping
    );
  }, [setThumbnailType, thumbnailType]);

  return {
    switchThumbnailType
  };
}
