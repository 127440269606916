import styled, {css} from "styled-components";
import {errorRed, formStyles} from "config/styleVars";
import IconButton from "components/Atoms/IconButton";
import {breakpointNames, media} from "theme/media";

type ContainerProps = {
  showBorder?: boolean;
};

export const Container = styled.div<ContainerProps>`
  min-height: ${(props) => (props.showBorder ? "1000px" : "0")};
  border-radius: 8px 8px 0 0;
  border: ${(props) => (props.showBorder ? "1px solid white" : "none")};
  border-bottom: none;
  margin: 0 auto 0 auto;
  position: relative;
`;

export const Name = styled.div`
  font-size: 20px;
  position: absolute;
  top: 330px;
  left: 136px;
`;

export const Username = styled.div`
  position: absolute;
  left: 136px;
  top: 358px;
  font-size: 14px;
`;

export const ProfileInfo = styled.div`
  width: 580px;
  margin-top: 70px;
  margin-left: 40px;
`;

export const Bio = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: white;
  overflow: visible;
  min-height: 1px;
`;

export const ProfileItem = styled.div`
  float: left;
  margin-top: 10px;
  margin-right: 28px;
  color: #a39ea9;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  span {
    margin-left: 5px;
    vertical-align: top;
  }
`;

type CropContainerType = {
  isLoading: boolean;
};

export const CropContainer = styled.div<CropContainerType>`
  opacity: ${(props) => (props.isLoading ? 0 : 1)};
  pointer-events: ${(props) => (props.isLoading ? "none" : "default")};

  input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
  }
`;

export const CropError = styled.div`
  padding: 40px 0;
  text-align: center;
  color: ${errorRed};
`;

export const Cropholder = styled.div`
  text-align: center;
  margin-top: 40px;
`;

export const EditProfileBanner = styled.div`
  position: absolute;
  width: 470px;
  height: 228px;
  left: 120px;
  top: 140px;
  border: 1px solid white;
  border-radius: 8px 8px 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const EditProfileAvatar = styled.div`
  position: absolute;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  width: 64px;
  height: 64px;
  top: 188px;
  left: 24px;
  background-color: black;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const FieldsContaner = styled.div`
  position: absolute;
  top: 416px;
  left: 120px;
  width: 710px;
`;

export const InputContainer = styled.div`
  margin-bottom: 16px;
`;

export const ImageTypeSuggestion = styled.div`
  position: absolute;
  width: 146px;
  height: 163px;
  right: 178px;
  top: 140px;
  color: #a39ea9;
  font-weight: 300;
  font-size: 13px;
  line-height: 30px;
  white-space: pre-line;

  ::first-line {
    font-weight: 500;
    font-size: 15px;
    line-height: 32px;
  }
`;

export const ImageSizeSuggestion = styled(ImageTypeSuggestion)`
  top: 253px;
`;

export const BackButton = styled.div`
  position: absolute;
  top: 60px;
  left: 20.67px;
  padding-left: 27.33px;
  color: #a39ea9;
  cursor: pointer;
`;

export const BackIcon = styled.img`
  position: absolute;
  left: 5px;
  top: 2px;
`;

export const Title = styled.div`
  position: absolute;
  top: 96px;
  left: 24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 400px;
  right: 28px;
`;

export const FollowIconImg = styled.img`
  margin-right: 0.5em;
  vertical-align: middle;
  position: absolute;
  left: 76px;
  top: 5px;
`;

export const FollowButton = styled.button<{variant: boolean; small: boolean}>`
  height: 32px;
  display: inline-block;
  text-align: center;
  width: ${(props) => (props.small ? "74px" : "248px")};
  font-weight: bold;
  font-size: 13px;
  line-height: 32px;
  border-radius: ${formStyles.borderRadius};
  padding: ${(props) => (props.small ? "0" : "0")};
  justify-content: center;
  transition: all 300ms ease;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  img {
    transition: all 300ms ease;
  }

  ${FollowIconImg} {
    display: ${(props) => (props.small ? "none" : "inline")};
  }

  ${({variant}) =>
    variant
      ? css`
          border: 1px solid ${formStyles.buttonHoverBorderColor};
          background: transparent;
          color: ${formStyles.textColor};

          &:hover {
            background-color: white;
            border: 1px solid #999;
            color: black;
          }
        `
      : css`
          background-color: white;
          border: none;

          img.white {
            opacity: 0;
          }
          img.black {
            opacity: 1;
          }

          &:hover {
            background-color: black;
            color: #fff;
            box-shadow: inset 0 0 1px 1px #333;

            img.white {
              opacity: 1;
            }
            img.black {
              opacity: 0;
            }
          }
        `}
`;

export const ChangeBackButton = styled.div`
  position: absolute;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  align-items: center;
  color: #e0115f;
  right: 0px;
  cursor: pointer;
  padding-left: 23px;
`;

export const ChangeBackIconContainer = styled.img`
  position: absolute;
  left: 0px;
  top: 0px;
`;

export const Text = styled.div`
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  color: #a39ea9;
`;

export const TextSection = styled(Text)`
  font-size: 11px;
  margin-top: 40px;
`;

export const UsernameText = styled.div`
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  margin-top: 6px;
  margin-bottom: 16px;
`;

export const ControlsAndVideosContainer = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  max-width: 1620px;
  padding-left: 2rem;
  padding-top: 42px;

  ${media.lessThan(breakpointNames.tablet)`
    padding-top: 24px;
  `}
`;

export const EditProfileButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 1;
`;

export const SmallEditProfileButton = styled(IconButton)`
  display: none;
  position: absolute;
  padding: 4px 4px 2px 4px;
  border-radius: 5px;
  right: calc(1rem + 1vw);
  top: 12px;
  z-index: 1;
`;

export const InfoAndVideosContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px calc(1rem + 1vw);

  ${media.lessThan(breakpointNames.tablet)`
    flex-direction: column;
    ${ControlsAndVideosContainer} {
      padding-left: 0;
    }
    ${EditProfileButton} {
      display: none;
    }
    ${SmallEditProfileButton} {
      display: flex;
    }
  `}
`;
