import styled from "styled-components";
import Text from "components/Atoms/Text";
import {textGray} from "config/styleVars";
import {Button} from "components/Button";

export const Container = styled.div`
  display: flex;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  align-items: flex-start;
`;

export const GrayText = styled(Text)`
  color: ${textGray};
  margin-bottom: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
`;

export const LinkButton = styled(Button)`
  cursor: pointer;
`;
