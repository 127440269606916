import React from "react";
import {
  AuthButton,
  AuthHeading,
  AuthWrapper,
  FooterText
} from "components/Auth/AuthElements";
import {Spinner} from "components/Spinner";
import {strings} from "config/strings";
import {formStyles} from "config/styleVars";
import {Input} from "components/Input";
import {
  FieldValidationMessage,
  InlineTextButton,
  ResponseMessage,
  ResponseMessageTypes
} from "components/StyledElements/FormElements";
import {Link} from "react-router-dom";
import {Routes} from "config/routes";

import {useSignInForm} from "./hooks";
import {ForgotPasswordButtonContainer} from "./styles";

const SignInForm: React.FC = () => {
  const {
    errors,
    formError,
    handleForgotPassword,
    handleSubmit,
    isSubmitting,
    onSubmit,
    register
  } = useSignInForm();

  return (
    <AuthWrapper isLoading={isSubmitting}>
      {isSubmitting && <Spinner />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <AuthHeading>{strings.auth.signIn}</AuthHeading>

        {formError && (
          <ResponseMessage
            message={formError}
            type={ResponseMessageTypes.Error}
          />
        )}

        <div style={{marginBottom: formStyles.marginBottom}}>
          <Input
            {...register("username")}
            invalid={!!errors.username}
            label={strings.generic.email}
            type="text"
          />
          {errors.username && (
            <FieldValidationMessage>
              {errors.username.message}
            </FieldValidationMessage>
          )}
        </div>

        <div>
          <Input
            {...register("password")}
            invalid={!!errors.password}
            label={strings.generic.password}
            type="password"
          />
          {errors.password && (
            <FieldValidationMessage>
              {errors.password.message}
            </FieldValidationMessage>
          )}
        </div>

        <ForgotPasswordButtonContainer>
          <InlineTextButton type="button" onClick={handleForgotPassword}>
            {strings.auth.forgotPassword}
          </InlineTextButton>
        </ForgotPasswordButtonContainer>

        <AuthButton disabled={isSubmitting} type="submit">
          {strings.auth.signIn}
        </AuthButton>

        <FooterText>
          {strings.auth.noAccount}
          <Link to={Routes.signup}>{strings.auth.signUp}</Link>
        </FooterText>
      </form>
    </AuthWrapper>
  );
};

export default React.memo(SignInForm);
