import LeftIcon from "icons/20/chevron-left.svg";
import {strings} from "config/strings";
import {useHistory} from "react-router-dom";
import {Modal} from "components/Modal";
import {useEffect, useState} from "react";
import {User} from "apollo";
import {ApolloError} from "@apollo/react-hooks";
import {Spinner} from "components/Spinner";
import Auth from "@aws-amplify/auth";
import {Routes} from "config/routes";
import {useAuth} from "hooks/useAuth";
import {
  BackButton,
  Icon,
  Container,
  MenuSection,
  Title,
  SectionContainer,
  Wrapper,
  SettingsItemText,
  SettingsItemPasswordContainer,
  SettingsItemPasswordEmail,
  SettingsButton,
  SettingsPasswordBtnContainer
} from "./styles";

type Props = {
  uuid: string;
  user: User | null;
  loading: boolean;
  error?: ApolloError;
};

export const SettingsPasswordContainer: React.FC<Props> = ({
  user,
  loading
}: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const {signOut} = useAuth();

  useEffect(() => {
    return () => {
      setOpenModal(false);
    };
  }, []);

  const handleOnBackClick = () => {
    history.goBack();
  };

  const resetPassword = async () => {
    try {
      await Auth.forgotPassword(user?.email!);
      setOpenModal(true);
    } catch (resetError: any) {
      console.error(resetError.message);
    }
  };

  const logoutAndResetPassword = async () => {
    signOut();
    history.push(Routes.forgotPassword, {
      emailSent: true,
      email: user?.email!
    });
  };

  return (
    <Container>
      {loading && <Spinner />}
      {!loading && user && (
        <>
          <BackButton onClick={handleOnBackClick}>
            <Icon src={LeftIcon} />
            {strings.generic.back}
          </BackButton>
          <Wrapper>
            <Title>{strings.pages.settings.changePassword}</Title>
            <SectionContainer>
              <MenuSection>
                <SettingsItemPasswordContainer>
                  <SettingsItemText>
                    {strings.pages.settings.passwordDisclaimer}
                    <SettingsItemPasswordEmail>
                      {user.email!}.
                    </SettingsItemPasswordEmail>
                  </SettingsItemText>
                </SettingsItemPasswordContainer>

                <SettingsPasswordBtnContainer>
                  <SettingsButton onClick={resetPassword}>
                    {strings.pages.settings.resetPassword}
                  </SettingsButton>
                </SettingsPasswordBtnContainer>
              </MenuSection>
            </SectionContainer>
            <Modal
              isDialog
              isMessage
              closeModalFn={logoutAndResetPassword}
              heading={strings.pages.settings.emailModalTitle}
              isOpen={openModal}
              message={strings.pages.settings.emailModalDisclaimer(user.email!)}
            />
          </Wrapper>
        </>
      )}
    </Container>
  );
};
