import styled from "styled-components";
import Text from "components/Atoms/Text";
import {breakpointNames, media} from "theme/media";

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 304px;
  min-width: 304px;
  overflow-wrap: break-word;

  ${media.lessThan(breakpointNames.tablet)`
    max-width: none;  
  `}
`;

export const AvatarContainer = styled.div`
  display: flex;
  position: relative;
  margin-top: -50px;
  width: 100px;
  height: 100px;
  z-index: 1;
`;

export const MetricsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
`;

export const FollowButtonContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 24px;
`;

export const MetricsAndFollowContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lessThan(breakpointNames.tablet)`
    flex-direction: row;
    
    ${FollowButtonContainer} {
      justify-content: flex-end;
    }
  `}
`;

export const BioTitle = styled(Text)`
  margin-top: 24px;
`;

export const BioText = styled(Text)`
  margin-top: 8px;
`;

export const IconItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 24px;
`;

export const IconItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
`;

export const IconText = styled(Text)`
  margin-left: 8px;
`;

export const SiteLink = styled.a`
  color: white;
`;
