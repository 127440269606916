import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  bottom: 7px;
  height: 34%;
  width: 100%;
  user-select: none;
`;

export const ProgressBarContainer = styled.div`
  position: absolute;
  height: 5px;
  width: calc(96% - 300px);
  background-color: rgb(255, 255, 255, 0.4);
  border-radius: 4px;
  cursor: pointer;
  top: 45%;
  left: 78px;
  user-select: none;
`;

export const ProgressBar = styled.div`
  position: absolute;
  height: 5px;
  width: 0.3%;
  background: #ffffff;
  border-radius: 4px;
  cursor: pointer;
`;

export const ProgressPointer = styled.div`
  position: absolute;
  height: 11px;
  width: 11px;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  left: 0px;
  top: -3px;
`;

export const PlayButton = styled.div`
  position: absolute;
  cursor: pointer;
  left: 48px;
  top: 32%;
`;

export const PauseButton = styled(PlayButton)`
  top: 28%;
  left: 45px;
`;

export const IconContainer = styled.img`
  position: absolute;
`;

export const CurrentTimeLabel = styled.span`
  position: absolute;
  width: 30px;
  height: 20px;
  right: calc(2% + 218px);
  top: 13px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  color: #ffffff;
`;

export const TimePreviewContainer = styled.div`
  position: absolute;
  width: 36px;
  height: 23px;
  left: 162px;
  top: -30px;
`;

export const TimePreviewText = styled.div`
  position: absolute;
  top: 4px;
  left: 22%;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #ffffff;
`;

export const FullScreenContainer = styled.div`
  position: absolute;
  right: 47px;
  bottom: 7px;
`;

export const VolumeBar = styled.div`
  position: absolute;
  top: 45%;
  right: 128px;
  height: 5px;
  width: calc(70px + 1%);
  background-color: rgb(255, 255, 255, 0.4);
  border-radius: 4px;
  cursor: pointer;
`;

export const VolumeButton = styled.div`
  position: absolute;
  right: 92px;
  top: 12px;
  cursor: pointer;
`;
