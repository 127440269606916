import RightIcon from "icons/24/chevron-right.svg";
import {useHistory} from "react-router-dom";
import {getSettingsUrl, Routes} from "config/routes";
import {
  SettingsItemIcon,
  SettingsItemContainer,
  SettingsItemTitle,
  SettingsItemText,
  SettingsItemTextContainer,
  SettingsExternalLink
} from "./styles";

type SettingsItemProps = {
  icon?: string;
  title: string;
  location?: Routes;
  showOnlyText?: boolean;
  handleOnClick?: () => void;
  uuid?: string;
  external?: boolean;
};

export const SettingsItem = ({
  icon,
  title,
  location,
  showOnlyText,
  handleOnClick,
  uuid,
  external
}: SettingsItemProps) => {
  const history = useHistory();

  const handleRoute = () => {
    if (location && !external) {
      history.push(getSettingsUrl(location, uuid || ""));
    } else if (handleOnClick) {
      handleOnClick();
    }
  };

  return showOnlyText ? (
    <SettingsItemTextContainer>
      <SettingsItemText>{title}</SettingsItemText>
    </SettingsItemTextContainer>
  ) : (
    <SettingsItemContainer onClick={handleRoute}>
      {external && <SettingsExternalLink href={location} target="_blank" />}
      <SettingsItemIcon src={icon} />
      <SettingsItemTitle>{title}</SettingsItemTitle>
      <SettingsItemIcon src={RightIcon} />
    </SettingsItemContainer>
  );
};
