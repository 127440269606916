import styled from "styled-components";
import {colors} from "theme/colors";

export const Container = styled.button`
  transition: all 0.2s ease-out;
  border-style: none;
  outline: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RootContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: ${colors.primary.white10};
  transition: all 0.2s ease-out;
  height: calc(100% - 2px);
  aspect-ratio: 1;
  margin: 1px 0px 1px 1px;
  :focus-within {
    outline: thin solid ${colors.primary.lightGrey};
  }
  &:hover {
    background: ${colors.primary.darkGrey};
  }
`;
