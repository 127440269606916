import {DisplayMode} from "apollo/reactive";

export type NavPropsType = {
  displayState: DisplayMode;
};

export enum Side {
  left,
  right,
  center
}

export enum NavigationItemType {
  link,
  customComponent
}

export enum AuthenticatedVisibility {
  authenticated,
  notAuthenticated,
  any
}

export interface NavigationItemBaseProps {
  authenticatedVisibility: AuthenticatedVisibility;
  isVisibleWhileLoading: boolean;
  key: string;
  side: Side;
  type: NavigationItemType;
}

export interface NavigationItemLinkProps extends NavigationItemBaseProps {
  hasUnderscore: boolean;
  title: string;
  route: string;
}

export interface NavigationItemButtonProps extends NavigationItemBaseProps {
  onClick: () => void;
  title: string;
}

export interface NavigationItemCustomComponentProps
  extends NavigationItemBaseProps {
  customComponent: JSX.Element;
}
