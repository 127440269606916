import React from "react";

import {ClippingToolPreviewProps} from "./types";
import {useClippingToolPreview} from "./hooks";
import {Container, Video} from "./styles";

const ClippingToolPreview: React.FC<ClippingToolPreviewProps> = (
  props: ClippingToolPreviewProps
) => {
  const {onTimeUpdate, playerOptions, setVideoNodeRef} =
    useClippingToolPreview(props);

  return (
    <Container>
      <Video
        className="video-js"
        data-setup={playerOptions}
        ref={setVideoNodeRef}
        autoPlay={true}
        muted={true}
        loop={true}
        onTimeUpdate={onTimeUpdate}
        disablePictureInPicture={true}
      />
    </Container>
  );
};

export default React.memo(ClippingToolPreview);
