import styled from "styled-components";
import {Button} from "components/StyledElements/FormElements";
import {textGray} from "config/styleVars";

type AuthWrapperProps = {
  isLoading?: boolean;
};

export const AuthWrapper = styled.div<AuthWrapperProps>`
  width: 300px;
  margin: 100px auto 0 auto;
  position: relative;

  &::after {
    content: "";
    display: ${(props) => (props.isLoading ? "block" : "none")};
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
`;

export const AuthHeading = styled.h1`
  text-align: center;
`;

export const AuthSubHeading = styled.p`
  color: ${textGray};
  text-align: center;
  font-size: 13px;
  margin-bottom: 40px;
`;

export const AuthButton = styled(Button)`
  width: 100%;
  margin-bottom: 1px;
  padding: 13px 17px;
`;

export const FooterText = styled.div`
  font-size: 13px;
  color: ${textGray};
  text-align: center;
  margin-top: 24px;
  white-space: break-spaces;
`;
