import styled from "styled-components";
import {
  GetPublishedVideoDocument,
  useGetVideoFeedFromFollowedQuery,
  FeedVideo
} from "apollo";
import {Spinner} from "components/Spinner";
import Player from "components/Video/Player";
import {getFollowingPlayerUrl} from "config/routes";
import {useHistory, useParams} from "react-router";
import {INITIAL_ITEMS_COUNT} from "config/constants";
import {strings} from "config/strings";
import {useEffect, useState} from "react";
import {useApolloClient} from "@apollo/react-hooks";

const Empty = styled.div`
  width: 100%;
  padding-top: 200px;
  text-align: center;
`;

const FollowingPlayer = () => {
  const client = useApolloClient();
  const [index, setIndex] = useState(0);
  const [videos, setVideos] = useState<FeedVideo[]>();
  const history = useHistory();
  const {videoId} = useParams<{videoId: string}>();

  const [singleVideo, setSingleVideo] = useState<FeedVideo>();

  const [singleVideoLoading, setSingleVideoLoading] = useState<boolean>(false);

  const {data, loading, fetchMore} = useGetVideoFeedFromFollowedQuery({
    variables: {
      page: {take: INITIAL_ITEMS_COUNT, skip: 0}
    },
    fetchPolicy: "cache-first"
  });

  useEffect(() => {
    if (videoId && !singleVideo) {
      client
        .query({
          query: GetPublishedVideoDocument,
          variables: {
            externalId: videoId
          }
        })
        .then((result) => {
          setSingleVideo(result.data?.getPublishedVideo as FeedVideo);
        })
        .finally(() => {
          setSingleVideoLoading(false);
        });
    }
  }, [videoId, singleVideo]);

  const setVideoConfig = (video: FeedVideo, newIndex?: number) => {
    setSingleVideo(video);
    const url = getFollowingPlayerUrl(video.externalId);
    history.push(url);
    if (newIndex) {
      setIndex(newIndex);
    }
  };

  useEffect(() => {
    if (!videoId && !!data?.getVideoFeedFromFollowed?.videos?.length) {
      const video = data.getVideoFeedFromFollowed.videos[0];
      setVideoConfig(video as FeedVideo);
    }
  }, [data?.getVideoFeedFromFollowed?.videos]);

  useEffect(() => {
    if (
      singleVideo &&
      !videos &&
      !!data?.getVideoFeedFromFollowed?.videos?.length
    ) {
      setVideos([
        singleVideo,
        ...((data?.getVideoFeedFromFollowed?.videos?.filter(
          (x) => x?.externalId !== videoId
        ) as Array<FeedVideo>) || [])
      ]);
    }
  }, [data?.getVideoFeedFromFollowed?.videos, singleVideo, videoId]);

  const handleVideoChange = (newIndex: number) => {
    if (data?.getVideoFeedFromFollowed?.videos && videos) {
      const shouldLoadNextPage =
        data?.getVideoFeedFromFollowed?.pager?.count &&
        newIndex < data.getVideoFeedFromFollowed.pager.count &&
        newIndex >= videos.length;
      const shouldMoveInsidePage =
        data?.getVideoFeedFromFollowed?.pager?.count &&
        newIndex < data.getVideoFeedFromFollowed.pager.count &&
        newIndex >= 0;

      if (shouldLoadNextPage) {
        fetchMore({
          variables: {
            page: {
              take:
                INITIAL_ITEMS_COUNT +
                Math.floor(newIndex / INITIAL_ITEMS_COUNT) * INITIAL_ITEMS_COUNT
            }
          }
        }).then(({data: newData}) => {
          setVideos([
            ...((newData?.getVideoFeedFromFollowed
              ?.videos as Array<FeedVideo>) || [])
          ]);
          const video = newData?.getVideoFeedFromFollowed?.videos?.[newIndex];
          setVideoConfig(video as FeedVideo, newIndex);
        });
      } else if (shouldMoveInsidePage) {
        const video = videos[newIndex];
        setVideoConfig(video, newIndex);
      }
    }
  };

  const hasVideos = () => {
    return !!data?.getVideoFeedFromFollowed?.videos?.length;
  };

  return (
    <>
      {(loading || singleVideoLoading) && <Spinner />}
      {!loading && !!videos?.length && singleVideo && (
        <Player
          videos={videos}
          videoIndex={index}
          handleVideoChange={handleVideoChange}
        />
      )}
      {!loading && !hasVideos() && (
        <Empty>{strings.pages.player.noFollowingVideos}</Empty>
      )}
    </>
  );
};

export default FollowingPlayer;
