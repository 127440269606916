import {FormEvent, useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {metaUploadValidator} from "utils/validators/upload";

import {IFormValues, MetadataFormValues, MetadataFormProps} from "./types";

export function useMetadataForm({onFillFn}: MetadataFormProps) {
  const [tags, setTags] = useState<string[]>([]);
  const methods = useForm<IFormValues>({
    mode: "all",
    resolver: yupResolver(metaUploadValidator)
  });
  const {
    handleSubmit,
    register,
    getValues,
    formState: {errors}
  } = methods;

  // not sure why, but without handling the submit Enter button pressed on an input fields does push navigation
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
  };

  const changeHandler = useCallback(() => {
    const values = getValues() as MetadataFormValues;
    if (values.title && values.description) {
      onFillFn({...values, tags});
    }
  }, [getValues, onFillFn, tags]);

  useEffect(() => {
    changeHandler();
  }, [changeHandler, tags]);

  return {
    changeHandler,
    errors,
    handleSubmit,
    methods,
    onSubmit,
    register,
    setTags
  };
}
