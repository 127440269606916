import styled from "styled-components";
import {colors} from "theme/colors";
import Button from "components/Atoms/Button";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 24px;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.primary.darkGrey};
  border-radius: 32px;
  margin-top: 24px;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: start;
  padding: 8px 16px;
  border-top: 1px solid ${colors.primary.darkGrey};
  &:first-child {
    border-top: none;
  }
`;

export const StyledButton = styled(Button)`
  color: ${colors.primary.white};
`;
