import {useCallback, useMemo} from "react";
import {
  GetUserDocument,
  IsUserFollowedByMeDocument,
  useFollowUserMutation,
  useUnfollowUserMutation
} from "apollo";
import {useUser} from "hooks/useUser";
import {AnalyticsEvents} from "config/constants";

import {UseProfileFollowButtonProps} from "./types";

export function useProfileFollowButton({
  followedByMe,
  uuid
}: UseProfileFollowButtonProps) {
  const {user: currentUser} = useUser();
  const currentUserUuid = currentUser?.uuid;

  const [followUser, {loading: followUserLoading}] = useFollowUserMutation({
    errorPolicy: "ignore",
    refetchQueries: [GetUserDocument, IsUserFollowedByMeDocument]
  });

  const [unfollowUser, {loading: unfollowUserLoading}] =
    useUnfollowUserMutation({
      errorPolicy: "ignore",
      refetchQueries: [GetUserDocument, IsUserFollowedByMeDocument]
    });

  const onFollowUser = useCallback(() => {
    if (followedByMe) {
      if (window.analytics) {
        window.analytics.track(AnalyticsEvents.UnfollowUser, {
          loggedUserId: currentUserUuid,
          followingUserId: uuid
        });
      }
      unfollowUser({variables: {uuid}});
      return;
    }
    if (window.analytics) {
      window.analytics.track(AnalyticsEvents.FollowUser, {
        loggedUserId: currentUserUuid,
        followingUserId: uuid
      });
    }
    followUser({variables: {uuid}});
  }, [followedByMe, followUser, uuid, unfollowUser, currentUserUuid]);

  const isFollowLoading = useMemo(() => {
    return followUserLoading || unfollowUserLoading;
  }, [followUserLoading, unfollowUserLoading]);

  return {isFollowLoading, onFollowUser};
}
