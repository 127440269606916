import React from "react";

import {
  AvatarPlaceholder,
  ImagePlaceholder,
  Line,
  LinesContainer,
  MetadataContainer,
  RootContainer
} from "./styles";
import {VideoListItemScrollPlaceholderProps} from "./types";

const VideoListItemScrollPlaceholder: React.FC<VideoListItemScrollPlaceholderProps> =
  ({onLoad}: VideoListItemScrollPlaceholderProps) => {
    return (
      <RootContainer>
        <ImagePlaceholder onLoad={onLoad} />
        <MetadataContainer>
          <AvatarPlaceholder />
          <LinesContainer>
            <Line height={18} marginRight={0} />
            <Line height={10} marginRight={40} />
          </LinesContainer>
        </MetadataContainer>
      </RootContainer>
    );
  };

export default React.memo(VideoListItemScrollPlaceholder);
