import styled from "styled-components";
import Text from "components/Atoms/Text";
import {colors} from "theme/colors";

import {GrayTextProps} from "./types";

export const GrayText = styled(Text)<GrayTextProps>`
  color: ${(props) =>
    props.isDisabled ? colors.primary.mediumGrey : colors.primary.lightGrey};
`;
