import React from "react";
import styled from "styled-components";
import {strings} from "config/strings";
import {textGray} from "config/styleVars";
import SpacemanIcon from "icons/spaceman.svg";
import {Button} from "components/StyledElements/FormElements";

const ErrorContainer = styled.div`
  width: 335px;
  text-align: center;
  margin: 200px auto 0 auto;

  h3 {
    font-size: 16px;
    line-height: 20px;
  }

  p {
    font-size: 14px;
    color: ${textGray};
  }
`;

function FeedError() {
  return (
    <ErrorContainer>
      <img alt="spaceman icon" src={SpacemanIcon} />
      <h3>{strings.generic.error}</h3>
      <p>{strings.pages.feed.feedErrorMessage}</p>
      <Button onClick={() => window.location.reload()}>Refresh Page</Button>
    </ErrorContainer>
  );
}

export default React.memo(FeedError);
