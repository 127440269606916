import {CollectionTiersInput, NftCollectionInput} from "apollo";
import {TierValues} from "components/Atoms/TiersDropdownOption/types";
import {BlockchainNetworks, UploadFormValues} from "./types";

function shapeTiers(values: UploadFormValues): CollectionTiersInput[] {
  if (!values.tier?.value) {
    return [];
  }

  const tierNumber = values.tier.value === TierValues.TwoTiers ? 2 : 1;
  const revenueSplit = 0;
  const quantity = values.tokensAvailable;
  const price = values.tierPrice;

  return Array.from({length: tierNumber}, (_, index) => {
    const tierId = values?.tier?.value === TierValues.TierVideo ? 2 : index + 1;
    const rewards = values?.rewardsPerTier?.[index] || [];

    return {
      tierId,
      name: `Tier ${index + 1}`,
      revenueSplit,
      price: price[index].value,
      quantity: quantity[index].value,
      tokenResellPercentage: values.tokenResalePercentage,
      unlockableContent: rewards.map((reward) => {
        return {
          type: reward.type,
          name: reward.description,
          url: reward.url,
          code: reward.code
        };
      })
    };
  });
}

export function shapeNftCollection(
  values: UploadFormValues
): NftCollectionInput {
  const chainId = values.blockchain === BlockchainNetworks.Ethereum ? 1 : 137;
  return {
    collectionId: "",
    contractId: "",
    chainId,
    assetId: values.externalId,
    tiers: shapeTiers(values),
    collaborators: []
  };
}
