import React from "react";
import styled from "styled-components";
import {DisplayMode, displayStateVar} from "apollo/reactive";
import {ReactComponent as MaximizeIcon} from "icons/24/maximize.svg";
import {ReactComponent as MinimizeIcon} from "icons/24/minimize.svg";
import {useReactiveVar} from "@apollo/client";
import {useFullScreen} from "hooks/useFullScreen";

const Root = styled.div`
  cursor: pointer;
`;

export const FullScreenButton: React.FC = () => {
  const displayState = useReactiveVar(displayStateVar);
  const [openFullScreen, exitFullScreen] = useFullScreen();

  const handleClick = () => {
    if (displayState === DisplayMode.fullscreen) {
      displayStateVar(DisplayMode.theater);
      exitFullScreen();
    } else {
      displayStateVar(DisplayMode.fullscreen);
      openFullScreen();
    }
  };

  return (
    <Root title="toggle fullscreen">
      {displayState !== DisplayMode.fullscreen ? (
        <MaximizeIcon onClick={handleClick} />
      ) : (
        <MinimizeIcon onClick={handleClick} />
      )}
    </Root>
  );
};
