import {useCallback, useMemo, useState} from "react";

import {UseAvatarProps} from "./types";

export function useAvatar({avatarURL, username}: UseAvatarProps) {
  const [imageError, setImageError] = useState<boolean>(false);

  const imageOnError = useCallback(() => {
    setImageError(true);
  }, []);

  const showLetters = useMemo(() => {
    return (!avatarURL || imageError) && username;
  }, [avatarURL, imageError, username]);

  return {imageOnError, showLetters};
}
