import styled from "styled-components";

export const CloseButton = styled.button`
  appearance: none;
  border: none;
  color: #a39ea9;
  position: absolute;
  right: 0;
  top: -18px;
  background: transparent;
  font-size: 14px;
`;

export const Heading = styled.div`
  padding: 40px 40px 0 40px;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
`;

export const Subheading = styled.div`
  padding: 8px 40px 0 40px;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #a39ea9;
`;

export const Content = styled.div`
  padding: 40px;
  max-height: calc(80vh - 100px);
  overflow: auto;
`;

export const Footer = styled.div`
  border-top: 1px solid #4b494d;
  display: flex;
`;

type FooterButtonProps = {
  accent?: boolean;
};

export const FooterButton = styled.button<FooterButtonProps>`
  appearance: none;
  background: transparent;
  flex: 1;
  border: none;
  color: ${(props) => (props.accent ? "#E0115F" : "white")};
  height: 56px;
  line-height: 56px;
  border-left: 1px solid #4b494d;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;

  &:first-child {
    border-left: none;
  }
`;
