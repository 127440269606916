import styled from "styled-components";
import {errorRed} from "config/styleVars";

export const Container = styled.div`
  min-height: 200px;
  position: relative;
  padding: 24px 0px;
`;

export const Grid = styled.div`
  overflow: auto;
`;

export const Error = styled.p`
  color: ${errorRed};
  text-align: center;
`;

export const Empty = styled.div`
  text-align: center;
  padding: 40px 0;
`;

export const ThumbContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(315px, 1fr));
  grid-column-gap: 12px;
  grid-row-gap: 12px;
`;

export const ThumbWrapper = styled.div`
  background: blue;
  aspect-ratio: 16/9;
`;

export const LoaderContainer = styled.div`
  display: block;
  position: relative;
  height: 100px;
`;

export const CenteredP = styled.p`
  text-align: center;
`;

export const UnlikeConfirmationText = styled.div`
  color: #a39ea9;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  white-space: pre-line;
  text-align: center;

  ::first-line {
    color: #ffffff;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
  }
`;
