import styled from "styled-components";
import {breakpointNames, media, viewportWidths} from "theme/media";

export const BANNER_HEIGHTS = {
  tablet: 216,
  smallDesktop: 279,
  mediumDesktop: 318,
  largeDesktop: 425
};

function getBannerHeightString({
  minScreenWidth,
  maxScreenWidth,
  minBannerHeight,
  maxBannerHeight
}: {
  minScreenWidth: number;
  maxScreenWidth: number;
  minBannerHeight: number;
  maxBannerHeight: number;
}) {
  return `calc((100vw - ${minScreenWidth}px) / (${maxScreenWidth} - ${minScreenWidth}) * (${maxBannerHeight} - ${minBannerHeight}) + ${minBannerHeight}px)`;
}

export const BannerContainer = styled.div`
  position: relative;
  width: 100%;

  height: ${BANNER_HEIGHTS.largeDesktop}px;
  ${media.lessThan(breakpointNames.largeDesktop)`
    min-height: ${BANNER_HEIGHTS.mediumDesktop}px;
    max-height: ${BANNER_HEIGHTS.largeDesktop}px;
    height: ${getBannerHeightString({
      minScreenWidth: viewportWidths.mediumDesktop,
      maxScreenWidth: viewportWidths.largeDesktop,
      minBannerHeight: BANNER_HEIGHTS.mediumDesktop,
      maxBannerHeight: BANNER_HEIGHTS.largeDesktop
    })};
  `}

  ${media.lessThan(breakpointNames.mediumDesktop)`
    min-height: ${BANNER_HEIGHTS.smallDesktop}px;
    max-height: ${BANNER_HEIGHTS.mediumDesktop}px;
    height: ${getBannerHeightString({
      minScreenWidth: viewportWidths.smallDesktop,
      maxScreenWidth: viewportWidths.mediumDesktop,
      minBannerHeight: BANNER_HEIGHTS.smallDesktop,
      maxBannerHeight: BANNER_HEIGHTS.mediumDesktop
    })};
  `}
  
  ${media.lessThan(breakpointNames.smallDesktop)`
    min-height: ${BANNER_HEIGHTS.tablet}px;
    max-height: ${BANNER_HEIGHTS.smallDesktop}px;
    height: ${getBannerHeightString({
      minScreenWidth: viewportWidths.tablet,
      maxScreenWidth: viewportWidths.smallDesktop,
      minBannerHeight: BANNER_HEIGHTS.tablet,
      maxBannerHeight: BANNER_HEIGHTS.smallDesktop
    })};
  `}
  
  ${media.lessThan(breakpointNames.tablet)`
    height: ${BANNER_HEIGHTS.tablet}px;
    min-height: ${BANNER_HEIGHTS.tablet}px;
    max-height: ${BANNER_HEIGHTS.tablet}px;
  `}
`;
