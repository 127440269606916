import React from "react";
import Text from "components/Atoms/Text";
import {TextTypes} from "components/Atoms/Text/types";

import {RootContainer} from "./styles";
import {useVideoDetails} from "./hooks";

const VideoDetails: React.FC = () => {
  const {isVideoLoading, video} = useVideoDetails();

  return (
    <RootContainer>
      <Text textType={TextTypes.Titles.H2}>
        {isVideoLoading ? "Loading..." : `Video Loaded: ${video?.title}`}
      </Text>
    </RootContainer>
  );
};

export default React.memo(VideoDetails);
