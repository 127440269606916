import React from "react";
import {Route, Redirect} from "react-router-dom";
import {Routes} from "config/routes";
import {useUser} from "hooks/useUser";
import {FeatureFlagList, useGetFeatureFlagQuery} from "apollo";

const PrivateRoute = ({component, path, redirectTo, ...rest}: any) => {
  const {user, authenticated, loading: loadingUser} = useUser();

  const {loading: loadingFeature, data: disableHomeAuth} =
    useGetFeatureFlagQuery({
      variables: {
        name: FeatureFlagList.DisableHomeAuth
      },
      skip: path !== Routes.home
    });
  const loading = loadingFeature || loadingUser;

  return (
    <Route
      {...rest}
      path={path}
      render={(props) => {
        if (loading) {
          return null;
        }
        if (user && user?.username && authenticated) {
          return React.createElement(component, props);
        }
        if (user && authenticated) {
          return <Redirect to={Routes.onboarding} />;
        }
        if (
          Routes.home === path &&
          disableHomeAuth?.getFeatureFlag?.status === 1
        ) {
          return React.createElement(component, props);
        }
        return <Redirect to={redirectTo || Routes.signin} />;
      }}
    />
  );
};

export default PrivateRoute;
