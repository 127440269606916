import styled from "styled-components";
import Text from "components/Atoms/Text";
import {colors} from "theme/colors";

export const RootContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledRadioButton = styled.input`
  width: 20px;
  height: 20px;
  appearance: none;
  margin: 0;
  border: 1px solid ${colors.primary.white};
  border-radius: 50%;
  transition: all 0.2s ease-out;
  cursor: pointer;
  ::after {
    content: "";
    display: block;
    border-radius: 10px;
    width: 12px;
    height: 12px;
    margin: 3px;
    transition: all 0.2s ease-out;
  }
  :hover {
    ::after {
      background-color: ${colors.primary.white16};
    }
  }
  :focus {
    outline: none;
  }
  :checked {
    ::after {
      background-color: ${colors.primary.white};
    }
    :hover {
      ::after {
        background-color: ${colors.primary.lightGrey};
      }
    }
  }
  :disabled {
    cursor: not-allowed;
    border: 1px solid ${colors.primary.darkGrey};
    :hover {
      ::after {
        background-color: ${colors.inputBackground};
      }
    }
    :checked {
      ::after {
        background-color: ${colors.primary.darkGrey};
      }
      :hover {
        ::after {
          background-color: ${colors.primary.darkGrey};
        }
      }
    }
  }
`;

export const GrayText = styled(Text)<{disabled?: boolean}>`
  color: ${({disabled}) =>
    disabled ? colors.primary.darkGrey : colors.primary.lightGrey};
  margin: 0 4px 0 8px;
  transition: all 0.2s ease-out;
`;
