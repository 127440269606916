import styled from "styled-components";
import {errorRed} from "config/styleVars";

export const Container = styled.div`
  min-height: 200px;
  position: relative;
  padding: 24px 0px;
`;

export const Error = styled.p`
  color: ${errorRed};
  text-align: center;
`;

export const UnlikeConfirmationText = styled.div`
  color: #a39ea9;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  white-space: pre-line;
  text-align: center;

  ::first-line {
    color: #ffffff;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
  }
`;
