import React from "react";
import {FormProvider} from "react-hook-form";
import Text from "components/Atoms/Text";
import {TextTypes, TextWights} from "components/Atoms/Text/types";
import Dropzone from "components/Molecules/Dropzone";
import {DropzoneConfig} from "components/Molecules/Dropzone/types";
import {strings} from "config/strings";

import {VideoPickerFormProps} from "./types";
import {useUploadForm} from "./hooks";
import {
  Container,
  Description,
  DescriptionContainer,
  DropzoneContainer,
  Error,
  GrayText,
  InnerContainer,
  Line,
  SpecificationText
} from "./styles";

const dropzoneConfig: DropzoneConfig = {
  accept: "video/*",
  aspectRatio: "16/9",
  name: "file"
};

const VideoPickerForm: React.FC<VideoPickerFormProps> = (
  props: VideoPickerFormProps
) => {
  const {errors, formOnSubmit, methods} = useUploadForm(props);
  const {user} = props;

  if (!user?.username) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <Container>
        <Text textType={TextTypes.Headings.H2} textWeight={TextWights.Medium}>
          {strings.pages.upload.headingInitial(user.username)}
        </Text>
        <GrayText textType={TextTypes.Bodies.B1} textWeight={TextWights.Medium}>
          {strings.pages.upload.subHeadingInitial}
        </GrayText>

        <InnerContainer>
          <DropzoneContainer>
            <form onSubmit={formOnSubmit}>
              <Dropzone
                config={dropzoneConfig}
                invalid={!!errors.file}
                label={strings.dropzone.dndVideo}
              />
              {errors.file && <Error>{errors.file.message}</Error>}
            </form>
          </DropzoneContainer>

          <DescriptionContainer>
            <Description>
              <div>
                <Text textType={TextTypes.Bodies.B1}>
                  {strings.pages.upload.instructionTop.heading}
                </Text>
                <SpecificationText textType={TextTypes.Bodies.B2}>
                  {strings.pages.upload.instructionTop.one}
                </SpecificationText>
                <SpecificationText textType={TextTypes.Bodies.B2}>
                  {strings.pages.upload.instructionTop.two}
                </SpecificationText>
              </div>

              <Line />

              <div>
                <Text textType={TextTypes.Bodies.B1}>
                  {strings.pages.upload.instructionBottom.heading}
                </Text>
                <SpecificationText textType={TextTypes.Bodies.B2}>
                  {strings.pages.upload.instructionBottom.one}
                </SpecificationText>
                <SpecificationText textType={TextTypes.Bodies.B2}>
                  {strings.pages.upload.instructionBottom.two}
                </SpecificationText>
                <SpecificationText textType={TextTypes.Bodies.B2}>
                  {strings.pages.upload.instructionBottom.three}
                </SpecificationText>
              </div>
            </Description>
          </DescriptionContainer>
        </InnerContainer>
      </Container>
    </FormProvider>
  );
};

export default React.memo(VideoPickerForm);
