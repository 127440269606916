import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  background-color: black;
  display: flex;
  border: 0.5px dashed #ffffff;
  border-radius: 3px;
  overflow: hidden;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;

  .video-js {
    display: flex !important;
    flex: 1;
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
  }
`;
