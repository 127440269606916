import styled from "styled-components";
import {ReactComponent as XIconSvg} from "icons/universal/x.svg";
import {formStyles} from "config/styleVars";
import {colors} from "theme/colors";

export const XIcon = styled(XIconSvg)`
  margin-left: 4px;
  width: 10px;
  height: 10px;
  stroke-width: 1.5px;
  stroke: ${colors.primary.lightGrey};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${formStyles.borderRadius};
  border: 1px solid ${colors.primary.mediumGrey};
  background-color: ${colors.primary.darkGrey};
  cursor: pointer;
  padding: 8px 8px 8px 8px;
  margin: 0px 4px 4px 0px;

  &:hover {
    background-color: ${colors.primary.mediumGrey};
  }

  &:hover ${XIcon} {
    stroke: ${colors.primary.white};
  }

  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
`;
