import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {videoUploadValidator} from "utils/validators/upload";
import {FormEvent, useCallback, useEffect} from "react";
import {AnalyticsEvents} from "config/constants";
import {Web3VideoPickerFormProps, Web3VideoPickerFormValues} from "./types";

export function useWeb3VideoPickerForm({
  user,
  onSuccess
}: Web3VideoPickerFormProps) {
  const uuid = user?.uuid;
  const methods = useForm<Web3VideoPickerFormValues>({
    mode: "onBlur",
    resolver: yupResolver(videoUploadValidator())
  });

  const {
    formState: {errors, isValid},
    getValues
  } = methods;

  const formOnSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    if (!isValid) {
      return;
    }

    if (window.analytics) {
      window.analytics.track(AnalyticsEvents.UploadProcessBegin, {
        uuid
      });
    }
    const {file} = getValues();
    onSuccess(file);
  }, [formOnSubmit, getValues, isValid, onSuccess, uuid]);

  return {errors, formOnSubmit, methods};
}
