import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {strings} from "config/strings";
import {FeedVideo} from "apollo";
import {Container} from "components/StyledElements/LayoutElements";
import {useFeed} from "hooks/useFeed";
import {Thumbnail} from "components/Video/Thumbnail";
import FeaturedVideo from "components/Video/FeaturedVideo";
import FeedError from "pages/Feed/FeedError";
import {useHistory} from "react-router-dom";
import {getFeedPlayerUrl, Routes} from "config/routes";
import GridView from "components/GridView/GridView";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {Thumb} from "components/Video/ThumbnailStyles";
import {INITIAL_ITEMS_COUNT} from "config/constants";
import {TimeoutAlert} from "components/TimeoutAlert/TimeoutAlert";

const FeedPageContainer = styled(Container)`
  max-width: none;
`;

function Feed() {
  const feedData = useFeed();
  const history = useHistory();
  const [hideVideo, setHideVideo] = useState(false);

  useEffect(() => {
    setHideVideo(
      history.location.pathname.toLowerCase().startsWith(Routes.explore)
    );
  }, [history.location]);

  const handleOnClick = (index: number) => {
    if (feedData?.videos) {
      const url = getFeedPlayerUrl(feedData.videos[index + 1].externalId!); // +1 accounts for sliced featured video
      history.push(url);
    }
  };

  const renderThumbs = (videos?: Array<FeedVideo>) => {
    if (!videos?.length) {
      return null;
    }
    return videos.map((vid, index) => (
      <Thumbnail
        video={vid}
        index={index}
        key={vid.externalId}
        onClick={handleOnClick}
      />
    ));
  };

  const renderSkeleton = () => {
    const items = [];
    for (let i = 0; i < 26; i++) {
      items.push(
        <Thumb key={i}>
          {/* eslint-disable-next-line react-extra/no-inline-styles */}
          <Skeleton style={{paddingTop: "55%"}} />
        </Thumb>
      );
    }

    return items;
  };

  // do not render featured vid in grid thumbnails
  const removeFeaturedVid = (videos: Array<FeedVideo> | undefined) => {
    if (videos) {
      return videos.slice(1);
    }
    return [];
  };

  const handleLoadMore = () => {
    if (!feedData || !feedData.videos || feedData.isLoading) {
      return;
    }
    feedData.fetchMore({
      variables: {
        feedId: feedData.feedId,
        page: {
          take: INITIAL_ITEMS_COUNT,
          skip: feedData.videos.length
        }
      }
    });
  };

  const hasMore = () => {
    if (feedData && feedData.count === 0) {
      return false; // user has no videos
    }
    if (feedData && feedData.videos && feedData.count) {
      const hasmore = feedData.videos.length < feedData.count;
      return hasmore;
    }
    return true;
  };

  return (
    <>
      {feedData?.loadingError && <FeedError />}
      {!feedData?.loadingError && (
        <FeedPageContainer>
          {!hideVideo && (
            <FeaturedVideo videos={feedData?.videos} videoIndex={0} />
          )}
          <SkeletonTheme color="#202020" highlightColor="#444">
            <GridView
              title={strings.pages.feed.explore}
              withFeaturedVideo={!hideVideo}
              handleLoadMore={handleLoadMore}
              hasMore={hasMore}
            >
              <>
                {feedData?.isLoading && renderSkeleton()}
                {renderThumbs(removeFeaturedVid(feedData?.videos))}
              </>
            </GridView>
          </SkeletonTheme>
          <TimeoutAlert />
        </FeedPageContainer>
      )}
    </>
  );
}

export default React.memo(Feed);
