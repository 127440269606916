import React from "react";
import {Highlight} from "react-instantsearch-hooks-web";

import {AlgoliaHighlightProps} from "./types";
import {HighlightText} from "./styles";

const AlgoliaHighlight: React.FC<AlgoliaHighlightProps> = ({
  attribute,
  hit,
  prefix,
  textType
}: AlgoliaHighlightProps) => {
  return (
    <HighlightText textType={textType}>
      {prefix}
      <Highlight attribute={attribute} hit={hit} />
    </HighlightText>
  );
};

export default React.memo(AlgoliaHighlight);
