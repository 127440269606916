import React, {useState, useEffect, useCallback, useRef} from "react";
import {useReactiveVar} from "@apollo/client";
import {
  DisplayMode,
  audioStateVar,
  displayStateVar,
  stopVideoStateVar
} from "apollo/reactive";
import {VideoJsPlayer} from "video.js";
import videojs from "@mux/videojs-kit";
import "@mux/videojs-kit/dist/index.css";
import Avatar from "components/Avatar";
import CommentIcon from "icons/24/message-square.svg";
import FilledCommentIcon from "icons/24/message-square-filled.svg";
import InfoIcon from "icons/24/info.svg";
import InfoIconActive from "icons/24/info-active.svg";
import {LikeButton} from "components/Button/LikeButton";
import {FeedVideo, useLikeVideoMutation, useUnlikeVideoMutation} from "apollo";
import {Link} from "react-router-dom";
import {getProfileUrl} from "config/routes";
import useSessionStorage from "hooks/useSessionStorage";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import {useFullScreen} from "hooks/useFullScreen";
import HeartIcon from "icons/heart-fill.svg";
import {useUser} from "hooks/useUser";
import debounce from "utils/Debounce";
import {TimeoutAlert} from "components/TimeoutAlert/TimeoutAlert";
import CommentsContainer from "./CommentsContainer";
import VideoErrorContainer from "./VideoErrorContainer/VideoErrorContainer";
import ArrowsControl from "./ArrowsControl";
import DescriptionContainer from "./DescriptionContainer";
import PlayerControls from "./PlayerControls";
import TagsContainer from "./TagsContainer";
import * as styled from "./PlayerStyles";

type PlayerProps = {
  videos: FeedVideo[] | undefined;
  videoIndex: number | null;
  handleVideoChange: (index: number) => void;
  time?: string;
};

export enum Direction {
  Up,
  Down
}

const Player: React.FC<PlayerProps> = ({
  videos,
  videoIndex,
  handleVideoChange,
  time
}: PlayerProps) => {
  let t: NodeJS.Timeout;
  const [player, setPlayer] = useState<VideoJsPlayer | null>(null);
  const [videoRef, setVideoRef] = useState<any | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>();
  const [isMuted, setIsMuted] = useState<boolean>();
  const [displayInfo, setDisplayInfo] = useState<boolean>(false);
  const [displayComments, setDisplayComments] = useState<boolean>(false);
  const [showControls, setShowControls] = useState(true);
  const [currentVideo, setCurrentVideo] = useState<FeedVideo>();
  const {user: currentUser} = useUser();
  const [controlsHover, setControlsHover] = useState(false);
  const [onError, setOnError] = useState(false);
  const [mouseMoveTimeout, setMouseMoveTimeout] = useState<number>();
  const unmounted = useRef(false);
  const infoBoxRef = useRef<HTMLDivElement>(null);
  const [liked, setLiked] = useState(false);
  const [likes, setLikes] = useState(0);
  const [playerCurrentTime, setPlayerCurrentTime] = useState<
    {id: string; time: number | undefined}[]
  >([]);
  const [showHeartAnim, setShowHeartAnim] = useState(false);
  const displayState = useReactiveVar(displayStateVar);
  const audioState = useReactiveVar(audioStateVar);
  const stopVideoState = useReactiveVar(stopVideoStateVar);

  const [openFullScreen, exitFullScreen] = useFullScreen();

  const {getToolState, updateToolState} = useSessionStorage();

  const isCurrentVideoValid = (): boolean => {
    return videoIndex !== null && videoIndex >= 0;
  };

  const getSrcForPlayer = useCallback(() => {
    if (isCurrentVideoValid() && videos && videos.length) {
      const video = videos[videoIndex || 0];
      if (video) {
        setCurrentVideo(video);
        return video.playbackUrl!.split("https://stream.mux.com/")[1];
      }
    }
    setOnError(true);
    const ENV = process.env.NODE_ENV;
    if (ENV === "development") {
      console.error("Error geting current video in Player component");
    }
    return "";
  }, [videoIndex, videos]);

  // catch errors for autoplay
  const handlePlay = (promise: Promise<void> | undefined) => {
    if (promise !== undefined) {
      if (window.analytics) {
        if (window.analytics) {
          window.analytics.track("Video Content Started", {
            asset_id: currentVideo?.externalId,
            creator: currentVideo?.username,
            description: currentVideo?.description,
            keywords: currentVideo?.tags,
            title: currentVideo?.title,
            total_length: currentVideo?.properties?.duration
          });
        }
      }
      promise
        .then(function () {
          if (!player?.paused()) {
            setIsPlaying(true);
          }
        })
        .catch(function () {
          // autoplay failed
          setIsPlaying(false);
        });
    }
  };

  useEffect(() => {
    const toolState = getToolState();
    if (toolState) {
      setDisplayInfo(!!toolState.informationBox);
      setDisplayComments(!!toolState.comments);
      setTimeout(() => {
        infoBoxRef.current?.scrollTo(0, toolState.informationBoxScrollY || 0);
      });
    }

    return () => {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    setTimeout(() => setShowHeartAnim(false), 500);
  }, [showHeartAnim]);

  const handleTogglePlayerState = () => {
    if (player?.paused()) {
      player.play();
    } else {
      player?.pause();
    }

    setIsPlaying(!player?.paused());
  };

  const keyDownHandler = (evt: any) => {
    if (evt.code === "Space" && !displayComments) {
      handleTogglePlayerState();
    }
  };

  const changeMuteState = (state: boolean) => {
    player?.muted(state);
    setIsMuted(state);
  };

  useEffect(() => {
    if (stopVideoState) {
      player?.pause();
    } else {
      player?.play();
    }
  }, [stopVideoState]);

  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler, false);
    return () => {
      document.removeEventListener("keydown", keyDownHandler, false);
    };
  });

  useEffect(() => {
    // mounted & ref is set. Instantiate player
    if (isCurrentVideoValid() && videoRef && !player) {
      setPlayer(
        videojs(videoRef, {
          plugins: {
            mux: {
              debug: false,
              data: {
                env_key: process.env.REACT_APP_MUX_KEY,
                player_name: "Single Video Player",
                player_init_time: Date.now()
              }
            }
          }
        })
      );
    }

    const newSrc = getSrcForPlayer();
    // player is ready, but no source yet. Add source and play video.
    if (
      videoRef &&
      player &&
      isCurrentVideoValid() &&
      (!player.src() || player.src() !== newSrc) &&
      newSrc
    ) {
      player.src({
        src: newSrc,
        type: "video/mux"
      });
      player.ready(() => {
        const p = player.play();
        handlePlay(p);
        player.volume(audioState.volume);
        changeMuteState(audioState.muted);
      });
    } else if (player) {
      player.pause();
    }
  }, [videoRef, player, videoIndex, getSrcForPlayer]);

  const trackEnd = debounce(() => {
    if (window.analytics) {
      window.analytics.track("Video Content End", {
        asset_id: currentVideo?.externalId,
        creator: currentVideo?.username,
        description: currentVideo?.description,
        keywords: currentVideo?.tags,
        position: player?.currentTime(),
        title: currentVideo?.title,
        total_length: currentVideo?.properties?.duration
      });
    }
  }, 1000);

  const checkAndTrackEnd = (timeNow: number) => {
    const duration = player?.duration() || 0;
    if (duration - timeNow < 0.5) {
      trackEnd();
    }
  };

  useEffect(() => {
    if (videoRef && player) {
      player.on("timeupdate", () => checkAndTrackEnd(player.currentTime()));
    }
  }, [player]);

  // fire heartbeat traking while video plays
  useEffect(() => {
    if (isPlaying) {
      t = setInterval(() => {
        if (window.analytics) {
          window.analytics.track("Video Content Playing", {
            asset_id: currentVideo?.externalId,
            creator: currentVideo?.username,
            description: currentVideo?.description,
            keywords: currentVideo?.tags,
            position: player?.currentTime(),
            title: currentVideo?.title,
            total_length: currentVideo?.properties?.duration
          });
        }
      }, 7000);
    } else if (t) {
      clearInterval(t);
    }
    return () => {
      if (t) {
        clearInterval(t);
      }
    };
  }, [isPlaying]);

  // cleanup when component is unmounted
  useEffect(() => {
    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, []);

  useEffect(() => {
    if (currentVideo) {
      setLiked(currentVideo.liked!);
      setLikes(currentVideo.likes!);

      const [video] = playerCurrentTime.filter(
        (item) => item.id === currentVideo.properties?.id
      );

      if (video && video.time) {
        setCurrentTime(video.time);
        player?.currentTime(video.time);
      } else if (time) {
        setCurrentTime(parseInt(time));
        player?.currentTime(parseInt(time));
      }
    }
  }, [currentVideo, player]);

  // need to re-play when re-opening
  useEffect(() => {
    if (player && displayState === DisplayMode.theater) {
      setTimeout(() => {
        const p = player.play();
        handlePlay(p);
        setIsPlaying(true);
      });
    }
  }, [player, displayState]);

  const [unlikeVideo, {loading: unlikeQueryLoading}] = useUnlikeVideoMutation({
    onCompleted: () => {
      setLiked(false);
      setLikes(likes - 1);
    }
  });

  const [likeVideo, {loading: likeQueryLoading}] = useLikeVideoMutation({
    onCompleted: () => {
      setLiked(true);
      setLikes(likes + 1);
    }
  });

  const likeButtonLoading = () => {
    return unlikeQueryLoading || likeQueryLoading;
  };

  const options: any = {
    timelineHoverPreviewsUrl: false,
    loadingSpinner: false,
    plugins: {
      mux: {
        data: {}
      }
    }
  };

  const onClickLikeButton = useCallback(() => {
    if (!likeButtonLoading()) {
      if (liked) {
        unlikeVideo({
          variables: {externalId: currentVideo?.externalId}
        });
        if (window.analytics) {
          window.analytics.track("Unliked Video", {
            externalId: currentVideo?.externalId,
            uuid: currentUser?.uuid
          });
        }
        return;
      }
      setShowHeartAnim(true);
      likeVideo({variables: {externalId: currentVideo?.externalId}});
      if (window.analytics) {
        window.analytics.track("Liked Video", {
          externalId: currentVideo?.externalId,
          uuid: currentUser?.uuid
        });
      }
    }
  }, [currentVideo?.externalId, liked, likeVideo, unlikeVideo]);

  const handleTimeUpdate = () => {
    setCurrentTime(player?.currentTime() || 0);
  };

  const handleCurrentTimeUpdate = (value: number) => {
    player?.currentTime(value);
  };

  const handleVolumeChange = (volume: number) => {
    player?.volume(volume);
    audioStateVar({...audioState, volume});
  };

  const handleMuteState = () => {
    changeMuteState(!isMuted);
    audioStateVar({...audioState, muted: !isMuted});
  };

  const handlePlayerMouseMove = () => {
    if (displayState === DisplayMode.fullscreen) {
      setShowControls(true);
      clearTimeout(mouseMoveTimeout);
      const result = window.setTimeout(() => {
        if (!unmounted.current) {
          setShowControls(false);
        }
      }, 3000);

      setMouseMoveTimeout(result);
    }
  };

  const handleControlsMouseOver = () => {
    setControlsHover(true);
  };

  const handleControlsMouseOut = () => {
    setControlsHover(false);
  };

  const handleDisplayInfo = () => {
    setDisplayComments(false);
    const state = getToolState();
    updateToolState({
      ...state,
      comments: false,
      informationBox: !displayInfo
    });
    setDisplayInfo(!displayInfo);
  };

  const handleDisplayComments = () => {
    setDisplayInfo(false);
    const state = getToolState();
    updateToolState({
      ...state,
      comments: !displayComments,
      informationBox: false
    });
    setDisplayComments(!displayComments);
  };

  const handleOnScroll = () => {
    const state = getToolState();
    const scrollPosition = infoBoxRef.current?.scrollTop || 0;
    updateToolState({...state, informationBoxScrollY: scrollPosition});
  };

  const trackAction = (action: Direction) => {
    if (window.analytics) {
      window.analytics.track(
        `Single video ${action === Direction.Up ? "ascend" : "descend"}`,
        {
          uuid: currentUser?.uuid || "Anonymous"
        }
      );
    }
  };

  const changeCurrentVideo = useCallback(
    (action: Direction) => {
      const videosLength = videos ? videos.length - 1 : 0;
      setShowHeartAnim(false);
      if (videosLength === 0 || displayComments) {
        return;
      }

      if (currentVideo) {
        const players = playerCurrentTime.filter(
          (el) => el.id !== currentVideo?.properties?.id || ""
        );
        setPlayerCurrentTime([
          ...players,
          {
            id: currentVideo?.properties?.id || "",
            time: player?.currentTime()
          }
        ]);
      }
      const nextIndex =
        action === Direction.Up ? videoIndex! + 1 : videoIndex! - 1;
      handleVideoChange(nextIndex);
      trackAction(action);
    },
    [
      currentVideo,
      displayComments,
      handleVideoChange,
      player,
      playerCurrentTime,
      videoIndex,
      videos
    ]
  );

  const handleDoubleClick = () => {
    if (displayState === DisplayMode.fullscreen) {
      displayStateVar(DisplayMode.theater);
      exitFullScreen();
    } else {
      displayStateVar(DisplayMode.fullscreen);
      openFullScreen();
    }
  };

  return (
    <>
      {displayInfo && (
        <DescriptionContainer
          description={currentVideo?.description}
          ref={infoBoxRef}
          tags={currentVideo?.tags}
          onScroll={handleOnScroll}
        />
      )}
      {displayComments && currentVideo && (
        <CommentsContainer video={currentVideo} />
      )}
      <styled.HeartAnim
        className={showHeartAnim ? "show" : ""}
        src={HeartIcon}
      />
      <ReactScrollWheelHandler
        disableSwipe
        downHandler={() => changeCurrentVideo(Direction.Down)}
        upHandler={() => changeCurrentVideo(Direction.Up)}
      >
        <styled.PlayerContainer
          displayState={displayState}
          hover={controlsHover}
          show={showControls}
          onMouseMove={handlePlayerMouseMove}
        >
          {onError ? (
            <VideoErrorContainer />
          ) : (
            <>
              <ArrowsControl
                currentVideo={videoIndex}
                displayState={displayState}
                hover={controlsHover}
                show={showControls}
                videos={videos}
                onChangeCurrentVideo={changeCurrentVideo}
                onMouseOut={handleControlsMouseOut}
                onMouseOver={handleControlsMouseOver}
                onVideoChange={handleVideoChange}
              />
              <video
                autoPlay={false}
                className="video-js vjs-fill"
                data-setup={JSON.stringify(options)}
                height="100%"
                id="mux-current-vid"
                loop={true}
                preload="auto"
                ref={setVideoRef}
                width="100%"
                onDoubleClick={handleDoubleClick}
                onError={() => setOnError(true)}
                onTimeUpdate={handleTimeUpdate}
              />
              <styled.Controls
                displayState={displayState}
                hover={controlsHover}
                show={showControls}
                onMouseOut={handleControlsMouseOut}
                onMouseOver={handleControlsMouseOver}
              >
                <div data-cy="video-author-profile">
                  <Link to={getProfileUrl(currentVideo?.username!)}>
                    <styled.Username>{currentVideo?.username}</styled.Username>
                  </Link>
                </div>
                <div data-cy="video-title">
                  <styled.Title>{currentVideo?.title}</styled.Title>
                </div>
                <div data-cy="video-tags">
                  <TagsContainer tags={currentVideo?.tags} />
                </div>

                <styled.SocialIconsContainer>
                  <styled.AvatarPositioner>
                    <Avatar
                      avatarURL={currentVideo?.avatar}
                      size={24}
                      username={currentVideo?.username}
                      uuid={currentVideo?.userUuid}
                    />
                  </styled.AvatarPositioner>
                  <styled.InfoButton
                    alt="info"
                    src={displayInfo ? InfoIconActive : InfoIcon}
                    onClick={handleDisplayInfo}
                  />
                  {currentUser && (
                    <>
                      <styled.LikeWrapper>
                        <LikeButton
                          isDisabled={likeButtonLoading()}
                          likeCount={likes}
                          liked={liked}
                          onClick={onClickLikeButton}
                        />
                      </styled.LikeWrapper>
                      <styled.CommentButton
                        alt="comment"
                        src={displayComments ? FilledCommentIcon : CommentIcon}
                        onClick={handleDisplayComments}
                      />
                    </>
                  )}
                </styled.SocialIconsContainer>
                <PlayerControls
                  currentTime={currentTime}
                  duration={player?.duration()}
                  isMuted={isMuted}
                  isPlaying={isPlaying}
                  volume={player?.volume()}
                  onMuteToggle={handleMuteState}
                  onTogglePlayerState={handleTogglePlayerState}
                  onUpdateCurrentTime={handleCurrentTimeUpdate}
                  onUpdateVolume={handleVolumeChange}
                />
              </styled.Controls>
            </>
          )}

          <TimeoutAlert />
        </styled.PlayerContainer>
      </ReactScrollWheelHandler>
    </>
  );
};

export default React.memo(Player);
