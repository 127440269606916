export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 25;
export const USERNAME_REGEX = /[^\da-zA-Z._]/; // NOT alphanumeric, period, or underscore

export const NAME_MIN_LENGTH = 3;
export const NAME_MAX_LENGTH = 25;

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 20;

export const AGE_MIN = 13;

export const LOCATION_MAX_LENGTH = 25;

export const WEBSITE_MAX_LENGTH = 50;

export const BIO_MAX_LENGTH = 150;

export const TITLE_MAX_LENGTH = 150;

export const DESC_MAX_LENGTH = 2500;

export const TAGS_MIN = 1;
export const TAGS_MAX = 30;

export const TOKEN_NAME = "505a2da021b02974e6fb12aa4e1bc45b90148251";

export const PROFILE_VIDEOS_PER_PAGE = 24;

export const PROFILE_USERS_PER_PAGE = 150;

export const COMMENTS_FETCH_LIMIT = 10;

export const COMMENTS_TEXT_LIMIT = 255;

export const INITIAL_ITEMS_COUNT = 20;

export const LOAD_MORE_ITEMS_COUNT = 25;

export const TEXT_HOVER_SCALE = 1.15;

export enum AnalyticsEvents {
  FollowUser = "Follow User",
  UnfollowUser = "Unfollow User",
  UploadProcessBegin = "User begins the video upload process",
  UploadProcessEnd = "User completes the video upload process",
  UploadedGif = "User uploads gif"
}

// clipping tool
export const CLIPPING_TOOL_IMAGE_SIZE = 72;
export const CLIPPING_TOOL_TIMELINE_OFFSET = 125;
export const SCRUBBER_SIDE_BAR_WIDTH = 12;
export const SCRUBBER_MIN_SECONDS = 1;
export const SCRUBBER_MAX_SECONDS = 10;
export const SECONDS_PER_FRAME = 5;
export const SCRUBBER_INITIAL_START = 0;
export const SCRUBBER_INITIAL_END = 10;
// clipping tool

export const MAX_TAGS_COUNT = 30;

export const MUX_BASE_URL = "https://stream.mux.com/";
