import {useCallback, useMemo} from "react";
import {DraggableData, DraggableEvent} from "react-draggable";
import {
  CLIPPING_TOOL_IMAGE_SIZE,
  CLIPPING_TOOL_TIMELINE_OFFSET,
  SCRUBBER_MAX_SECONDS,
  SCRUBBER_MIN_SECONDS,
  SCRUBBER_SIDE_BAR_WIDTH,
  SECONDS_PER_FRAME
} from "config/constants";
import {ScrubberSideBarProps} from "./types";

// 1 second
const MIN_WIDTH =
  (CLIPPING_TOOL_IMAGE_SIZE * SCRUBBER_MIN_SECONDS) / SECONDS_PER_FRAME;
// 10 seconds
const MAX_WIDTH =
  (CLIPPING_TOOL_IMAGE_SIZE * SCRUBBER_MAX_SECONDS) / SECONDS_PER_FRAME;

export function useScrubberSideBar({
  isLeft,
  scrubberTranslationX,
  scrubberWidth,
  setScrubberTranslationX,
  setScrubberWidth,
  timelineContentSize,
  timelineScrollPosition
}: ScrubberSideBarProps) {
  const defaultPosition = useMemo(() => {
    return {
      x: isLeft ? scrubberTranslationX : scrubberTranslationX + scrubberWidth,
      y: 0
    };
  }, [isLeft, scrubberTranslationX, scrubberWidth]);

  const onDrag = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      if (isLeft) {
        // changing start position and width
        const endPosition = scrubberTranslationX + scrubberWidth;
        setScrubberWidth(endPosition - data.x);
        setScrubberTranslationX(data.x);
        return;
      }

      // changing width only
      setScrubberWidth(data.x - scrubberTranslationX);
    },
    [
      isLeft,
      scrubberTranslationX,
      scrubberWidth,
      setScrubberTranslationX,
      setScrubberWidth
    ]
  );

  const bounds = useMemo(() => {
    if (isLeft) {
      const positionOfTimelineStart =
        CLIPPING_TOOL_TIMELINE_OFFSET - timelineScrollPosition;
      const endPosition = scrubberTranslationX + scrubberWidth;

      return {
        left: Math.max(
          endPosition - MAX_WIDTH,
          positionOfTimelineStart,
          SCRUBBER_SIDE_BAR_WIDTH
        ),
        right: endPosition - MIN_WIDTH
      };
    }

    const rootContainer = document.getElementById("rootContainer");
    const positionOfTimelineEnd =
      timelineContentSize -
      CLIPPING_TOOL_TIMELINE_OFFSET -
      timelineScrollPosition;
    return {
      left: scrubberTranslationX + MIN_WIDTH,
      right: rootContainer?.clientWidth
        ? Math.min(
            scrubberTranslationX + MAX_WIDTH,
            positionOfTimelineEnd,
            rootContainer.clientWidth - SCRUBBER_SIDE_BAR_WIDTH - 12
          )
        : Math.min(scrubberTranslationX + MAX_WIDTH, positionOfTimelineEnd)
    };
  }, [
    isLeft,
    scrubberTranslationX,
    scrubberWidth,
    timelineContentSize,
    timelineScrollPosition
  ]);

  const position = useMemo(() => {
    return {
      x: isLeft ? scrubberTranslationX : scrubberTranslationX + scrubberWidth,
      y: 0
    };
  }, [isLeft, scrubberTranslationX, scrubberWidth]);

  const positionOffset = useMemo(() => {
    return {x: isLeft ? "-100%" : 0, y: 0};
  }, [isLeft]);

  return {bounds, defaultPosition, onDrag, position, positionOffset};
}
