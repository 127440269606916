import {ReactComponent as DiscordIcon} from "icons/24/discord.svg";
import {ReactComponent as ZoomIcon} from "icons/24/zoom.svg";
import {ReactComponent as PackageIcon} from "icons/24/send-package.svg";
import {ReactComponent as GroupIcon} from "icons/24/group.svg";
import {strings} from "config/strings";
import {UnlockableContentTypes} from "apollo";

export interface Reward {
  type: UnlockableContentTypes;
  description: string;
  url: string;
  code?: string;
}

export interface IFormValues {
  [UnlockableContentTypes.DiscordInvite]?: Reward;
  [UnlockableContentTypes.ScheduledCall]?: Reward;
  [UnlockableContentTypes.SendProduct]?: Reward;
  [UnlockableContentTypes.DigitalTicket]?: Reward;
}

export const RequireCode = [
  UnlockableContentTypes.SendProduct,
  UnlockableContentTypes.DigitalTicket
];

export const TierNumberDescription = [
  strings.generic.thumbnailNFTs,
  strings.generic.fullVideoNFTs
];

export const ContentMap = {
  [UnlockableContentTypes.ScheduledCall]: {
    label:
      strings.pages.upload.metaItems.exclusivebenefits.exclusivebenefitsModal
        .rewards.videoCall.title,
    icon: ZoomIcon
  },
  [UnlockableContentTypes.DiscordInvite]: {
    label:
      strings.pages.upload.metaItems.exclusivebenefits.exclusivebenefitsModal
        .rewards.discord.title,
    icon: DiscordIcon
  },
  [UnlockableContentTypes.SendProduct]: {
    label:
      strings.pages.upload.metaItems.exclusivebenefits.exclusivebenefitsModal
        .rewards.product.title,
    icon: PackageIcon
  },
  [UnlockableContentTypes.DigitalTicket]: {
    label:
      strings.pages.upload.metaItems.exclusivebenefits.exclusivebenefitsModal
        .rewards.ticket.title,
    icon: GroupIcon
  }
};

export interface ExclusiveBenefitsProps {
  disabled?: boolean;
  name?: string;
  tiersNumber: number[];
  onChange: (value: Array<Reward[]>) => void;
  value?: Array<Reward[]>;
}
