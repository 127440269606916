import React from "react";
import {Router, Switch, Route} from "react-router-dom";
import {createBrowserHistory} from "history";
import {ApolloProvider} from "@apollo/client";

import Feed from "pages/Feed/FeedPage";
import Onboarding from "pages/User/Onboarding";
import Profile from "pages/User/Profile";
import Settings from "pages/Settings/Settings";
import SettingsAccount from "pages/Settings/SettingsAccount";
import SettingsPassword from "pages/Settings/SettingsPassword";
import SettingsHelp from "pages/Settings/SettingsHelp";
import ProfileEdit from "pages/User/ProfileEdit";
import Upload from "pages/Upload";
import Web3Upload from "pages/Web3Upload";
import ErrorPage from "pages/ErrorPage";
import SignInPage from "pages/Auth/SignInPage";
import SignUpPage from "pages/Auth/SignUpPage";
import VerifyEmailPage from "pages/Auth/VerifyEmailPage";
import ForgotPassword from "pages/Auth/ForgotPasswordPage";
import TopNavigation from "components/TopNavigation";
import PrivateRoute from "components/Auth/PrivateRoute";
import {Routes} from "config/routes";
import {Analytics} from "components/Analytics";
import {client} from "apollo";
import RouteEvents from "utils/RouteEvents";
import FeedPlayer from "pages/Player/FeedPlayer";
import LikedPostPlayer from "pages/Player/LikedPostPlayer";
import PostedVideosPlayer from "pages/Player/PostedVideosPlayer";
import FollowingPlayer from "pages/Player/FollowingPlayer";
import ForYouPlayer from "pages/Player/ForYouPlayer";
import Marketplace from "pages/NFT/Marketplace";
import MintNFTs from "pages/NFT/MintNFTs";
import Search from "pages/Search";
import VideoDetails from "pages/VideoDetails";

import {
  ChainId,
  ThirdwebProvider
} from "@thirdweb-dev/react/dist/thirdweb-dev-react.cjs";

export const App: React.FC = () => {
  return (
    <ThirdwebProvider desiredChainId={ChainId.Rinkeby}>
      <ApolloProvider client={client}>
        <Router history={createBrowserHistory()}>
          <>
            <TopNavigation />
            <RouteEvents>
              <Switch>
                <PrivateRoute component={Upload} path={Routes.upload} />
                <PrivateRoute component={Web3Upload} path={Routes.web3Upload} />
                <Route component={SignInPage} path={Routes.signin} />
                <Route component={SignUpPage} path={Routes.signup} />
                <Route component={FeedPlayer} path={Routes.feedPlayer} />
                <Route component={VerifyEmailPage} path={Routes.verifyEmail} />
                <Route component={VideoDetails} path={Routes.videoDetails} />
                <Route
                  component={ForgotPassword}
                  path={Routes.forgotPassword}
                />
                <Route component={Onboarding} path={Routes.onboarding} />
                <Route
                  component={PostedVideosPlayer}
                  exact={true}
                  path={Routes.PostedVideoPlayer}
                />
                <Route
                  component={LikedPostPlayer}
                  exact={true}
                  path={Routes.likedPostPlayer}
                />
                <PrivateRoute exact component={Feed} path={Routes.home} />
                <PrivateRoute exact component={Feed} path={Routes.explore} />
                <PrivateRoute
                  component={ProfileEdit}
                  path={Routes.profileEdit}
                />
                <PrivateRoute
                  component={SettingsAccount}
                  path={Routes.settingsAccount}
                />
                <PrivateRoute
                  component={SettingsPassword}
                  path={Routes.settingsPassword}
                />
                <PrivateRoute
                  component={SettingsHelp}
                  path={Routes.settingsHelp}
                />
                <PrivateRoute component={Settings} path={Routes.settings} />
                <PrivateRoute
                  component={ForYouPlayer}
                  path={Routes.forYouPlayer}
                  redirectTo={Routes.signup}
                />
                <PrivateRoute
                  component={FollowingPlayer}
                  path={Routes.followingPlayer}
                  redirectTo={Routes.signup}
                />
                <Route component={Marketplace} path={Routes.marketplace} />
                <Route component={MintNFTs} path={Routes.mintNFTs} />
                <Route component={Search} path={Routes.search} />
                <Route component={Profile} exact={true} path={Routes.profile} />
                <Route component={ErrorPage} path="*" />
              </Switch>
            </RouteEvents>
            <Analytics />
          </>
        </Router>
      </ApolloProvider>
    </ThirdwebProvider>
  );
};
