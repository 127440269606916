import React, {useState} from "react";

import {
  Container,
  Label,
  HiddenCheckbox,
  StyledCheckbox,
  Icon,
  Props
} from "./styles";

export const Checkbox = React.forwardRef<HTMLInputElement, Props>(
  (props: Props, ref: any) => {
    const {checked: checkedProp, className, activeBorderColor} = props;
    const [checked, setChecked] = useState(checkedProp);

    return (
      <Container className={className}>
        <Label>
          <HiddenCheckbox
            {...props}
            checked={checked}
            ref={ref}
            onChange={(e) => {
              setChecked(e.target.checked);
              if (props.onChange) {
                props.onChange(e, e.target.checked);
              }
            }}
          />
          <StyledCheckbox
            checked={checked}
            invalid={props.invalid}
            activeBorderColor={activeBorderColor}
          >
            <Icon
              style={{
                stroke: props.stroke ? props.stroke : "white"
              }}
              viewBox="0 0 24 24"
            >
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </StyledCheckbox>
          {props.label && <span>{props.label}</span>}
        </Label>
      </Container>
    );
  }
);
