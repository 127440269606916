import styled from "styled-components";
import {colors} from "theme/colors";
import TextInputCounter from "components/Atoms/TextInputCounter";
import Text from "components/Atoms/Text";

import {
  InputBorderType,
  InputProps,
  StateProps,
  TextInputContainerProps
} from "./types";

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.label<TextInputContainerProps>`
  background-color: ${(props) =>
    props.isDisabled ? colors.primary.black : colors.inputBackground};
  ${(props) =>
    props.borderType === InputBorderType.underlined
      ? "border: none;"
      : "border: 1px solid;"}
  border-bottom: 1px solid;
  border-radius: ${(props) =>
    props.borderType === InputBorderType.underlined ? "0px" : "8px"};
  border-color: ${(props) => {
    const {isDisabled, isFocused, isInvalid, isSucceeded} = props;
    if (isDisabled) {
      return colors.primary.darkGrey;
    }

    if (isInvalid) {
      return colors.secondary.error;
    }

    if (isSucceeded) {
      return colors.secondary.success;
    }

    if (isFocused) {
      return colors.primary.cyan;
    }

    return colors.primary.darkGrey;
  }};
  position: relative;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  overflow: hidden;
`;

export const InputAndLabelContainer = styled.div<{
  label?: string;
  clearButtonExist?: boolean;
  leftIconExist: boolean;
  rightIconExist: boolean;
}>`
  padding-top: ${({label}) => (label ? "18" : "12")}px;
  padding-bottom: ${({label}) => (label ? "6" : "12")}px;
  padding-left: ${({clearButtonExist, leftIconExist}) =>
    clearButtonExist || leftIconExist ? 8 : 14}px;
  padding-right: ${({rightIconExist}) => (rightIconExist ? 8 : 14)}px;
  position: relative;
  display: flex;
  grid-column: 3;
`;

export const Input = styled.input<InputProps>`
  width: 100%;
  border: none;
  background: transparent;
  appearance: none;
  outline: none;
  padding: 0px;

  font-family: ${(props) => props.fontFamily};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => props.lineHeight}px;

  color: ${(props) =>
    props.disabled ? colors.primary.mediumGrey : colors.primary.white};

  &::placeholder {
    color: ${(props) =>
      props.label ? "transparent" : colors.primary.lightGrey};
  }

  &:focus::placeholder {
    color: ${colors.primary.lightGrey};
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Area = styled.textarea<InputProps>`
  width: 100%;
  border: none;
  background: transparent;
  appearance: none;
  outline: none;
  padding: 0px;

  font-family: ${(props) => props.fontFamily};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => props.lineHeight}px;

  color: ${(props) =>
    props.disabled ? colors.primary.mediumGrey : colors.primary.white};

  &::placeholder {
    color: ${(props) =>
      props.label ? "transparent" : colors.primary.lightGrey};
  }

  &:focus::placeholder {
    color: ${colors.primary.lightGrey};
  }
`;

export const RightSideContainer = styled.div`
  display: grid;
  grid-column: 4;
  grid-template-rows: 1fr auto;
  margin-right: 14px;
`;

export const LeftSideContainer = styled.div`
  display: flex;
  grid-column: 1;
  height: 100%;
  aspect-ratio: 1;
  position: relative;
`;

export const Counter = styled(TextInputCounter)`
  grid-row: 2;
  margin-bottom: 4px;
`;

export const LeftIconContainer = styled.div<{clearButtonExist?: boolean}>`
  display: flex;
  grid-column: 2;
  align-items: center;
  justify-content: center;
  margin-left: ${({clearButtonExist}) => (clearButtonExist ? 8 : 14)}px;
`;

export const IconContainer = styled.div`
  grid-row: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HelperText = styled(Text)<StateProps>`
  color: ${(props) => {
    const {isDisabled, isInvalid, isSucceeded} = props;
    if (isDisabled) {
      return colors.primary.mediumGrey;
    }

    if (isInvalid) {
      return colors.secondary.error;
    }

    if (isSucceeded) {
      return colors.secondary.success;
    }

    return colors.primary.lightGrey;
  }};
  margin-top: 4px;
  margin-left: 13px;
`;
