import React from "react";
import ClippingToolItemText from "components/Atoms/ClippingToolItemText";
import ClippingToolItemImage from "components/Molecules/ClippingToolItemImage";

import {ClippingToolItemProps} from "./types";
import {Container} from "./styles";

const ClippingToolItem: React.FC<ClippingToolItemProps> = ({
  itemProps,
  isLast,
  isVideoCodecSupported,
  uploadedVideoStoryboard,
  videoInfo,
  videoFile
}: ClippingToolItemProps) => {
  return (
    <Container>
      <ClippingToolItemText
        itemProps={itemProps}
        isLast={isLast}
        videoDuration={videoInfo.duration}
      />
      <ClippingToolItemImage
        itemProps={itemProps}
        isVideoCodecSupported={isVideoCodecSupported}
        uploadedVideoStoryboard={uploadedVideoStoryboard}
        videoFile={videoFile}
        videoInfo={videoInfo}
      />
    </Container>
  );
};

export default React.memo(ClippingToolItem);
