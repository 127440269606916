import LeftIcon from "icons/20/chevron-left.svg";
import UserIcon from "icons/24/user.svg";
import HelpIcon from "icons/24/help-circle.svg";
import InfoIcon from "icons/24/info.svg";
import LogoutIcon from "icons/24/logout.svg";
import {strings} from "config/strings";
import {useHistory} from "react-router-dom";
import {Routes} from "config/routes";
import {Modal} from "components/Modal";
import {useState} from "react";
import {useAuth} from "hooks/useAuth";
import {SettingsItem} from "./SettingsItem";
import {
  BackButton,
  Icon,
  Container,
  MenuSection,
  SectionTitle,
  Title,
  SectionContainer,
  Wrapper
} from "./styles";

type Props = {
  uuid: string;
};

export const SettingsContainer: React.FC<Props> = ({uuid}: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const {signOut} = useAuth();

  const handleOnSignout = () => {
    signOut();
    history.push(Routes.home);
  };

  const handleOnBackClick = () => {
    history.goBack();
  };

  return (
    <Container>
      <BackButton onClick={handleOnBackClick}>
        <Icon src={LeftIcon} />
        {strings.generic.back}
      </BackButton>
      <Wrapper>
        <Title>{strings.pages.settings.title}</Title>
        <SectionContainer>
          <MenuSection showBorder>
            <SectionTitle>{strings.pages.settings.accounts}</SectionTitle>
            <SettingsItem
              icon={UserIcon}
              location={Routes.settingsAccount}
              title={strings.pages.settings.manageAccount}
              uuid={uuid}
            />
          </MenuSection>
          <MenuSection>
            <SectionTitle>{strings.pages.settings.general}</SectionTitle>
            <SettingsItem
              icon={HelpIcon}
              location={Routes.settingsHelp}
              title={strings.pages.settings.help}
            />
            <SettingsItem
              external
              icon={InfoIcon}
              location={Routes.privacy}
              title={strings.pages.settings.privacy}
            />
            <SettingsItem
              external
              icon={InfoIcon}
              location={Routes.tos}
              title={strings.pages.settings.tos}
            />
            <SettingsItem
              handleOnClick={() => setOpenModal(true)}
              icon={LogoutIcon}
              title={strings.pages.settings.logout}
            />
          </MenuSection>
        </SectionContainer>
        <Modal
          isDialog
          closeModalFn={() => setOpenModal(false)}
          heading={strings.pages.settings.logout}
          isOpen={openModal}
          saveBtnLabel={strings.pages.settings.logout}
          saveFn={handleOnSignout}
          subheading={strings.pages.settings.messageLogout}
        />
      </Wrapper>
    </Container>
  );
};
