import {useEffect, useState} from "react";
import {Auth, Hub} from "aws-amplify";
// eslint-disable-next-line import/no-extraneous-dependencies
import {HubCallback} from "@aws-amplify/core";
// eslint-disable-next-line import/no-extraneous-dependencies
import {useApolloClient} from "@apollo/client";
// eslint-disable-next-line import/no-extraneous-dependencies
import {User, GetUserDocument} from "apollo";
// eslint-disable-next-line import/no-extraneous-dependencies
import {CognitoUser} from "@aws-amplify/auth";
import {useQuery} from "@apollo/react-hooks";
import {useAuthToken} from "./useAuthToken";

export function getAnonymousId(): Promise<string> {
  return new Promise((resolve) => {
    window.analytics?.ready(() => {
      resolve(window.analytics?.user()?.anonymousId());
    });
  });
}

export function useUser() {
  const client = useApolloClient();
  const [user, setUser] = useState<User | null>(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const {setAuthToken, removeAuthToken} = useAuthToken();
  const [anonymousUid, setAnonymousUid] = useState("");

  const {
    loading: IsUserDataLoading,
    data,
    startPolling,
    stopPolling
  } = useQuery(GetUserDocument, {
    skip: !authenticated
  });

  useEffect(() => {
    if (!IsUserDataLoading && data && data.getUser) {
      setUser(data.getUser);
    }
  }, [data, IsUserDataLoading]);

  const getUser = async (cognitoEmail: string) => {
    const {
      data: {getUser: getUserData}
    } = await client.query({query: GetUserDocument});
    // user already onboarded
    if (getUserData) {
      setUser(getUserData);
      return getUserData;
      // user not yet onboarded
    }
    const nonOnboardedUser = {email: cognitoEmail};
    setUser(nonOnboardedUser);
    return nonOnboardedUser;
  };

  useEffect(() => {
    const setUserAsAuthenticated = async (newData: any) => {
      setAuthenticated(true);
      setAuthToken(newData.getAccessToken().getJwtToken());
      const cognitoEmail = newData.getIdToken().payload.email;
      await getUser(cognitoEmail).catch(() => {
        // Noop. This will occaisionally fail if the user spams the refresh button too fast. For
        // some reason the apollo link does not pick up the token from localstorage and so
        // the request fails
      });

      setLoading(false);
    };

    const setUserAsUnauthenticated = async () => {
      setAnonymousUid(await getAnonymousId());
      setAuthenticated(false);
      removeAuthToken();
      setUser(null);
      setLoading(false);
    };

    const refreshSession = async (cognitoUser: CognitoUser, session: any) => {
      cognitoUser.refreshSession(
        session.getRefreshToken(),
        async (err, newData) => {
          if (!err) {
            await setUserAsAuthenticated(newData);
          } else {
            setUserAsUnauthenticated();
          }
        }
      );
    };

    Auth.currentAuthenticatedUser()
      .then(async (cognitoUser: CognitoUser) => {
        const session = await Auth.currentSession();
        const idTokenExpire = session.getIdToken().getExpiration();
        const currentTimeSeconds = Math.round(+new Date() / 1000);

        if (idTokenExpire < currentTimeSeconds) {
          await refreshSession(cognitoUser, session);
        } else {
          await setUserAsAuthenticated(session);
        }
      })
      .catch(() => setUserAsUnauthenticated());

    const callback: HubCallback = async (newData) => {
      switch (newData.payload.event) {
        case "signOut":
          setUser(null);
          removeAuthToken();
          setAuthenticated(false);
          break;
        case "signIn":
          const session = await Auth.currentSession();
          setAuthToken(session.getAccessToken().getJwtToken());
          const cognitoEmail = session.getIdToken().payload.email;
          await getUser(cognitoEmail);
          setAuthenticated(true);
          break;
      }
    };

    Hub.listen("auth", callback);

    return () => Hub.remove("auth", callback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    user,
    anonymousUid,
    authenticated,
    loading,
    refetch: getUser,
    startPolling,
    stopPolling
  };
}
