import React from "react";
import {TextTypes} from "components/Atoms/Text/types";
import {DropdownStyleProps} from "components/Atoms/Dropdown/types";

import {CryptoDropdownDataProps} from "./types";
import {
  Description,
  getTitleAndIconColor,
  RootContainer,
  TextContainer,
  Title
} from "./styles";

const CryptoDropdownOption: React.FC<
  DropdownStyleProps<CryptoDropdownDataProps>
> = (props: DropdownStyleProps<CryptoDropdownDataProps>) => {
  const {
    description,
    isDisabled,
    isFocused,
    isOption,
    isSelected,
    label,
    renderIcon
  } = props;
  return (
    <RootContainer>
      {renderIcon &&
        renderIcon(getTitleAndIconColor(isOption, isSelected, isDisabled), 24)}
      <TextContainer>
        <Title
          isDisabled={isDisabled}
          isFocused={isFocused}
          isSelected={isSelected}
          isOption={isOption}
          textType={TextTypes.Other.LinkLabelLLight}
        >
          {label}
        </Title>
        <Description
          isDisabled={isDisabled}
          isFocused={isFocused}
          isSelected={isSelected}
          isOption={isOption}
          textType={TextTypes.Other.LinkLabelLLight}
        >
          {description}
        </Description>
      </TextContainer>
    </RootContainer>
  );
};

export default React.memo(CryptoDropdownOption);
