import React from "react";
import {PossibleTextTypes} from "components/Atoms/Text/types";
import Text from "components/Atoms/Text";

import {
  Container,
  Description,
  Icon,
  IconContainer,
  MintNFTsStepProps,
  StepName,
  TopContainer,
  TopTextContainer
} from "./styles";

const MintNFTsStep: React.FC<MintNFTsStepProps> = (
  props: MintNFTsStepProps
) => {
  const {borderColor, className, description, iconSource, stepName, title} =
    props;
  return (
    <Container borderColor={borderColor} className={className}>
      <TopContainer>
        <IconContainer>
          <Icon alt={stepName} src={iconSource} />
        </IconContainer>
        <TopTextContainer>
          <StepName textType={PossibleTextTypes.CardDescription}>
            {stepName}
          </StepName>
          <Text textType={PossibleTextTypes.CardTitle}>{title}</Text>
        </TopTextContainer>
      </TopContainer>
      <Description textType={PossibleTextTypes.CardDescription}>
        {description}
      </Description>
    </Container>
  );
};

export default React.memo(MintNFTsStep);
