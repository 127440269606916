import * as Yup from "yup";
import * as constants from "config/constants";
import {format, parse, subYears, isDate} from "date-fns";
import {strings} from "config/strings";

export function getNameValidator() {
  return Yup.string()
    .min(constants.NAME_MIN_LENGTH, strings.pages.profile.nameTooShort)
    .max(constants.NAME_MAX_LENGTH, strings.pages.profile.nameTooLong)
    .required(strings.generic.requiredField);
}

export function parseDateString(value: string, originalValue: string) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, "MM/dd/yyyy", new Date());

  return parsedDate;
}

const maxDate = format(subYears(new Date(), 13), "MM/dd/yyyy");
const minDate = format(subYears(new Date(), 130), "MM/dd/yyyy");

export function getBirthdayValidator() {
  return Yup.date()
    .typeError(strings.pages.profile.birthDateInvalid)
    .transform(parseDateString)
    .max(maxDate, strings.pages.profile.userTooYoung)
    .min(minDate, strings.pages.profile.userTooOld)
    .required(strings.generic.requiredField);
}

export function getLocationValidator() {
  return Yup.string()
    .max(constants.LOCATION_MAX_LENGTH, strings.pages.profile.userTooOld)
    .nullable(true);
}

export function getWebsiteValidator() {
  return Yup.string()
    .max(constants.WEBSITE_MAX_LENGTH, strings.pages.profile.websiteTooLong)
    .url(strings.pages.profile.urlNoHttp)
    .nullable(true);
}

export function getBioValidator() {
  return Yup.string()
    .max(constants.BIO_MAX_LENGTH, strings.pages.profile.bioTooLong)
    .nullable(true);
}

export function getEditProfileValidators() {
  return Yup.object().shape({
    name: getNameValidator(),
    location: getLocationValidator(),
    birthday: getBirthdayValidator(),
    website: getWebsiteValidator(),
    bio: getBioValidator()
  });
}
