import {useState, useEffect} from "react";
import {useApolloClient} from "@apollo/client";

import {UploadVideoLinkDocument, CreateVideoDocument} from "apollo";

/**
 * Creates a video instance and returns a link which can be used to upload the content to mux
 */
export const useVideoLink = () => {
  const client = useApolloClient();
  const [link, setLink] = useState<string | undefined>(undefined);
  const [externalId, setExternalId] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  const start = async () => {
    const {data: videoData} = await client.mutate({
      mutation: CreateVideoDocument,
      fetchPolicy: "no-cache"
    });

    const {data: linkData} = await client.query({
      query: UploadVideoLinkDocument,
      variables: {externalId: videoData?.createVideo?.externalId},
      fetchPolicy: "no-cache"
    });

    setLink(linkData?.uploadVideoLink);
    setExternalId(videoData?.createVideo?.externalId);
    setLoading(false);
  };

  useEffect(() => {
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {externalId, videoLink: link, videoLoading: loading};
};
