import styled from "styled-components";
import Text from "components/Atoms/Text";
import {colors} from "theme/colors";

import {StyleProps} from "./types";

export function getTitleAndIconColor(
  isOption: boolean,
  isSelected: boolean,
  isDisabled?: boolean
) {
  if (isDisabled) {
    return colors.primary.lightGrey;
  }

  if (!isOption || !isSelected) {
    return colors.primary.white;
  }

  return colors.primary.black;
}

export const RootContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 20px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-right: auto;
`;

export const Title = styled(Text)<StyleProps>`
  color: ${({isDisabled, isOption, isSelected}) =>
    getTitleAndIconColor(isOption, isSelected, isDisabled)};
`;

export const MenuListContainer = styled.div``;

export const MenuListHeader = styled.div`
  display: flex;
  padding: 14px 20px;
  border-bottom: thin solid ${colors.primary.white10};
`;

export const MenuListTitle = styled(Text)`
  color: ${colors.primary.white};
  margin-left: 16px;
`;

export const MenuListFooterText = styled(Text)`
  color: ${colors.primary.white};
`;

export const MenulistFooter = styled.div`
  display: flex;
  border-top: thin solid ${colors.primary.white10};
  padding: 12px 20px;
  justify-content: center;
`;

export const SelectedContainer = styled.div`
  margin-left: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
