import LeftIcon from "icons/20/chevron-left.svg";
import {strings} from "config/strings";
import {useHistory} from "react-router-dom";
import {Routes} from "config/routes";
import {SettingsItem} from "./SettingsItem";
import {SettingsAction} from "./SettingsAction";
import {
  BackButton,
  Icon,
  Container,
  MenuSection,
  SectionTitle,
  Title,
  SectionContainer,
  Wrapper
} from "./styles";

type Props = {
  uuid: string;
};

export const SettingsAccountContainer: React.FC<Props> = ({uuid}: Props) => {
  const history = useHistory();

  const handleOnBackClick = () => {
    history.goBack();
  };

  return (
    <Container>
      <BackButton onClick={handleOnBackClick}>
        <Icon src={LeftIcon} />
        {strings.generic.back}
      </BackButton>
      <Wrapper>
        <Title>{strings.pages.settings.manageAccountTitle}</Title>
        <SectionContainer>
          <MenuSection>
            <SectionTitle>
              {strings.pages.settings.personalInformation}
            </SectionTitle>
            <SettingsItem
              showOnlyText
              title={strings.pages.settings.personalDisclaimer}
            />
            <SettingsAction
              actionText={strings.pages.settings.change}
              location={Routes.settingsPassword}
              text={strings.pages.settings.password}
              uuid={uuid}
            />
          </MenuSection>
        </SectionContainer>
      </Wrapper>
    </Container>
  );
};
