import {formatTimelineTime} from "utils/FormatUtils";
import {SECONDS_PER_FRAME} from "config/constants";

import {ClippingToolItemTextProps} from "./types";

const DOTS_SMALL = ".";
const DOTS_MEDIUM = ".  .";
const DOTS_COMMON = ".  .  .";

const RENDER_SECOND_TEXT_THRESHOLD = 0.7;
const DOTS_SMALL_THRESHOLD = 0.6;
const DOTS_MEDIUM_THRESHOLD = 0.8;

function getDots(isLast: boolean, second: number, duration: number) {
  if (isLast) {
    const percentage = (duration - second) / SECONDS_PER_FRAME;
    if (percentage <= DOTS_SMALL_THRESHOLD) {
      return DOTS_SMALL;
    }
    if (percentage <= DOTS_MEDIUM_THRESHOLD) {
      return DOTS_MEDIUM;
    }
  }

  return DOTS_COMMON;
}

export function useClippingToolItemText({
  isLast,
  itemProps,
  videoDuration
}: ClippingToolItemTextProps) {
  const {start} = itemProps;
  const startText = formatTimelineTime(start);
  const integerDuration = Math.floor(videoDuration);
  const endText = formatTimelineTime(integerDuration);
  const shouldRenderSecondText =
    isLast &&
    (integerDuration - start) / SECONDS_PER_FRAME >=
      RENDER_SECOND_TEXT_THRESHOLD;
  const dots = getDots(isLast, start, integerDuration);

  return {dots, endText, shouldRenderSecondText, startText};
}
