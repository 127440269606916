import {Token} from "apollo/types";

const COMMENTS_TOKEN_KEY = "commentsTokenKey";
const storage = window.sessionStorage;

export async function getCommentsToken(): Promise<Token | null> {
  try {
    const tokenString: string | null = storage.getItem(COMMENTS_TOKEN_KEY);
    if (tokenString !== null) {
      return JSON.parse(tokenString);
    }
  } catch (error) {
    console.error("error getting token from the storage", error);
  }

  return null;
}

export async function setCommentsToken(token: Token): Promise<boolean> {
  try {
    const tokenString = JSON.stringify(token);
    await storage.setItem(COMMENTS_TOKEN_KEY, tokenString);
    return true;
  } catch (error) {
    console.error("error setting token to the storage", error);
  }

  return false;
}

export async function removeCommentsToken(): Promise<boolean> {
  try {
    await storage.removeItem(COMMENTS_TOKEN_KEY);
    return true;
  } catch (error) {
    console.error("error removing token", error);
  }

  return false;
}
