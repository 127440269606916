import {strings} from "config/strings";
import * as constants from "config/constants";

export function validateName(name: string) {
  if (
    !name ||
    name.length < constants.NAME_MIN_LENGTH ||
    name.length > constants.NAME_MAX_LENGTH
  ) {
    return strings.pages.onboarding.nameError;
  }
  return false;
}

export function validateUsername(username: string) {
  if (
    !username ||
    username.length < constants.USERNAME_MIN_LENGTH ||
    username.length > constants.USERNAME_MAX_LENGTH
  ) {
    return strings.pages.onboarding.usernameEmpty;
  }
  if (constants.USERNAME_REGEX.test(username)) {
    return strings.pages.onboarding.usernameCharacters;
  }
  return false;
}

export function validateBirthday(birthday: string) {
  const msInYear = 31557600000;

  if (!birthday) {
    return strings.pages.onboarding.birthdayEmpty;
  }
  try {
    const entry = new Date(birthday).getTime();
    const today = new Date().getTime();
    if (today - entry <= constants.AGE_MIN * msInYear) {
      return strings.pages.onboarding.birthdayInvalid;
    }
  } catch {
    return strings.pages.onboarding.birthdayInvalid;
  }

  return false;
}
