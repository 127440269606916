import styled from "styled-components";
import Text from "components/Atoms/Text";
import {colors} from "theme/colors";

export const Container = styled.div`
  padding: 24px 72px;
`;

export const ResaleDescription = styled(Text)<{marginBottom?: number}>`
  color: ${colors.primary.lightGrey};
  margin-bottom: ${({marginBottom}) => marginBottom || 0}px;
`;

export const TitleFee = styled(Text)<{marginBottom?: number}>`
  color: ${colors.primary.white};
  margin-bottom: ${({marginBottom}) => marginBottom || 0}px;
`;

export const Legend = styled.span`
  margin-left: 4px;
  color: ${colors.primary.lightGrey};
`;

export const Separator = styled.div`
  margin-block-start: 8px;
  margin-block-end: 16px;
  border-top: 1px dashed ${colors.primary.white};
`;

export const InternalSeparator = styled.div<{
  marginStart: number;
  marginEnd: number;
}>`
  margin-block-start: ${({marginStart}) => marginStart}px;
  margin-block-end: ${({marginEnd}) => marginEnd}px;
  border-top: 1px solid ${colors.primary.mediumGrey};
`;

export const PercentageDetail = styled.div`
  margin-bottom: 16px;
`;

export const PercentageDetailText = styled(Text)<{
  color?: string;
  disabled?: boolean;
}>`
  color: ${({color, disabled}) =>
    disabled ? colors.primary.mediumGrey : color || colors.primary.lightGrey};
  span {
    color: ${({disabled}) =>
      disabled ? colors.primary.mediumGrey : colors.primary.cyan};
    margin-left: auto;
  }
`;
