import React from "react";
import "./Spinner.css";

export const Spinner: React.FC = () => (
  <svg className="ev-spinner" viewBox="0 0 50 50">
    <circle
      className="path"
      cx="25"
      cy="25"
      fill="none"
      r="20"
      strokeWidth="5"
    />
  </svg>
);
