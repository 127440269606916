import styled from "styled-components";
import Text from "components/Atoms/Text";
import {colors} from "theme/colors";
import {DropdownStyleProps} from "components/Atoms/Dropdown/types";
import {getTitleAndIconColor} from "components/Atoms/CryptoDropdownOption/styles";

export const RootContainer = styled.div<{isOption: boolean}>`
  display: flex;
  flex-direction: column;
  padding: ${({isOption}) => (!isOption ? "12px 12px" : "12px 20px")};
`;

export const Title = styled(Text)<DropdownStyleProps<object>>`
  color: ${({isDisabled, isOption, isSelected}) =>
    getTitleAndIconColor(isOption, isSelected, isDisabled)};
`;

export const Description = styled(Text)<DropdownStyleProps<object>>`
  color: ${(props) => {
    if (!props.isOption) {
      return colors.primary.lightGrey;
    }

    if (props.isSelected) {
      return colors.primary.darkGrey;
    }

    if (props.isFocused) {
      return colors.primary.white;
    }

    return colors.primary.lightGrey;
  }};
`;
