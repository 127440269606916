import {getProfileUrl} from "config/routes";
import {useHistory} from "react-router";
import {useCallback} from "react";
import {
  ClearSearch,
  SearchBox
} from "components/Molecules/AlgoliaSearchBox/types";

export function useUserListItem(username: string) {
  const history = useHistory();
  const profileUrl = getProfileUrl(username);

  const onClick = useCallback(() => {
    document.getElementById(ClearSearch)?.click();
    document.getElementById(SearchBox)?.blur();
    history.push(profileUrl);
  }, [history, profileUrl]);

  return {onClick};
}
