import React from "react";
import styled from "styled-components";
import {InfoContentBox} from "./PlayerStyles";

const ScrollContainer = styled.div`
  position: relative;
  width: calc(100% - 17px);
  margin: 47px 4px 6px 13px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255, 0.4);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Title = styled.div`
  position: absolute;
  top: 15px;
  left: 13px;
  font-size: 15px;
  font-weight: 500;
`;

const Description = styled.pre`
  margin-top: 0;
  position: relative;
  width: 100%;
  display: block;
  font-family: "Calibri", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 100;
`;

const Tags = styled(Description)`
  margin-top: 15px;
`;

type DescriptionProps = {
  description: string | undefined;
  tags: string | undefined;
  onScroll: () => void;
};

const DescriptionContainer = React.forwardRef<HTMLDivElement, DescriptionProps>(
  ({description, tags, onScroll}: DescriptionProps, ref) => {
    return (
      <InfoContentBox>
        <Title>Description</Title>
        <ScrollContainer ref={ref} onScroll={onScroll}>
          <Description>{description}</Description>
          <Tags>
            {tags
              ?.split(",")
              .map((tag) => `#${tag.trim()}`)
              .join(" ")}
          </Tags>
        </ScrollContainer>
      </InfoContentBox>
    );
  }
);

export default DescriptionContainer;
