import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Category = {
  __typename?: 'Category';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type CategoryInput = {
  id?: Maybe<Scalars['Int']>;
};

export type ChainContract = {
  __typename?: 'ChainContract';
  address?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  externalLink?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  schemaName?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  totalSupply?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Collaborators = {
  __typename?: 'Collaborators';
  approved?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

export type CollaboratorsInput = {
  approved?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
};

export type CollectionTiers = {
  __typename?: 'CollectionTiers';
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  revenueSplit?: Maybe<Scalars['Float']>;
  tierId?: Maybe<Scalars['Int']>;
  tokenResellPercentage?: Maybe<Scalars['Float']>;
  unlockableContent?: Maybe<Array<Maybe<UnlockableContent>>>;
};

export type CollectionTiersInput = {
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  revenueSplit?: Maybe<Scalars['Float']>;
  tierId?: Maybe<Scalars['Int']>;
  tokenResellPercentage?: Maybe<Scalars['Float']>;
  unlockableContent?: Maybe<Array<Maybe<UnlockableContentInput>>>;
};

export type CreateUser = {
  avatar?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profilePreferences?: Maybe<ProfilePreferencesInput>;
  username?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type CreateVideo = {
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type DeleteVideo = {
  externalId?: Maybe<Scalars['String']>;
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  name: Scalars['String'];
  status: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
};

export enum FeatureFlagList {
  DisableHomeAuth = 'DISABLE_HOME_AUTH'
}

export type Feed = {
  __typename?: 'Feed';
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  pager?: Maybe<Pager>;
  updatedOn?: Maybe<Scalars['String']>;
  userUuid?: Maybe<Scalars['String']>;
  videos?: Maybe<Array<Maybe<FeedVideo>>>;
};

export type FeedVideo = {
  __typename?: 'FeedVideo';
  avatar?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  explicit?: Maybe<Scalars['Boolean']>;
  externalId?: Maybe<Scalars['String']>;
  formStatus?: Maybe<FormStatus>;
  ipfsContentId?: Maybe<Scalars['String']>;
  liked?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  playbackUrl?: Maybe<Scalars['String']>;
  plays?: Maybe<Scalars['Int']>;
  properties?: Maybe<VideoProperties>;
  tags?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<Scalars['String']>;
  userUuid?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type FollowUser = {
  uuid?: Maybe<Scalars['String']>;
};

export enum FormStatus {
  Draft = 'DRAFT',
  Ready = 'READY'
}

export type ImageLink = {
  __typename?: 'ImageLink';
  key?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
};

export type LikeVideo = {
  externalId?: Maybe<Scalars['String']>;
};

export type LikeVideoCount = {
  __typename?: 'LikeVideoCount';
  likesCount?: Maybe<Scalars['Int']>;
  userUuid?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createFeed?: Maybe<Feed>;
  createUser?: Maybe<User>;
  createVideo?: Maybe<Video>;
  deleteVideo?: Maybe<Video>;
  followUser?: Maybe<Scalars['Boolean']>;
  likeVideo?: Maybe<Scalars['Boolean']>;
  removeFollower?: Maybe<Scalars['Boolean']>;
  revokeToken?: Maybe<Scalars['Boolean']>;
  unfollowUser?: Maybe<Scalars['Boolean']>;
  unlikeVideo?: Maybe<Scalars['Boolean']>;
  updateNftCollection?: Maybe<NftCollection>;
  updateUser?: Maybe<User>;
  updateVideo?: Maybe<Video>;
  uploadVideoMetadata?: Maybe<Scalars['String']>;
  videoPlayerEvent?: Maybe<Scalars['Boolean']>;
  videoUpdatedNotification?: Maybe<Video>;
};


export type MutationCreateFeedArgs = {
  anonymousUid?: Maybe<Scalars['String']>;
  page?: Maybe<PageRequest>;
};


export type MutationCreateUserArgs = {
  user: CreateUser;
};


export type MutationCreateVideoArgs = {
  video?: Maybe<CreateVideo>;
};


export type MutationDeleteVideoArgs = {
  video?: Maybe<DeleteVideo>;
};


export type MutationFollowUserArgs = {
  user?: Maybe<FollowUser>;
};


export type MutationLikeVideoArgs = {
  video?: Maybe<LikeVideo>;
};


export type MutationRemoveFollowerArgs = {
  user?: Maybe<FollowUser>;
};


export type MutationRevokeTokenArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationUnfollowUserArgs = {
  user?: Maybe<FollowUser>;
};


export type MutationUnlikeVideoArgs = {
  video?: Maybe<UnlikeVideo>;
};


export type MutationUpdateNftCollectionArgs = {
  nftCollection: NftCollectionInput;
};


export type MutationUpdateUserArgs = {
  user: UpdateUser;
};


export type MutationUpdateVideoArgs = {
  video: UpdateVideo;
};


export type MutationUploadVideoMetadataArgs = {
  externalId: Scalars['String'];
};


export type MutationVideoPlayerEventArgs = {
  videoPlayerEvent?: Maybe<VideoPlayerEvent>;
};


export type MutationVideoUpdatedNotificationArgs = {
  video?: Maybe<UpdateVideoFull>;
};

export type NftAsset = {
  __typename?: 'NFTAsset';
  animationOriginalURL?: Maybe<Scalars['String']>;
  animationURL?: Maybe<Scalars['String']>;
  blockchain?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Array<Maybe<NftOwner>>>;
  contract?: Maybe<ChainContract>;
  description?: Maybe<Scalars['String']>;
  everseType?: Maybe<Scalars['String']>;
  externalLink?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageOriginalURL?: Maybe<Scalars['String']>;
  imagePreviewURL?: Maybe<Scalars['String']>;
  imageThumbnailURL?: Maybe<Scalars['String']>;
  imageURL?: Maybe<Scalars['String']>;
  legalURL?: Maybe<Scalars['String']>;
  mintedBy?: Maybe<Scalars['String']>;
  mintedDate?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owners?: Maybe<Array<Maybe<NftOwner>>>;
  revenueShare?: Maybe<Scalars['Float']>;
  supply?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
  tokenStandard?: Maybe<Scalars['String']>;
  traits?: Maybe<Array<Maybe<NftTraits>>>;
};

export type NftCreator = {
  __typename?: 'NFTCreator';
  address?: Maybe<Scalars['String']>;
  profileImgURL?: Maybe<Scalars['String']>;
};

export type NftOwner = {
  __typename?: 'NFTOwner';
  amount?: Maybe<Scalars['String']>;
  avatarURL?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  walletAddress?: Maybe<Scalars['String']>;
};

export type NftTraits = {
  __typename?: 'NFTTraits';
  maxValue?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  traitCount?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type NftCollection = {
  __typename?: 'NftCollection';
  assetId?: Maybe<Scalars['String']>;
  chainId?: Maybe<Scalars['Int']>;
  collaborators?: Maybe<Array<Maybe<Collaborators>>>;
  collectionId?: Maybe<Scalars['String']>;
  contractId?: Maybe<Scalars['String']>;
  creatorId?: Maybe<Scalars['String']>;
  formStatus?: Maybe<FormStatus>;
  metadataContentId?: Maybe<Scalars['String']>;
  tiers?: Maybe<Array<Maybe<CollectionTiers>>>;
};

export type NftCollectionInput = {
  assetId?: Maybe<Scalars['String']>;
  chainId?: Maybe<Scalars['Int']>;
  collaborators?: Maybe<Array<Maybe<CollaboratorsInput>>>;
  collectionId?: Maybe<Scalars['String']>;
  contractId?: Maybe<Scalars['String']>;
  tiers?: Maybe<Array<Maybe<CollectionTiersInput>>>;
};

export type PageRequest = {
  cursor?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PagedNftAsset = {
  __typename?: 'PagedNFTAsset';
  list?: Maybe<Array<Maybe<NftAsset>>>;
  pager?: Maybe<Pager>;
};

export type PagedUserList = {
  __typename?: 'PagedUserList';
  id?: Maybe<Scalars['String']>;
  pager?: Maybe<Pager>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type PagedVideoList = {
  __typename?: 'PagedVideoList';
  id?: Maybe<Scalars['String']>;
  pager?: Maybe<Pager>;
  videos?: Maybe<Array<Maybe<FeedVideo>>>;
};

export type Pager = {
  __typename?: 'Pager';
  count?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PlaybackId = {
  __typename?: 'PlaybackId';
  id?: Maybe<Scalars['String']>;
  policy?: Maybe<Scalars['String']>;
};

export type ProfilePreferences = {
  __typename?: 'ProfilePreferences';
  show?: Maybe<ProfilePreferencesShow>;
};

export type ProfilePreferencesInput = {
  show?: Maybe<ProfilePreferencesShowInput>;
};

export type ProfilePreferencesShow = {
  __typename?: 'ProfilePreferencesShow';
  birthday?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['Boolean']>;
};

export type ProfilePreferencesShowInput = {
  birthday?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  checkUsernameReservation?: Maybe<UsernameReservation>;
  generateThumbnailFromVideo?: Maybe<Scalars['Boolean']>;
  getAnimatedGifUrl?: Maybe<Scalars['String']>;
  getCategories?: Maybe<Array<Maybe<Category>>>;
  getFeatureFlag?: Maybe<FeatureFlag>;
  getFeed?: Maybe<Feed>;
  getNftCollection?: Maybe<NftCollection>;
  getPlaybackUrl?: Maybe<Scalars['String']>;
  getPublishedVideo?: Maybe<FeedVideo>;
  getSignedVideoStoryboard?: Maybe<VideoStoryboard>;
  getStaticImageUrl?: Maybe<Scalars['String']>;
  getToken?: Maybe<Token>;
  getUploadProfileImageLink?: Maybe<ImageLink>;
  getUser?: Maybe<User>;
  getUserVideoTotalLikes?: Maybe<TotalLikesCount>;
  getVideo?: Maybe<Video>;
  getVideoFeedFromFollowed?: Maybe<PagedVideoList>;
  isUserFollowedByMe?: Maybe<Scalars['Boolean']>;
  likeVideoCounts?: Maybe<Array<Maybe<LikeVideoCount>>>;
  listLikedVideos?: Maybe<PagedVideoList>;
  listNFTs?: Maybe<PagedNftAsset>;
  listUserFollows?: Maybe<PagedUserList>;
  listUsers?: Maybe<Array<Maybe<User>>>;
  listVideos?: Maybe<PagedVideoList>;
  uploadThumbnailLink?: Maybe<ImageLink>;
  uploadVideoLink?: Maybe<Scalars['String']>;
  usernameExists?: Maybe<Scalars['Boolean']>;
};


export type QueryCheckUsernameReservationArgs = {
  uuid?: Maybe<Scalars['String']>;
};


export type QueryGenerateThumbnailFromVideoArgs = {
  end?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['String']>;
  fps?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Int']>;
};


export type QueryGetAnimatedGifUrlArgs = {
  end?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['String']>;
  fps?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Int']>;
};


export type QueryGetFeatureFlagArgs = {
  name: FeatureFlagList;
};


export type QueryGetFeedArgs = {
  feedId?: Maybe<Scalars['Int']>;
  page?: Maybe<PageRequest>;
};


export type QueryGetNftCollectionArgs = {
  externalId: Scalars['String'];
};


export type QueryGetPlaybackUrlArgs = {
  expiration?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  policy?: Maybe<Scalars['String']>;
};


export type QueryGetPublishedVideoArgs = {
  externalId: Scalars['String'];
};


export type QueryGetSignedVideoStoryboardArgs = {
  externalId?: Maybe<Scalars['String']>;
};


export type QueryGetStaticImageUrlArgs = {
  externalId?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Float']>;
};


export type QueryGetUploadProfileImageLinkArgs = {
  uploadObjectContentType?: Maybe<Scalars['String']>;
  uploadObjectType?: Maybe<Scalars['String']>;
};


export type QueryGetUserArgs = {
  username?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};


export type QueryGetUserVideoTotalLikesArgs = {
  uuid: Scalars['String'];
};


export type QueryGetVideoArgs = {
  externalId: Scalars['String'];
};


export type QueryGetVideoFeedFromFollowedArgs = {
  page?: Maybe<PageRequest>;
};


export type QueryIsUserFollowedByMeArgs = {
  uuid?: Maybe<Scalars['String']>;
};


export type QueryLikeVideoCountsArgs = {
  userUuids?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryListLikedVideosArgs = {
  page?: Maybe<PageRequest>;
  uuid: Scalars['String'];
};


export type QueryListNfTsArgs = {
  contractAddres?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  page?: Maybe<PageRequest>;
};


export type QueryListUserFollowsArgs = {
  list?: Maybe<Scalars['String']>;
  page?: Maybe<PageRequest>;
  uuid?: Maybe<Scalars['String']>;
};


export type QueryListVideosArgs = {
  page?: Maybe<PageRequest>;
  uuid?: Maybe<Scalars['String']>;
};


export type QueryUploadThumbnailLinkArgs = {
  contentType?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
};


export type QueryUploadVideoLinkArgs = {
  externalId?: Maybe<Scalars['String']>;
};


export type QueryUsernameExistsArgs = {
  username?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  onVideoUpdated?: Maybe<Video>;
};


export type SubscriptionOnVideoUpdatedArgs = {
  externalId?: Maybe<Scalars['String']>;
};

export type TagInput = {
  name?: Maybe<Scalars['String']>;
};

export type Token = {
  __typename?: 'Token';
  exp?: Maybe<Scalars['Int']>;
  iat?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type TotalLikesCount = {
  __typename?: 'TotalLikesCount';
  likesCount?: Maybe<Scalars['Int']>;
};

export type Track = {
  __typename?: 'Track';
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  max_frame_rate?: Maybe<Scalars['Float']>;
  max_height?: Maybe<Scalars['Int']>;
  max_width?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type UnlikeVideo = {
  externalId?: Maybe<Scalars['String']>;
};

export type UnlockableContent = {
  __typename?: 'UnlockableContent';
  code?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type?: Maybe<UnlockableContentTypes>;
  url: Scalars['String'];
};

export type UnlockableContentInput = {
  code?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type?: Maybe<UnlockableContentTypes>;
  url: Scalars['String'];
};

export enum UnlockableContentTypes {
  DigitalTicket = 'DIGITAL_TICKET',
  DiscordInvite = 'DISCORD_INVITE',
  ScheduledCall = 'SCHEDULED_CALL',
  SendProduct = 'SEND_PRODUCT'
}

export type UpdateUser = {
  avatar?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profilePreferences?: Maybe<ProfilePreferencesInput>;
  username?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type UpdateVideo = {
  category?: Maybe<CategoryInput>;
  description?: Maybe<Scalars['String']>;
  explicit?: Maybe<Scalars['Boolean']>;
  externalId?: Maybe<Scalars['String']>;
  tagList?: Maybe<Array<Maybe<TagInput>>>;
  tags?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type UpdateVideoFull = {
  createdOn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['String']>;
  thumbnailS3Key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<Scalars['String']>;
  userUuid?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  followedByMe?: Maybe<Scalars['Boolean']>;
  followersCount?: Maybe<Scalars['Int']>;
  followingCount?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profilePreferences?: Maybe<ProfilePreferences>;
  updatedOn?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type UsernameReservation = {
  __typename?: 'UsernameReservation';
  remainingTime?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type Video = {
  __typename?: 'Video';
  createdOn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  ipfsContentId?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['Int']>;
  plays?: Maybe<Scalars['Int']>;
  properties?: Maybe<VideoProperties>;
  tags?: Maybe<Scalars['String']>;
  thumbnailS3Key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<Scalars['String']>;
  userUuid?: Maybe<Scalars['String']>;
};

export type VideoPlayerEvent = {
  externalId?: Maybe<Scalars['String']>;
  videoPlayerEventType?: Maybe<VideoPlayerEventType>;
};

export enum VideoPlayerEventType {
  Play = 'PLAY'
}

export type VideoProperties = {
  __typename?: 'VideoProperties';
  aspect_ratio?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  master_access?: Maybe<Scalars['String']>;
  max_stored_frame_rate?: Maybe<Scalars['String']>;
  max_stored_resolution?: Maybe<Scalars['String']>;
  mp4_support?: Maybe<Scalars['String']>;
  passthrough?: Maybe<Scalars['String']>;
  playback_ids?: Maybe<Array<Maybe<PlaybackId>>>;
  status?: Maybe<Scalars['String']>;
  tracks?: Maybe<Array<Maybe<Track>>>;
};

export type VideoStoryboard = {
  __typename?: 'VideoStoryboard';
  duration?: Maybe<Scalars['Float']>;
  tile_height?: Maybe<Scalars['Int']>;
  tile_width?: Maybe<Scalars['Int']>;
  tiles?: Maybe<Array<Maybe<VideoStoryboardTile>>>;
  url?: Maybe<Scalars['String']>;
};

export type VideoStoryboardTile = {
  __typename?: 'VideoStoryboardTile';
  start?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

export type PagerFragmentFragment = { __typename?: 'Pager', count?: Maybe<number>, take?: Maybe<number>, skip?: Maybe<number> };

export type FeedPagerFragmentFragment = { __typename?: 'Feed', pager?: Maybe<{ __typename?: 'Pager', count?: Maybe<number>, take?: Maybe<number>, skip?: Maybe<number> }> };

export type VideoListPagerFragmentFragment = { __typename?: 'PagedVideoList', pager?: Maybe<{ __typename?: 'Pager', count?: Maybe<number>, take?: Maybe<number>, skip?: Maybe<number> }> };

export type VideoPropertiesFragmentFragment = { __typename?: 'VideoProperties', aspect_ratio?: Maybe<string>, created_at?: Maybe<string>, duration?: Maybe<string>, id?: Maybe<string>, master_access?: Maybe<string>, max_stored_frame_rate?: Maybe<string>, max_stored_resolution?: Maybe<string>, mp4_support?: Maybe<string>, passthrough?: Maybe<string>, status?: Maybe<string>, playback_ids?: Maybe<Array<Maybe<{ __typename?: 'PlaybackId', id?: Maybe<string>, policy?: Maybe<string> }>>>, tracks?: Maybe<Array<Maybe<{ __typename?: 'Track', duration?: Maybe<number>, id?: Maybe<string>, max_frame_rate?: Maybe<number>, max_height?: Maybe<number>, type?: Maybe<string>, max_width?: Maybe<number> }>>> };

export type VideoFragmentFragment = { __typename?: 'FeedVideo', createdOn?: Maybe<string>, description?: Maybe<string>, externalId?: Maybe<string>, playbackUrl?: Maybe<string>, plays?: Maybe<number>, likes?: Maybe<number>, tags?: Maybe<string>, thumbnailUrl?: Maybe<string>, title?: Maybe<string>, category?: Maybe<string>, formStatus?: Maybe<FormStatus>, updatedOn?: Maybe<string>, uploadId?: Maybe<string>, uploadStatus?: Maybe<string>, userUuid?: Maybe<string>, username?: Maybe<string>, avatar?: Maybe<string>, properties?: Maybe<{ __typename?: 'VideoProperties', aspect_ratio?: Maybe<string>, created_at?: Maybe<string>, duration?: Maybe<string>, id?: Maybe<string>, master_access?: Maybe<string>, max_stored_frame_rate?: Maybe<string>, max_stored_resolution?: Maybe<string>, mp4_support?: Maybe<string>, passthrough?: Maybe<string>, status?: Maybe<string>, playback_ids?: Maybe<Array<Maybe<{ __typename?: 'PlaybackId', id?: Maybe<string>, policy?: Maybe<string> }>>>, tracks?: Maybe<Array<Maybe<{ __typename?: 'Track', duration?: Maybe<number>, id?: Maybe<string>, max_frame_rate?: Maybe<number>, max_height?: Maybe<number>, type?: Maybe<string>, max_width?: Maybe<number> }>>> }> };

export type FeedBaseFragmentFragment = { __typename?: 'Feed', id?: Maybe<string>, createdOn?: Maybe<string>, updatedOn?: Maybe<string>, userUuid?: Maybe<string>, videos?: Maybe<Array<Maybe<{ __typename?: 'FeedVideo', createdOn?: Maybe<string>, description?: Maybe<string>, externalId?: Maybe<string>, playbackUrl?: Maybe<string>, plays?: Maybe<number>, likes?: Maybe<number>, tags?: Maybe<string>, thumbnailUrl?: Maybe<string>, title?: Maybe<string>, category?: Maybe<string>, formStatus?: Maybe<FormStatus>, updatedOn?: Maybe<string>, uploadId?: Maybe<string>, uploadStatus?: Maybe<string>, userUuid?: Maybe<string>, username?: Maybe<string>, avatar?: Maybe<string>, properties?: Maybe<{ __typename?: 'VideoProperties', aspect_ratio?: Maybe<string>, created_at?: Maybe<string>, duration?: Maybe<string>, id?: Maybe<string>, master_access?: Maybe<string>, max_stored_frame_rate?: Maybe<string>, max_stored_resolution?: Maybe<string>, mp4_support?: Maybe<string>, passthrough?: Maybe<string>, status?: Maybe<string>, playback_ids?: Maybe<Array<Maybe<{ __typename?: 'PlaybackId', id?: Maybe<string>, policy?: Maybe<string> }>>>, tracks?: Maybe<Array<Maybe<{ __typename?: 'Track', duration?: Maybe<number>, id?: Maybe<string>, max_frame_rate?: Maybe<number>, max_height?: Maybe<number>, type?: Maybe<string>, max_width?: Maybe<number> }>>> }> }>>> };

export type UpdateNftCollectionMutationVariables = Exact<{
  nftCollection: NftCollectionInput;
}>;


export type UpdateNftCollectionMutation = { __typename?: 'Mutation', updateNftCollection?: Maybe<{ __typename?: 'NftCollection', collectionId?: Maybe<string>, contractId?: Maybe<string>, chainId?: Maybe<number>, assetId?: Maybe<string>, creatorId?: Maybe<string>, formStatus?: Maybe<FormStatus>, tiers?: Maybe<Array<Maybe<{ __typename?: 'CollectionTiers', tierId?: Maybe<number>, name?: Maybe<string>, revenueSplit?: Maybe<number>, price?: Maybe<number>, quantity?: Maybe<number>, tokenResellPercentage?: Maybe<number>, unlockableContent?: Maybe<Array<Maybe<{ __typename?: 'UnlockableContent', type?: Maybe<UnlockableContentTypes>, url: string, code?: Maybe<string>, name: string }>>> }>>>, collaborators?: Maybe<Array<Maybe<{ __typename?: 'Collaborators', email?: Maybe<string>, approved?: Maybe<boolean> }>>> }> };

export type UploadVideoMetadataMutationVariables = Exact<{
  externalId: Scalars['String'];
}>;


export type UploadVideoMetadataMutation = { __typename?: 'Mutation', uploadVideoMetadata?: Maybe<string> };

export type CreateUserMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: Maybe<{ __typename?: 'User', name?: Maybe<string>, username?: Maybe<string>, birthday?: Maybe<string>, email?: Maybe<string>, uuid?: Maybe<string> }> };

export type UpdateUserMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  showBirthday?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: Maybe<{ __typename?: 'User', name?: Maybe<string>, username?: Maybe<string>, location?: Maybe<string>, birthday?: Maybe<string>, website?: Maybe<string>, bio?: Maybe<string>, uuid?: Maybe<string>, profilePreferences?: Maybe<{ __typename?: 'ProfilePreferences', show?: Maybe<{ __typename?: 'ProfilePreferencesShow', birthday?: Maybe<boolean> }> }> }> };

export type RemoveFollowerMutationVariables = Exact<{
  uuid?: Maybe<Scalars['String']>;
}>;


export type RemoveFollowerMutation = { __typename?: 'Mutation', removeFollower?: Maybe<boolean> };

export type FollowUserMutationVariables = Exact<{
  uuid?: Maybe<Scalars['String']>;
}>;


export type FollowUserMutation = { __typename?: 'Mutation', followUser?: Maybe<boolean> };

export type UnfollowUserMutationVariables = Exact<{
  uuid?: Maybe<Scalars['String']>;
}>;


export type UnfollowUserMutation = { __typename?: 'Mutation', unfollowUser?: Maybe<boolean> };

export type CreateFeedMutationVariables = Exact<{
  page?: Maybe<PageRequest>;
  anonymousUid?: Maybe<Scalars['String']>;
}>;


export type CreateFeedMutation = { __typename?: 'Mutation', createFeed?: Maybe<{ __typename?: 'Feed', id?: Maybe<string>, createdOn?: Maybe<string>, updatedOn?: Maybe<string>, userUuid?: Maybe<string>, videos?: Maybe<Array<Maybe<{ __typename?: 'FeedVideo', createdOn?: Maybe<string>, description?: Maybe<string>, externalId?: Maybe<string>, playbackUrl?: Maybe<string>, plays?: Maybe<number>, likes?: Maybe<number>, tags?: Maybe<string>, thumbnailUrl?: Maybe<string>, title?: Maybe<string>, category?: Maybe<string>, formStatus?: Maybe<FormStatus>, updatedOn?: Maybe<string>, uploadId?: Maybe<string>, uploadStatus?: Maybe<string>, userUuid?: Maybe<string>, username?: Maybe<string>, avatar?: Maybe<string>, properties?: Maybe<{ __typename?: 'VideoProperties', aspect_ratio?: Maybe<string>, created_at?: Maybe<string>, duration?: Maybe<string>, id?: Maybe<string>, master_access?: Maybe<string>, max_stored_frame_rate?: Maybe<string>, max_stored_resolution?: Maybe<string>, mp4_support?: Maybe<string>, passthrough?: Maybe<string>, status?: Maybe<string>, playback_ids?: Maybe<Array<Maybe<{ __typename?: 'PlaybackId', id?: Maybe<string>, policy?: Maybe<string> }>>>, tracks?: Maybe<Array<Maybe<{ __typename?: 'Track', duration?: Maybe<number>, id?: Maybe<string>, max_frame_rate?: Maybe<number>, max_height?: Maybe<number>, type?: Maybe<string>, max_width?: Maybe<number> }>>> }> }>>>, pager?: Maybe<{ __typename?: 'Pager', count?: Maybe<number>, take?: Maybe<number>, skip?: Maybe<number> }> }> };

export type CreateVideoMutationVariables = Exact<{
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
}>;


export type CreateVideoMutation = { __typename?: 'Mutation', createVideo?: Maybe<{ __typename?: 'Video', tags?: Maybe<string>, title?: Maybe<string>, description?: Maybe<string>, externalId?: Maybe<string> }> };

export type UpdateVideoMutationVariables = Exact<{
  externalId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  category?: Maybe<CategoryInput>;
}>;


export type UpdateVideoMutation = { __typename?: 'Mutation', updateVideo?: Maybe<{ __typename?: 'Video', externalId?: Maybe<string>, tags?: Maybe<string>, title?: Maybe<string>, description?: Maybe<string> }> };

export type DeleteVideoMutationVariables = Exact<{
  externalId?: Maybe<Scalars['String']>;
}>;


export type DeleteVideoMutation = { __typename?: 'Mutation', deleteVideo?: Maybe<{ __typename?: 'Video', externalId?: Maybe<string> }> };

export type LikeVideoMutationVariables = Exact<{
  externalId?: Maybe<Scalars['String']>;
}>;


export type LikeVideoMutation = { __typename?: 'Mutation', likeVideo?: Maybe<boolean> };

export type UnlikeVideoMutationVariables = Exact<{
  externalId?: Maybe<Scalars['String']>;
}>;


export type UnlikeVideoMutation = { __typename?: 'Mutation', unlikeVideo?: Maybe<boolean> };

export type GetUserQueryVariables = Exact<{
  uuid?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
}>;


export type GetUserQuery = { __typename?: 'Query', getUser?: Maybe<{ __typename?: 'User', uuid?: Maybe<string>, username?: Maybe<string>, name?: Maybe<string>, email?: Maybe<string>, phone?: Maybe<string>, birthday?: Maybe<string>, bio?: Maybe<string>, location?: Maybe<string>, website?: Maybe<string>, banner?: Maybe<string>, avatar?: Maybe<string>, followingCount?: Maybe<number>, followersCount?: Maybe<number>, followedByMe?: Maybe<boolean>, createdOn?: Maybe<string>, updatedOn?: Maybe<string>, profilePreferences?: Maybe<{ __typename?: 'ProfilePreferences', show?: Maybe<{ __typename?: 'ProfilePreferencesShow', email?: Maybe<boolean>, birthday?: Maybe<boolean> }> }> }> };

export type UsernameExistsQueryVariables = Exact<{
  username?: Maybe<Scalars['String']>;
}>;


export type UsernameExistsQuery = { __typename?: 'Query', usernameExists?: Maybe<boolean> };

export type GetUploadProfileImageLinkQueryVariables = Exact<{
  uploadObjectContentType?: Maybe<Scalars['String']>;
  uploadObjectType?: Maybe<Scalars['String']>;
}>;


export type GetUploadProfileImageLinkQuery = { __typename?: 'Query', getUploadProfileImageLink?: Maybe<{ __typename?: 'ImageLink', key?: Maybe<string>, uploadUrl?: Maybe<string> }> };

export type IsUserFollowedByMeQueryVariables = Exact<{
  uuid?: Maybe<Scalars['String']>;
}>;


export type IsUserFollowedByMeQuery = { __typename?: 'Query', isUserFollowedByMe?: Maybe<boolean> };

export type ListUserFollowsQueryVariables = Exact<{
  list: Scalars['String'];
  uuid: Scalars['String'];
  page?: Maybe<PageRequest>;
}>;


export type ListUserFollowsQuery = { __typename?: 'Query', listUserFollows?: Maybe<{ __typename?: 'PagedUserList', id?: Maybe<string>, users?: Maybe<Array<Maybe<{ __typename?: 'User', uuid?: Maybe<string>, username?: Maybe<string>, name?: Maybe<string>, avatar?: Maybe<string> }>>>, pager?: Maybe<{ __typename?: 'Pager', count?: Maybe<number>, skip?: Maybe<number>, take?: Maybe<number> }> }> };

export type CheckUsernameReservationQueryVariables = Exact<{
  uuid?: Maybe<Scalars['String']>;
}>;


export type CheckUsernameReservationQuery = { __typename?: 'Query', checkUsernameReservation?: Maybe<{ __typename?: 'UsernameReservation', username?: Maybe<string>, remainingTime?: Maybe<string> }> };

export type GetUserVideoTotalLikesQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type GetUserVideoTotalLikesQuery = { __typename?: 'Query', getUserVideoTotalLikes?: Maybe<{ __typename?: 'TotalLikesCount', likesCount?: Maybe<number> }> };

export type GetFeatureFlagQueryVariables = Exact<{
  name: FeatureFlagList;
}>;


export type GetFeatureFlagQuery = { __typename?: 'Query', getFeatureFlag?: Maybe<{ __typename?: 'FeatureFlag', name: string, status: number, value?: Maybe<string> }> };

export type UploadVideoLinkQueryVariables = Exact<{
  externalId?: Maybe<Scalars['String']>;
}>;


export type UploadVideoLinkQuery = { __typename?: 'Query', uploadVideoLink?: Maybe<string> };

export type UploadThumbnailLinkQueryVariables = Exact<{
  contentType?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
}>;


export type UploadThumbnailLinkQuery = { __typename?: 'Query', uploadThumbnailLink?: Maybe<{ __typename?: 'ImageLink', key?: Maybe<string>, uploadUrl?: Maybe<string> }> };

export type GetVideoQueryVariables = Exact<{
  externalId: Scalars['String'];
}>;


export type GetVideoQuery = { __typename?: 'Query', getVideo?: Maybe<{ __typename?: 'Video', externalId?: Maybe<string>, userUuid?: Maybe<string>, title?: Maybe<string>, description?: Maybe<string>, tags?: Maybe<string>, thumbnailS3Key?: Maybe<string>, uploadId?: Maybe<string>, uploadStatus?: Maybe<string>, createdOn?: Maybe<string>, updatedOn?: Maybe<string>, likes?: Maybe<number>, plays?: Maybe<number> }> };

export type GenerateThumbnailFromVideoQueryVariables = Exact<{
  externalId?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Float']>;
  end?: Maybe<Scalars['Float']>;
  fps?: Maybe<Scalars['Int']>;
}>;


export type GenerateThumbnailFromVideoQuery = { __typename?: 'Query', generateThumbnailFromVideo?: Maybe<boolean> };

export type GetAnimatedGifUrlQueryVariables = Exact<{
  externalId?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Float']>;
  end?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Int']>;
  fps?: Maybe<Scalars['Int']>;
}>;


export type GetAnimatedGifUrlQuery = { __typename?: 'Query', getAnimatedGifUrl?: Maybe<string> };

export type GetFeedQueryVariables = Exact<{
  feedId?: Maybe<Scalars['Int']>;
  page?: Maybe<PageRequest>;
}>;


export type GetFeedQuery = { __typename?: 'Query', getFeed?: Maybe<{ __typename?: 'Feed', id?: Maybe<string>, createdOn?: Maybe<string>, updatedOn?: Maybe<string>, userUuid?: Maybe<string>, videos?: Maybe<Array<Maybe<{ __typename?: 'FeedVideo', createdOn?: Maybe<string>, description?: Maybe<string>, externalId?: Maybe<string>, playbackUrl?: Maybe<string>, plays?: Maybe<number>, likes?: Maybe<number>, tags?: Maybe<string>, thumbnailUrl?: Maybe<string>, title?: Maybe<string>, category?: Maybe<string>, formStatus?: Maybe<FormStatus>, updatedOn?: Maybe<string>, uploadId?: Maybe<string>, uploadStatus?: Maybe<string>, userUuid?: Maybe<string>, username?: Maybe<string>, avatar?: Maybe<string>, properties?: Maybe<{ __typename?: 'VideoProperties', aspect_ratio?: Maybe<string>, created_at?: Maybe<string>, duration?: Maybe<string>, id?: Maybe<string>, master_access?: Maybe<string>, max_stored_frame_rate?: Maybe<string>, max_stored_resolution?: Maybe<string>, mp4_support?: Maybe<string>, passthrough?: Maybe<string>, status?: Maybe<string>, playback_ids?: Maybe<Array<Maybe<{ __typename?: 'PlaybackId', id?: Maybe<string>, policy?: Maybe<string> }>>>, tracks?: Maybe<Array<Maybe<{ __typename?: 'Track', duration?: Maybe<number>, id?: Maybe<string>, max_frame_rate?: Maybe<number>, max_height?: Maybe<number>, type?: Maybe<string>, max_width?: Maybe<number> }>>> }> }>>>, pager?: Maybe<{ __typename?: 'Pager', count?: Maybe<number>, take?: Maybe<number>, skip?: Maybe<number> }> }> };

export type ListVideosQueryVariables = Exact<{
  uuid?: Maybe<Scalars['String']>;
  page?: Maybe<PageRequest>;
}>;


export type ListVideosQuery = { __typename?: 'Query', listVideos?: Maybe<{ __typename?: 'PagedVideoList', id?: Maybe<string>, videos?: Maybe<Array<Maybe<{ __typename?: 'FeedVideo', createdOn?: Maybe<string>, description?: Maybe<string>, externalId?: Maybe<string>, playbackUrl?: Maybe<string>, plays?: Maybe<number>, likes?: Maybe<number>, tags?: Maybe<string>, thumbnailUrl?: Maybe<string>, title?: Maybe<string>, category?: Maybe<string>, formStatus?: Maybe<FormStatus>, updatedOn?: Maybe<string>, uploadId?: Maybe<string>, uploadStatus?: Maybe<string>, userUuid?: Maybe<string>, username?: Maybe<string>, avatar?: Maybe<string>, properties?: Maybe<{ __typename?: 'VideoProperties', aspect_ratio?: Maybe<string>, created_at?: Maybe<string>, duration?: Maybe<string>, id?: Maybe<string>, master_access?: Maybe<string>, max_stored_frame_rate?: Maybe<string>, max_stored_resolution?: Maybe<string>, mp4_support?: Maybe<string>, passthrough?: Maybe<string>, status?: Maybe<string>, playback_ids?: Maybe<Array<Maybe<{ __typename?: 'PlaybackId', id?: Maybe<string>, policy?: Maybe<string> }>>>, tracks?: Maybe<Array<Maybe<{ __typename?: 'Track', duration?: Maybe<number>, id?: Maybe<string>, max_frame_rate?: Maybe<number>, max_height?: Maybe<number>, type?: Maybe<string>, max_width?: Maybe<number> }>>> }> }>>>, pager?: Maybe<{ __typename?: 'Pager', count?: Maybe<number>, take?: Maybe<number>, skip?: Maybe<number> }> }> };

export type ListLikedVideosQueryVariables = Exact<{
  uuid: Scalars['String'];
  page?: Maybe<PageRequest>;
}>;


export type ListLikedVideosQuery = { __typename?: 'Query', listLikedVideos?: Maybe<{ __typename?: 'PagedVideoList', id?: Maybe<string>, videos?: Maybe<Array<Maybe<{ __typename?: 'FeedVideo', createdOn?: Maybe<string>, description?: Maybe<string>, externalId?: Maybe<string>, playbackUrl?: Maybe<string>, plays?: Maybe<number>, likes?: Maybe<number>, tags?: Maybe<string>, thumbnailUrl?: Maybe<string>, title?: Maybe<string>, category?: Maybe<string>, formStatus?: Maybe<FormStatus>, updatedOn?: Maybe<string>, uploadId?: Maybe<string>, uploadStatus?: Maybe<string>, userUuid?: Maybe<string>, username?: Maybe<string>, avatar?: Maybe<string>, properties?: Maybe<{ __typename?: 'VideoProperties', aspect_ratio?: Maybe<string>, created_at?: Maybe<string>, duration?: Maybe<string>, id?: Maybe<string>, master_access?: Maybe<string>, max_stored_frame_rate?: Maybe<string>, max_stored_resolution?: Maybe<string>, mp4_support?: Maybe<string>, passthrough?: Maybe<string>, status?: Maybe<string>, playback_ids?: Maybe<Array<Maybe<{ __typename?: 'PlaybackId', id?: Maybe<string>, policy?: Maybe<string> }>>>, tracks?: Maybe<Array<Maybe<{ __typename?: 'Track', duration?: Maybe<number>, id?: Maybe<string>, max_frame_rate?: Maybe<number>, max_height?: Maybe<number>, type?: Maybe<string>, max_width?: Maybe<number> }>>> }> }>>>, pager?: Maybe<{ __typename?: 'Pager', count?: Maybe<number>, take?: Maybe<number>, skip?: Maybe<number> }> }> };

export type GetVideoFeedFromFollowedQueryVariables = Exact<{
  page?: Maybe<PageRequest>;
}>;


export type GetVideoFeedFromFollowedQuery = { __typename?: 'Query', getVideoFeedFromFollowed?: Maybe<{ __typename?: 'PagedVideoList', id?: Maybe<string>, videos?: Maybe<Array<Maybe<{ __typename?: 'FeedVideo', createdOn?: Maybe<string>, description?: Maybe<string>, externalId?: Maybe<string>, playbackUrl?: Maybe<string>, plays?: Maybe<number>, likes?: Maybe<number>, tags?: Maybe<string>, thumbnailUrl?: Maybe<string>, title?: Maybe<string>, category?: Maybe<string>, formStatus?: Maybe<FormStatus>, updatedOn?: Maybe<string>, uploadId?: Maybe<string>, uploadStatus?: Maybe<string>, userUuid?: Maybe<string>, username?: Maybe<string>, avatar?: Maybe<string>, properties?: Maybe<{ __typename?: 'VideoProperties', aspect_ratio?: Maybe<string>, created_at?: Maybe<string>, duration?: Maybe<string>, id?: Maybe<string>, master_access?: Maybe<string>, max_stored_frame_rate?: Maybe<string>, max_stored_resolution?: Maybe<string>, mp4_support?: Maybe<string>, passthrough?: Maybe<string>, status?: Maybe<string>, playback_ids?: Maybe<Array<Maybe<{ __typename?: 'PlaybackId', id?: Maybe<string>, policy?: Maybe<string> }>>>, tracks?: Maybe<Array<Maybe<{ __typename?: 'Track', duration?: Maybe<number>, id?: Maybe<string>, max_frame_rate?: Maybe<number>, max_height?: Maybe<number>, type?: Maybe<string>, max_width?: Maybe<number> }>>> }> }>>>, pager?: Maybe<{ __typename?: 'Pager', count?: Maybe<number>, take?: Maybe<number>, skip?: Maybe<number> }> }> };

export type GetSignedVideoStoryboardQueryVariables = Exact<{
  externalId?: Maybe<Scalars['String']>;
}>;


export type GetSignedVideoStoryboardQuery = { __typename?: 'Query', getSignedVideoStoryboard?: Maybe<{ __typename?: 'VideoStoryboard', duration?: Maybe<number>, tile_height?: Maybe<number>, tile_width?: Maybe<number>, url?: Maybe<string>, tiles?: Maybe<Array<Maybe<{ __typename?: 'VideoStoryboardTile', start?: Maybe<number>, x?: Maybe<number>, y?: Maybe<number> }>>> }> };

export type GetPublishedVideoQueryVariables = Exact<{
  externalId: Scalars['String'];
}>;


export type GetPublishedVideoQuery = { __typename?: 'Query', getPublishedVideo?: Maybe<{ __typename?: 'FeedVideo', createdOn?: Maybe<string>, description?: Maybe<string>, externalId?: Maybe<string>, playbackUrl?: Maybe<string>, plays?: Maybe<number>, likes?: Maybe<number>, tags?: Maybe<string>, thumbnailUrl?: Maybe<string>, title?: Maybe<string>, category?: Maybe<string>, formStatus?: Maybe<FormStatus>, updatedOn?: Maybe<string>, uploadId?: Maybe<string>, uploadStatus?: Maybe<string>, userUuid?: Maybe<string>, username?: Maybe<string>, avatar?: Maybe<string>, properties?: Maybe<{ __typename?: 'VideoProperties', aspect_ratio?: Maybe<string>, created_at?: Maybe<string>, duration?: Maybe<string>, id?: Maybe<string>, master_access?: Maybe<string>, max_stored_frame_rate?: Maybe<string>, max_stored_resolution?: Maybe<string>, mp4_support?: Maybe<string>, passthrough?: Maybe<string>, status?: Maybe<string>, playback_ids?: Maybe<Array<Maybe<{ __typename?: 'PlaybackId', id?: Maybe<string>, policy?: Maybe<string> }>>>, tracks?: Maybe<Array<Maybe<{ __typename?: 'Track', duration?: Maybe<number>, id?: Maybe<string>, max_frame_rate?: Maybe<number>, max_height?: Maybe<number>, type?: Maybe<string>, max_width?: Maybe<number> }>>> }> }> };

export type GetPlaybackUrlQueryVariables = Exact<{
  externalId?: Maybe<Scalars['String']>;
}>;


export type GetPlaybackUrlQuery = { __typename?: 'Query', getPlaybackUrl?: Maybe<string> };

export type GetCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCategoriesQuery = { __typename?: 'Query', getCategories?: Maybe<Array<Maybe<{ __typename?: 'Category', id?: Maybe<number>, name?: Maybe<string> }>>> };

export const PagerFragmentFragmentDoc = gql`
    fragment PagerFragment on Pager {
  count
  take
  skip
}
    `;
export const FeedPagerFragmentFragmentDoc = gql`
    fragment FeedPagerFragment on Feed {
  pager {
    ...PagerFragment
  }
}
    ${PagerFragmentFragmentDoc}`;
export const VideoListPagerFragmentFragmentDoc = gql`
    fragment VideoListPagerFragment on PagedVideoList {
  pager {
    ...PagerFragment
  }
}
    ${PagerFragmentFragmentDoc}`;
export const VideoPropertiesFragmentFragmentDoc = gql`
    fragment VideoPropertiesFragment on VideoProperties {
  aspect_ratio
  created_at
  duration
  id
  master_access
  max_stored_frame_rate
  max_stored_resolution
  mp4_support
  passthrough
  playback_ids {
    id
    policy
  }
  status
  tracks {
    duration
    id
    max_frame_rate
    max_height
    type
    max_width
  }
}
    `;
export const VideoFragmentFragmentDoc = gql`
    fragment VideoFragment on FeedVideo {
  createdOn
  description
  externalId
  playbackUrl
  plays
  likes
  tags
  thumbnailUrl
  title
  category
  formStatus
  updatedOn
  uploadId
  uploadStatus
  userUuid
  username
  avatar
  properties {
    ...VideoPropertiesFragment
  }
}
    ${VideoPropertiesFragmentFragmentDoc}`;
export const FeedBaseFragmentFragmentDoc = gql`
    fragment FeedBaseFragment on Feed {
  id
  createdOn
  updatedOn
  userUuid
  videos {
    ...VideoFragment
  }
}
    ${VideoFragmentFragmentDoc}`;
export const UpdateNftCollectionDocument = gql`
    mutation updateNftCollection($nftCollection: NftCollectionInput!) {
  updateNftCollection(nftCollection: $nftCollection) {
    collectionId
    contractId
    chainId
    assetId
    creatorId
    formStatus
    tiers {
      tierId
      name
      revenueSplit
      price
      quantity
      tokenResellPercentage
      unlockableContent {
        type
        url
        code
        name
      }
    }
    collaborators {
      email
      approved
    }
  }
}
    `;
export type UpdateNftCollectionMutationFn = Apollo.MutationFunction<UpdateNftCollectionMutation, UpdateNftCollectionMutationVariables>;

/**
 * __useUpdateNftCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateNftCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNftCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNftCollectionMutation, { data, loading, error }] = useUpdateNftCollectionMutation({
 *   variables: {
 *      nftCollection: // value for 'nftCollection'
 *   },
 * });
 */
export function useUpdateNftCollectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNftCollectionMutation, UpdateNftCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNftCollectionMutation, UpdateNftCollectionMutationVariables>(UpdateNftCollectionDocument, options);
      }
export type UpdateNftCollectionMutationHookResult = ReturnType<typeof useUpdateNftCollectionMutation>;
export type UpdateNftCollectionMutationResult = Apollo.MutationResult<UpdateNftCollectionMutation>;
export type UpdateNftCollectionMutationOptions = Apollo.BaseMutationOptions<UpdateNftCollectionMutation, UpdateNftCollectionMutationVariables>;
export const UploadVideoMetadataDocument = gql`
    mutation uploadVideoMetadata($externalId: String!) {
  uploadVideoMetadata(externalId: $externalId)
}
    `;
export type UploadVideoMetadataMutationFn = Apollo.MutationFunction<UploadVideoMetadataMutation, UploadVideoMetadataMutationVariables>;

/**
 * __useUploadVideoMetadataMutation__
 *
 * To run a mutation, you first call `useUploadVideoMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadVideoMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadVideoMetadataMutation, { data, loading, error }] = useUploadVideoMetadataMutation({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useUploadVideoMetadataMutation(baseOptions?: Apollo.MutationHookOptions<UploadVideoMetadataMutation, UploadVideoMetadataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadVideoMetadataMutation, UploadVideoMetadataMutationVariables>(UploadVideoMetadataDocument, options);
      }
export type UploadVideoMetadataMutationHookResult = ReturnType<typeof useUploadVideoMetadataMutation>;
export type UploadVideoMetadataMutationResult = Apollo.MutationResult<UploadVideoMetadataMutation>;
export type UploadVideoMetadataMutationOptions = Apollo.BaseMutationOptions<UploadVideoMetadataMutation, UploadVideoMetadataMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($name: String, $username: String, $birthday: String, $email: String) {
  createUser(
    user: {name: $name, username: $username, birthday: $birthday, email: $email}
  ) {
    name
    username
    birthday
    email
    uuid
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      username: // value for 'username'
 *      birthday: // value for 'birthday'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($name: String, $username: String, $location: String, $birthday: String, $website: String, $bio: String, $showBirthday: Boolean) {
  updateUser(
    user: {name: $name, username: $username, location: $location, birthday: $birthday, website: $website, bio: $bio, profilePreferences: {show: {birthday: $showBirthday}}}
  ) {
    name
    username
    location
    birthday
    website
    bio
    profilePreferences {
      show {
        birthday
      }
    }
    uuid
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      username: // value for 'username'
 *      location: // value for 'location'
 *      birthday: // value for 'birthday'
 *      website: // value for 'website'
 *      bio: // value for 'bio'
 *      showBirthday: // value for 'showBirthday'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const RemoveFollowerDocument = gql`
    mutation removeFollower($uuid: String) {
  removeFollower(user: {uuid: $uuid})
}
    `;
export type RemoveFollowerMutationFn = Apollo.MutationFunction<RemoveFollowerMutation, RemoveFollowerMutationVariables>;

/**
 * __useRemoveFollowerMutation__
 *
 * To run a mutation, you first call `useRemoveFollowerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFollowerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFollowerMutation, { data, loading, error }] = useRemoveFollowerMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRemoveFollowerMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFollowerMutation, RemoveFollowerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFollowerMutation, RemoveFollowerMutationVariables>(RemoveFollowerDocument, options);
      }
export type RemoveFollowerMutationHookResult = ReturnType<typeof useRemoveFollowerMutation>;
export type RemoveFollowerMutationResult = Apollo.MutationResult<RemoveFollowerMutation>;
export type RemoveFollowerMutationOptions = Apollo.BaseMutationOptions<RemoveFollowerMutation, RemoveFollowerMutationVariables>;
export const FollowUserDocument = gql`
    mutation followUser($uuid: String) {
  followUser(user: {uuid: $uuid})
}
    `;
export type FollowUserMutationFn = Apollo.MutationFunction<FollowUserMutation, FollowUserMutationVariables>;

/**
 * __useFollowUserMutation__
 *
 * To run a mutation, you first call `useFollowUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followUserMutation, { data, loading, error }] = useFollowUserMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useFollowUserMutation(baseOptions?: Apollo.MutationHookOptions<FollowUserMutation, FollowUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FollowUserMutation, FollowUserMutationVariables>(FollowUserDocument, options);
      }
export type FollowUserMutationHookResult = ReturnType<typeof useFollowUserMutation>;
export type FollowUserMutationResult = Apollo.MutationResult<FollowUserMutation>;
export type FollowUserMutationOptions = Apollo.BaseMutationOptions<FollowUserMutation, FollowUserMutationVariables>;
export const UnfollowUserDocument = gql`
    mutation unfollowUser($uuid: String) {
  unfollowUser(user: {uuid: $uuid})
}
    `;
export type UnfollowUserMutationFn = Apollo.MutationFunction<UnfollowUserMutation, UnfollowUserMutationVariables>;

/**
 * __useUnfollowUserMutation__
 *
 * To run a mutation, you first call `useUnfollowUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowUserMutation, { data, loading, error }] = useUnfollowUserMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUnfollowUserMutation(baseOptions?: Apollo.MutationHookOptions<UnfollowUserMutation, UnfollowUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnfollowUserMutation, UnfollowUserMutationVariables>(UnfollowUserDocument, options);
      }
export type UnfollowUserMutationHookResult = ReturnType<typeof useUnfollowUserMutation>;
export type UnfollowUserMutationResult = Apollo.MutationResult<UnfollowUserMutation>;
export type UnfollowUserMutationOptions = Apollo.BaseMutationOptions<UnfollowUserMutation, UnfollowUserMutationVariables>;
export const CreateFeedDocument = gql`
    mutation createFeed($page: PageRequest, $anonymousUid: String) {
  createFeed(page: $page, anonymousUid: $anonymousUid) {
    ...FeedBaseFragment
    ...FeedPagerFragment
  }
}
    ${FeedBaseFragmentFragmentDoc}
${FeedPagerFragmentFragmentDoc}`;
export type CreateFeedMutationFn = Apollo.MutationFunction<CreateFeedMutation, CreateFeedMutationVariables>;

/**
 * __useCreateFeedMutation__
 *
 * To run a mutation, you first call `useCreateFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedMutation, { data, loading, error }] = useCreateFeedMutation({
 *   variables: {
 *      page: // value for 'page'
 *      anonymousUid: // value for 'anonymousUid'
 *   },
 * });
 */
export function useCreateFeedMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeedMutation, CreateFeedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeedMutation, CreateFeedMutationVariables>(CreateFeedDocument, options);
      }
export type CreateFeedMutationHookResult = ReturnType<typeof useCreateFeedMutation>;
export type CreateFeedMutationResult = Apollo.MutationResult<CreateFeedMutation>;
export type CreateFeedMutationOptions = Apollo.BaseMutationOptions<CreateFeedMutation, CreateFeedMutationVariables>;
export const CreateVideoDocument = gql`
    mutation createVideo($title: String, $description: String, $tags: String) {
  createVideo(video: {title: $title, description: $description, tags: $tags}) {
    tags
    title
    description
    externalId
  }
}
    `;
export type CreateVideoMutationFn = Apollo.MutationFunction<CreateVideoMutation, CreateVideoMutationVariables>;

/**
 * __useCreateVideoMutation__
 *
 * To run a mutation, you first call `useCreateVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoMutation, { data, loading, error }] = useCreateVideoMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useCreateVideoMutation(baseOptions?: Apollo.MutationHookOptions<CreateVideoMutation, CreateVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVideoMutation, CreateVideoMutationVariables>(CreateVideoDocument, options);
      }
export type CreateVideoMutationHookResult = ReturnType<typeof useCreateVideoMutation>;
export type CreateVideoMutationResult = Apollo.MutationResult<CreateVideoMutation>;
export type CreateVideoMutationOptions = Apollo.BaseMutationOptions<CreateVideoMutation, CreateVideoMutationVariables>;
export const UpdateVideoDocument = gql`
    mutation updateVideo($externalId: String, $title: String, $description: String, $tags: String, $category: CategoryInput) {
  updateVideo(
    video: {externalId: $externalId, title: $title, description: $description, tags: $tags, category: $category}
  ) {
    externalId
    tags
    title
    description
  }
}
    `;
export type UpdateVideoMutationFn = Apollo.MutationFunction<UpdateVideoMutation, UpdateVideoMutationVariables>;

/**
 * __useUpdateVideoMutation__
 *
 * To run a mutation, you first call `useUpdateVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVideoMutation, { data, loading, error }] = useUpdateVideoMutation({
 *   variables: {
 *      externalId: // value for 'externalId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      tags: // value for 'tags'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useUpdateVideoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVideoMutation, UpdateVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVideoMutation, UpdateVideoMutationVariables>(UpdateVideoDocument, options);
      }
export type UpdateVideoMutationHookResult = ReturnType<typeof useUpdateVideoMutation>;
export type UpdateVideoMutationResult = Apollo.MutationResult<UpdateVideoMutation>;
export type UpdateVideoMutationOptions = Apollo.BaseMutationOptions<UpdateVideoMutation, UpdateVideoMutationVariables>;
export const DeleteVideoDocument = gql`
    mutation deleteVideo($externalId: String) {
  deleteVideo(video: {externalId: $externalId}) {
    externalId
  }
}
    `;
export type DeleteVideoMutationFn = Apollo.MutationFunction<DeleteVideoMutation, DeleteVideoMutationVariables>;

/**
 * __useDeleteVideoMutation__
 *
 * To run a mutation, you first call `useDeleteVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVideoMutation, { data, loading, error }] = useDeleteVideoMutation({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useDeleteVideoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVideoMutation, DeleteVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVideoMutation, DeleteVideoMutationVariables>(DeleteVideoDocument, options);
      }
export type DeleteVideoMutationHookResult = ReturnType<typeof useDeleteVideoMutation>;
export type DeleteVideoMutationResult = Apollo.MutationResult<DeleteVideoMutation>;
export type DeleteVideoMutationOptions = Apollo.BaseMutationOptions<DeleteVideoMutation, DeleteVideoMutationVariables>;
export const LikeVideoDocument = gql`
    mutation likeVideo($externalId: String) {
  likeVideo(video: {externalId: $externalId})
}
    `;
export type LikeVideoMutationFn = Apollo.MutationFunction<LikeVideoMutation, LikeVideoMutationVariables>;

/**
 * __useLikeVideoMutation__
 *
 * To run a mutation, you first call `useLikeVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeVideoMutation, { data, loading, error }] = useLikeVideoMutation({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useLikeVideoMutation(baseOptions?: Apollo.MutationHookOptions<LikeVideoMutation, LikeVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LikeVideoMutation, LikeVideoMutationVariables>(LikeVideoDocument, options);
      }
export type LikeVideoMutationHookResult = ReturnType<typeof useLikeVideoMutation>;
export type LikeVideoMutationResult = Apollo.MutationResult<LikeVideoMutation>;
export type LikeVideoMutationOptions = Apollo.BaseMutationOptions<LikeVideoMutation, LikeVideoMutationVariables>;
export const UnlikeVideoDocument = gql`
    mutation unlikeVideo($externalId: String) {
  unlikeVideo(video: {externalId: $externalId})
}
    `;
export type UnlikeVideoMutationFn = Apollo.MutationFunction<UnlikeVideoMutation, UnlikeVideoMutationVariables>;

/**
 * __useUnlikeVideoMutation__
 *
 * To run a mutation, you first call `useUnlikeVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlikeVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlikeVideoMutation, { data, loading, error }] = useUnlikeVideoMutation({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useUnlikeVideoMutation(baseOptions?: Apollo.MutationHookOptions<UnlikeVideoMutation, UnlikeVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlikeVideoMutation, UnlikeVideoMutationVariables>(UnlikeVideoDocument, options);
      }
export type UnlikeVideoMutationHookResult = ReturnType<typeof useUnlikeVideoMutation>;
export type UnlikeVideoMutationResult = Apollo.MutationResult<UnlikeVideoMutation>;
export type UnlikeVideoMutationOptions = Apollo.BaseMutationOptions<UnlikeVideoMutation, UnlikeVideoMutationVariables>;
export const GetUserDocument = gql`
    query getUser($uuid: String, $username: String) {
  getUser(uuid: $uuid, username: $username) {
    uuid
    username
    name
    email
    phone
    birthday
    bio
    location
    website
    banner
    avatar
    email
    phone
    profilePreferences {
      show {
        email
        birthday
      }
    }
    followingCount
    followersCount
    followedByMe
    createdOn
    updatedOn
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const UsernameExistsDocument = gql`
    query usernameExists($username: String) {
  usernameExists(username: $username)
}
    `;

/**
 * __useUsernameExistsQuery__
 *
 * To run a query within a React component, call `useUsernameExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsernameExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsernameExistsQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUsernameExistsQuery(baseOptions?: Apollo.QueryHookOptions<UsernameExistsQuery, UsernameExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsernameExistsQuery, UsernameExistsQueryVariables>(UsernameExistsDocument, options);
      }
export function useUsernameExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsernameExistsQuery, UsernameExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsernameExistsQuery, UsernameExistsQueryVariables>(UsernameExistsDocument, options);
        }
export type UsernameExistsQueryHookResult = ReturnType<typeof useUsernameExistsQuery>;
export type UsernameExistsLazyQueryHookResult = ReturnType<typeof useUsernameExistsLazyQuery>;
export type UsernameExistsQueryResult = Apollo.QueryResult<UsernameExistsQuery, UsernameExistsQueryVariables>;
export const GetUploadProfileImageLinkDocument = gql`
    query getUploadProfileImageLink($uploadObjectContentType: String, $uploadObjectType: String) {
  getUploadProfileImageLink(
    uploadObjectContentType: $uploadObjectContentType
    uploadObjectType: $uploadObjectType
  ) {
    key
    uploadUrl
  }
}
    `;

/**
 * __useGetUploadProfileImageLinkQuery__
 *
 * To run a query within a React component, call `useGetUploadProfileImageLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadProfileImageLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadProfileImageLinkQuery({
 *   variables: {
 *      uploadObjectContentType: // value for 'uploadObjectContentType'
 *      uploadObjectType: // value for 'uploadObjectType'
 *   },
 * });
 */
export function useGetUploadProfileImageLinkQuery(baseOptions?: Apollo.QueryHookOptions<GetUploadProfileImageLinkQuery, GetUploadProfileImageLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUploadProfileImageLinkQuery, GetUploadProfileImageLinkQueryVariables>(GetUploadProfileImageLinkDocument, options);
      }
export function useGetUploadProfileImageLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUploadProfileImageLinkQuery, GetUploadProfileImageLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUploadProfileImageLinkQuery, GetUploadProfileImageLinkQueryVariables>(GetUploadProfileImageLinkDocument, options);
        }
export type GetUploadProfileImageLinkQueryHookResult = ReturnType<typeof useGetUploadProfileImageLinkQuery>;
export type GetUploadProfileImageLinkLazyQueryHookResult = ReturnType<typeof useGetUploadProfileImageLinkLazyQuery>;
export type GetUploadProfileImageLinkQueryResult = Apollo.QueryResult<GetUploadProfileImageLinkQuery, GetUploadProfileImageLinkQueryVariables>;
export const IsUserFollowedByMeDocument = gql`
    query isUserFollowedByMe($uuid: String) {
  isUserFollowedByMe(uuid: $uuid)
}
    `;

/**
 * __useIsUserFollowedByMeQuery__
 *
 * To run a query within a React component, call `useIsUserFollowedByMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUserFollowedByMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserFollowedByMeQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useIsUserFollowedByMeQuery(baseOptions?: Apollo.QueryHookOptions<IsUserFollowedByMeQuery, IsUserFollowedByMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsUserFollowedByMeQuery, IsUserFollowedByMeQueryVariables>(IsUserFollowedByMeDocument, options);
      }
export function useIsUserFollowedByMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsUserFollowedByMeQuery, IsUserFollowedByMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsUserFollowedByMeQuery, IsUserFollowedByMeQueryVariables>(IsUserFollowedByMeDocument, options);
        }
export type IsUserFollowedByMeQueryHookResult = ReturnType<typeof useIsUserFollowedByMeQuery>;
export type IsUserFollowedByMeLazyQueryHookResult = ReturnType<typeof useIsUserFollowedByMeLazyQuery>;
export type IsUserFollowedByMeQueryResult = Apollo.QueryResult<IsUserFollowedByMeQuery, IsUserFollowedByMeQueryVariables>;
export const ListUserFollowsDocument = gql`
    query listUserFollows($list: String!, $uuid: String!, $page: PageRequest) {
  listUserFollows(list: $list, uuid: $uuid, page: $page) {
    id
    users {
      uuid
      username
      name
      avatar
    }
    pager {
      count
      skip
      take
    }
  }
}
    `;

/**
 * __useListUserFollowsQuery__
 *
 * To run a query within a React component, call `useListUserFollowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserFollowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserFollowsQuery({
 *   variables: {
 *      list: // value for 'list'
 *      uuid: // value for 'uuid'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useListUserFollowsQuery(baseOptions: Apollo.QueryHookOptions<ListUserFollowsQuery, ListUserFollowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUserFollowsQuery, ListUserFollowsQueryVariables>(ListUserFollowsDocument, options);
      }
export function useListUserFollowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserFollowsQuery, ListUserFollowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUserFollowsQuery, ListUserFollowsQueryVariables>(ListUserFollowsDocument, options);
        }
export type ListUserFollowsQueryHookResult = ReturnType<typeof useListUserFollowsQuery>;
export type ListUserFollowsLazyQueryHookResult = ReturnType<typeof useListUserFollowsLazyQuery>;
export type ListUserFollowsQueryResult = Apollo.QueryResult<ListUserFollowsQuery, ListUserFollowsQueryVariables>;
export const CheckUsernameReservationDocument = gql`
    query checkUsernameReservation($uuid: String) {
  checkUsernameReservation(uuid: $uuid) {
    username
    remainingTime
  }
}
    `;

/**
 * __useCheckUsernameReservationQuery__
 *
 * To run a query within a React component, call `useCheckUsernameReservationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUsernameReservationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUsernameReservationQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCheckUsernameReservationQuery(baseOptions?: Apollo.QueryHookOptions<CheckUsernameReservationQuery, CheckUsernameReservationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckUsernameReservationQuery, CheckUsernameReservationQueryVariables>(CheckUsernameReservationDocument, options);
      }
export function useCheckUsernameReservationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckUsernameReservationQuery, CheckUsernameReservationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckUsernameReservationQuery, CheckUsernameReservationQueryVariables>(CheckUsernameReservationDocument, options);
        }
export type CheckUsernameReservationQueryHookResult = ReturnType<typeof useCheckUsernameReservationQuery>;
export type CheckUsernameReservationLazyQueryHookResult = ReturnType<typeof useCheckUsernameReservationLazyQuery>;
export type CheckUsernameReservationQueryResult = Apollo.QueryResult<CheckUsernameReservationQuery, CheckUsernameReservationQueryVariables>;
export const GetUserVideoTotalLikesDocument = gql`
    query getUserVideoTotalLikes($uuid: String!) {
  getUserVideoTotalLikes(uuid: $uuid) {
    likesCount
  }
}
    `;

/**
 * __useGetUserVideoTotalLikesQuery__
 *
 * To run a query within a React component, call `useGetUserVideoTotalLikesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserVideoTotalLikesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserVideoTotalLikesQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetUserVideoTotalLikesQuery(baseOptions: Apollo.QueryHookOptions<GetUserVideoTotalLikesQuery, GetUserVideoTotalLikesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserVideoTotalLikesQuery, GetUserVideoTotalLikesQueryVariables>(GetUserVideoTotalLikesDocument, options);
      }
export function useGetUserVideoTotalLikesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserVideoTotalLikesQuery, GetUserVideoTotalLikesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserVideoTotalLikesQuery, GetUserVideoTotalLikesQueryVariables>(GetUserVideoTotalLikesDocument, options);
        }
export type GetUserVideoTotalLikesQueryHookResult = ReturnType<typeof useGetUserVideoTotalLikesQuery>;
export type GetUserVideoTotalLikesLazyQueryHookResult = ReturnType<typeof useGetUserVideoTotalLikesLazyQuery>;
export type GetUserVideoTotalLikesQueryResult = Apollo.QueryResult<GetUserVideoTotalLikesQuery, GetUserVideoTotalLikesQueryVariables>;
export const GetFeatureFlagDocument = gql`
    query getFeatureFlag($name: FeatureFlagList!) {
  getFeatureFlag(name: $name) {
    name
    status
    value
  }
}
    `;

/**
 * __useGetFeatureFlagQuery__
 *
 * To run a query within a React component, call `useGetFeatureFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetFeatureFlagQuery(baseOptions: Apollo.QueryHookOptions<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>(GetFeatureFlagDocument, options);
      }
export function useGetFeatureFlagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>(GetFeatureFlagDocument, options);
        }
export type GetFeatureFlagQueryHookResult = ReturnType<typeof useGetFeatureFlagQuery>;
export type GetFeatureFlagLazyQueryHookResult = ReturnType<typeof useGetFeatureFlagLazyQuery>;
export type GetFeatureFlagQueryResult = Apollo.QueryResult<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>;
export const UploadVideoLinkDocument = gql`
    query uploadVideoLink($externalId: String) {
  uploadVideoLink(externalId: $externalId)
}
    `;

/**
 * __useUploadVideoLinkQuery__
 *
 * To run a query within a React component, call `useUploadVideoLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useUploadVideoLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUploadVideoLinkQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useUploadVideoLinkQuery(baseOptions?: Apollo.QueryHookOptions<UploadVideoLinkQuery, UploadVideoLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UploadVideoLinkQuery, UploadVideoLinkQueryVariables>(UploadVideoLinkDocument, options);
      }
export function useUploadVideoLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UploadVideoLinkQuery, UploadVideoLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UploadVideoLinkQuery, UploadVideoLinkQueryVariables>(UploadVideoLinkDocument, options);
        }
export type UploadVideoLinkQueryHookResult = ReturnType<typeof useUploadVideoLinkQuery>;
export type UploadVideoLinkLazyQueryHookResult = ReturnType<typeof useUploadVideoLinkLazyQuery>;
export type UploadVideoLinkQueryResult = Apollo.QueryResult<UploadVideoLinkQuery, UploadVideoLinkQueryVariables>;
export const UploadThumbnailLinkDocument = gql`
    query uploadThumbnailLink($contentType: String, $externalId: String) {
  uploadThumbnailLink(contentType: $contentType, externalId: $externalId) {
    key
    uploadUrl
  }
}
    `;

/**
 * __useUploadThumbnailLinkQuery__
 *
 * To run a query within a React component, call `useUploadThumbnailLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useUploadThumbnailLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUploadThumbnailLinkQuery({
 *   variables: {
 *      contentType: // value for 'contentType'
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useUploadThumbnailLinkQuery(baseOptions?: Apollo.QueryHookOptions<UploadThumbnailLinkQuery, UploadThumbnailLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UploadThumbnailLinkQuery, UploadThumbnailLinkQueryVariables>(UploadThumbnailLinkDocument, options);
      }
export function useUploadThumbnailLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UploadThumbnailLinkQuery, UploadThumbnailLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UploadThumbnailLinkQuery, UploadThumbnailLinkQueryVariables>(UploadThumbnailLinkDocument, options);
        }
export type UploadThumbnailLinkQueryHookResult = ReturnType<typeof useUploadThumbnailLinkQuery>;
export type UploadThumbnailLinkLazyQueryHookResult = ReturnType<typeof useUploadThumbnailLinkLazyQuery>;
export type UploadThumbnailLinkQueryResult = Apollo.QueryResult<UploadThumbnailLinkQuery, UploadThumbnailLinkQueryVariables>;
export const GetVideoDocument = gql`
    query getVideo($externalId: String!) {
  getVideo(externalId: $externalId) {
    externalId
    userUuid
    title
    description
    tags
    thumbnailS3Key
    uploadId
    uploadStatus
    createdOn
    updatedOn
    likes
    plays
  }
}
    `;

/**
 * __useGetVideoQuery__
 *
 * To run a query within a React component, call `useGetVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useGetVideoQuery(baseOptions: Apollo.QueryHookOptions<GetVideoQuery, GetVideoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVideoQuery, GetVideoQueryVariables>(GetVideoDocument, options);
      }
export function useGetVideoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideoQuery, GetVideoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVideoQuery, GetVideoQueryVariables>(GetVideoDocument, options);
        }
export type GetVideoQueryHookResult = ReturnType<typeof useGetVideoQuery>;
export type GetVideoLazyQueryHookResult = ReturnType<typeof useGetVideoLazyQuery>;
export type GetVideoQueryResult = Apollo.QueryResult<GetVideoQuery, GetVideoQueryVariables>;
export const GenerateThumbnailFromVideoDocument = gql`
    query generateThumbnailFromVideo($externalId: String, $start: Float, $end: Float, $fps: Int) {
  generateThumbnailFromVideo(
    externalId: $externalId
    start: $start
    end: $end
    fps: $fps
  )
}
    `;

/**
 * __useGenerateThumbnailFromVideoQuery__
 *
 * To run a query within a React component, call `useGenerateThumbnailFromVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateThumbnailFromVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateThumbnailFromVideoQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      fps: // value for 'fps'
 *   },
 * });
 */
export function useGenerateThumbnailFromVideoQuery(baseOptions?: Apollo.QueryHookOptions<GenerateThumbnailFromVideoQuery, GenerateThumbnailFromVideoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateThumbnailFromVideoQuery, GenerateThumbnailFromVideoQueryVariables>(GenerateThumbnailFromVideoDocument, options);
      }
export function useGenerateThumbnailFromVideoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateThumbnailFromVideoQuery, GenerateThumbnailFromVideoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateThumbnailFromVideoQuery, GenerateThumbnailFromVideoQueryVariables>(GenerateThumbnailFromVideoDocument, options);
        }
export type GenerateThumbnailFromVideoQueryHookResult = ReturnType<typeof useGenerateThumbnailFromVideoQuery>;
export type GenerateThumbnailFromVideoLazyQueryHookResult = ReturnType<typeof useGenerateThumbnailFromVideoLazyQuery>;
export type GenerateThumbnailFromVideoQueryResult = Apollo.QueryResult<GenerateThumbnailFromVideoQuery, GenerateThumbnailFromVideoQueryVariables>;
export const GetAnimatedGifUrlDocument = gql`
    query getAnimatedGifUrl($externalId: String, $start: Float, $end: Float, $width: Int, $fps: Int) {
  getAnimatedGifUrl(
    externalId: $externalId
    start: $start
    end: $end
    width: $width
    fps: $fps
  )
}
    `;

/**
 * __useGetAnimatedGifUrlQuery__
 *
 * To run a query within a React component, call `useGetAnimatedGifUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnimatedGifUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnimatedGifUrlQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      width: // value for 'width'
 *      fps: // value for 'fps'
 *   },
 * });
 */
export function useGetAnimatedGifUrlQuery(baseOptions?: Apollo.QueryHookOptions<GetAnimatedGifUrlQuery, GetAnimatedGifUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnimatedGifUrlQuery, GetAnimatedGifUrlQueryVariables>(GetAnimatedGifUrlDocument, options);
      }
export function useGetAnimatedGifUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnimatedGifUrlQuery, GetAnimatedGifUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnimatedGifUrlQuery, GetAnimatedGifUrlQueryVariables>(GetAnimatedGifUrlDocument, options);
        }
export type GetAnimatedGifUrlQueryHookResult = ReturnType<typeof useGetAnimatedGifUrlQuery>;
export type GetAnimatedGifUrlLazyQueryHookResult = ReturnType<typeof useGetAnimatedGifUrlLazyQuery>;
export type GetAnimatedGifUrlQueryResult = Apollo.QueryResult<GetAnimatedGifUrlQuery, GetAnimatedGifUrlQueryVariables>;
export const GetFeedDocument = gql`
    query getFeed($feedId: Int, $page: PageRequest) {
  getFeed(feedId: $feedId, page: $page) {
    ...FeedBaseFragment
    ...FeedPagerFragment
  }
}
    ${FeedBaseFragmentFragmentDoc}
${FeedPagerFragmentFragmentDoc}`;

/**
 * __useGetFeedQuery__
 *
 * To run a query within a React component, call `useGetFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedQuery({
 *   variables: {
 *      feedId: // value for 'feedId'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetFeedQuery(baseOptions?: Apollo.QueryHookOptions<GetFeedQuery, GetFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeedQuery, GetFeedQueryVariables>(GetFeedDocument, options);
      }
export function useGetFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedQuery, GetFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeedQuery, GetFeedQueryVariables>(GetFeedDocument, options);
        }
export type GetFeedQueryHookResult = ReturnType<typeof useGetFeedQuery>;
export type GetFeedLazyQueryHookResult = ReturnType<typeof useGetFeedLazyQuery>;
export type GetFeedQueryResult = Apollo.QueryResult<GetFeedQuery, GetFeedQueryVariables>;
export const ListVideosDocument = gql`
    query listVideos($uuid: String, $page: PageRequest) {
  listVideos(uuid: $uuid, page: $page) {
    id
    videos {
      ...VideoFragment
    }
    ...VideoListPagerFragment
  }
}
    ${VideoFragmentFragmentDoc}
${VideoListPagerFragmentFragmentDoc}`;

/**
 * __useListVideosQuery__
 *
 * To run a query within a React component, call `useListVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useListVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListVideosQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useListVideosQuery(baseOptions?: Apollo.QueryHookOptions<ListVideosQuery, ListVideosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListVideosQuery, ListVideosQueryVariables>(ListVideosDocument, options);
      }
export function useListVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListVideosQuery, ListVideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListVideosQuery, ListVideosQueryVariables>(ListVideosDocument, options);
        }
export type ListVideosQueryHookResult = ReturnType<typeof useListVideosQuery>;
export type ListVideosLazyQueryHookResult = ReturnType<typeof useListVideosLazyQuery>;
export type ListVideosQueryResult = Apollo.QueryResult<ListVideosQuery, ListVideosQueryVariables>;
export const ListLikedVideosDocument = gql`
    query listLikedVideos($uuid: String!, $page: PageRequest) {
  listLikedVideos(uuid: $uuid, page: $page) {
    id
    videos {
      ...VideoFragment
    }
    ...VideoListPagerFragment
  }
}
    ${VideoFragmentFragmentDoc}
${VideoListPagerFragmentFragmentDoc}`;

/**
 * __useListLikedVideosQuery__
 *
 * To run a query within a React component, call `useListLikedVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLikedVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLikedVideosQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useListLikedVideosQuery(baseOptions: Apollo.QueryHookOptions<ListLikedVideosQuery, ListLikedVideosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLikedVideosQuery, ListLikedVideosQueryVariables>(ListLikedVideosDocument, options);
      }
export function useListLikedVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLikedVideosQuery, ListLikedVideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLikedVideosQuery, ListLikedVideosQueryVariables>(ListLikedVideosDocument, options);
        }
export type ListLikedVideosQueryHookResult = ReturnType<typeof useListLikedVideosQuery>;
export type ListLikedVideosLazyQueryHookResult = ReturnType<typeof useListLikedVideosLazyQuery>;
export type ListLikedVideosQueryResult = Apollo.QueryResult<ListLikedVideosQuery, ListLikedVideosQueryVariables>;
export const GetVideoFeedFromFollowedDocument = gql`
    query getVideoFeedFromFollowed($page: PageRequest) {
  getVideoFeedFromFollowed(page: $page) {
    id
    videos {
      ...VideoFragment
    }
    ...VideoListPagerFragment
  }
}
    ${VideoFragmentFragmentDoc}
${VideoListPagerFragmentFragmentDoc}`;

/**
 * __useGetVideoFeedFromFollowedQuery__
 *
 * To run a query within a React component, call `useGetVideoFeedFromFollowedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoFeedFromFollowedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoFeedFromFollowedQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetVideoFeedFromFollowedQuery(baseOptions?: Apollo.QueryHookOptions<GetVideoFeedFromFollowedQuery, GetVideoFeedFromFollowedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVideoFeedFromFollowedQuery, GetVideoFeedFromFollowedQueryVariables>(GetVideoFeedFromFollowedDocument, options);
      }
export function useGetVideoFeedFromFollowedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideoFeedFromFollowedQuery, GetVideoFeedFromFollowedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVideoFeedFromFollowedQuery, GetVideoFeedFromFollowedQueryVariables>(GetVideoFeedFromFollowedDocument, options);
        }
export type GetVideoFeedFromFollowedQueryHookResult = ReturnType<typeof useGetVideoFeedFromFollowedQuery>;
export type GetVideoFeedFromFollowedLazyQueryHookResult = ReturnType<typeof useGetVideoFeedFromFollowedLazyQuery>;
export type GetVideoFeedFromFollowedQueryResult = Apollo.QueryResult<GetVideoFeedFromFollowedQuery, GetVideoFeedFromFollowedQueryVariables>;
export const GetSignedVideoStoryboardDocument = gql`
    query getSignedVideoStoryboard($externalId: String) {
  getSignedVideoStoryboard(externalId: $externalId) {
    duration
    tile_height
    tile_width
    tiles {
      start
      x
      y
    }
    url
  }
}
    `;

/**
 * __useGetSignedVideoStoryboardQuery__
 *
 * To run a query within a React component, call `useGetSignedVideoStoryboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignedVideoStoryboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignedVideoStoryboardQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useGetSignedVideoStoryboardQuery(baseOptions?: Apollo.QueryHookOptions<GetSignedVideoStoryboardQuery, GetSignedVideoStoryboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSignedVideoStoryboardQuery, GetSignedVideoStoryboardQueryVariables>(GetSignedVideoStoryboardDocument, options);
      }
export function useGetSignedVideoStoryboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSignedVideoStoryboardQuery, GetSignedVideoStoryboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSignedVideoStoryboardQuery, GetSignedVideoStoryboardQueryVariables>(GetSignedVideoStoryboardDocument, options);
        }
export type GetSignedVideoStoryboardQueryHookResult = ReturnType<typeof useGetSignedVideoStoryboardQuery>;
export type GetSignedVideoStoryboardLazyQueryHookResult = ReturnType<typeof useGetSignedVideoStoryboardLazyQuery>;
export type GetSignedVideoStoryboardQueryResult = Apollo.QueryResult<GetSignedVideoStoryboardQuery, GetSignedVideoStoryboardQueryVariables>;
export const GetPublishedVideoDocument = gql`
    query getPublishedVideo($externalId: String!) {
  getPublishedVideo(externalId: $externalId) {
    ...VideoFragment
  }
}
    ${VideoFragmentFragmentDoc}`;

/**
 * __useGetPublishedVideoQuery__
 *
 * To run a query within a React component, call `useGetPublishedVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublishedVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublishedVideoQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useGetPublishedVideoQuery(baseOptions: Apollo.QueryHookOptions<GetPublishedVideoQuery, GetPublishedVideoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublishedVideoQuery, GetPublishedVideoQueryVariables>(GetPublishedVideoDocument, options);
      }
export function useGetPublishedVideoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublishedVideoQuery, GetPublishedVideoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublishedVideoQuery, GetPublishedVideoQueryVariables>(GetPublishedVideoDocument, options);
        }
export type GetPublishedVideoQueryHookResult = ReturnType<typeof useGetPublishedVideoQuery>;
export type GetPublishedVideoLazyQueryHookResult = ReturnType<typeof useGetPublishedVideoLazyQuery>;
export type GetPublishedVideoQueryResult = Apollo.QueryResult<GetPublishedVideoQuery, GetPublishedVideoQueryVariables>;
export const GetPlaybackUrlDocument = gql`
    query getPlaybackUrl($externalId: String) {
  getPlaybackUrl(externalId: $externalId)
}
    `;

/**
 * __useGetPlaybackUrlQuery__
 *
 * To run a query within a React component, call `useGetPlaybackUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaybackUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaybackUrlQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useGetPlaybackUrlQuery(baseOptions?: Apollo.QueryHookOptions<GetPlaybackUrlQuery, GetPlaybackUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlaybackUrlQuery, GetPlaybackUrlQueryVariables>(GetPlaybackUrlDocument, options);
      }
export function useGetPlaybackUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaybackUrlQuery, GetPlaybackUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlaybackUrlQuery, GetPlaybackUrlQueryVariables>(GetPlaybackUrlDocument, options);
        }
export type GetPlaybackUrlQueryHookResult = ReturnType<typeof useGetPlaybackUrlQuery>;
export type GetPlaybackUrlLazyQueryHookResult = ReturnType<typeof useGetPlaybackUrlLazyQuery>;
export type GetPlaybackUrlQueryResult = Apollo.QueryResult<GetPlaybackUrlQuery, GetPlaybackUrlQueryVariables>;
export const GetCategoriesDocument = gql`
    query getCategories {
  getCategories {
    id
    name
  }
}
    `;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
      }
export function useGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
        }
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>;
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>;
export type GetCategoriesQueryResult = Apollo.QueryResult<GetCategoriesQuery, GetCategoriesQueryVariables>;