import React, {useState, useCallback} from "react";
import {strings} from "config/strings";
import {
  useListVideosQuery,
  useDeleteVideoMutation,
  User,
  Maybe,
  FeedVideo
} from "apollo";
import InfiniteScroll from "react-infinite-scroller";
import {Spinner} from "components/Spinner";
import {Modal} from "components/Modal";
import {PROFILE_VIDEOS_PER_PAGE} from "config/constants";
import {useHistory} from "react-router";
import VideoListItem from "components/Molecules/VideoListItem";
import {getPostedVideoUrl} from "config/routes";
import {
  Container,
  Grid,
  Error,
  Empty,
  LoaderContainer,
  CenteredP,
  ThumbContainer
} from "./ProfileVideoListStyles";

type Props = {
  isOwnProfile: boolean;
  user: User;
};

export const ProfilePosts: React.FC<Props> = ({isOwnProfile, user}: Props) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const history = useHistory();
  const {data, loading, error, refetch, fetchMore} = useListVideosQuery({
    variables: {
      uuid: user.uuid,
      page: {
        take: PROFILE_VIDEOS_PER_PAGE,
        skip: 0
      }
    }
  });

  const [deleteVideo] = useDeleteVideoMutation({
    onCompleted: () => refetch()
  });

  const handleConfirmDelete = () => {
    if (deleteId) {
      deleteVideo({variables: {externalId: deleteId}});
      setOpenConfirm(false);
      setDeleteId("");
    }
  };

  const openConfirmation = (externalId: string) => {
    if (externalId) {
      setOpenConfirm(true);
      setDeleteId(externalId);
    }
  };

  const handleCloseConfirmation = () => {
    setOpenConfirm(false);
  };

  const handleOnClick = useCallback(
    (index: number) => {
      if (data?.listVideos?.videos) {
        const externalId = data.listVideos.videos[index]?.externalId!;
        const url = getPostedVideoUrl(user.username!, externalId, index);
        history.push(url);
      }
    },
    [data?.listVideos?.videos, history, user.username]
  );

  const renderListItem = useCallback(
    (video: Maybe<FeedVideo>, index: number) => {
      return (
        <VideoListItem
          index={index}
          isOwnProfile={isOwnProfile}
          key={video?.externalId}
          onClick={handleOnClick}
          openDeleteConfirmation={openConfirmation}
          // @ts-ignore
          video={video!}
        />
      );
    },
    [handleOnClick, isOwnProfile]
  );

  const renderThumbnails = useCallback(() => {
    if (
      data &&
      data.listVideos &&
      data.listVideos.videos &&
      data.listVideos.videos.length
    ) {
      return (
        <ThumbContainer>
          {
            // @ts-ignore
            data.listVideos?.videos.map(renderListItem)
          }
        </ThumbContainer>
      );
    }
    if (isOwnProfile) {
      return <Empty>{strings.pages.profile.noPosts}</Empty>;
    }
    return <Empty>{strings.pages.profile.userNoPosts}</Empty>;
  }, [data, isOwnProfile, renderListItem]);

  const handleLoadMore = () => {
    if (!data || !data.listVideos || !data.listVideos.videos) {
      return;
    }
    if (loading) {
      return;
    }
    fetchMore({
      variables: {
        uuid: user.uuid,
        page: {
          take: PROFILE_VIDEOS_PER_PAGE,
          skip: data.listVideos.videos.length
        }
      }
    });
  };

  const hasMore = () => {
    if (
      data?.listVideos &&
      data.listVideos.pager &&
      data.listVideos.pager.count === 0
    ) {
      return false; // user has no videos
    }
    if (
      data?.listVideos &&
      data.listVideos.videos &&
      data.listVideos.pager?.count
    ) {
      const hasmore =
        data.listVideos.videos.length < data.listVideos.pager.count;
      return hasmore;
    }
    return true;
  };

  return (
    <Container>
      {loading && !data?.listVideos && <Spinner />}
      {error && <Error>{strings.pages.profile.error}</Error>}
      {!error && data && (
        <Grid>
          <InfiniteScroll
            hasMore={hasMore()}
            loadMore={handleLoadMore}
            loader={
              <LoaderContainer key={new Date().getTime()}>
                <Spinner />
              </LoaderContainer>
            }
            pageStart={1}
            threshold={100}
            useWindow={true}
          >
            {renderThumbnails()}
          </InfiniteScroll>
        </Grid>
      )}
      <Modal
        closeModalFn={handleCloseConfirmation}
        isDialog={true}
        isOpen={openConfirm}
        saveBtnLabel={strings.generic.delete}
        saveFn={handleConfirmDelete}
      >
        <CenteredP>{strings.generic.areYouSure}</CenteredP>
      </Modal>
    </Container>
  );
};
