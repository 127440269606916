import React, {useCallback, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {strings} from "config/strings";

import {useHistory} from "react-router";
import useTabAnimation from "hooks/animations/useTabAnimation";
import {User} from "apollo/types";
import Text from "components/Atoms/Text";
import {TextTypes} from "components/Atoms/Text/types";
import ProfileLikedPosts from "components/Organisms/ProfileLikedPosts";
import {ProfilePosts} from "components/Profile/ProfilePosts";

const Container = styled.div`
  min-height: 52vh;
`;

const TabsHolder = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: white;
  text-align: left;
  overflow: auto;
  position: relative;
  width: 100%;
`;

const Tab = styled.div`
  float: left;
  padding: 10px 0;
  margin-right: 32px;
  cursor: pointer;
`;

const TabUnderline = styled.div`
  background: white;
  transition: width 0.3s, left 0.3s;
  left: 47px;
  height: 2px;
  width: 0px;
  display: block;
  position: absolute;
  bottom: 0px;
`;

const CollectiblesContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
`;

type Props = {
  isOwnProfile: boolean;
  user: User;
};

enum TabTypes {
  posts = "posts",
  likedPosts = "liked_posts",
  collectibles = "collectibles"
}

const tabs = [
  {
    type: TabTypes.posts,
    label: strings.pages.profile.posts
  },
  {
    type: TabTypes.likedPosts,
    label: strings.pages.profile.likedPosts
  },
  {
    type: TabTypes.collectibles,
    label: strings.pages.profile.collectibles
  }
];

const ProfileVideos: React.FC<Props> = ({isOwnProfile, user}: Props) => {
  const history = useHistory();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const tabsHolderRef = useRef<HTMLDivElement>(null);
  const {underlineStyle, handleMouseEnter, handleMouseLeave} = useTabAnimation(
    tabsHolderRef,
    activeTabIndex
  );

  useEffect(() => {
    const state = history.location.state as any;
    if (state && state.activeTabIndex) {
      setActiveTabIndex(state.activeTabIndex);
    }
  }, []);

  useEffect(() => {
    history.replace({
      key: history.location.key,
      state: {...(history.location.state as any), activeTabIndex}
    });
  }, [activeTabIndex, history]);

  const renderList = useCallback(() => {
    switch (activeTabIndex) {
      case 0:
        return <ProfilePosts isOwnProfile={isOwnProfile} user={user} />;

      case 1:
        return <ProfileLikedPosts isOwnProfile={isOwnProfile} user={user} />;

      case 2:
        return (
          <CollectiblesContainer>
            <Text textType={TextTypes.Bodies.B1}>
              {strings.pages.profile.collectiblesComingSoon}
            </Text>
          </CollectiblesContainer>
        );

      default:
        return null;
    }
  }, [activeTabIndex, isOwnProfile, user]);

  return (
    <Container>
      <TabsHolder ref={tabsHolderRef}>
        {tabs.map((tab, index) => (
          <Tab
            aria-selected={activeTabIndex === index}
            key={tab.type}
            role="tab"
            onClick={() => setActiveTabIndex(index)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {tab.label}
          </Tab>
        ))}
        <TabUnderline style={underlineStyle} />
      </TabsHolder>
      {renderList()}
    </Container>
  );
};

export default React.memo(ProfileVideos);
