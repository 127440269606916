import styled from "styled-components";
import {accentColor, textDarkGray} from "config/styleVars";
import {Link} from "react-router-dom";

const MENU_COLOR = "rgba(0, 0, 0, 0.72)";

export const RootContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ul {
    transition-delay: 300ms;
  }

  &:hover ul {
    visibility: visible;
    opacity: 1;
    transition-delay: 0ms;
  }
`;

export const DropMenu = styled.ul`
  position: absolute;
  background: ${MENU_COLOR};
  backdrop-filter: blur(8px);
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: stretch;
  border-radius: 4px;
  right: 24px;
  top: 64px;
  min-width: 250px;
  transition: visibility 0s linear 0.3s, opacity 0.3s linear;
  visibility: hidden;
  opacity: 0;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: -12px;
    right: 9px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid ${MENU_COLOR};
  }
`;

export const UserContainer = styled.li`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 16px;
`;

export const Divider = styled.div`
  width: 100%;
  background: ${textDarkGray};
  height: 0.5px;
  opacity: 0.72;
`;

export const UserTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ProfileLink = styled(Link)`
  color: white;
  outline: none;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
`;

export const ManageProfileLink = styled(ProfileLink)`
  color: ${accentColor};
`;
