import styled, {css} from "styled-components";
import {formStyles} from "config/styleVars";
import {ReactComponent as FollowIcon} from "icons/user-plus.svg";

export const FollowIconImg = styled(FollowIcon)`
  stroke: black;
  stroke-width: 1.5px;
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  transition: all 300ms ease;
`;

export const FollowButton = styled.button<{isFollowed: boolean}>`
  height: 34px;
  display: flex;
  width: 128px;
  border-radius: 10px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  ${({isFollowed}) =>
    isFollowed
      ? css`
          border: 1px solid ${formStyles.buttonHoverBorderColor};
          background: transparent;
          color: white;

          &:hover {
            background-color: white;
            border: 1px solid #999;
            color: black;
          }
        `
      : css`
          background-color: white;
          border: none;

          &:hover {
            background-color: black;
            color: #fff;
            box-shadow: inset 0 0 1px 1px #333;
          }

          &:hover ${FollowIconImg} {
            stroke: white;
          }
        `}
`;
