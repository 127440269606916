import React from "react";
import Text from "components/Atoms/Text";
import {PossibleTextTypes} from "components/Atoms/Text/types";
import {strings} from "config/strings";
import InnerCircle from "icons/innerCircle.svg";
import OuterCircle from "icons/outerCircle.svg";
import EVerseLogo from "icons/eVerseGradient.svg";
import {
  BackgroundGradient,
  BiggerRing,
  Description,
  Divider,
  GradientText,
  Logo,
  MarketplaceText,
  Rings,
  RootContainer,
  SmallerRing,
  TitleSvg
} from "./styles";

const Marketplace: React.FC = () => {
  return (
    <RootContainer>
      <BackgroundGradient />
      <Rings>
        <SmallerRing alt="" src={InnerCircle} />
        <BiggerRing alt="" src={OuterCircle} />
      </Rings>
      <Logo alt="" src={EVerseLogo} />
      <GradientText textType={PossibleTextTypes.H2}>
        <TitleSvg />
      </GradientText>
      <Text textType={PossibleTextTypes.H1}>
        {strings.pages.marketplace.create}
      </Text>
      <Divider />
      <Description textType={PossibleTextTypes.CardTitle}>
        {strings.pages.marketplace.description}
      </Description>
      <MarketplaceText textType={PossibleTextTypes.H5}>
        {strings.pages.marketplace.marketplace}
      </MarketplaceText>
      <Text textType={PossibleTextTypes.H4}>
        {strings.pages.marketplace.comingSoon}
      </Text>
    </RootContainer>
  );
};

export default React.memo(Marketplace);
