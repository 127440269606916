export enum TierValues {
  TierThumbnail = "1tierThumbnail",
  TierVideo = "1tierVideo",
  TwoTiers = "2tiers"
}

export interface TiersDropdownDataProps {
  value: TierValues;
  label: string;
  description: string;
}
