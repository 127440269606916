import React from "react";
import {TextTypes} from "components/Atoms/Text/types";

import {TextInputFloatingLabelProps} from "./types";
import {FloatingText} from "./styles";

const TextInputFloatingLabel: React.FC<TextInputFloatingLabelProps> = ({
  isDisabled,
  isFloating,
  label
}: TextInputFloatingLabelProps) => {
  return (
    <FloatingText
      isDisabled={isDisabled}
      isFloating={isFloating}
      textType={TextTypes.Other.LinkLabelLLight}
    >
      {label}
    </FloatingText>
  );
};

export default React.memo(TextInputFloatingLabel);
