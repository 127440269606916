import React, {RefObject, useState} from "react";
import styled from "styled-components";

import {StyledInput} from "components/StyledElements/FormElements";

const SingleInput = styled(StyledInput)`
  width: 45px;
  height: 45px;
  line-height: 45px;
  float: left;
  margin-right: 5px;
  font-size: 32px;
  padding: 4px 0 0 0; // for font baseline offset
  text-align: center;
`;

type VerificationCodeInputProps = {
  fieldCount: number;
  invalid?: boolean;
  onFocus: React.FocusEventHandler<HTMLInputElement>;
  onComplete: (value: string) => void;
};

const VerificationCodeInput = ({
  fieldCount,
  invalid,
  onFocus,
  onComplete
}: VerificationCodeInputProps): JSX.Element => {
  const [values, setValues] = useState<Array<string>>([]);
  const initRefs: Array<RefObject<HTMLInputElement>> = [];
  const refs = React.useRef(initRefs);
  for (let i = 0; i < fieldCount; i++) {
    refs.current[i] = React.createRef();
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    const val = e.currentTarget.value;
    const newValues = [...values];
    newValues[index] = val;
    setValues(newValues);
    if (newValues.length === fieldCount) {
      onComplete(newValues.join(""));
    }
    if (val && refs.current[index + 1]) {
      refs.current[index + 1].current?.focus();
    }
  }

  function handlePaste(e: React.ClipboardEvent<HTMLInputElement>) {
    const val = e.clipboardData.getData("text").trim();
    const valArray = val.split("");
    if (val && val.length === fieldCount) {
      setValues(valArray);
      for (let i = 0; i < fieldCount; i++) {
        if (
          refs &&
          refs.current &&
          refs.current[i] &&
          refs.current[i].current
        ) {
          // Compiler still thinks this line is unguarded
          // @ts-ignore
          refs.current[i].current.value = valArray[i];
        }
      }
      onComplete(val);
    }
  }

  return (
    <div>
      {Array.from(Array(fieldCount), (e, i) => {
        return (
          <SingleInput
            invalid={invalid}
            key={i}
            maxLength={1}
            ref={refs.current[i]}
            onChange={(ev) => handleChange(ev, i)}
            onFocus={onFocus}
            onPaste={handlePaste}
          />
        );
      })}
    </div>
  );
};

export default VerificationCodeInput;
