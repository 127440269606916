import React, {useState} from "react";
import styled from "styled-components";
import {strings} from "config/strings";
import InputFloatLabel from "components/InputFloatLabel/InputFloatLabel";
import {validateUsername} from "pages/User/Validation";
import {USERNAME_MIN_LENGTH} from "config/constants";

import CheckIcon from "icons/24/check-green.svg";
import AlertIcon from "icons/24/alert-triangle.svg";
import LoadingIcon from "icons/24/loader.svg";
import {UsernameExistsQuery, useUsernameExistsLazyQuery} from "apollo";

const Wrapper = styled.div`
  position: relative;
`;

const Status = styled.div`
  position: absolute;
  top: 3px;
  right: 9px;
  width: 24px;
  height: 24px;
`;

const Loading = styled.img`
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

enum StatusTypes {
  null = "",
  invalid = "invalid",
  taken = "taken",
  available = "available",
  loading = "loading"
}

type Props = {
  maxChars: number;
  label?: string;
  initialValue?: string;
  invalid?: boolean;
  onFocusFn: () => void;
  onChangeFn: (value: string) => void;
  onErrorFn: (value?: string) => void;
  validateOnBlur?: boolean;
};

function UsernameInput(props: Props) {
  const [status, setStatus] = useState(StatusTypes.null);

  function handleUsernameResponse(data: UsernameExistsQuery) {
    if (data && data.usernameExists) {
      props.onErrorFn(strings.pages.onboarding.usernameTaken);
      setStatus(StatusTypes.taken);
    } else {
      setStatus(StatusTypes.available);
    }
  }

  const [usernameExists] = useUsernameExistsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data: UsernameExistsQuery) => {
      handleUsernameResponse(data);
    }
  });

  async function checkUsername(username: string) {
    await usernameExists({variables: {username}});
  }

  let checkTimeout: NodeJS.Timeout;

  const validateInput = (newVal: string) => {
    const invalid = validateUsername(newVal);

    if (!newVal || newVal.length < USERNAME_MIN_LENGTH) {
      // do/show nothing until entry is long enough
      setStatus(StatusTypes.null);
      props.onErrorFn(undefined);
    } else if (invalid) {
      setStatus(StatusTypes.invalid);
      props.onErrorFn(invalid);
    } else {
      setStatus(StatusTypes.loading);
      props.onErrorFn(undefined);
      clearTimeout(checkTimeout);
      checkTimeout = setTimeout(() => {
        checkUsername(newVal);
      }, 700);
    }
  };

  function handleUsernameChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newVal = e.currentTarget.value;
    props.onChangeFn(newVal);
    props.onErrorFn(undefined);
    validateInput(newVal);
  }

  const handleOnBlur = () => {
    if (props.initialValue && props.validateOnBlur) {
      validateInput(props.initialValue);
    }
  };

  const {invalid, label, initialValue, onFocusFn, maxChars} = props;

  return (
    <Wrapper>
      <InputFloatLabel
        initialValue={initialValue}
        invalid={invalid}
        label={!label ? strings.generic.username : label}
        maxChars={maxChars}
        prefix="@"
        type="text"
        onBlur={handleOnBlur}
        onChange={handleUsernameChange}
        onFocus={onFocusFn}
      />
      <Status>
        {status === StatusTypes.available ? (
          <img alt="username available" src={CheckIcon} />
        ) : (
          ""
        )}
        {(status === StatusTypes.taken || status === StatusTypes.invalid) &&
        invalid ? (
          <img alt="username invalid" src={AlertIcon} />
        ) : (
          ""
        )}
        {status === StatusTypes.loading ? (
          <Loading alt="loading" src={LoadingIcon} />
        ) : (
          ""
        )}
      </Status>
    </Wrapper>
  );
}

export default UsernameInput;
