import {FeedVideo, GetUserDocument} from "apollo";
import {COMMENTS_FETCH_LIMIT} from "config/constants";
import React, {useContext, useEffect} from "react";
import {StreamChat, Channel, chatCodes} from "stream-chat";
import {gql} from "@apollo/client";
import {
  getCommentsToken,
  removeCommentsToken,
  setCommentsToken
} from "utils/CommentsToken";
import {useApolloClient} from "@apollo/react-hooks";

export const GET_TOKEN = gql`
  query getToken {
    getToken {
      uuid
      token
      exp
      iat
    }
  }
`;

export const REVOKE_TOKEN = gql`
  mutation revokeToken($token: String!) {
    revokeToken(token: $token)
  }
`;

const getStreamContext = React.createContext({
  getStreamClient: StreamChat.getInstance(process.env.REACT_APP_GETSTREAM || "")
});

const useComments = () => {
  const {getStreamClient} = useContext(getStreamContext);
  const client = useApolloClient();

  async function disconnectUser() {
    await removeCommentsToken();
    await getStreamClient.disconnectUser();
  }

  useEffect(() => {
    return () => {
      disconnectUser();
    };
  }, []);

  async function connectUser(force?: boolean) {
    // we don't have to connect already connected user. Stream-chat api throws warnings.
    if (getStreamClient.userID && !force) {
      return;
    }

    const {
      data: {getUser: user}
    } = await client.query({query: GetUserDocument});

    let token = await getCommentsToken();
    if (!token || !token.exp || token.exp * 1000 < Date.now()) {
      const newTokenData = await client.query({query: GET_TOKEN});
      token = newTokenData.data.getToken;
      if (token !== null) {
        await setCommentsToken(token);
      }
    }

    await getStreamClient.connectUser(
      {
        id: user.uuid as string,
        name: user.username as string,
        avatar: user.avatar
      },
      token?.token
    );
  }

  async function getChannel(
    video: FeedVideo,
    getLatestStreamClient: StreamChat
  ) {
    await connectUser();

    if (video) {
      try {
        const channel = getLatestStreamClient.channel(
          "messaging",
          video.externalId,
          {
            name: video.title
          }
        );
        await channel.create();
        return channel;
      } catch (e: any) {
        if (e.code === chatCodes.TOKEN_EXPIRED) {
          await connectUser(true);
        }
      }
    }
    return null;
  }

  // eslint-disable-next-line consistent-return -- MAR-841
  async function queryComments(
    channel: Channel,
    beforeDate?: string,
    afterDate?: string
  ) {
    if (channel) {
      return channel.query({
        messages: {
          limit: COMMENTS_FETCH_LIMIT,
          created_at_before: beforeDate,
          created_at_after: afterDate
        }
      });
    }
  }

  const getComments = async (
    video: FeedVideo,
    beforeDate?: string,
    afterDate?: string
    // eslint-disable-next-line consistent-return -- MAR-841
  ) => {
    const channel = await getChannel(video, getStreamClient);
    if (channel) {
      const newComments = await queryComments(channel, beforeDate, afterDate);

      if (newComments?.messages) {
        return newComments.messages.reverse();
      }
    }
  };

  async function addComment(video: FeedVideo, comment: string) {
    if (video) {
      try {
        const channel = await getChannel(video, getStreamClient);
        if (channel) {
          await channel.sendMessage({text: comment});
        }
      } catch (e) {
        throw e;
      }
    }
  }

  return {getComments, addComment};
};

export default useComments;
