import {useEffect} from "react";
import {DisplayMode, displayStateVar} from "apollo/reactive";

const handleFullScreenChange = () => {
  // @ts-ignore
  if (
    !document.fullscreenElement &&
    // @ts-ignore
    !document.webkitFullscreenElement &&
    // @ts-ignore
    !document.msFullscreenElement
  ) {
    displayStateVar(DisplayMode.theater);
  }
};

export const useFullScreen = () => {
  const elem = document.getElementById("root");

  // fullscreen can be exited natively (eg: pressing escape)
  // state needs to adapt accordingly
  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return function cleanup() {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  });

  const openFullScreen = () => {
    // @ts-ignore
    if (elem && elem.requestFullscreen) {
      elem.requestFullscreen();
      // @ts-ignore
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      // @ts-ignore
      elem.webkitRequestFullscreen();
      // @ts-ignore
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      // @ts-ignore
      elem.msRequestFullscreen();
    }
  };

  const exitFullScreen = () => {
    // check if anything is fullscreened or not
    if (
      document.fullscreenElement ||
      // @ts-ignore
      document.webkitFullscreenElement ||
      // @ts-ignore
      document.msFullscreenElement
    ) {
      if (window.document.exitFullscreen) {
        window.document.exitFullscreen();
        // @ts-ignore
      } else if (window.document.webkitExitFullscreen) {
        /* Safari */
        // @ts-ignore
        window.document.webkitExitFullscreen();
        // @ts-ignore
      } else if (window.document.msExitFullscreen) {
        /* IE11 */
        // @ts-ignore
        window.document.msExitFullscreen();
      }
    }
  };

  return [openFullScreen, exitFullScreen];
};
