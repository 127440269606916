import styled from "styled-components";
import {Link} from "react-router-dom";
import {getProfileUrl} from "config/routes";

type ContainerProps = {
  size: number;
  hideBorder?: boolean;
  blackBorder?: boolean;
};

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const Container = styled.div<ContainerProps>`
  ${(props) => {
    if (props.hideBorder) {
      return "border: none;";
    }
    if (props.blackBorder) {
      return "border: 3px solid black;";
    }
    return "";
  }}
  border-radius: ${(props) => (props.size <= 30 ? "4px" : "8px")};
  background: black;
  display: inline-block;
  text-align: center;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  text-transform: uppercase;
  color: white;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  line-height: ${(props) => props.size * 0.9}px;

  a {
    color: white;
  }
`;

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

type AvatarProps = {
  size: number;
  uuid?: string;
  username?: string;
  avatarURL?: string;
  hideBorder?: boolean;
  blackBorder?: boolean;
  disableLink?: boolean;
};

function Avatar({
  blackBorder,
  hideBorder,
  size,
  avatarURL,
  username
}: AvatarProps) {
  return (
    <StyledLink
      to={getProfileUrl(username!)}
      onClick={(e) => e.stopPropagation()}
    >
      <Container blackBorder={blackBorder} hideBorder={hideBorder} size={size}>
        {avatarURL && <Image style={{backgroundImage: `url(${avatarURL})`}} />}
        {!avatarURL && username && username.charAt(0)}
      </Container>
    </StyledLink>
  );
}

export default Avatar;
