import {useHistory} from "react-router-dom";
import {useAuth} from "hooks/useAuth";
import {useCallback, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {getSignInValidators} from "utils/validators/auth";
import {IFormValues} from "components/Auth/SignInForm/types";
import {Routes} from "config/routes";
import {authErrorTranslate} from "components/Auth/AuthUtils";

export function useSignInForm() {
  const history = useHistory();
  const {signin} = useAuth();
  const [formError, setFormError] = useState<string | undefined>();
  const {
    register,
    handleSubmit,
    formState: {errors, isSubmitting}
  } = useForm<IFormValues>({
    resolver: yupResolver(getSignInValidators())
  });

  const onSubmit: SubmitHandler<IFormValues> = useCallback(
    async ({username, password}) => {
      try {
        const user = await signin(username, password);
        if (user?.username) {
          if (window.analytics && user && user.uuid) {
            window.analytics.identify(user.uuid, {
              name: user?.name,
              username: user?.username,
              birthday: user?.birthday,
              email: user?.email
            });
            window.analytics.track("Signed In", {
              username
            });
          }
          history.push(Routes.home);
        } else {
          history.push(Routes.onboarding);
        }
      } catch (error: any) {
        setFormError(authErrorTranslate(error.message));
      }
    },
    [history, signin]
  );

  const handleForgotPassword = useCallback(() => {
    history.push(Routes.forgotPassword);
  }, [history]);

  return {
    errors,
    formError,
    handleForgotPassword,
    handleSubmit,
    isSubmitting,
    onSubmit,
    register
  };
}
