export enum ModalSide {
  top,
  bottom,
  left,
  right
}

export interface SwipeableSideModalProps {
  animationDuration?: number;
  dragVelocityThreshold?: number;
  dragLengthThreshold?: number;
  handle?: string;
  isOpen: boolean;
  side: ModalSide;
  toggleModal: () => void;
}
