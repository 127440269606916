import styled from "styled-components";
import {errorRed} from "config/styleVars";

export const Container = styled.div`
  max-height: 80vh;
  min-height: 100px;
`;

export const Heading = styled.h4`
  margin-top: 0;
`;

export const Error = styled.p`
  color: ${errorRed};
  text-align: center;
`;

export const Empty = styled.div`
  text-align: center;
  padding: 40px 0;
`;

export const LoaderContainer = styled.div`
  display: block;
  position: relative;
  height: 80px;
`;

export const List = styled.div`
  overflow: auto;
  height: 60vh;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255, 0.4);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const UserItem = styled.div`
  margin-bottom: 16px;
  position: relative;
`;

export const Names = styled.div`
  display: inline-block;
  width: 215px;
  margin-bottom: 5px;
`;

export const Name = styled.div`
  float: left;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  width: 215px;
`;

export const Username = styled.div`
  float: left;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #a39ea9;
  width: 215px;
`;

export const Actions = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
`;

export const RemoveButton = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  display: inline-block;
  line-height: 32px;
  vertical-align: middle;
  padding: 0px 8px;
`;

export const AutomationQAWrapper = styled.div`
  display: inline-block;
`;
