import styled from "styled-components";
import {colors} from "theme/colors";
import {ReactComponent as CopyIcon} from "icons/universal/copy.svg";

export const RootContainer = styled.button<{hasError: boolean}>`
  cursor: pointer;
  border-radius: 40px;
  border: 2px dashed
    ${({hasError}) =>
      hasError ? colors.secondary.error : colors.primary.white};
  width: 368px;
  height: 220px;
  background: transparent;
  backdrop-filter: blur(4px);
`;

export const Copy = styled(CopyIcon)`
  fill: ${colors.primary.white};
  width: 88px;
  height: 88px;
`;
