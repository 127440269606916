import styled from "styled-components";
import Text from "components/Atoms/Text";
import {colors} from "theme/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TagsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
`;

export const TextInputContainer = styled.div`
  position: relative;
`;

export const CounterContainer = styled.div`
  position: absolute;
  right: 14px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const CounterText = styled(Text)`
  color: ${colors.primary.lightGrey};
`;
