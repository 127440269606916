import React from "react";
import {useParams} from "react-router-dom";

import {useGetUserQuery, User} from "apollo";

import ProfileEditContainer from "components/Profile/ProfileEditContainer";

const ProfileEditPage: React.FunctionComponent = () => {
  const {username} = useParams<{username: string}>();

  const {
    data: userData,
    loading,
    error
  } = useGetUserQuery({
    variables: {
      username
    }
  });

  return (
    <ProfileEditContainer
      error={error}
      isLoading={loading}
      user={userData?.getUser as User}
    />
  );
};

export default ProfileEditPage;
