import {colours} from "config/styleVars";
import {PossibleTextTypes} from "components/Atoms/Text/types";

export interface ButtonColours {
  default: string;
  hovered: string;
  clicked: string;
  disabled: string;
}

export enum IconSide {
  left,
  right
}

export interface IconTitleProps {
  iconSide: IconSide;
}

export interface ButtonStyleProps {
  borderColours?: ButtonColours;
  backgroundColours?: ButtonColours;
  fontSize: string;
  textColours?: ButtonColours;
}

export interface IconButtonProps
  extends IconTitleProps,
    Omit<ButtonStyleProps, "fontSize"> {
  className?: string;
  disabled?: boolean;
  fontSize?: string;
  onClick: () => void;
  textType?: PossibleTextTypes;
  title?: string;
}

export const BUTTON_DEFAULT_BORDER_COLOURS: ButtonColours = {
  default: colours.white,
  hovered: colours.white,
  clicked: colours.white,
  disabled: colours.darkGray
};

export const BUTTON_DEFAULT_BACKGROUND_COLOURS: ButtonColours = {
  default: colours.black,
  hovered: colours.white,
  clicked: colours.white,
  disabled: colours.lightGray
};

export const BUTTON_DEFAULT_TEXT_COLOURS: ButtonColours = {
  default: colours.white,
  hovered: colours.black,
  clicked: colours.black,
  disabled: colours.darkGray
};
