import {useCallback, useState} from "react";
import {
  ImageAttributes,
  ImageSizes,
  ImageTypes,
  ProgressiveImageAttributes
} from "./types";

const ImageSizeAttributes = {
  [ImageTypes.Thumbnail]: {
    [ImageSizes.Medium]: "_1280_720",
    [ImageSizes.Small]: "_854_480",
    [ImageSizes.Original]: ""
  },
  [ImageTypes.Avatar]: {
    [ImageSizes.Medium]: "_180_180",
    [ImageSizes.Small]: "_90_90",
    [ImageSizes.Original]: ""
  },
  [ImageTypes.Banner]: {
    [ImageSizes.Medium]: "_1280_720",
    [ImageSizes.Small]: "_854_480",
    [ImageSizes.Original]: ""
  }
};

export function useProgressiveImage({
  imageAttributes,
  onError,
  onLoad,
  shouldLoadAnimatedImage,
  url
}: {
  imageAttributes: ProgressiveImageAttributes;
  onError?: () => void;
  onLoad?: () => void;
  shouldLoadAnimatedImage: boolean;
  url: string;
}) {
  const {bigAttributes, smallAttributes, type} = imageAttributes;
  const [smallImageLoaded, setSmallImageLoaded] = useState<boolean>(false);
  const [smallImageError, setSmallImageError] = useState<boolean>(false);
  const [bigImageError, setBigImageError] = useState<boolean>(false);
  const [bigImageLoaded, setBigImageLoaded] = useState<boolean>(false);

  const getImageUrl = useCallback(
    (imageUrl: string, attributes: ImageAttributes): string => {
      if (!imageUrl) {
        return "";
      }

      const lastDot = imageUrl.lastIndexOf(".");
      const basePath = imageUrl.substring(0, lastDot);
      const fileType = imageUrl.substring(lastDot);
      const sizeAttributes = ImageSizeAttributes[type][attributes.size];
      const staticAttribute = attributes.isStatic ? "_static" : "";
      return `${basePath}${sizeAttributes}${staticAttribute}${fileType}`;
    },
    [type]
  );

  const smallImageUrl = getImageUrl(url, smallAttributes);
  const bigImageUrl = getImageUrl(url, bigAttributes);

  const onSmallImageError = useCallback(() => {
    setSmallImageError(true);
    if (!shouldLoadAnimatedImage && onError) {
      onError();
    }
  }, [onError, setSmallImageError, shouldLoadAnimatedImage]);

  const onSmallImageLoaded = useCallback(() => {
    if (onLoad) {
      onLoad();
    }
    setSmallImageLoaded(true);
  }, [onLoad]);

  const onBigImageError = useCallback(() => {
    setBigImageError(true);
    if (onError) {
      onError();
    }
  }, [onError, setBigImageError]);

  const onBigImageLoaded = useCallback(() => {
    setBigImageLoaded(true);
  }, []);

  const onBigImageLoadStart = useCallback(() => {
    setBigImageLoaded(false);
  }, []);

  const bigImageNotLoaded = !shouldLoadAnimatedImage || !bigImageLoaded;

  return {
    bigImageUrl,
    bigImageError,
    bigImageNotLoaded,
    onSmallImageError,
    onSmallImageLoaded,
    onBigImageError,
    onBigImageLoaded,
    onBigImageLoadStart,
    smallImageError,
    smallImageLoaded,
    smallImageUrl
  };
}
