import styled from "styled-components";
import {colors} from "theme/colors";
import Text from "components/Atoms/Text";
import {StyleProps} from "./types";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.primary.white};
  padding: 0px 72px;
`;

export const StyledText = styled(Text)`
  margin-bottom: 16px;
`;

export const StyledOptionText = styled(Text)<StyleProps>`
  padding: 12px 20px;
  color: ${({isDisabled, isOption, isSelected}) => {
    if (isDisabled) {
      return colors.primary.lightGrey;
    }

    if (!isOption || !isSelected) {
      return colors.primary.white;
    }

    return colors.primary.black;
  }};
`;
