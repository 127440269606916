import React from "react";
import {VIDEO_THUMBNAIL_ATTRIBUTES} from "components/Atoms/ProgressiveImage/types";
import ProgressiveImage from "components/Atoms/ProgressiveImage";
import Avatar from "components/Atoms/Avatar";
import {BorderType} from "components/Atoms/Avatar/types";
import Text from "components/Atoms/Text";
import {TextTypes, TextWights} from "components/Atoms/Text/types";
import UnlikeIcon from "icons/16/x.svg";
import {DropMenu, DropMenuItem} from "components/DropMenu";
import DeleteIcon from "icons/16/trash-2.svg";
import {strings} from "config/strings";
import ReactVisibilitySensor from "react-visibility-sensor";

import {
  ContextMenu,
  LeftMetadataBottomContainer,
  LeftMetadataContainer,
  MetadataContainer,
  MetadataText,
  ProfileLink,
  RightMetadataContainer,
  RootContainer,
  ThumbnailContainer,
  Title,
  UnlikeButton
} from "./styles";
import {useVideoListItem} from "./hooks";
import {VideoListItemProps} from "./types";

const VideoListItem: React.FC<VideoListItemProps> = ({
  index,
  isOwnProfile,
  onClick,
  onLoad,
  openDeleteConfirmation,
  openUnlikeModal,
  video
}: VideoListItemProps) => {
  const {
    avatar,
    createdOn,
    externalId,
    plays,
    properties,
    thumbnailUrl,
    title,
    username
  } = video;
  const duration = properties?.duration || "0";
  const {
    deleteOnClick,
    durationText,
    profileUrl,
    stopPropagationOnClick,
    timeText,
    unlikeOnClick,
    verticalVisibilityOffset,
    videoItemOnClick,
    viewsText
  } = useVideoListItem({
    createdOn,
    duration,
    externalId,
    index,
    onClick,
    openDeleteConfirmation,
    openUnlikeModal,
    plays,
    username,
    title
  });

  return (
    <ReactVisibilitySensor
      partialVisibility={true}
      resizeCheck={true}
      scrollCheck={true}
      scrollThrottle={16}
      offset={verticalVisibilityOffset}
    >
      {({isVisible}) => (
        <RootContainer onClick={videoItemOnClick}>
          <ThumbnailContainer>
            <ProgressiveImage
              imageAttributes={VIDEO_THUMBNAIL_ATTRIBUTES}
              url={thumbnailUrl!}
              shouldLoadAnimatedImage={isVisible}
              onLoad={onLoad}
            />
          </ThumbnailContainer>
          <MetadataContainer>
            <ProfileLink onClick={stopPropagationOnClick} to={profileUrl}>
              <Avatar
                size={42}
                avatarURL={avatar}
                borderType={BorderType.none}
              />
            </ProfileLink>
            <LeftMetadataContainer>
              <div data-cy="video-list-item-title">
                <Title
                  textType={TextTypes.Bodies.B1}
                  textWeight={TextWights.Medium}
                >
                  {title}
                </Title>
              </div>
              <LeftMetadataBottomContainer>
                <ProfileLink onClick={stopPropagationOnClick} to={profileUrl}>
                  <Text
                    textType={TextTypes.Bodies.B3}
                    textWeight={TextWights.Medium}
                  >
                    @{username}
                  </Text>
                </ProfileLink>
                {plays! > 0 && (
                  <MetadataText textType={TextTypes.Bodies.B3}>
                    {viewsText}
                  </MetadataText>
                )}
                <MetadataText textType={TextTypes.Bodies.B3}>
                  {timeText}
                </MetadataText>
              </LeftMetadataBottomContainer>
            </LeftMetadataContainer>
            <RightMetadataContainer>
              <Text
                textType={TextTypes.Bodies.B1}
                textWeight={TextWights.Medium}
              >
                {durationText}
              </Text>
            </RightMetadataContainer>
          </MetadataContainer>

          {openDeleteConfirmation && isOwnProfile && (
            <ContextMenu onClick={stopPropagationOnClick}>
              <DropMenu>
                <DropMenuItem
                  clickFn={deleteOnClick}
                  iconPath={DeleteIcon}
                  label={strings.generic.delete}
                />
              </DropMenu>
            </ContextMenu>
          )}

          {openUnlikeModal && (
            <UnlikeButton
              title={strings.generic.unlike}
              onClick={unlikeOnClick}
            >
              <img alt="Unlike icon" src={UnlikeIcon} />
            </UnlikeButton>
          )}
        </RootContainer>
      )}
    </ReactVisibilitySensor>
  );
};

export default React.memo(VideoListItem);
