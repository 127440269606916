import {generateMedia} from "styled-media-query";

export const breakpointNames = {
  mobile: "mobile",
  tablet: "tablet",
  smallDesktop: "smallDesktop",
  mediumDesktop: "mediumDesktop",
  largeDesktop: "largeDesktop"
};

export const viewportWidths = {
  [breakpointNames.mobile]: 380,
  [breakpointNames.tablet]: 780,
  [breakpointNames.smallDesktop]: 1440,
  [breakpointNames.mediumDesktop]: 2400,
  [breakpointNames.largeDesktop]: 3200
};

export const customViewports = {
  mobile: {
    name: breakpointNames.mobile,
    styles: {
      width: `${viewportWidths.mobile}px`,
      height: "822px"
    }
  },
  tablet: {
    name: breakpointNames.tablet,
    styles: {
      width: `${viewportWidths.tablet}px`,
      height: "1116px"
    }
  },
  smallDesktop: {
    name: breakpointNames.smallDesktop,
    styles: {
      width: `${viewportWidths.smallDesktop}px`,
      height: "810px"
    }
  },
  mediumDesktop: {
    name: breakpointNames.mediumDesktop,
    styles: {
      width: `${viewportWidths.mediumDesktop}px`,
      height: "1350px"
    }
  },
  largeDesktop: {
    name: breakpointNames.largeDesktop,
    styles: {
      width: `${viewportWidths.largeDesktop}px`,
      height: "1800px"
    }
  }
};

export const media = generateMedia({
  [breakpointNames.mobile]: `${viewportWidths.mobile}px`,
  [breakpointNames.tablet]: `${viewportWidths.tablet}px`,
  [breakpointNames.smallDesktop]: `${viewportWidths.smallDesktop}px`,
  [breakpointNames.mediumDesktop]: `${viewportWidths.mediumDesktop}px`,
  [breakpointNames.largeDesktop]: `${viewportWidths.largeDesktop}px`
});
