import React, {useCallback, useMemo, useState} from "react";
import {
  CellMeasurer,
  CellMeasurerCache,
  Index,
  IndexRange,
  SectionRenderedParams
} from "react-virtualized";

import {IGridCellProps, UseVirtualizedGridProps} from "./types";
import {CellContainer} from "./styles";

const keyMapper = () => 1;

export function useVirtualizedGrid<T>({
  columnBreakpoints,
  items,
  loadMoreItems,
  renderItem,
  renderPlaceholder,
  totalResults
}: UseVirtualizedGridProps<T>) {
  const [columnCount, setColumnCount] = useState<number>(1);
  const measurerCache = useMemo(() => {
    return new CellMeasurerCache({
      defaultWidth: 150,
      minWidth: 100,
      fixedWidth: true,
      minHeight: 50,
      keyMapper
    });
  }, []);

  const autoSizerOnResize = useCallback(() => {
    measurerCache.clearAll();
  }, [measurerCache]);

  const updateColumnCount = useCallback(
    (count: number) => {
      if (columnCount !== count) {
        setColumnCount(count);
      }

      return count;
    },
    [columnCount]
  );

  const calculateColumnCount = useCallback(
    (width: number) => {
      if (!columnBreakpoints?.length) {
        return updateColumnCount(1);
      }

      for (let i = 0; i < columnBreakpoints.length; i++) {
        if (width <= columnBreakpoints[i]) {
          return updateColumnCount(i + 1);
        }
      }

      return updateColumnCount(columnBreakpoints.length + 1);
    },
    [columnBreakpoints, updateColumnCount]
  );

  const cellRenderer = useCallback(
    ({columnIndex, key, parent, rowIndex, style}: IGridCellProps) => {
      if (!items) {
        return null;
      }

      const realIndex = rowIndex * columnCount + columnIndex;
      const item = items[realIndex];

      return (
        <CellMeasurer
          cache={measurerCache}
          columnIndex={columnIndex}
          key={key}
          parent={parent}
          rowIndex={rowIndex}
        >
          {({measure, registerChild}) => {
            return (
              // @ts-ignore
              <CellContainer ref={registerChild} style={style}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {item &&
                  renderItem({
                    item,
                    index: realIndex,
                    onLoad: measure
                  })}
                {!item &&
                  (realIndex >= totalResults! ? null : renderPlaceholder())}
              </CellContainer>
            );
          }}
        </CellMeasurer>
      );
    },
    [items, columnCount, measurerCache, renderItem, totalResults]
  );

  const isRowLoaded = useCallback(
    ({index}: Index) => items.length > index,
    [items.length]
  );

  const listRowCount = useMemo(() => {
    if (!totalResults) {
      return 0;
    }

    if (items.length < totalResults) {
      return Math.ceil(
        items.length / columnCount +
          Math.min(2, (totalResults - items.length) / columnCount)
      );
    }

    return Math.ceil(items.length / columnCount);
  }, [columnCount, items.length, totalResults]);

  const loadMoreRows = async (range: IndexRange) => {
    if (items?.length && loadMoreItems) {
      await loadMoreItems(range);
    }
  };

  const onSectionRendered = useCallback(
    ({
      onRowsRendered,
      renderedParams
    }: {
      onRowsRendered: (params: IndexRange) => void;
      renderedParams: SectionRenderedParams;
    }) => {
      const {columnStartIndex, columnStopIndex, rowStartIndex, rowStopIndex} =
        renderedParams;
      const startIndex = rowStartIndex * columnCount + columnStartIndex;
      const stopIndex = rowStopIndex * columnCount + columnStopIndex;
      return onRowsRendered({
        startIndex,
        stopIndex
      });
    },
    [columnCount]
  );

  return {
    autoSizerOnResize,
    calculateColumnCount,
    cellRenderer,
    columnCount,
    isRowLoaded,
    listRowCount,
    loadMoreRows,
    measurerCache,
    onSectionRendered
  };
}
