import React, {useCallback, useEffect, useState} from "react";
import {getInfo, FileInfo, TrackInfo, TrackVideoInfo} from "react-mediainfo";
import Text from "components/Atoms/Text";
import {SCRUBBER_INITIAL_END} from "config/constants";
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import {TextTypes} from "components/Atoms/Text/types";
import {Spinner} from "components/Spinner";
import {strings} from "config/strings";
import {
  GetPlaybackUrlDocument,
  GetSignedVideoStoryboardDocument,
  GetVideoDocument,
  VideoStoryboard
} from "apollo";

import {SpinnerContainer, SpinnerText, SpinnerWrapper} from "./styles";
import {ClippingToolProps, VideoInfo} from "./types";

export function useClippingTool({
  externalId,
  isVideoUploaded,
  setEndTime,
  video
}: ClippingToolProps) {
  const [videoInfo, setVideoInfo] = useState<VideoInfo>();
  const [isVideoCodecSupported, setIsVideoCodecSupported] =
    useState<boolean>(false);
  const [isVideoReady, setIsVideoReady] = useState<boolean>(false);
  const [uploadedVideoPlaybackUrl, setUploadedVideoPlaybackUrl] =
    useState<string>();
  const [uploadedVideoStoryboard, setUploadedVideoStoryboard] =
    useState<VideoStoryboard>();

  useEffect(() => {
    if (!video) {
      return;
    }

    getInfo(video).then((mediaInfo: FileInfo) => {
      const infoArray: TrackInfo[] = mediaInfo.media.track;
      for (let i = 0; i < infoArray.length; i++) {
        const item = infoArray[i];
        if (item["@type"] === "Video") {
          const trackVideoInfo = item as TrackVideoInfo;
          if (trackVideoInfo.CodecID === "avc1") {
            setIsVideoCodecSupported(true);
          }
          const width = parseInt(trackVideoInfo.Width, 10);
          const height = parseInt(trackVideoInfo.Height, 10);
          const duration = parseInt(trackVideoInfo.Duration, 10);
          if (duration < SCRUBBER_INITIAL_END) {
            setEndTime(duration);
          }
          setVideoInfo({duration, height, width});
        }
      }
    });
  }, [setEndTime, video]);

  const {data, startPolling, stopPolling} = useQuery(GetVideoDocument, {
    variables: {externalId},
    skip: !externalId || isVideoCodecSupported
  });

  const fetchStoryboardOnComplete = useCallback(
    ({
      getSignedVideoStoryboard
    }: {
      getSignedVideoStoryboard: VideoStoryboard;
    }) => {
      setUploadedVideoStoryboard(getSignedVideoStoryboard);
    },
    []
  );

  const [fetchStoryboard] = useLazyQuery(GetSignedVideoStoryboardDocument, {
    variables: {externalId},
    onCompleted: fetchStoryboardOnComplete
  });

  const getPlaybackUrlOnComplete = useCallback((getUrlData) => {
    setUploadedVideoPlaybackUrl(getUrlData?.getPlaybackUrl);
  }, []);

  const [getPlaybackUrl] = useLazyQuery(GetPlaybackUrlDocument, {
    variables: {externalId},
    onCompleted: getPlaybackUrlOnComplete
  });

  // check for video ready state from mux
  useEffect(() => {
    if (
      isVideoUploaded &&
      externalId &&
      !isVideoReady &&
      !isVideoCodecSupported
    ) {
      startPolling(1000);
    }
  }, [
    externalId,
    isVideoCodecSupported,
    isVideoReady,
    isVideoUploaded,
    startPolling
  ]);

  // wait for video ready state and grab initial state for clipping tool
  useEffect(() => {
    if (data?.getVideo?.uploadStatus === "READY") {
      stopPolling();
      setIsVideoReady(true);
      if (!isVideoCodecSupported) {
        getPlaybackUrl();
        fetchStoryboard();
      }
    }
  }, [
    data,
    fetchStoryboard,
    isVideoCodecSupported,
    getPlaybackUrl,
    stopPolling
  ]);

  const renderSpinner = useCallback(() => {
    if (videoInfo && (isVideoCodecSupported || uploadedVideoPlaybackUrl)) {
      return null;
    }

    return (
      <SpinnerContainer>
        <Text textType={TextTypes.SubHeadings.SH3}>
          {videoInfo
            ? strings.pages.upload.thumbnailProcessingHeader
            : strings.pages.upload.thumbnailGettingVideoInfo}
        </Text>
        {videoInfo && !isVideoCodecSupported && (
          <SpinnerText textType={TextTypes.Body}>
            {strings.pages.upload.thumbnailProcessing}
          </SpinnerText>
        )}
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      </SpinnerContainer>
    );
  }, [isVideoCodecSupported, uploadedVideoPlaybackUrl, videoInfo]);

  return {
    isVideoCodecSupported,
    renderSpinner,
    uploadedVideoPlaybackUrl,
    uploadedVideoStoryboard,
    videoInfo
  };
}
