import React, {PropsWithChildren} from "react";
import Text from "components/Atoms/Text";
import {TextTypes} from "components/Atoms/Text/types";
import {Button, IconContainer, RootContainer} from "./styles";
import {UserMenuItemProps} from "./types";
import {useUserMenuItem} from "./hooks";

const UserMenuItem: React.FC<PropsWithChildren<UserMenuItemProps>> = ({
  children,
  onClick,
  text,
  to
}: PropsWithChildren<UserMenuItemProps>) => {
  const {buttonOnClick} = useUserMenuItem(onClick, to);
  return (
    <Button onClick={buttonOnClick}>
      <RootContainer>
        {children && <IconContainer>{children}</IconContainer>}
        <Text textType={TextTypes.Bodies.B1}>{text}</Text>
      </RootContainer>
    </Button>
  );
};

export default React.memo(UserMenuItem);
