import styled from "styled-components";
import {ResponseMessage} from "components/StyledElements/FormElements";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: 16px 0;
`;

export const ErrorMessage = styled(ResponseMessage)`
  margin-top: 16px;
`;
