import React from "react";
import {motion, AnimatePresence} from "framer-motion";

import {TooltipProps} from "./types";
import {useTooltip} from "./hooks";
import {RootContainer, StyledText} from "./styles";
import {TextTypes} from "../Text/types";

const Tooltip: React.FC<TooltipProps> = (props: TooltipProps) => {
  const {getFloatingProps, isOpen, renderChildren, floating, strategy, x, y} =
    useTooltip(props);
  const {text, width} = props;

  return (
    <>
      {renderChildren()}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{opacity: 0, scale: 0.85}}
            animate={{opacity: 1, scale: 1}}
            exit={{opacity: 0}}
            transition={{type: "spring", damping: 20, stiffness: 300}}
            ref={floating}
            className="Tooltip"
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              zIndex: 9999
            }}
            {...getFloatingProps()}
          >
            <RootContainer width={width}>
              <StyledText textType={TextTypes.Other.LinkLabelL}>
                {text}
              </StyledText>
            </RootContainer>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default React.memo(Tooltip);
