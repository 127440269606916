import {FC, memo} from "react";
import {User} from "apollo/types";
import {shortenAddress} from "utils/Web3Utils";
import Text from "components/Atoms/Text";
import {strings} from "config/strings";
import Avatar from "components/Atoms/Avatar";
import {TextTypes} from "components/Atoms/Text/types";
import {ReactComponent as UserIcon} from "icons/32/user.svg";
import ConnectWalletButtons from "components/Molecules/ConnectWalletButtons";
import WalletMenu from "components/Molecules/WalletMenu";
import {useMyWallet} from "./hooks";
import {
  Container,
  UserContainer,
  AvatarContainer,
  BalanceContainer,
  BalanceItemContainer,
  StyledBalanceText,
  StyledTitleText
} from "./styles";

type MyWalletProps = {
  user?: User | null;
  toggleWallet?: () => void;
};

const MyWallet: FC<MyWalletProps> = ({user, toggleWallet}: MyWalletProps) => {
  const {balanceData, address, walletRef, refetchBalance} =
    useMyWallet(toggleWallet);

  return (
    <Container ref={walletRef}>
      <UserContainer>
        {address ? (
          <WalletMenu refetchBalance={refetchBalance}>
            <AvatarContainer>
              <Avatar
                avatarURL={user?.avatar}
                size={32}
                username={user?.username}
              />
              <StyledTitleText textType={TextTypes.Titles.H4}>
                {user?.name}
              </StyledTitleText>
            </AvatarContainer>
          </WalletMenu>
        ) : (
          <AvatarContainer>
            <UserIcon width={32} height={32} />
            <StyledTitleText textType={TextTypes.Titles.H4}>
              {strings.wallet.myAccount}
            </StyledTitleText>
          </AvatarContainer>
        )}
        <Text textType={TextTypes.Subtitle.L}>{shortenAddress(address)}</Text>
      </UserContainer>
      {address ? (
        <BalanceContainer>
          <BalanceItemContainer>
            <Text textType={TextTypes.SubHeadings.SH3}>
              {strings.wallet.totalBalance}
            </Text>
            <StyledBalanceText textType={TextTypes.Titles.H3}>
              {balanceData?.displayValue} {balanceData?.symbol}
            </StyledBalanceText>
          </BalanceItemContainer>
          <BalanceItemContainer>
            <Text textType={TextTypes.SubHeadings.SH3}>Add Funds</Text>
          </BalanceItemContainer>
        </BalanceContainer>
      ) : (
        <ConnectWalletButtons />
      )}
    </Container>
  );
};

export default memo(MyWallet);
