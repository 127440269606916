import React, {useCallback} from "react";
import {Routes} from "config/routes";
import {isMobile} from "react-device-detect";
import {useModal} from "hooks/useModal";

export function useTopNavigationMenuItem(route: string) {
  const {isModalOpen, toggleModal} = useModal();
  const linkOnClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (isMobile && route.includes(Routes.web3Upload)) {
        e.preventDefault();
        toggleModal();
      }
    },
    [route, toggleModal]
  );

  return {isModalOpen, linkOnClick, toggleModal};
}
