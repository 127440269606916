import React from "react";
import {strings} from "config/strings";
import {TextTypes} from "components/Atoms/Text/types";
import {InputIconSide} from "components/Atoms/TextInput/types";

import {TextInputStyled} from "./styles";
import {useAlgoliaSearchBox} from "./hooks";
import {AlgoliaSearchBoxProps, SearchBox} from "./types";

const AlgoliaSearchBox: React.FC<AlgoliaSearchBoxProps> = (
  props: AlgoliaSearchBoxProps
) => {
  const {onBlur, onFocus} = props;
  const {query, renderIcon, textOnChange} = useAlgoliaSearchBox(props);

  return (
    <TextInputStyled
      id={SearchBox}
      initialValue={query}
      onChange={textOnChange}
      onBlur={onBlur}
      onFocus={onFocus}
      placeholder={strings.search.search}
      renderIcon={renderIcon}
      iconSide={InputIconSide.both}
      textType={TextTypes.Other.ButtonM}
    />
  );
};

export default React.memo(AlgoliaSearchBox);
