import {memo} from "react";
import {strings} from "config/strings";
import Text from "components/Atoms/Text";
import {ButtonType, ButtonSize} from "components/Atoms/Button/types";
import {TextTypes} from "components/Atoms/Text/types";
import {ReactComponent as MetaMaskIcon} from "icons/24/metamask.svg";
import {ReactComponent as WalletConnectIcon} from "icons/24/walletconnect.svg";
import {useConnectWalletButtons} from "./hooks";
import {Container, ListContainer, ItemContainer, StyledButton} from "./styles";

const ConnectWalletButtons = () => {
  const {isConnecting, onMetaMaskClick, onWalletConnectClick} =
    useConnectWalletButtons();

  return (
    <Container>
      <Text textType={TextTypes.Body}>{strings.wallet.walletListText}</Text>
      <ListContainer>
        <ItemContainer>
          <StyledButton
            disabled={isConnecting}
            text={strings.wallet.metaMask}
            type={ButtonType.text}
            size={ButtonSize.m}
            onClick={onMetaMaskClick}
            renderIcon={(color, size) => (
              <MetaMaskIcon width={size} height={size} />
            )}
          />
        </ItemContainer>
        <ItemContainer>
          <StyledButton
            disabled={isConnecting}
            text={strings.wallet.walletConnect}
            type={ButtonType.text}
            size={ButtonSize.m}
            onClick={onWalletConnectClick}
            renderIcon={(color, size) => (
              <WalletConnectIcon width={size} height={size} />
            )}
          />
        </ItemContainer>
      </ListContainer>
    </Container>
  );
};

export default memo(ConnectWalletButtons);
