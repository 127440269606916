import styled from "styled-components";
import {ReactComponent as ChevronRight} from "icons/universal/chevron-right.svg";

export const RootContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Chevron = styled(ChevronRight)<{isOpen: boolean}>`
  margin-left: 4px;
  width: 24px;
  height: 24px;

  transform: rotate(${({isOpen}) => (isOpen ? -90 : 90)}deg);
  transition: transform 0.3s ease;
`;
