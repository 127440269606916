import React, {FC, memo} from "react";
import {TextTypes} from "components/Atoms/Text/types";
import {colors} from "theme/colors";

import {
  Container,
  ContentContainer,
  ItemContainer,
  IconContainer,
  StyledDescription,
  Title,
  ChildrenContainer,
  StyledInfoDialog
} from "./styles";
import {MetaItemContainerProps} from "./types";

const MetaItemContainer: FC<MetaItemContainerProps> = ({
  title,
  description,
  disabled,
  isRequired,
  icon,
  info,
  children
}: MetaItemContainerProps) => {
  return (
    <Container>
      <ItemContainer>
        {icon && <IconContainer disabled={disabled}>{icon}</IconContainer>}
        <ContentContainer>
          <Title disabled={disabled} textType={TextTypes.Titles.H4}>
            {title}
            {isRequired && <span>*</span>}
          </Title>
          <StyledDescription
            disabled={disabled}
            textType={TextTypes.Subtitle.LStrong}
          >
            {description}
            {info && (
              <StyledInfoDialog
                color={
                  disabled ? colors.primary.lightGrey40 : colors.primary.white
                }
                info={info}
              />
            )}
          </StyledDescription>
        </ContentContainer>
      </ItemContainer>
      {children && <ChildrenContainer>{children}</ChildrenContainer>}
    </Container>
  );
};

export default memo(MetaItemContainer);
