import styled from "styled-components";
import {textDarkGray} from "config/styleVars";
import Text from "components/Atoms/Text";

export type MintNFTsStepProps = {
  borderColor: string;
  className?: string;
  description: string;
  iconSource: string;
  key: string;
  stepName: string;
  title: string;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props: {borderColor: string; className?: string}) =>
    props.borderColor};
  border-radius: 8px;
  width: 308px;
  min-width: 308px;
  height: 244px;
  min-height: 244px;
  padding: 24px;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

export const TopTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
`;

export const StepName = styled(Text)`
  color: ${textDarkGray};
`;

export const Description = styled(Text)`
  color: ${textDarkGray};
  margin-top: 12px;
`;

export const IconContainer = styled.div`
  height: 53px;
  width: 53px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
`;

export const Icon = styled.img`
  width: 48px;
  height: 48px;
`;
