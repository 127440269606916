import React from "react";
import Text from "components/Atoms/Text";
import {TextTypes} from "components/Atoms/Text/types";
import {TagProps} from "./types";
import {Container, XIcon} from "./styles";
import {useTag} from "./hooks";

const Tag: React.FC<TagProps> = (props: TagProps) => {
  const {tag} = props;
  const {deleteOnClick} = useTag(props);

  return (
    <Container onClick={deleteOnClick}>
      <Text textType={TextTypes.Other.ButtonMLight}>#{tag}</Text>
      <XIcon />
    </Container>
  );
};

export default React.memo(Tag);
