import React from "react";
import {TextTypes} from "components/Atoms/Text/types";
import {UploadSuccessNavigationItemProps} from "./types";
import {Container, GrayText, LinkButton, TextContainer} from "./styles";

const UploadSuccessNavigationItem: React.FC<UploadSuccessNavigationItemProps> =
  ({
    button,
    caption,
    className,
    icon,
    onClick
  }: UploadSuccessNavigationItemProps) => {
    return (
      <Container className={className}>
        <img alt="" src={icon} />
        <TextContainer>
          <GrayText textType={TextTypes.SubHeadings.SH3}>{caption}</GrayText>
          <LinkButton variant="link" onClick={onClick}>
            {button}
          </LinkButton>
        </TextContainer>
      </Container>
    );
  };

export default React.memo(UploadSuccessNavigationItem);
