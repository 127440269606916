import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "reportWebVitals";
import Amplify from "aws-amplify";

import {App} from "App";
import Modal from "react-modal";
import awsconfig from "./aws-exports";

import "index.css";
import {setMomentRelativeTimeSetting} from "./setMoment";

Amplify.configure(awsconfig);
Modal.setAppElement("#root");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
setMomentRelativeTimeSetting();
